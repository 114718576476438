import React, { Fragment, useRef, useState } from "react";
import { Col, Input, Row, Spin, Switch } from "antd";
import { toastError } from "helpers/toasters";
import SecondaryEditor from "modules/SubComponents/Editor/SecondaryEditor";
import { getAllVideosDetailsForHeader } from "Network";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { baseUrl } from "services";
import Hls from "hls.js";
import uploadFileBlueIcon from "assets/icons/uploadAudioBlueIcon.svg";
import downloadIcon from "assets/icons/downloadIcon.svg";
import { addTokenToMedia } from "helpers/addTokenToMedia";

function ViewEpisodeM3u8({ headerId, seasonId, episodeId }) {
  const [loading, setLoading] = useState(false);
  const [selectedHeaderId, setSelectedHeaderId] = useState(null);
  const [selectedSeasonId, setSelectedSeasonId] = useState(null);
  const [selectedEpisodeId, setSelectedEpisodeId] = useState(null);
  const [allHeaderDataWithVideos, setAllHeaderDataWithVideos] = useState(null);
  const [selectedEpisodeData, setSelectedEpisodeData] = useState(null);
  //   const [videoQualityName, setVideoQualityName] = useState(null);

  const { videoQualitiesOnSystem } = useSelector(
    (state) => state?.staticData?.staticDataOnSystem
  );

  useEffect(() => {
    if (headerId?.length > 0 && seasonId?.length > 0 && episodeId?.length > 0) {
      setLoading(true);
      setSelectedHeaderId(headerId);
      setSelectedSeasonId(seasonId);
      setSelectedEpisodeId(episodeId);
    }
  }, [headerId, seasonId, episodeId]);

  useEffect(() => {
    if (selectedHeaderId?.length > 0 && selectedSeasonId?.length > 0) {
      getAllVideosDetailsForHeader(
        {
          videoHeaderId: selectedHeaderId,
          seasonId: selectedSeasonId,
        },
        (success) => {
          if (success?.isSuccess) {
            setAllHeaderDataWithVideos(success.data[0]);
          }
        },
        (fail) => {
          toastError(fail.data.err_msg);
        }
      );
    }
  }, [selectedHeaderId, selectedSeasonId]);

  useEffect(() => {
    if (
      allHeaderDataWithVideos?.videos?.length > 0 &&
      selectedEpisodeId?.length > 0
    ) {
      const selectedData = allHeaderDataWithVideos.videos.filter(
        (episodeData) => episodeData?.episodePartId === selectedEpisodeId
      );
      setSelectedEpisodeData(selectedData[0]);
      setLoading(false);
    }
  }, [allHeaderDataWithVideos, selectedEpisodeId]);

  useEffect(() => {
    if (Hls.isSupported() && selectedEpisodeData?.path) {
      const hlsInstance = new Hls();
      hlsInstance.loadSource(
        addTokenToMedia(baseUrl + selectedEpisodeData.path)
      );
      hlsInstance.attachMedia(document.getElementById(selectedEpisodeData?.id));
    }
  }, [selectedEpisodeData]);

  //   useEffect(() => {
  //     if (selectedEpisodeData?.qualityId?.length > 0) {
  //       const videoQualityObj = videoQualitiesOnSystem.filter(
  //         (vidQuality) => vidQuality?._id === selectedEpisodeData?.qualityId
  //       );
  //       setVideoQualityName(videoQualityObj[0]);
  //     }
  //     //eslint-disable-next-line
  //   }, [selectedEpisodeData]);

  const handleDownloadFile = (filePath, fileName, fileType) => {
    const a = Object.assign(document.createElement("a"), {
      href: filePath,
      style: "display:none",
      download: `${fileName}.${fileType}`,
    });
    document.body.appendChild(a);
    a.click();
    a.remove();
  };
  const handleDataFromTextEditor = (descriptionEn, descriptionAr) => {};

  return (
    <Spin spinning={loading}>
      <div className="add-episodes-to-tvshows wd-100 mrg-y-30 pad-y-30 ">
        <div className=" wd-100 pad-x-10 ">
          <Row>
            <Col>
              <label className="view-tvshow-lbl-main mrg-ye-20">
                {selectedEpisodeData?.episodePart}
              </label>
            </Col>
          </Row>

          <Row gutter={16} className="mrg-ye-20">
            {selectedEpisodeData?.imgPath && (
              <>
                <Col xs={24} md={8}>
                  <div className="upload-files">
                    <div className="upload-files__container relative">
                      <figure>
                        <img
                          className="upload-files__upload-file"
                          src={baseUrl + selectedEpisodeData?.imgPath}
                          alt="actorImage"
                        />
                      </figure>
                    </div>
                  </div>
                </Col>
                <Col xs={24} md={16}>
                  <div className="upload-files">
                    <div className="upload-files__container relative">
                      {selectedEpisodeData?.videoPath && (
                        <video
                          id={selectedEpisodeData?.id}
                          width="100%"
                          height="100%"
                          controls
                          muted
                        ></video>
                      )}
                    </div>
                  </div>
                </Col>
              </>
            )}
          </Row>
          <Row>
            <Col>
              <label className="view-tvshow-lbl-main ">Description</label>
            </Col>
          </Row>
          <Row className="mrg-ye-20">
            <Col span={24}>
              <div className="wd-100 mrg-x-auto  mrg-ye-20 ">
                {/* <span className="title-style">Description</span> */}
                <SecondaryEditor
                  className="d-none"
                  mode="view"
                  putDescriptionEn={selectedEpisodeData?.description?.en}
                  putDescriptionAr={selectedEpisodeData?.description?.ar}
                  getTextEditorData={(descriptionEn, descriptionAr) =>
                    handleDataFromTextEditor(descriptionEn, descriptionAr)
                  }
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={24} className="mrg-ye-20">
              <div className="new-actor__name new-category ">
                <label className="view-tvshow-lbl-main" htmlFor="">
                  Episode title
                </label>
                <Input
                  type="text"
                  className="search__searchField py-2"
                  value={selectedEpisodeData?.title}
                ></Input>
              </div>
            </Col>
          </Row>
          <Row gutter={36} className="mrg-ye-20">
            <Col xs={24} md={12} className="mrg-ye-10">
              <div className="new-category  ">
                <label className="view-tvshow-lbl-main ">
                  Year of production
                </label>
                <Input
                  type={"number"}
                  className="search__searchField py-2"
                  value={selectedEpisodeData?.yearOfProduction}
                ></Input>
              </div>
            </Col>
            {/* <Col xs={24} md={12} className="mrg-ye-10">
              <div className="new-category">
                <label className="view-tvshow-lbl-main">Quality</label>
                <Input
                  className="search__searchField py-2"
                  value={videoQualityName?.videoQuality}
                ></Input>
              </div>
            </Col> */}
          </Row>
          <Row justify="start">
            <Col>
              <label className="view-tvshow-lbl-main ">Free</label>
            </Col>
          </Row>
          <Row justify="start">
            <Col span={10}>
              <p>User can watch this video without subscription</p>
            </Col>
            <Col>
              <Switch
                style={{
                  backgroundColor: "#31AA4A",
                }}
                checked={selectedEpisodeData?.isFree}

                // // Reflect the state of the 'isFree' variable
                // onChange={(checked) =>
                //   handleFreeVideoChange(checked, partIndex)
                // } // Toggle the 'isFree' state on change
              />
            </Col>
          </Row>
        </div>
      </div>
    </Spin>
  );
}

export default ViewEpisodeM3u8;
