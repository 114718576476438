import React, { Fragment, useEffect, useRef, useState } from "react";
import { Col, Form, Input, Progress, Row, Select, Switch } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { baseUrl } from "services";
import { toastError, toastSuccess } from "helpers/toasters";
import uploadImageIcon from "assets/icons/upImageIcon.svg";
import uploadButton from "assets/icons/uploadButton.svg";
import editIcon from "assets/icons/pen.svg";
import SubHeader from "modules/SubComponents/SubHeader";
import PlusButton from "modules/SubComponents/Buttons/PlusButton";
import PrimaryButton from "modules/SubComponents/Buttons/PrimaryButton";
import SecondaryEditor from "modules/SubComponents/Editor/SecondaryEditor";
import {
  customizeVideoDuration,
  getUnusedFilesForEpisode,
} from "helpers/helpfulFunctions";
import { addEpisodesToHeader, removeUnUsedFiles } from "Network";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import ROUTES from "constants/NewRoutes";
import { dropdownStyle } from "helpers/helpfulFunctions";
import DraggerUploader from "modules/SubComponents/Upload/DraggerUploader";
import FolderUploader from "modules/SubComponents/Upload/FolderUploader";
import { duration } from "moment";

function AddPartM3u8({
  headerAddedId,
  audioList,
  subtitleList,
  partsCastList,
  genreId,
  genreName,
  partsCount,
}) {
  const { partsOnSystem, videoQualitiesOnSystem } = useSelector(
    (state) => state?.staticData?.staticDataOnSystem
  );
  const location = useLocation();
  const [isUploadingNow, setIsUploadingNow] = useState(false);
  const [isFree, setIsFree] = useState(false);
  const [episodes, setEpisodes] = useState([
    {
      videoHeaderId: headerAddedId,
      isSeries: false,
      seriesPartNoId: partsOnSystem[0].indexId,
      title: "",
      imgPath: "",
      path: "",
      lowQualityVideoPath: "",
      isUploading: false,
      videoOptions: {},
      isFree: false,
      yearOfProduction: "",
      // qualityId: "",
      duration: "",
      "description.en": "",
      "description.ar": "",

      episodeErrors: [
        { hasError: false, error: "Please provide part title" },
        { hasError: false, error: "Please select part" },
        {
          hasError: false,
          error: "Please provide years of production",
        },
        { hasError: false, error: "Please select video quality" },
        {
          hasError: false,
          error: "Please upload an image ",
          progressStatus: false,
          progressPercent: "",
        },
        {
          hasError: false,
          error: "Please upload a video ",
          progressStatus: false,
          progressPercent: "",
        },
        {
          hasError: false,
          error: "Please provide arabic and english description",
        },
      ],
    },
  ]);

  const [videoPath, setVideoPath] = useState("");

  const [selectedEpisodeToUpload, setSelectedEpisodeToUpload] = useState(null);

  const [loading, setLoading] = useState(false);
  const [oldSubtitles, setOldSubtitles] = useState(null);
  const [oldAudios, setOldAudios] = useState(null);

  const [isUploading, setIsUploading] = useState(false);

  const folderUploaderRef = useRef(null);

  const handleFreeVideoChange = (isChecked, episodeIndex) => {
    let allEpisodes = [...episodes];
    // let pattern = /^\d|^$/;
    // if (pattern.test(e.target.value)) {
    allEpisodes[episodeIndex].isFree = isChecked;
    allEpisodes[episodeIndex].episodeErrors[2].hasError = false;
    setEpisodes(allEpisodes);
    setIsFree(isChecked);
  };

  const navigate = useNavigate();

  useEffect(() => {
    if (partsCount > episodes?.length) {
      let numOfAddedEpisodes = partsCount - episodes.length;
      handleAddAnotherEpisode(numOfAddedEpisodes);
    } //eslint-disable-next-line
  }, [partsCount]);

  useEffect(() => {
    const isUploading = episodes?.some(
      (episode) => episode?.isUploading === true
    );
    if (isUploading) {
      setIsUploadingNow(true);
    } else {
      setIsUploadingNow(false);
    }
  }, [episodes]);

  useEffect(() => {
    //remove languages which removed from header

    let allEpisodes = [...episodes];
    if (!oldSubtitles) {
      setOldSubtitles(subtitleList);
    } else {
      let filteredSubtitles = oldSubtitles.filter((oldObj) => {
        return !subtitleList.some(
          (newObj) => oldObj.languageId === newObj.languageId
        );
      });
      setOldSubtitles(subtitleList);
      filteredSubtitles.forEach((removedSub) =>
        allEpisodes.map((episode, episodeIndex) =>
          episode.subtitles?.subtitles.find((currSub, currSubIndex) => {
            if (currSub?.languageId === removedSub?.languageId) {
              currSub.language = "";
              currSub.path = "";
              currSub.languageId = "";
              currSub.languageName = "";
              currSub.disableUploadFile = true;
            }
          })
        )
      );
    }
    if (!oldAudios) {
      setOldAudios(audioList);
    } else {
      //find objects that deleted from oldAudios
      let filteredAudios = oldAudios.filter((oldObj) => {
        return !audioList.some(
          (newObj) => oldObj.languageId === newObj.languageId
        );
      });
      setOldAudios(audioList);
      filteredAudios.forEach((removedSub) =>
        allEpisodes.map((episode, episodeIndex) =>
          episode.audios?.audios.find((currSub, currSubIndex) => {
            if (currSub?.languageId === removedSub?.languageId) {
              currSub.language = "";
              currSub.path = "";
              currSub.languageId = "";
              currSub.languageName = "";
              currSub.disableUploadFile = true;
            }
          })
        )
      );
    }
    //eslint-disable-next-line
  }, [subtitleList, audioList]);

  useEffect(() => {
    let allEpisodes = [...episodes];
    if (partsCastList?.length < allEpisodes?.length) {
      allEpisodes.splice(partsCastList?.length, allEpisodes.length);
      setEpisodes(allEpisodes);
    } //eslint-disable-next-line
  }, [partsCastList?.length]);

  const handleGetVideoDuration = (episodeIndex, duration) => {
    let allEpisodes = [...episodes];
    const formattedDuration = customizeVideoDuration(duration);
    allEpisodes[episodeIndex].duration = formattedDuration;
    setEpisodes(allEpisodes);
  };

  const observeUploading = (status) => {
    setIsUploading(status);
  };

  const handleAddAnotherEpisode = (num = 1) => {
    let allEpisodes = [...episodes];
    if (episodes?.length < partsCount) {
      for (let i = 0; i < num; i++) {
        allEpisodes.push({
          videoHeaderId: headerAddedId,
          isSeries: false,
          seriesPartNoId: partsOnSystem[episodes?.length + i].indexId,
          title: "",
          imgPath: "",
          path: "",
          isFree: false,
          lowQualityVideoPath: "",
          isUploading: false,
          videoOptions: {},
          yearOfProduction: "",
          // qualityId: "",
          duration: "",
          "description.en": "",
          "description.ar": "",

          episodeErrors: [
            { hasError: false, error: "Please provide part title " },
            { hasError: false, error: "Please select part" },
            {
              hasError: false,
              error: "Please provide years of production",
            },
            { hasError: false, error: "Please select video quality" },
            {
              hasError: false,
              error: "Please upload an image ",
              progressStatus: false,
              progressPercent: "",
            },
            {
              hasError: false,
              error: "Please upload a video ",
              progressStatus: false,
              progressPercent: "",
            },
            {
              hasError: false,
              error: "Please provide arabic and english description",
            },
          ],
        });
      }
    } else {
      toastError(
        `You can't add more than ${
          partsCastList.length === 1
            ? "one part"
            : partsCastList.length + " parts"
        }`
      );
    }
    setEpisodes(allEpisodes);
  };

  const handleChangeTitle = (event, episodeIndex, headerAddedId) => {
    if (headerAddedId?.length > 0) {
      let allEpisodes = [...episodes];
      allEpisodes[episodeIndex].title = event.target.value;
      // allEpisodes[episodeIndex].videoHeaderId = headerAddedId;
      allEpisodes[episodeIndex].episodeErrors[0].hasError = false;
      setEpisodes(allEpisodes);
    }
  };

  const handleSelectEpisode = (partId, episodeIndex) => {
    let allEpisodes = [...episodes];
    if (allEpisodes[episodeIndex].seasonId === "") {
      allEpisodes[episodeIndex].episodeErrors[0].hasError = true;
      allEpisodes[episodeIndex].seriesPartNoId = "";
      setEpisodes(allEpisodes);
    } else {
      if (partId?.length > 0) {
        if (allEpisodes.some((episode) => episode?.seriesPartNoId === partId)) {
          toastError("this part was selected before!");
          allEpisodes[episodeIndex].seriesPartNoId = "";
          setEpisodes(allEpisodes);
        } else {
          allEpisodes[episodeIndex].seriesPartNoId = partId;
          allEpisodes[episodeIndex].episodeErrors[1].hasError = false;
          setEpisodes(allEpisodes);
        }
      }
    }
  };

  const handleYearsOfProduction = (e, episodeIndex) => {
    let allEpisodes = [...episodes];
    const pattern = /^\d{4,4}$/;
    const value = e.target.value;

    if (pattern.test(value)) {
      allEpisodes[episodeIndex].yearOfProduction = +value;
      allEpisodes[episodeIndex].episodeErrors[2].hasError = false;
      setEpisodes(allEpisodes);
    } else {
      allEpisodes[episodeIndex].yearOfProduction = +value;
      allEpisodes[episodeIndex].episodeErrors[2].hasError = true;
      setEpisodes(allEpisodes);
    }
  };

  ///image config
  const handleSetImageFilePath = (imageFilePath, episodeIndex) => {
    if (imageFilePath?.length > 0) {
      let allEpisodes = [...episodes];
      allEpisodes[episodeIndex].imgPath = imageFilePath;
      allEpisodes[episodeIndex].episodeErrors[4].hasError = false;
      setEpisodes(allEpisodes);
    }
  };

  const handleShowImagesProgressBar = (show, episodeIndex) => {
    let allEpisodes = [...episodes];
    allEpisodes[episodeIndex].episodeErrors[4].progressStatus = show;
    setEpisodes(allEpisodes);
  };

  const handleSetImageProgressPercent = (percent, episodeIndex) => {
    let allEpisodes = [...episodes];
    allEpisodes[episodeIndex].episodeErrors[4].progressPercent = percent;
    setEpisodes(allEpisodes);
  };

  /// video config
  const handleEditUploadVideo = (episodeIndex) => {
    setSelectedEpisodeToUpload(episodeIndex);
  };

  const handleSetVideoFilePathForM3u8 = (
    // filePath,
    // fileName,
    index,
    videoPath
  ) => {
    // console.log("🚀 ~ handleFreeVideoChange ~ videoPath:", videoPath);
    // console.log("🚀 ~ handleFreeVideoChange ~ fileName:", fileName);
    // console.log("🚀 ~ handleSetVideoFilePathForM3u8 ~ filePath:", filePath);
    let allEpisodes = [...episodes];
    allEpisodes[index].path = videoPath;
    allEpisodes[index].episodeErrors[5].hasError = false;
    console.log("allEpisodes", allEpisodes[index]);
    setEpisodes(allEpisodes);
    setVideoPath(videoPath);
  };

  // const handleSetVideoFilePath = (
  //   filePath,
  //   lowQualityVideoPath,
  //   fileName,
  //   duration,
  //   episodeIndex,
  //   videoIndex
  // ) => {
  //   const newDuration = customizeVideoDuration(duration);
  //   let allEpisodes = [...episodes];
  //   // console.log("🚀 ~ allEpisodes:", allEpisodes);

  //   // allEpisodes[episodeIndex].path = filePath;
  //   // allEpisodes[episodeIndex].lowQualityVideoPath = lowQualityVideoPath;
  //   // allEpisodes[episodeIndex].duration = newDuration;
  //   // allEpisodes[episodeIndex].episodeErrors[5].hasError = false;
  //   // allEpisodes[episodeIndex].lowQualityVideoPath = lowQualityVideoPath;
  //   // allEpisodes[episodeIndex].duration = newDuration;

  //   allEpisodes[episodeIndex].path = filePath;
  //   allEpisodes[episodeIndex].episodeErrors[9].videoFileErrors[
  //     videoIndex
  //   ].hasError = false;
  //   allEpisodes[episodeIndex].videos.videos[videoIndex].path = filePath;
  //   allEpisodes[episodeIndex].videos.videos[videoIndex].lowQualityVideoPath =
  //     lowQualityVideoPath;
  //   setEpisodes(allEpisodes);
  // };

  const handleDataFromTextEditor = (
    descriptionEn,
    descriptionAr,
    episodeIndex
  ) => {
    if (descriptionEn && descriptionAr) {
      let allEpisodes = [...episodes];
      allEpisodes[episodeIndex]["description.ar"] = descriptionAr;
      allEpisodes[episodeIndex]["description.en"] = descriptionEn;
      allEpisodes[episodeIndex].episodeErrors[6].hasError = false;
      setEpisodes(allEpisodes);
    }
  };

  const removeThisEpisode = (episodeIndex) => {
    if (folderUploaderRef.current) {
      folderUploaderRef.current.cancelUpload();
    }
    let allEpisodes = [...episodes];
    const paths = getUnusedFilesForEpisode(allEpisodes[episodeIndex]);
    if (paths?.length > 0) {
      removeUnUsedFiles(
        paths,
        (success) => {
          if (success?.isSuccess) {
            allEpisodes.pop();
            setEpisodes(allEpisodes);
          } else {
            toastError(success.err_msg);
          }
        },
        (fail) => {
          if (fail?.data) {
            toastError(fail.data?.err_msg);
          } else {
            toastError();
          }
        }
      );
    } else {
      allEpisodes.pop();
      setEpisodes(allEpisodes);
    }
  };

  // const validateAllEpisodes = () => {
  //   episodes.map((episode, episodeIndex) => {
  //     if (episode?.title === "") {
  //       let allEpisodes = [...episodes];
  //       allEpisodes[episodeIndex].episodeErrors[0].hasError = true;
  //       setEpisodes(allEpisodes);
  //     }

  //     if (episode?.seriesPartNoId === "") {
  //       let allEpisodes = [...episodes];
  //       allEpisodes[episodeIndex].episodeErrors[1].hasError = true;
  //       setEpisodes(allEpisodes);
  //     }

  //     if (episode?.yearOfProduction === "") {
  //       let allEpisodes = [...episodes];
  //       allEpisodes[episodeIndex].episodeErrors[2].hasError = true;
  //       setEpisodes(allEpisodes);
  //     }
  //     // if (episode?.qualityId === "") {
  //     //   let allEpisodes = [...episodes];
  //     //   allEpisodes[episodeIndex].episodeErrors[3].hasError = true;
  //     //   setEpisodes(allEpisodes);
  //     // }

  //     if (episode?.imgPath === "") {
  //       let allEpisodes = [...episodes];
  //       allEpisodes[episodeIndex].episodeErrors[4].hasError = true;
  //       setEpisodes(allEpisodes);
  //     }

  //     if (episode?.path === "") {
  //       let allEpisodes = [...episodes];
  //       allEpisodes[episodeIndex].episodeErrors[5].hasError = true;
  //       setEpisodes(allEpisodes);
  //     }

  //     if (
  //       episode["description.en"] === "" ||
  //       episode["description.ar"] === ""
  //     ) {
  //       let allEpisodes = [...episodes];
  //       allEpisodes[episodeIndex].episodeErrors[6].hasError = true;
  //       setEpisodes(allEpisodes);
  //     }

  //     // episode?.subtitles?.subtitles.map((subtitle, subtitleIndex) => {
  //     //   if (subtitle.language === "") {
  //     //     let allEpisodes = [...episodes];
  //     //     allEpisodes[episodeIndex].episodeErrors[7].subtitleLanguageErrors[
  //     //       subtitleIndex
  //     //     ].hasError = true;
  //     //     setEpisodes(allEpisodes);
  //     //   }
  //     //   if (subtitle?.path === "") {
  //     //     let allEpisodes = [...episodes];
  //     //     allEpisodes[episodeIndex].episodeErrors[7].subtitleFileErrors[
  //     //       subtitleIndex
  //     //     ].hasError = true;
  //     //     setEpisodes(allEpisodes);
  //     //   }
  //     // });

  //     // episode?.audios.audios?.map((audio, audioIndex) => {
  //     //   if (audio.language === "") {
  //     //     let allEpisodes = [...episodes];
  //     //     allEpisodes[episodeIndex].episodeErrors[8].audioLanguageErrors[
  //     //       audioIndex
  //     //     ].hasError = true;
  //     //     setEpisodes(allEpisodes);
  //     //   }
  //     //   if (audio?.path === "") {
  //     //     let allEpisodes = [...episodes];
  //     //     allEpisodes[episodeIndex].episodeErrors[8].audioFileErrors[
  //     //       audioIndex
  //     //     ].hasError = true;
  //     //     setEpisodes(allEpisodes);
  //     //   }
  //     // });
  //   });
  // };
  const callAddEpisodesOnly = async (finalEpisodes) => {
    await addEpisodesToHeader(
      finalEpisodes,
      (success) => {
        if (success.isSuccess) {
          toastSuccess(success.message);
          setLoading(false);
          if (location?.state?.genreName === "Anime") {
            navigate(ROUTES.ANIME, {
              state: {
                genreId,
                name: genreName,
              },
            });
          } else if (location?.state?.genreName === "Movies") {
            navigate(ROUTES.MOVIES, {
              state: {
                genreId,
                name: genreName,
              },
            });
          } else if (location?.state?.genreName === "Fun Store") {
            navigate(ROUTES.FUNSTORE, {
              state: {
                genreId,
                name: genreName,
              },
            });
          } else {
            navigate(ROUTES.MOVIES, {
              state: {
                genreId,
                name: genreName,
              },
            });
          }
        } else {
          toastError(success.err_msg);
          setLoading(false);
        }
      },
      (fail) => {
        if (fail.data) {
          toastError(fail.data.err_msg);
          setLoading(false);
        } else {
          toastError();
          setLoading(false);
        }
      }
    );
  };

  const validateAllEpisodes = () => {
    episodes.map((episode, episodeIndex) => {
      if (episode?.title === "") {
        let allEpisodes = [...episodes];
        allEpisodes[episodeIndex].episodeErrors[0].hasError = true;
        setEpisodes(allEpisodes);
      }

      if (episode?.seriesPartNoId === "") {
        let allEpisodes = [...episodes];
        allEpisodes[episodeIndex].episodeErrors[1].hasError = true;
        setEpisodes(allEpisodes);
      }

      if (
        episode?.yearOfProduction === "" ||
        episode?.yearOfProduction === null ||
        episode?.yearOfProduction === 0
      ) {
        let allEpisodes = [...episodes];
        allEpisodes[episodeIndex].episodeErrors[2].hasError = true;
        setEpisodes(allEpisodes);
      }

      if (episode?.imgPath === "") {
        let allEpisodes = [...episodes];
        allEpisodes[episodeIndex].episodeErrors[4].hasError = true;
        setEpisodes(allEpisodes);
      }

      if (episode?.path === "") {
        let allEpisodes = [...episodes];
        allEpisodes[episodeIndex].episodeErrors[5].hasError = true;
        setEpisodes(allEpisodes);
      }

      if (
        episode["description.en"] === "" ||
        episode["description.ar"] === ""
      ) {
        let allEpisodes = [...episodes];
        allEpisodes[episodeIndex].episodeErrors[6].hasError = true;
        setEpisodes(allEpisodes);
      }
    });
  };

  const handleOnFinish = async () => {
    validateAllEpisodes();
    const checkAnyError = [];
    const episodeWhichHasError = [];

    episodes.forEach((episode, episodeIndex) => {
      const hasEpisodeError = episode.episodeErrors.some(
        (singleObject) => singleObject.hasError
      );
      if (hasEpisodeError) {
        checkAnyError.push(episodeIndex);
        episodeWhichHasError.push(episodeIndex + 1);
      }
    });

    if (checkAnyError.length === 0) {
      setLoading(true);
      // Retrieve only subtitles which have a path

      const episodesWithoutErrors = episodes.map((episode) => {
        const { episodeErrors, isUploading, videoOptions, ...remainData } =
          episode;
        return remainData;
      });

      try {
        const promises = episodesWithoutErrors.map(
          async (episode, episodeIndex) => {
            return episodesWithoutErrors[episodeIndex];
          }
        );
        const updatedEpisodes = await Promise.all(promises);
        await callAddEpisodesOnly(updatedEpisodes);
      } catch (error) {
        toastError();
        setLoading(false);
      }
    } else {
      let episodesErrorToSet = new Set(episodeWhichHasError);
      let episodesHasError = [...episodesErrorToSet];
      if (episodesHasError?.length === 1) {
        toastError(`Please fill all data for part ( ${episodesHasError[0]} ) `);
      } else {
        toastError(
          `Please fill all data for parts ( ${episodesHasError.join(",")} ) `
        );
      }
    }
  };

  return (
    <Fragment>
      <SubHeader bgColor="#27A2D9" title="Parts" />
      <Form className="wd-100" onFinish={handleOnFinish}>
        {episodes.map((episode, episodeIndex) => (
          <div
            className="add-episodes-to-tvshows wd-100 mrg-y-30 pad-y-30"
            key={episodeIndex}
          >
            <div className=" wd-100 pad-x-10 ">
              {episodeIndex !== 0 &&
                episodeIndex === episodes?.length - 1 &&
                isUploadingNow === false && (
                  <Row justify="end" className="mrg-ye-20">
                    <Col>
                      <div
                        className="removeEpisods pad-xe-30"
                        role={"button"}
                        onClick={() => removeThisEpisode(episodeIndex)}
                      >
                        <CloseOutlined
                          style={{
                            fontSize: "20px",
                            color: "#6F767E",
                          }}
                        />
                      </div>
                    </Col>
                  </Row>
                )}
              <Row gutter={36}>
                <Col xs={24} md={12} className="mrg-ye-20">
                  <div className="new-category">
                    <label className="view-tvshow-lbl-main">Choose part</label>
                    <Select
                      placeholder={"Choose Parts"}
                      dropdownStyle={dropdownStyle}
                      className="search__searchField py-1 wd-100"
                      onChange={(partId) =>
                        handleSelectEpisode(partId, episodeIndex)
                      }
                      value={episode?.seriesPartNoId || undefined}
                    >
                      {partsOnSystem?.map(({ indexId, episode }, index) => {
                        if (episodeIndex === index) {
                          return (
                            <Select.Option key={indexId} value={indexId}>
                              {episode}
                            </Select.Option>
                          );
                        }
                      })}
                    </Select>
                    {episode?.episodeErrors[1]?.hasError && (
                      <div className="episode-errors">
                        {episode?.episodeErrors[1]?.error}
                      </div>
                    )}
                  </div>
                </Col>
                <Col xs={24} md={12}>
                  <div className="new-actor__name new-category ">
                    <label className="view-tvshow-lbl-main" htmlFor="">
                      Part title
                    </label>
                    <Input
                      placeholder="Part title"
                      type="text"
                      className="search__searchField py-2"
                      onChange={(event) =>
                        handleChangeTitle(event, episodeIndex, headerAddedId)
                      }
                    />
                  </div>
                  {episode?.episodeErrors[0]?.hasError && (
                    <div className="episode-errors">
                      {episode?.episodeErrors[0]?.error}
                    </div>
                  )}
                </Col>
              </Row>
              <Row gutter={36}>
                <Col xs={24} md={12} className="mrg-ye-20">
                  <div className="new-category  ">
                    <label className="view-tvshow-lbl-main">
                      Year of production
                    </label>
                    <Input
                      // type={"number"}
                      placeholder="Year of production"
                      className="search__searchField py-2"
                      onChange={(e) => handleYearsOfProduction(e, episodeIndex)}
                      value={episode?.yearOfProduction || undefined}
                    ></Input>
                    {episode?.episodeErrors[2]?.hasError && (
                      <div className="episode-errors">
                        {episode?.episodeErrors[2]?.error}
                      </div>
                    )}
                  </div>
                </Col>
              </Row>
              <Row gutter={16} style={{ marginBottom: "2.5rem" }}>
                {/* <Col xs={24} style={{ height: "251px" }}> */}
                <Col xs={24} md={8} style={{ height: "251px" }}>
                  <div className="upload-files upload-files__image-small ">
                    <div className="upload-files__container upload-files__container--image-small ">
                      {episode?.imgPath && (
                        <figure>
                          <img
                            className="upload-files__upload-file"
                            src={baseUrl + episode?.imgPath}
                            alt="actorImage"
                            style={{
                              position: "absolute",
                              zIndex: "3",
                              width: "100%",
                            }}
                          />
                        </figure>
                      )}

                      <DraggerUploader
                        withCrop
                        aspect={2 / 1}
                        formDataType={"img"}
                        acceptedTypes={"image/*"}
                        setShowProgressBar={(show) =>
                          handleShowImagesProgressBar(show, episodeIndex)
                        }
                        setProgressPercent={(percent) =>
                          handleSetImageProgressPercent(percent, episodeIndex)
                        }
                        setFilePath={(filePath) =>
                          handleSetImageFilePath(filePath, episodeIndex)
                        }
                      >
                        {episode?.imgPath ? (
                          <div className="upload-edit-icon mrg-x-20">
                            <img role="button" src={editIcon} alt="edit" />
                          </div>
                        ) : (
                          <div
                            role="button"
                            className="upload-files__button"
                            style={{
                              boxShadow: "none",
                              backgroundColor: "transparent",
                              justifyContent: "center",
                              gap: "30px",
                              margin: "auto",
                              borderRadius: "12px",
                            }}
                          >
                            <div className="upload-files__icon">
                              <img src={uploadButton} alt="" />
                            </div>
                            <div className="upload-files__title">
                              {"Upload Image"}
                            </div>
                            <div className="upload-files__icon">
                              <img src={uploadImageIcon} alt="" />
                            </div>
                          </div>
                        )}
                      </DraggerUploader>
                    </div>

                    {episode?.episodeErrors[4]?.hasError && (
                      <div className="episode-errors">
                        {episode?.episodeErrors[4]?.error}
                      </div>
                    )}
                  </div>
                  {episode?.episodeErrors[4]?.progressStatus && (
                    <Progress
                      style={{ marginTop: "1rem" }}
                      percent={episode?.episodeErrors[4]?.progressPercent}
                    />
                  )}
                </Col>
                <Col xs={24} md={16} style={{ position: "relative" }}>
                  {/* <DraggerFileUploader
                    setFilePath={(filePath, fileName, videoPath) =>
                      handleSetVideoFilePathForM3u8(
                        filePath,
                        episodeIndex,
                        fileName,
                        videoPath
                      )
                    }
                    episodeIndex={episodeIndex}
                  /> */}
                  <FolderUploader
                    ref={folderUploaderRef}
                    observeUploading={observeUploading}
                    getVideoDuration={(duration) =>
                      handleGetVideoDuration(episodeIndex, duration)
                    }
                    setFilePath={(videoPath) =>
                      handleSetVideoFilePathForM3u8(episodeIndex, videoPath)
                    }
                    episodeIndex={episodeIndex}
                  />
                  {episode?.episodeErrors[5]?.hasError && (
                    <div className="episode-errors">
                      {episode?.episodeErrors[5]?.error}
                    </div>
                  )}
                </Col>
                <Col span={24}>
                  <div className="wd-100 mrg-x-auto mt-4">
                    <span className="view-tvshow-lbl-main">Description</span>
                    <SecondaryEditor
                      className="d-none"
                      getTextEditorData={(descriptionEn, descriptionAr) =>
                        handleDataFromTextEditor(
                          descriptionEn,
                          descriptionAr,
                          episodeIndex
                        )
                      }
                    />
                    {episode?.episodeErrors[6]?.hasError && (
                      <div className="episode-errors">
                        {episode?.episodeErrors[6]?.error}
                      </div>
                    )}
                  </div>
                </Col>
              </Row>{" "}
              <Row justify="start">
                <Col>
                  <label className="view-tvshow-lbl-main ">Free</label>
                </Col>
              </Row>
              <Row justify="start">
                <Col span={9}>
                  <p>User can watch this video without subscription</p>
                </Col>
                <Col>
                  <Switch
                    style={{
                      backgroundColor: episode?.isFree ? "#31AA4A" : "", // Change the background color when checked
                    }}
                    checked={episode?.isFree} // Reflect the state of the 'isFree' variable
                    onChange={(checked) =>
                      handleFreeVideoChange(checked, episodeIndex)
                    } // Toggle the 'isFree' state on change
                  />
                </Col>
              </Row>
            </div>
          </div>
        ))}
        <div className="flex justify-content-between wd-100">
          <span className="view-tvshow-lbl uploooooding  flex-1">
            {isUploadingNow
              ? " You cannot add new parts until the current uploads are completed"
              : ""}
          </span>
          <div className="config-video-buttons flex justify-end  wd-40 mrg-ye-50 ">
            {
              <div className="frist-button mrg-xe-20">
                <PlusButton
                  buttonName="Add Part"
                  styling="secondary"
                  action={() => handleAddAnotherEpisode()}
                  disableMe={isUploadingNow}
                />
              </div>
            }
            <div className="btn-save mrg-xe-20" style={{ width: "129px" }}>
              <PrimaryButton
                type={"submit"}
                buttonName="Add"
                loading={loading}
              />
            </div>
          </div>
        </div>
      </Form>
    </Fragment>
  );
}
export default AddPartM3u8;
