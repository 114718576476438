import { useState, useEffect } from "react";
import Header from "modules/SubComponents/Header";
import SubHeader from "modules/SubComponents/SubHeader";
import { Space, Switch, Table } from "antd";
import { editAdmin, getAllAdminsData } from "Network";
import { toastError, toastSuccess } from "helpers/toasters";
import { searchFunction } from "helpers/helpfulFunctions";
import { useLocation, useNavigate } from "react-router-dom";
import ROUTES from "constants/NewRoutes";
import DeleteModal from "modules/Modals/DeleteModal";
import warrningIcon from "assets/icons/warning.svg";
import { useSelector } from "react-redux";

function Admins() {
  const location = useLocation();
  // const { currentPage, searchVal } = location?.state;
  const [loading, setLoading] = useState(true);
  const [renderMe, setRenderMe] = useState(false);
  const [selectedPage, setSelectedPage] = useState();
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [loadingActivateModal, setLoadingActivateModal] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [allDataSource, setAllDataSource] = useState([]);
  const [searchResult, setSearchResult] = useState([]);
  const navigate = useNavigate();
  const { authorization } = useSelector((state) => state?.auth);
  let adminEmail = authorization.email;
  const queryParameters = new URLSearchParams(window.location.search);
  const name = queryParameters.get("name");
  const currentPage = queryParameters.get("currentPage");
  const searchVal = queryParameters.get("searchVal");

  useEffect(() => {
    window.addEventListener("beforeunload", function (event) {
      const navTiming = performance.getEntriesByType("navigation")[0];
      if (navTiming.type === "reload") {
        navigate(ROUTES.ADMINS, {
          state: {
            currentPage: 1,
            searchVal: "",
          },
        });
      }
    });
  }, []);

  useEffect(() => {
    if (currentPage) {
      setSelectedPage(currentPage);
    } else {
      setSelectedPage(1);
    }
  }, [currentPage]);

  useEffect(() => {
    getAdmins();
  }, [renderMe]);

  useEffect(() => {
    const searchColumns = ["adminName", "role", "email"];
    setSearchResult(searchFunction(allDataSource, searchValue, searchColumns));
    //eslint-disable-next-line
  }, [searchValue]);
  useEffect(() => {
    setSearchValue(searchVal);
    getAdmins(searchVal);
    //eslint-disable-next-line
  }, [searchVal]);

  const getAdmins = (search) => {
    getAllAdminsData(
      (success) => {
        if (success?.isSuccess) {
          setLoading(false);
          setAllDataSource(success.data);
          if (search?.length > 0) {
            const searchColumns = ["adminName", "role", "email"];
            setSearchResult(
              searchFunction(success.data, search, searchColumns)
            );
          }
        }
      },
      (fail) => {
        if (fail?.data) {
          toastError(fail.data?.err_msg);
        } else {
          toastError();
        }
      }
    );
  };

  const handleStatusOnChange = (record) => {
    if (record?.email !== adminEmail) {
      setSelectedRowData(record);
      setDeleteModalVisible(true);
    }
  };

  const handleGetSearchValueFromHeader = (value) => {
    setSearchValue(value);
  };

  const columns = [
    {
      key: "id",
      title: "ADMIN NAME",
      dataIndex: "adminName",
      render: (_, record) => {
        return (
          <span role={"button"} onClick={() => handleEditAdminData(record)}>
            {record?.adminName}
          </span>
        );
      },
    },
    {
      key: "id",
      title: "ROLE",
      dataIndex: "isSuperAdmin",
      render: (_, record) => {
        return <>{record?.isSuperAdmin ? "Super Admin" : "Admin"}</>;
      },
    },
    {
      key: "id",
      title: "EMAIL",
      dataIndex: "email",
    },
    {
      key: "id",
      title: "STATUS",
      dataIndex: "status",
      render: (_, record) => {
        return (
          <Space size={10}>
            <Switch
              style={{
                backgroundColor: `${
                  record?.isActive ? "rgb(49, 170, 74)" : "gray"
                }`,
                minWidth: "35px",
              }}
              checked={record?.isActive}
              onChange={() => handleStatusOnChange(record)}
            />
            <span>{record?.isActive ? "Active" : "Inactive"}</span>
          </Space>
        );
      },
    },
  ];

  const handleAddAdmin = () => {
    navigate(ROUTES.ADDEDITADMIN, {
      state: { mode: "add" },
    });
  };

  const onCancel = () => {
    setDeleteModalVisible(false);
  };

  const handlePageOnChange = (page) => {
    setSelectedPage(page);
  };

  const handleEditAdminData = ({
    adminName,
    email,
    isActive,
    isSuperAdmin,
    password,
    phone,
    _id,
  }) => {
    navigate(ROUTES.ADDEDITADMIN, {
      state: {
        adminId: _id,
        adminName,
        email,
        isActive,
        isSuperAdmin,
        password,
        mode: "edit",
        selectedPage,
        searchVal: searchValue?.length > 0 ? searchValue : "",
      },
    });
  };

  const handleEditAdminStatus = () => {
    //need an api to edit admin status only
    setLoadingActivateModal(true);
    if (selectedRowData) {
      let payload = {
        adminId: selectedRowData._id,
        adminName: selectedRowData.adminName,
        email: selectedRowData.email,
        isActive: !selectedRowData.isActive,
        isSuperAdmin: selectedRowData.isSuperAdmin,
      };
      editAdmin(
        payload,
        (success) => {
          if (success?.isSuccess) {
            toastSuccess(
              `Admin ${
                success.data?.isActive ? "activated" : "deactivated"
              } successfully`
            );
            setRenderMe((prev) => !prev);
            setLoadingActivateModal(false);
            onCancel();
          } else {
            toastError(success.err_msg);
            setLoadingActivateModal(false);
          }
        },
        (fail) => {
          if (fail?.data) {
            toastError(fail.data.err_msg);
            setLoadingActivateModal(false);
          } else {
            toastError();
            setLoadingActivateModal(false);
          }
        }
      );
    }
  };

  return (
    <section className="users">
      <div className="users__all-contents ">
        <div className="users__content">
          <div className="users__usersData">
            <Header
              title="Admins"
              search="Find Admin"
              getSearchedFromHeader={handleGetSearchValueFromHeader}
              plusButtonName="Add Admin"
              plusButtonAction={handleAddAdmin}
              searchVal={searchVal?.length > 0 ? searchVal : ""}
            />
            <SubHeader bgColor="#F58234" title="All Admins" />
            <div className="users__table grid-table mrg-y-30 ">
              <Table
                className=""
                pagination={{
                  defaultPageSize: "7",
                  responsive: true,
                  hideOnSinglePage: true,
                  onChange: handlePageOnChange,
                  current: selectedPage,
                }}
                rowKey={(row) => row?._id}
                columns={columns}
                dataSource={searchValue ? searchResult : allDataSource}
                rowClassName={(record, index) =>
                  index % 2 === 0 && "background-even-records"
                }
                scroll={{ x: 600 }}
                loading={loading}
              />
            </div>
          </div>
        </div>
      </div>
      <DeleteModal
        deleteModalVisible={deleteModalVisible}
        loading={loadingActivateModal}
        SubHeader={
          <SubHeader
            bgColor="#F58234"
            title="Edit Admin Status"
            closeButton
            onCancel={onCancel}
          />
        }
        icon={warrningIcon}
        msg={`Are you sure you want to ${
          selectedRowData?.isActive ? "deactivate" : "activate"
        } this admin? `}
        onCancel={onCancel}
        action={handleEditAdminStatus}
        secondaryBtnName="Cancel"
        primaryBtnName={`${
          selectedRowData?.isActive ? "Deactivate" : "Activate"
        }`}
      />
    </section>
  );
}
export default Admins;
