import React, { Fragment, useState, useEffect } from "react";
import { Form, Select, Row, Col, Input, Divider, Progress, Button } from "antd";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { baseUrl } from "services";
import { toastError, toastSuccess } from "helpers/toasters";
import Header from "modules/SubComponents/Header";
import PrimaryButton from "modules/SubComponents/Buttons/PrimaryButton";
import SecondaryPlusButton from "modules/SubComponents/Buttons/SecondaryPlusButton";
import CustomFileUploader from "modules/SubComponents/Upload/CustomFileUploader";
import editIcon from "assets/icons/pen.svg";
import { getSpecifiedSeasons } from "helpers/helpfulFunctions";
import {
  deleteSeasonFromTvShows,
  editTvHeader,
  getAllActors,
  getAllCategoriesByGenreId,
  getAllVideosDetailsForHeader,
} from "Network";
import { dropdownStyle } from "helpers/helpfulFunctions";
import EditEpisodeM3u8 from "./EditEpisodeM3u8";

function EditHeaderM3u8() {
  const {
    state: {
      selectedHeaderId,
      selectedSeasonId,
      seasonsDependsOnHeaderId,
      genreName,
    },
  } = useLocation();

  const [allHeaderDataWithVideos, setAllHeaderDataWithVideos] = useState([]);

  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState("");
  const [selectedGenre, setSelectedGenre] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [seasonsCast, setSeasonsCast] = useState([
    {
      seasonId: "",
      castIds: [],
    },
  ]);
  const [editHeaderErrors, setEditHeaderErrors] = useState([
    {
      hasError: false,
      error: "Please provide title",
    },
    {
      hasError: false,
      error: "Please select genre",
    },
    {
      hasError: false,
      error: "Please select category",
    },
    {
      seasons: [{ hasError: false, error: "Please select season" }],
    },
    {
      casts: [{ hasError: false, error: "Please select casts" }],
    },
    {
      hasError: false,
      error: "Please upload an  image",
    },
  ]);
  const [filePath, setFilePath] = useState("");
  const [categoriesByGenreId, setCategoriesByGenreId] = useState([]);
  const [progressPercent, setProgressPercent] = useState(0);
  const [showProgressBar, setShowProgressBar] = useState(false);
  const [allActorsOnSystem, setAllActorsOnSystem] = useState([]);
  const [allLanguagesOnSystem, setAllLanguagesOnSystem] = useState([]);
  const [specifiedSeasons, setSpecifiedSeasons] = useState([]);
  const [renderEditEpisode, setRenderEditEpisode] = useState(false);
  const [renderHeader, setRenderHeader] = useState(false);
  const [isSaved, setIsSaved] = useState(false);

  const { languagesOnSystem, seasonsOnSystem } = useSelector(
    (state) => state?.staticData?.staticDataOnSystem
  );

  useEffect(() => {
    setRenderEditEpisode((prev) => !prev);
  }, [renderHeader]);

  useEffect(() => {
    if (selectedHeaderId?.length > 0 && selectedSeasonId?.length > 0) {
      getAllHeaderDataAfterActions();
    }
    //eslint-disable-next-line
  }, [selectedHeaderId, selectedSeasonId, renderHeader]);

  useEffect(() => {
    if (allHeaderDataWithVideos) {
      if (allHeaderDataWithVideos?.title?.length > 0) {
        setTitle(allHeaderDataWithVideos.title);
      }
      if (allHeaderDataWithVideos?.genreId?.length > 0) {
        setSelectedGenre(allHeaderDataWithVideos.genreId);
      }
      if (allHeaderDataWithVideos?.categoryId?.length > 0) {
        setSelectedCategory(allHeaderDataWithVideos.categoryId);
      }
      if (allHeaderDataWithVideos?.seasonId?.length > 0) {
        let allSeasonsCast = [...seasonsCast];
        allSeasonsCast[0].seasonId = allHeaderDataWithVideos.seasonId;
        setSeasonsCast(allSeasonsCast);
      }
      if (allHeaderDataWithVideos?.castIds?.length > 0) {
        let allSeasonsCast = [...seasonsCast];
        let selectedSeasonCasts = allHeaderDataWithVideos?.castIds
          ?.find((cast) => cast?.seasonId === selectedSeasonId)
          .casts.map((c) => c?._id);
        allSeasonsCast[0].castIds = selectedSeasonCasts;
        setSeasonsCast(allSeasonsCast);
      }
      if (allHeaderDataWithVideos?.imgPath?.length > 0) {
        setFilePath(allHeaderDataWithVideos.imgPath);
      }
    }
    //eslint-disable-next-line
  }, [allHeaderDataWithVideos]);

  useEffect(() => {
    if (selectedGenre?.length > 0) {
      getAllCategoriesByGenreId(
        { genreId: selectedGenre },
        (success) => {
          if (success?.isSuccess) {
            setCategoriesByGenreId(success?.data);
          }
        },
        (fail) => {
          if (fail?.data?.error) {
            toastError(fail.data.err_msg);
          }
        }
      );
    }
  }, [selectedGenre]);

  useEffect(() => {
    let casts = [];
    let languages = [];
    getAllActors(
      (success) => {
        if (success?.isSuccess) {
          success.data?.forEach((act, actIndex) => {
            casts.push({ value: act?.id, label: act?.name });
          });
          setAllActorsOnSystem(casts);
        }
      },
      (fail) => {
        if (fail.data) {
          toastError(fail.data.err_msg);
        }
        toastError();
      }
    );

    languagesOnSystem.forEach((lang) =>
      languages.push({ label: lang?.name, value: lang?.id })
    );
    setAllLanguagesOnSystem(languages);
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (seasonsOnSystem && seasonsDependsOnHeaderId) {
      setSpecifiedSeasons(
        getSpecifiedSeasons(seasonsOnSystem, seasonsDependsOnHeaderId)
      );
    }
  }, [seasonsOnSystem, seasonsDependsOnHeaderId]);

  const getAllHeaderDataAfterActions = () => {
    if (selectedHeaderId?.length > 0 && selectedSeasonId?.length > 0) {
      console.log("DETAILS");
      getAllVideosDetailsForHeader(
        { videoHeaderId: selectedHeaderId, seasonId: selectedSeasonId },
        (success) => {
          if (success?.isSuccess) {
            setAllHeaderDataWithVideos(success.data[0]);
          }
        },
        (fail) => {
          toastError(fail.data.err_msg);
        }
      );
    } else {
      toastError("please select header and season frist!");
    }
  };

  const handleChangeTitle = (e) => {
    setTitle(e.target.value);
    let allHeaderErrors = [...editHeaderErrors];
    allHeaderErrors[0].hasError = false;
    setEditHeaderErrors(allHeaderErrors);
  };

  const handleSelectCategory = (categoryId) => {
    if (categoryId?.length > 0) {
      setSelectedCategory(categoryId);
      let allHeaderErrors = [...editHeaderErrors];
      allHeaderErrors[2].hasError = false;
      setEditHeaderErrors(allHeaderErrors);
    }
  };

  // const handleAddAnotherSeason = () => {
  //   let allSeries = [...addSeries];
  //   allSeries[0].seasonsCast.push({
  //     seasonId: seasonsOnSystem[allSeries[0].seasonsCast.length].indexId,
  //     castIds: [],
  //   });
  //   setAddSeries(allSeries);
  //   let allSeriesErrors = [...addSeriesErrors];

  //   allSeriesErrors[3].seasons.push({
  //     hasError: false,
  //     error: "Please select season",
  //   });
  //   allSeriesErrors[4].casts.push({
  //     hasError: false,
  //     error: "Please select casts",
  //   });
  //   setAddSeriesErrors(allSeriesErrors);
  // };

  const handleAddAnotherSeason = () => {
    let allSeasonsCast = [...seasonsCast];
    allSeasonsCast.push({ seasonId: "", castIds: [] });
    setSeasonsCast(allSeasonsCast);
    let allHeaderError = [...editHeaderErrors];
    allHeaderError[3].seasons.push({
      hasError: false,
      error: "Please select season",
    });
    allHeaderError[4].casts.push({
      hasError: false,
      error: "Please select casts ",
    });
    setEditHeaderErrors(allHeaderError);
  };

  const handleDeleteSeasonCasts = (seasonCast, seasonCastIndex) => {
    let allSeasonsCast = [...seasonsCast];
    let allEditHeaderErrors = [...editHeaderErrors];

    if (allSeasonsCast?.length > 1) {
      allSeasonsCast.splice(seasonCastIndex, 1);
      allSeasonsCast.forEach((season, seasonIndex) => {
        if (seasonIndex >= seasonCastIndex) {
          season.seasonId = "";
        }
      });
      allEditHeaderErrors[3].seasons.splice(seasonCastIndex, 1);
      allEditHeaderErrors[4].casts.splice(seasonCastIndex, 1);
      setSeasonsCast(allSeasonsCast);
      setEditHeaderErrors(allEditHeaderErrors);
    }
  };

  const handleSelectSeason = (seasonId, seasonCastIndex) => {
    if (seasonId?.length > 0) {
      let allSeasonsCast = [...seasonsCast];
      if (
        allSeasonsCast.some((seasonCast) => seasonCast?.seasonId === seasonId)
      ) {
        toastError("this season was selected before!");
        allSeasonsCast[seasonCastIndex].seasonId = "";
        setSeasonsCast(allSeasonsCast);
      } else {
        allSeasonsCast[seasonCastIndex].seasonId = seasonId;
        setSeasonsCast(allSeasonsCast);
        let allHeaderErrors = [...editHeaderErrors];
        allHeaderErrors[3].seasons[seasonCastIndex].hasError = false;
        setEditHeaderErrors(allHeaderErrors);
      }
    }
  };

  const handleChangeCasts = (castsIds, seasonCastIndex) => {
    // if (castsIds?.length > 0) {
    let allSeasonsCast = [...seasonsCast];
    allSeasonsCast[seasonCastIndex].castIds = castsIds;
    setSeasonsCast(allSeasonsCast);
    let allHeaderErrors = [...editHeaderErrors];
    allHeaderErrors[4].casts[seasonCastIndex].hasError = false;
    setEditHeaderErrors(allHeaderErrors);
    // }
  };

  const handleSetFilePath = (fileP) => {
    if (fileP?.length > 0) {
      setFilePath(fileP);
      let allHeaderErrors = [...editHeaderErrors];
      allHeaderErrors[5].hasError = false;
      setEditHeaderErrors(allHeaderErrors);
    }
  };

  const editHeaderDataValidator = () => {
    if (title === "") {
      let allHeaderErrors = [...editHeaderErrors];
      allHeaderErrors[0].hasError = true;
      setEditHeaderErrors(allHeaderErrors);
    }
    if (selectedGenre === "") {
      let allHeaderErrors = [...editHeaderErrors];
      allHeaderErrors[1].hasError = true;
      setEditHeaderErrors(allHeaderErrors);
    }
    if (selectedCategory === "") {
      let allHeaderErrors = [...editHeaderErrors];
      allHeaderErrors[2].hasError = true;
      setEditHeaderErrors(allHeaderErrors);
    }
    seasonsCast.map(({ seasonId, castIds }, seasonCastIndex) => {
      if (seasonId === "") {
        let allHeaderErrors = [...editHeaderErrors];
        allHeaderErrors[3].seasons[seasonCastIndex].hasError = true;
        setEditHeaderErrors(allHeaderErrors);
      }
    });

    if (filePath === "") {
      let allHeaderErrors = [...editHeaderErrors];
      allHeaderErrors[5].hasError = true;
      setEditHeaderErrors(allHeaderErrors);
    }
  };

  const handleOnFinish = () => {
    editHeaderDataValidator();
    const checkAnyError = [];
    if (editHeaderErrors) {
      const hasHeaderError = editHeaderErrors.some((el) => el.hasError);
      if (hasHeaderError) {
        checkAnyError.push("1");
      }
    }
    if (editHeaderErrors[3].seasons) {
      const hasSeasonError = editHeaderErrors[3]?.seasons.some(
        (el) => el.hasError
      );
      if (hasSeasonError) {
        checkAnyError.push("2");
      }
    }
    if (editHeaderErrors[4].casts) {
      const hasCastsError = editHeaderErrors[4]?.casts.some(
        (el) => el.hasError
      );
      if (hasCastsError) {
        checkAnyError.push("3");
      }
    }
    if (checkAnyError?.length > 0) {
      toastError("Please, fill all the required fields");
    } else {
      setLoading(true);
      const data = {
        videoHeaderId: selectedHeaderId,
        isSeries: true,
        title,
        genreId: selectedGenre,
        categoryId: selectedCategory,
        seasonsCast: seasonsCast?.length > 0 ? seasonsCast : [],
        imgPath: filePath,
      };

      editTvHeader(
        data,
        (success) => {
          if (success?.isSuccess) {
            toastSuccess(success.message);
            getAllHeaderDataAfterActions();
            setRenderHeader((prev) => !prev);
            setRenderEditEpisode((prev) => !prev);
            setLoading(false);
            setIsSaved(true);
          } else {
            toastError(success.err_msg);
            setLoading(false);
          }
        },
        (fail) => {
          if (fail?.data) {
            toastError(fail.data?.err_msg);
            setLoading(false);
          } else {
            toastError();
            setLoading(false);
          }
        }
      );
    }
  };

  return (
    <section className="users">
      <div className="users__all-contents ">
        {/* <SideBar /> */}
        <div className="users__content">
          {/* <Navbar /> */}
          <div className="tv-shows wd-100 ">
            <Header
              title={
                genreName === "Series" ? genreName : genreName + " | Series"
              }
            />
            <Form
              onFinish={handleOnFinish}
              className="tvshows-form wd-100 pad-x-20 "
            >
              <Row gutter={[24, 24]} className="mrg-ye-20">
                <Col span={24}>
                  <div className="new-actor__name new-category ">
                    <label className="view-tvshow-lbl" htmlFor="">
                      Title
                    </label>
                    <Input
                      type="text"
                      className="search__searchField py-2"
                      value={title}
                      onChange={handleChangeTitle}
                    />
                  </div>
                  {editHeaderErrors[0]?.hasError && (
                    <div className="episode-errors">
                      {editHeaderErrors[0]?.error}
                    </div>
                  )}
                </Col>
                <Col className="gutter-row" span={24}>
                  <div className="new-actor__name new-category">
                    <label className="view-tvshow-lbl" htmlFor="">
                      Choose Category
                    </label>
                    <Select
                      className="search__searchField py-1 wd-100"
                      value={selectedCategory}
                      onChange={handleSelectCategory}
                      dropdownStyle={dropdownStyle}
                    >
                      {categoriesByGenreId?.map(({ id, name }, index) => {
                        return (
                          <Select.Option key={index} value={id}>
                            {name}
                          </Select.Option>
                        );
                      })}
                    </Select>
                    {editHeaderErrors[2]?.hasError && (
                      <div className="episode-errors">
                        {editHeaderErrors[2]?.error}
                      </div>
                    )}
                  </div>
                </Col>
                {seasonsCast.map((seasonCast, seasonCastIndex) => {
                  return (
                    <Fragment key={seasonCastIndex}>
                      <Col span={10}>
                        <div className="new-actor__name new-category select-genre">
                          <label className="view-tvshow-lbl" htmlFor="">
                            Choose Season
                          </label>
                          <Select
                            className="search__searchField py-1 wd-100"
                            onChange={(seasonId) =>
                              handleSelectSeason(seasonId, seasonCastIndex)
                            }
                            value={seasonCast?.seasonId}
                            disabled={seasonCastIndex === 0 ? true : false}
                            dropdownStyle={dropdownStyle}
                          >
                            {seasonCastIndex === 0
                              ? seasonsOnSystem?.map(
                                  ({ indexId, season }, index) => {
                                    return (
                                      <Select.Option
                                        key={index}
                                        value={indexId}
                                      >
                                        {season}
                                      </Select.Option>
                                    );
                                  }
                                )
                              : specifiedSeasons?.map(
                                  ({ indexId, season }, index) => {
                                    if (index === seasonCastIndex - 1) {
                                      return (
                                        <Select.Option
                                          key={index}
                                          value={indexId}
                                        >
                                          {season}
                                        </Select.Option>
                                      );
                                    }
                                  }
                                )}
                          </Select>
                        </div>
                        {editHeaderErrors[3]?.seasons[seasonCastIndex]
                          .hasError && (
                          <div className="episode-errors">
                            {
                              editHeaderErrors[3]?.seasons[seasonCastIndex]
                                .error
                            }
                          </div>
                        )}
                      </Col>
                      <Col span={10}>
                        <div className="new-actor__name new-category  select-genre">
                          <label className="view-tvshow-lbl" htmlFor="">
                            Cast of this season
                          </label>
                          <Select
                            mode="multiple"
                            maxTagCount={2}
                            maxTagTextLength={8}
                            className="search__searchField py-1 wd-100"
                            value={seasonCast.castIds}
                            onChange={(castsIds) =>
                              handleChangeCasts(castsIds, seasonCastIndex)
                            }
                            dropdownStyle={dropdownStyle}
                            options={allActorsOnSystem}
                            filterOption={(input, option) =>
                              (option?.label ?? "")
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                            filterSort={(optionA, optionB) =>
                              (optionA?.label ?? "")
                                .toLowerCase()
                                .localeCompare(
                                  (optionB?.label ?? "").toLowerCase()
                                )
                            }
                          />
                          {/* {allActorsOnSystem?.map(({ id, name }, index) => (
                            <Select.Option key={index} value={id}>
                              {name}
                            </Select.Option>
                          ))}
                        </Select> */}
                          {editHeaderErrors[4]?.casts[seasonCastIndex]
                            .hasError && (
                            <div className="episode-errors">
                              {
                                editHeaderErrors[4]?.casts[seasonCastIndex]
                                  .error
                              }
                            </div>
                          )}
                        </div>
                      </Col>
                      <Col span={4}>
                        <div className="mrg-xe-20">
                          <label htmlFor=""></label>
                          <Button
                            className="delete-video-button"
                            onClick={() =>
                              handleDeleteSeasonCasts(
                                seasonCast,
                                seasonCastIndex
                              )
                            }
                            disabled={
                              seasonCastIndex === 0 || isSaved ? true : false
                            }
                          >
                            Delete
                          </Button>
                        </div>
                      </Col>
                    </Fragment>
                  );
                })}
              </Row>
              <Row justify="end">
                <Col>
                  <SecondaryPlusButton
                    buttonName={"Add Another Season"}
                    action={handleAddAnotherSeason}
                  />
                </Col>
              </Row>
              <Row gutter={[24, 24]} className="mrg-ye-20">
                <Col span={24}>
                  <div className="upload-files ">
                    <div className="upload-files__container  relative">
                      <figure>
                        <img
                          className="upload-files__upload-file"
                          src={baseUrl + filePath}
                          alt="actorImage"
                        />
                      </figure>
                      <CustomFileUploader
                        aspect={2 / 1}
                        withCrop
                        formDataType={"img"}
                        acceptedTypes={"image/*"}
                        setShowProgressBar={setShowProgressBar}
                        setProgressPercent={setProgressPercent}
                        setFilePath={(fileP) => handleSetFilePath(fileP)}
                      >
                        <div className="upload-edit-icon mrg-x-20">
                          <img role="button" src={editIcon} alt="edit" />
                        </div>
                      </CustomFileUploader>
                    </div>
                    {editHeaderErrors[5]?.hasError && (
                      <div className="episode-errors">
                        {editHeaderErrors[5]?.error}
                      </div>
                    )}
                  </div>
                  {showProgressBar && <Progress percent={progressPercent} />}
                </Col>
              </Row>
              <Row justify="end">
                <Col>
                  <PrimaryButton
                    type={"submit"}
                    buttonName="Save"
                    loading={loading}
                  />
                </Col>
              </Row>
              <Divider
                style={{
                  backgroundColor: "#CEE2F6",
                  height: "2px",
                  opacity: 0.5,
                }}
              />
            </Form>
            <EditEpisodeM3u8
              selectedHeaderId={selectedHeaderId}
              selectedSeasonId={selectedSeasonId}
              seasonsDependsOnHeaderId={seasonsDependsOnHeaderId}
              renderMeAfterEditHeader={renderEditEpisode}
              renderHeaderAfterEditEpisode={() =>
                setRenderHeader((prev) => !prev)
              }
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default EditHeaderM3u8;
