import React, { Fragment, useState } from "react";
import { Button } from "antd";
import { useEffect } from "react";

function PrimaryButton({ buttonName, loading, onClick, type, disableMe }) {
  const [loadingBtn, setLoadingBtn] = useState();

  useEffect(() => {
    setLoadingBtn(loading);
  }, [loading]);

  return (
    <Fragment>
      <Button
        htmlType={type ? type : "button"}
        loading={loadingBtn}
        onClick={onClick}
        className="primary-popup-button "
        disabled={disableMe}
      >
        {buttonName}
      </Button>
    </Fragment>
  );
}

export default PrimaryButton;
