import React, { Fragment, useEffect, useRef, useState } from "react";
import { Button, Col, Form, Input, Progress, Row, Select, Switch } from "antd";
import { CloseOutlined, PlusOutlined, UploadOutlined } from "@ant-design/icons";
import { baseUrl } from "services";
import { toastError, toastSuccess } from "helpers/toasters";
import uploadImageIcon from "assets/icons/upImageIcon.svg";
import uploadButton from "assets/icons/uploadButton.svg";
import uploadBlueIcon from "assets/icons/uploadFileBluIcon.svg";
import uploadGrayIcon from "assets/icons/uploadGrayIcon.svg";
import SubHeader from "modules/SubComponents/SubHeader";
import CustomFileUploader from "modules/SubComponents/Upload/CustomFileUploader";
import PlusButton from "modules/SubComponents/Buttons/PlusButton";
import PrimaryButton from "modules/SubComponents/Buttons/PrimaryButton";
import SecondaryEditor from "modules/SubComponents/Editor/SecondaryEditor";
import {
  customizeVideoDuration,
  getUnusedFilesForEpisode,
} from "helpers/helpfulFunctions";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import ROUTES from "constants/NewRoutes";
import editIcon from "assets/icons/pen.svg";
import { dropdownStyle } from "helpers/helpfulFunctions";

import {
  addEpisodesToHeader,
  getAllSeasonsDependsOnSeries,
  removeUnUsedFiles,
} from "Network";
import DraggerUploader from "modules/SubComponents/Upload/DraggerUploader";
import FolderUploader from "modules/SubComponents/Upload/FolderUploader";

function AddEpisodesM3u8({
  headerAddedId,
  audioList,
  subtitleList,
  genreId,
  genreName,
  numOfEpisodes,
  updateNumOfEpisodes,
}) {
  const { episodesOnSystem, seasonsOnSystem } = useSelector(
    (state) => state?.staticData?.staticDataOnSystem
  );
  const [loading, setLoading] = useState(false);
  const [isUploadingNow, setIsUploadingNow] = useState(false);
  const [selectedEpisodeToUpload, setSelectedEpisodeToUpload] = useState(null);
  const [oldSubtitles, setOldSubtitles] = useState(null);
  const [oldAudios, setOldAudios] = useState(null);
  const [seasonsDependsOnHeaderId, setSeasonsDependsOnHeaderId] = useState([]);
  const [isFree, setIsFree] = useState(false);
  const [episodes, setEpisodes] = useState([
    {
      videoHeaderId: headerAddedId,
      isSeries: true,
      seasonId: seasonsOnSystem[0].indexId,
      seriesPartNoId: episodesOnSystem[0].indexId,
      title: "",
      imgPath: "",
      path: "",
      isFree: false,
      lowQualityVideoPath: "",
      isUploading: false,
      videoOptions: {},
      yearOfProduction: "",
      // qualityId: "",
      duration: "",
      "description.en": "",
      "description.ar": "",
      episodeErrors: [
        { hasError: false, error: "Please select season" },
        { hasError: false, error: "Please select episode" },
        {
          hasError: false,
          error: "Please provide years of production",
        },
        { hasError: false, error: "Please select video quality" },
        {
          hasError: false,
          error: "Please upload an image ",
          progressStatus: false,
          progressPercent: "",
        },
        {
          hasError: false,
          error: "Please upload a video ",
          progressStatus: false,
          progressPercent: "",
        },
        {
          hasError: false,
          error: "Please provide arabic and english description",
        },
        {
          hasError: false,
          error: "Please provide title",
        },
      ],
    },
  ]);
  const location = useLocation();
  const handleFreeVideoChange = (isChecked, episodeIndex) => {
    console.log("🚀 ~ handleFreeVideoChange ~ episodeIndex:", episodeIndex);
    console.log("🚀 ~ handleFreeVideoChange ~ isChecked:", isChecked);

    let allEpisodes = [...episodes];
    // let pattern = /^\d|^$/;
    // if (pattern.test(e.target.value)) {
    allEpisodes[episodeIndex].isFree = isChecked;
    allEpisodes[episodeIndex].episodeErrors[2].hasError = false;
    setEpisodes(allEpisodes);

    console.log("🚀 ~ handleFreeVideoChange ~ allEpisodes:", allEpisodes);
    // setIsFree(isChecked);
  };

  const navigate = useNavigate();

  const folderUploaderRef = useRef(null);

  useEffect(() => {
    updateNumOfEpisodes(episodes.length);
    //eslint-disable-next-line
  }, [episodes.length]);

  useEffect(() => {
    let allEpisodes = [...episodes];
    if (numOfEpisodes > 1 && numOfEpisodes > episodes?.length) {
      let numOfAddedEpisodes = numOfEpisodes - episodes.length;
      handleAddAnotherEpisode(numOfAddedEpisodes);
    } else if (numOfEpisodes > 1 && numOfEpisodes < episodes?.length) {
      let numOfRemovedEpisodes = episodes.length - numOfEpisodes;
      for (let i = 1; i <= numOfRemovedEpisodes; i++) {
        removeUnUsedUploadedFilesForEpisode(episodes?.length - i);
      }
      allEpisodes.splice(-numOfRemovedEpisodes);
      setEpisodes(allEpisodes);
    }
    //eslint-disable-next-line
  }, [numOfEpisodes]);

  useEffect(() => {
    const isUploading = episodes?.some(
      (episode) => episode?.isUploading === true
    );
    if (isUploading) {
      setIsUploadingNow(true);
    } else {
      setIsUploadingNow(false);
    }
  }, [episodes]);

  useEffect(() => {
    //remove languages which removed from header

    let allEpisodes = [...episodes];
    if (!oldSubtitles) {
      setOldSubtitles(subtitleList);
    } else {
      let filteredSubtitles = oldSubtitles.filter((oldObj) => {
        return !subtitleList.some(
          (newObj) => oldObj.languageId === newObj.languageId
        );
      });
      setOldSubtitles(subtitleList);
      filteredSubtitles.forEach((removedSub) =>
        allEpisodes.map((episode, episodeIndex) =>
          episode.subtitles?.subtitles.find((currSub, currSubIndex) => {
            if (currSub?.languageId === removedSub?.languageId) {
              currSub.language = "";
              currSub.path = "";
              currSub.languageId = "";
              currSub.languageName = "";
              currSub.disableUploadFile = true;
            }
          })
        )
      );
    }
    if (!oldAudios) {
      setOldAudios(audioList);
    } else {
      //find objects that deleted from oldAudios
      let filteredAudios = oldAudios.filter((oldObj) => {
        return !audioList.some(
          (newObj) => oldObj.languageId === newObj.languageId
        );
      });
      setOldAudios(audioList);
      filteredAudios.forEach((removedSub) =>
        allEpisodes.map((episode, episodeIndex) =>
          episode.audios?.audios.find((currSub, currSubIndex) => {
            if (currSub?.languageId === removedSub?.languageId) {
              currSub.language = "";
              currSub.path = "";
              currSub.languageId = "";
              currSub.languageName = "";
              currSub.disableUploadFile = true;
            }
          })
        )
      );
    }
    //eslint-disable-next-line
  }, [subtitleList, audioList]);

  useEffect(() => {
    if (headerAddedId !== null) {
      getAllSeasonsDependsOnSeries(
        { videoHeaderId: headerAddedId },
        (success) => {
          if (success?.isSuccess) {
            setSeasonsDependsOnHeaderId(success.data);
          }
        },
        (fail) => {
          if (fail?.data) {
            toastError(fail.data.err_msg);
          } else {
            toastError();
          }
        }
      );
    }
  }, [headerAddedId]);

  const handleGetVideoDuration = (episodeIndex, duration) => {
    let allEpisodes = [...episodes];
    const formattedDuration = customizeVideoDuration(duration);
    allEpisodes[episodeIndex].duration = formattedDuration;
    setEpisodes(allEpisodes);
  };

  const removeUnUsedUploadedFilesForEpisode = (episodeIndex) => {
    let allEpisodes = [...episodes];
    const paths = getUnusedFilesForEpisode(allEpisodes[episodeIndex]);
    if (paths?.length > 0) {
      removeUnUsedFiles(
        paths,
        (success) => {
          if (success?.isSuccess) {
            allEpisodes.pop();
            setEpisodes(allEpisodes);
          } else {
            toastError(success.err_msg);
          }
        },
        (fail) => {
          if (fail?.data) {
            toastError(fail.data?.err_msg);
          } else {
            toastError();
          }
        }
      );
    } else {
      allEpisodes.pop();
      setEpisodes(allEpisodes);
    }
  };

  const handleAddAnotherEpisode = (num = 1) => {
    let allEpisodes = [...episodes];
    for (let i = 0; i < num; i++) {
      allEpisodes.push({
        videoHeaderId: headerAddedId,
        isSeries: true,
        seasonId: seasonsOnSystem[0].indexId,
        seriesPartNoId: episodesOnSystem[episodes?.length + i].indexId,
        title: "",
        imgPath: "",
        path: "",
        lowQualityVideoPath: "",

        isUploading: false,
        videoOptions: {},
        yearOfProduction: "",
        // qualityId: "",
        duration: "",
        "description.en": "",
        "description.ar": "",
        episodeErrors: [
          { hasError: false, error: "Please select season" },
          { hasError: false, error: "Please select episode" },
          {
            hasError: false,
            error: "Please provide years of production",
          },
          { hasError: false, error: "Please select video quality" },
          {
            hasError: false,
            error: "Please upload an image ",
            progressStatus: false,
            progressPercent: "",
          },
          {
            hasError: false,
            error: "Please upload a video ",
            progressStatus: false,
            progressPercent: "",
          },
          {
            hasError: false,
            error: "Please provide arabic and english description",
          },

          {
            hasError: false,
            error: "Please provide title",
          },
        ],
      });
    }

    setEpisodes(allEpisodes);
  };

  const handleChangeEpisodeTitle = (event, episodeIndex) => {
    let allEpisodes = [...episodes];
    allEpisodes[episodeIndex].title = event.target.value;
    allEpisodes[episodeIndex].episodeErrors[7].hasError = false;
    setEpisodes(allEpisodes);
  };

  const handleSelectSeason = (
    seasonId,
    seasonObj,
    episodeIndex,
    headerAddedId
  ) => {
    let allEpisodes = [...episodes];
    allEpisodes[episodeIndex].seasonId = seasonId;
    allEpisodes[episodeIndex].videoHeaderId = headerAddedId;
    allEpisodes[episodeIndex].episodeErrors[0].hasError = false;
    setEpisodes(allEpisodes);
  };

  const handleSelectEpisode = (episodeId, episodeIndex) => {
    let allEpisodes = [...episodes];
    if (allEpisodes[episodeIndex].seasonId === "") {
      allEpisodes[episodeIndex].episodeErrors[0].hasError = true;
      allEpisodes[episodeIndex].seriesPartNoId = "";
      setEpisodes(allEpisodes);
    } else {
      if (episodeId?.length > 0) {
        if (
          allEpisodes.some((episode) => episode?.seriesPartNoId === episodeId)
        ) {
          toastError("this episode was selected before!");
          allEpisodes[episodeIndex].seriesPartNoId = "";
          setEpisodes(allEpisodes);
        } else {
          allEpisodes[episodeIndex].seriesPartNoId = episodeId;
          allEpisodes[episodeIndex].episodeErrors[1].hasError = false;
          setEpisodes(allEpisodes);
        }
      }
    }
  };

  const handleYearsOfProduction = (e, episodeIndex) => {
    const allEpisodes = [...episodes];
    const pattern = /^[\d]{4,4}$/;
    const value = e.target.value;

    if (pattern.test(value)) {
      allEpisodes[episodeIndex].yearOfProduction = +value;
      allEpisodes[episodeIndex].episodeErrors[2].hasError = false;
      setEpisodes(allEpisodes);
    } else {
      allEpisodes[episodeIndex].yearOfProduction = +value;
      allEpisodes[episodeIndex].episodeErrors[2].hasError = true;
      setEpisodes(allEpisodes);
    }
  };

  ///image config
  const handleSetImageFilePath = (imageFilePath, episodeIndex) => {
    if (imageFilePath?.length > 0) {
      let allEpisodes = [...episodes];
      allEpisodes[episodeIndex].imgPath = imageFilePath;
      allEpisodes[episodeIndex].episodeErrors[4].hasError = false;
      setEpisodes(allEpisodes);
    }
  };

  const handleShowImagesProgressBar = (show, episodeIndex) => {
    let allEpisodes = [...episodes];
    allEpisodes[episodeIndex].episodeErrors[4].progressStatus = show;
    setEpisodes(allEpisodes);
  };

  const handleSetImageProgressPercent = (percent, episodeIndex) => {
    let allEpisodes = [...episodes];
    allEpisodes[episodeIndex].episodeErrors[4].progressPercent = percent;
    setEpisodes(allEpisodes);
  };

  const handleSetVideoFilePathForM3u8 = (
    // filePath,
    // fileName,
    index,
    videoPath
  ) => {
    // console.log("🚀 ~ handleFreeVideoChange ~ videoPath:", videoPath);
    // console.log("🚀 ~ handleFreeVideoChange ~ fileName:", fileName);
    // console.log("🚀 ~ handleSetVideoFilePathForM3u8 ~ filePath:", filePath);
    let allEpisodes = [...episodes];
    allEpisodes[index].path = videoPath;
    allEpisodes[index].episodeErrors[5].hasError = false;
    console.log("allEpisodes", allEpisodes[index]);
    setEpisodes(allEpisodes);
    // setVideoPath(videoPath);
  };

  const handleEditUploadVideo = (episodeIndex) => {
    setSelectedEpisodeToUpload(episodeIndex);
  };

  const handleDataFromTextEditor = (
    descriptionEn,
    descriptionAr,
    episodeIndex
  ) => {
    if (descriptionEn && descriptionAr) {
      let allEpisodes = [...episodes];
      allEpisodes[episodeIndex]["description.ar"] = descriptionAr;
      allEpisodes[episodeIndex]["description.en"] = descriptionEn;
      allEpisodes[episodeIndex].episodeErrors[6].hasError = false;
      setEpisodes(allEpisodes);
    }
  };

  /*____________________Start videos________________________*/

  const removeThisEpisode = (episodeIndex) => {
    if (folderUploaderRef.current) {
      folderUploaderRef.current.cancelUpload();
    }
    let allEpisodes = [...episodes];
    removeUnUsedUploadedFilesForEpisode(episodeIndex);
    allEpisodes.splice(episodeIndex, 1);
    setEpisodes(allEpisodes);
  };

  const validateAllEpisodes = () => {
    episodes.map((episode, episodeIndex) => {
      if (episode?.title === "") {
        let allEpisodes = [...episodes];
        allEpisodes[episodeIndex].episodeErrors[7].hasError = true;
        setEpisodes(allEpisodes);
      }
      if (episode?.seasonId === "") {
        let allEpisodes = [...episodes];
        allEpisodes[episodeIndex].episodeErrors[0].hasError = true;
        setEpisodes(allEpisodes);
      }
      if (episode?.seriesPartNoId === "") {
        let allEpisodes = [...episodes];
        allEpisodes[episodeIndex].episodeErrors[1].hasError = true;
        setEpisodes(allEpisodes);
      }
      if (
        episode?.yearOfProduction === "" ||
        episode?.yearOfProduction === null ||
        episode?.yearOfProduction === 0
      ) {
        let allEpisodes = [...episodes];
        allEpisodes[episodeIndex].episodeErrors[2].hasError = true;
        setEpisodes(allEpisodes);
      }

      if (episode?.imgPath === "") {
        let allEpisodes = [...episodes];
        allEpisodes[episodeIndex].episodeErrors[4].hasError = true;
        setEpisodes(allEpisodes);
      }

      if (episode?.path === "") {
        let allEpisodes = [...episodes];
        allEpisodes[episodeIndex].episodeErrors[5].hasError = true;
        setEpisodes(allEpisodes);
      }

      if (
        episode?.["description.en"]?.length === 0 ||
        episode?.["description.ar"]?.length === 0
      ) {
        let allEpisodes = [...episodes];
        allEpisodes[episodeIndex].episodeErrors[6].hasError = true;
        setEpisodes(allEpisodes);
      }
    });
  };

  const callAddEpisodesOnly = async (filteredEpisodes) => {
    // setTimeout(
    await addEpisodesToHeader(
      filteredEpisodes,
      (success) => {
        if (success?.isSuccess) {
          toastSuccess(success.message);
          setLoading(false);
          if (location?.state?.genreName === "Know it") {
            navigate(ROUTES.TVPROGRAMS, {
              state: {
                genreId,
                name: genreName,
              },
            });
          } else if (location?.state?.genreName === "Anime") {
            navigate(ROUTES.ANIME, {
              state: {
                genreId,
                name: genreName,
              },
            });
          } else if (location?.state?.genreName === "Fun Store") {
            navigate(ROUTES.FUNSTORE, {
              state: {
                genreId,
                name: genreName,
              },
            });
          } else {
            navigate(ROUTES.TVSHOWS, {
              state: { genreId, name: genreName },
            });
          }
        } else {
          toastError(success.err_msg);
          setLoading(false);
        }
      },
      (fail) => {
        if (fail?.data) {
          toastError(fail.data?.err_msg);
          setLoading(false);
        } else {
          toastError();
          setLoading(false);
        }
      }
    );
    //   ,
    //   1000
    // );
  };

  const handleOnFinish = async () => {
    validateAllEpisodes();
    const checkAnyError = [];
    const episodeWhichHasError = [];
    await episodes.forEach((episode, episodeIndex) => {
      const hasEpisodeError = episode.episodeErrors.some(
        (singleObject) => singleObject.hasError
      );
      if (hasEpisodeError) {
        checkAnyError.push(episodeIndex);
        episodeWhichHasError.push(episodeIndex + 1);
      }
    });

    if (checkAnyError.length === 0) {
      setLoading(true);
      //filter only subtitles which have paths and reject that has no path
      const filteredEpisodes = await episodes.map((episode) => {
        return {
          ...episode,
        };
      });

      const episodesWithoutErrors = await filteredEpisodes.map((episode) => {
        const { episodeErrors, isUploading, videoOptions, ...remainData } =
          episode;
        return remainData;
      });

      try {
        const promises = episodesWithoutErrors.map(
          async (episode, episodeIndex) => {
            return episodesWithoutErrors[episodeIndex];
          }
        );
        const updatedEpisodes = await Promise.all(promises);
        await callAddEpisodesOnly(updatedEpisodes);
      } catch (error) {
        toastError();
        setLoading(false);
      }
    } else {
      let episodesErrorToSet = new Set(episodeWhichHasError);
      let episodesHasError = [...episodesErrorToSet];
      if (episodesHasError?.length === 1) {
        toastError(
          `Please, fill all the required fields for episode ( ${episodesHasError[0]} ) `
        );
      } else {
        toastError(
          `Please, fill all the required fields for episodes ( ${episodesHasError.join(
            ","
          )} ) `
        );
      }
    }
  };

  return (
    <Fragment>
      <SubHeader bgColor="#27A2D9" title="Episodes" />
      <Form className="wd-100" onFinish={handleOnFinish}>
        {episodes.map((episode, episodeIndex) => (
          <div
            className="add-episodes-to-tvshows wd-100 mrg-y-30 pad-y-30"
            key={episodeIndex}
          >
            <div className=" wd-100 pad-x-10 ">
              {episodeIndex !== 0 &&
                episodeIndex === episodes?.length - 1 &&
                isUploadingNow === false && (
                  <Row justify="end" className="mrg-ye-20">
                    <Col>
                      <div
                        className="removeEpisods pad-xe-30"
                        role={"button"}
                        onClick={() => removeThisEpisode(episodeIndex)}
                      >
                        <CloseOutlined
                          style={{
                            fontSize: "20px",
                            color: "#6F767E",
                          }}
                        />
                      </div>
                    </Col>
                  </Row>
                )}
              <Row></Row>
              {/* Season */}
              <Row gutter={36}>
                <Col xs={24} md={12} className="mrg-ye-20">
                  <div className="new-category  ">
                    <label className="view-tvshow-lbl-main">Season</label>
                    <Select
                      placeholder={"Choose Season"}
                      dropdownStyle={dropdownStyle}
                      className="search__searchField py-1 wd-100"
                      onChange={(seasonId, seasonObj) =>
                        handleSelectSeason(
                          seasonId,
                          seasonObj,
                          episodeIndex,
                          headerAddedId
                        )
                      }
                      value={episode?.seasonId || undefined}
                    >
                      {seasonsDependsOnHeaderId?.map(
                        ({ id, name }, seasonIndex) => {
                          if (seasonIndex === 0) {
                            return (
                              <Select.Option key={seasonIndex} value={id}>
                                {name}
                              </Select.Option>
                            );
                          }
                        }
                      )}
                    </Select>
                    {episode?.episodeErrors[0]?.hasError && (
                      <div className="episode-errors">
                        {episode?.episodeErrors[0]?.error}
                      </div>
                    )}
                  </div>
                </Col>
                {/* Episode */}
                <Col xs={24} md={12} className="mrg-ye-20">
                  <div className="new-category">
                    <label className="view-tvshow-lbl-main">
                      Choose Episode
                    </label>
                    <Select
                      placeholder="Choose Episode"
                      dropdownStyle={dropdownStyle}
                      className="search__searchField py-1 wd-100"
                      onChange={(episodeId) =>
                        handleSelectEpisode(episodeId, episodeIndex)
                      }
                      value={episode?.seriesPartNoId}
                    >
                      {episodesOnSystem?.map(({ indexId, episode }, index) => {
                        if (episodeIndex === index) {
                          return (
                            <Select.Option key={indexId} value={indexId}>
                              {episode}
                            </Select.Option>
                          );
                        }
                      })}
                    </Select>
                    {episode?.episodeErrors[1]?.hasError && (
                      <div className="episode-errors">
                        {episode?.episodeErrors[1]?.error}
                      </div>
                    )}
                  </div>
                </Col>
              </Row>
              {/* EpisodeTitle */}
              <Row gutter={36}>
                <Col xs={24} md={12} className="mrg-ye-20">
                  <div className="new-actor__name new-category ">
                    <label className="view-tvshow-lbl-main" htmlFor="">
                      Episode title
                    </label>
                    <Input
                      placeholder="Episode title"
                      type="text"
                      className="search__searchField py-2"
                      onChange={(event) =>
                        handleChangeEpisodeTitle(event, episodeIndex)
                      }
                    />
                  </div>
                  {episode?.episodeErrors[7]?.hasError && (
                    <div className="episode-errors">
                      {episode?.episodeErrors[7]?.error}
                    </div>
                  )}
                </Col>
                {/* YearsOfProduction */}
                <Col xs={24} md={12} className="mrg-ye-20">
                  <div className="new-category  ">
                    <label className="view-tvshow-lbl-main">
                      Year of production
                    </label>
                    <Input
                      type="number"
                      placeholder="Year of production"
                      className="search__searchField py-2"
                      onChange={(e) => handleYearsOfProduction(e, episodeIndex)}
                      value={episode?.yearOfProduction || undefined}
                    ></Input>
                    {episode?.episodeErrors[2]?.hasError && (
                      <div className="episode-errors">
                        {episode?.episodeErrors[2]?.error}
                      </div>
                    )}
                  </div>
                </Col>
              </Row>
              <Row gutter={16} style={{ marginBottom: "2.5rem" }}>
                {/* Image */}
                <Col xs={24} md={8} style={{ height: "251px" }}>
                  <div className="upload-files upload-files__image-small">
                    <div className="upload-files__container upload-files__container--image-small relative">
                      {" "}
                      {episode?.imgPath?.length > 0 && (
                        <figure>
                          <img
                            className="upload-files__upload-file"
                            src={baseUrl + episode?.imgPath}
                            alt="actorImage"
                            style={{
                              position: "absolute",
                              zIndex: "3",
                              width: "100%",
                            }}
                          />
                        </figure>
                      )}
                      <DraggerUploader
                        withCrop
                        aspect={2 / 1}
                        formDataType={"img"}
                        acceptedTypes={"image/*"}
                        setShowProgressBar={(show) =>
                          handleShowImagesProgressBar(show, episodeIndex)
                        }
                        setProgressPercent={(percent) =>
                          handleSetImageProgressPercent(percent, episodeIndex)
                        }
                        setFilePath={(filePath) =>
                          handleSetImageFilePath(filePath, episodeIndex)
                        }
                      >
                        {episode?.imgPath?.length ? (
                          <div className="upload-edit-icon mrg-x-20">
                            <img role="button" src={editIcon} alt="edit" />
                          </div>
                        ) : (
                          <div
                            role="button"
                            className="upload-files__button  "
                            style={{
                              boxShadow: "none",
                              backgroundColor: "transparent",
                              justifyContent: "center",
                              gap: "30px",
                              margin: "auto",
                            }}
                          >
                            <div className="upload-files__icon">
                              <img src={uploadButton} alt="" />
                            </div>
                            <div className="upload-files__title">
                              {"Upload Image"}
                            </div>
                            <div className="upload-files__icon">
                              <img src={uploadImageIcon} alt="" />
                            </div>
                          </div>
                        )}
                      </DraggerUploader>
                    </div>
                    {episode?.episodeErrors[4]?.hasError && (
                      <div className="episode-errors">
                        {episode?.episodeErrors[4]?.error}
                      </div>
                    )}
                  </div>
                  {episode?.episodeErrors[4]?.progressStatus && (
                    <Progress
                      style={{ marginTop: "1rem" }}
                      percent={episode?.episodeErrors[4]?.progressPercent}
                    />
                  )}
                </Col>
                {/* </div> */}
                {/* Video */}
                <Col xs={24} md={16}>
                  {/* <CompressedFileUploader
                    setFilePath={(filePath, fileName, videoPath) =>
                      handleSetVideoFilePathForM3u8(
                        filePath,
                        episodeIndex,
                        fileName,
                        videoPath
                      )
                    }
                    episodeIndex={episodeIndex}
                  /> */}
                  {/* <DraggerFileUploader
                    setFilePath={(filePath, fileName, videoPath) =>
                      handleSetVideoFilePathForM3u8(
                        filePath,
                        episodeIndex,
                        fileName,
                        videoPath
                      )
                    }
                    episodeIndex={episodeIndex}
                  /> */}
                  <FolderUploader
                    getVideoDuration={(duration) =>
                      handleGetVideoDuration(episodeIndex, duration)
                    }
                    ref={folderUploaderRef}
                    setFilePath={(videoPath) =>
                      handleSetVideoFilePathForM3u8(episodeIndex, videoPath)
                    }
                    episodeIndex={episodeIndex}
                  />
                  {episode?.episodeErrors[5]?.hasError && (
                    <div className="episode-errors">
                      {episode?.episodeErrors[5]?.error}
                    </div>
                  )}
                </Col>
              </Row>
              {/* Descriptions */}
              <Row>
                <Col span={24}>
                  <div className="wd-100 mrg-x-auto  mrg-y-20 ">
                    <span className="view-tvshow-lbl-main">Description</span>
                    <SecondaryEditor
                      className="d-none"
                      getTextEditorData={(descriptionEn, descriptionAr) =>
                        handleDataFromTextEditor(
                          descriptionEn,
                          descriptionAr,
                          episodeIndex
                        )
                      }
                    />
                    {episode?.episodeErrors[6]?.hasError && (
                      <div className="episode-errors">
                        {episode?.episodeErrors[6]?.error}
                      </div>
                    )}
                  </div>
                </Col>
              </Row>
              <Row justify="start">
                <Col>
                  <label className="view-tvshow-lbl-main ">Free</label>
                </Col>
              </Row>
              <Row justify="start">
                <Col span={9}>
                  <p>User can watch this video without subscription</p>
                </Col>
                <Col>
                  <Switch
                    style={{
                      backgroundColor: episode?.isFree ? "#31AA4A" : "", // Change the background color when checked
                      // Change the border color when checked
                    }}
                    checked={episode.isFree} // Reflect the state of the 'isFree' variable
                    onChange={(checked) =>
                      handleFreeVideoChange(checked, episodeIndex)
                    } // Toggle the 'isFree' state on change
                  />
                </Col>
              </Row>
            </div>
          </div>
        ))}
        <div className="flex justify-content-between wd-100 ">
          <span className="view-tvshow-lbl uploooooding  flex-1">
            {isUploadingNow
              ? " You cannot add new episodes until the current uploads are completed"
              : ""}
          </span>

          <div className="config-video-buttons flex justify-end  wd-40 mrg-ye-50 ">
            <div className="frist-button mrg-xe-20">
              <PlusButton
                buttonName="Add Episode"
                styling="secondary"
                action={() => handleAddAnotherEpisode()}
                disableMe={isUploadingNow}
              />
            </div>
            <div className="btn-save mrg-xe-20" style={{ width: "129px" }}>
              <PrimaryButton
                type={"submit"}
                buttonName="Add"
                loading={loading}
              />
            </div>
          </div>
        </div>
      </Form>
    </Fragment>
  );
}
export default AddEpisodesM3u8;
