import { Upload, Progress } from "antd";
import uploadButton from "assets/icons/uploadButton.svg";
import uploadVideoIcon from "assets/icons/uploadVideoIcon.svg";
import editIcon from "assets/icons/pen.svg";
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { toastError } from "helpers/toasters";
import { baseUrl } from "services";
import axios from "axios";
import Hls from "hls.js";
import { addTokenToMedia } from "helpers/addTokenToMedia";
import { PauseCircleOutlined, PlayCircleOutlined } from "@ant-design/icons";
import LostConnectionModal from "../Modal/LostConnection";

const { Dragger } = Upload;

const API = {
  GENERATE_FOLDER_ID: `${baseUrl}api/video/m3u8/createFolderId?mainFolderName`,
  CHECK_IS_EXIST: `${baseUrl}api/video/m3u8/checkFileExist`,
  UPLOAD_FOLDER: `${baseUrl}api/video/m3u8/uploadFolder`,
};
const allowedTypes = [".m3u8", ".ts"];

const FolderUploader = forwardRef(
  (
    { setFilePath, episodeIndex, srcVideo, observeUploading, getVideoDuration },
    ref
  ) => {
    const [uploaded, setUploaded] = useState(false);
    const [isUploading, setIsUploading] = useState(false);
    const [filesList, setFilesList] = useState([]);
    const [notValidType, setNotValidType] = useState([]);
    const [filesLength, setFilesLength] = useState(0);
    const [folderId, setFolderId] = useState("");
    const [progress, setProgress] = useState(0);
    const [videoPath, setVideoPath] = useState("");
    const [isPaused, setIsPaused] = useState(false);
    const [userPauseTheUpload, setUserPauseTheUpload] = useState(false);
    const [isOnline, setIsOnline] = useState(navigator.onLine);

    const currentFileIndex = useRef(0);
    const cancelTokenSource = useRef(null);
    const modalRef = useRef();

    useEffect(() => {
      return () => {
        if (cancelTokenSource.current) {
          cancelTokenSource.current.cancel("Upload canceled");
        }
        setUploaded(false);
        setIsUploading(false);
        setFilesList([]);
        setNotValidType([]);
        setFilesLength(0);
        setFolderId(null);
        setProgress(0);
        setIsPaused(false);
        setUserPauseTheUpload(false);
        currentFileIndex.current = 0;
      };
    }, []);

    useEffect(() => {
      if (observeUploading) {
        observeUploading(isUploading);
      }
    }, [isUploading]);

    // Observe internet connection
    useEffect(() => {
      const handleOffline = () => {
        setIsOnline(false);
        if (progress > 0) {
          setIsPaused(true);
          if (!userPauseTheUpload) {
            modalRef.current.openModal();
          }
          if (cancelTokenSource.current) {
            cancelTokenSource.current.cancel("Upload paused: connection lost");
          }
        }
      };

      const handleOnline = () => {
        setIsOnline(true);
        if (isPaused) {
          modalRef.current.closeModal();
          if (!userPauseTheUpload) {
            setIsPaused(false);
          }
        }
      };

      window.addEventListener("offline", handleOffline);
      window.addEventListener("online", handleOnline);

      return () => {
        window.removeEventListener("offline", handleOffline);
        window.removeEventListener("online", handleOnline);
      };
    }, [isPaused, progress, userPauseTheUpload]);

    // Set video path is edit mode
    useEffect(() => {
      if (srcVideo) {
        if (!isUploading) {
          setUploaded(true);
        } else {
          setUploaded(false);
        }
        setVideoPath(srcVideo);
      }
    }, [srcVideo, isUploading]);

    // Fetch m3u8 file and play video
    useEffect(() => {
      const videoElement = document.getElementById(episodeIndex);
      if (Hls.isSupported() && videoPath && uploaded) {
        const hlsInstance = new Hls();
        hlsInstance.loadSource(addTokenToMedia(baseUrl + videoPath));
        hlsInstance.attachMedia(document.getElementById(episodeIndex));

        hlsInstance.on(Hls.Events.MEDIA_ATTACHED, () => {
          videoElement.addEventListener("loadedmetadata", () => {
            getVideoDuration?.(videoElement.duration);
          });
        });
      }
    }, [videoPath, uploaded]);

    useEffect(() => {
      if (notValidType.length > 0) {
        toastError("Not valid type!");
      }
    }, [notValidType]);

    // Generate folder ID
    useEffect(() => {
      const generateFolderId = async () => {
        try {
          const mainFolderName = filesList[0].path.split("/")[0];
          const response = await axios.get(
            `${API.GENERATE_FOLDER_ID}=${mainFolderName}`
          );
          const { isSuccess, folderId } = response.data;
          if (!isSuccess) {
            throw new Error("Something went wrong");
          }
          setFolderId(folderId);
        } catch (error) {
          toastError(error.message || "Something went wrong");
        }
      };

      if (filesLength === filesList.length && filesList.length > 0) {
        generateFolderId();
      }
    }, [filesLength, filesList]);

    // Check file is exist then upload
    useEffect(() => {
      const uploadFiles = async () => {
        setIsUploading(true);
        try {
          for (let i = currentFileIndex.current; i < filesList.length; i++) {
            if (isPaused) break;

            setProgress(Math.round(((i + 1) / filesList.length) * 100));

            const file = filesList[i];

            const response = await axios.get(
              `${API.CHECK_IS_EXIST}?folderId=${folderId}&relativePath=${file.path}`
            );

            const { isSuccess, message } = response.data;

            if (!isSuccess) {
              throw new Error(message || "Something went wrong");
            }
            // Upload file
            try {
              cancelTokenSource.current = axios.CancelToken.source();

              const formData = new FormData();
              formData.append("file", file.file);
              formData.append("relativePath", file.path); // Add relative path
              formData.append("totalCount", filesList.length);
              formData.append("folderId", folderId);

              const response = await axios.post(API.UPLOAD_FOLDER, formData, {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
                cancelToken: cancelTokenSource.current.token,
              });

              const { isSuccess, message, m3u8FilePath } = response.data;

              if (!isSuccess) {
                // throw new Error(message || "Something went wrong");
                continue;
              }

              if (m3u8FilePath) {
                setUploaded(true);
                setFilePath(m3u8FilePath);
                setVideoPath(m3u8FilePath);
              }

              // setProgress(((i + 1) / filesList.length) * 100);

              currentFileIndex.current = i + 1;

              if (i + 1 === filesList.length) {
                setIsUploading(false);
              }
            } catch (error) {
              return toastError(error.message || "Something went wrong");
            }
          }
        } catch (error) {
          return toastError(error.message || "Something went wrong");
        }
      };

      if (folderId && !isPaused) {
        uploadFiles();
      }
    }, [folderId, isPaused]);

    const handlePause = (e) => {
      e.preventDefault();
      setIsPaused(true);
      setUserPauseTheUpload(true);
      if (cancelTokenSource.current)
        cancelTokenSource.current.cancel("Upload paused: user action");
    };

    const handleResume = (e) => {
      e.preventDefault();
      setIsPaused(false);
      setUserPauseTheUpload(false);
    };

    const handleEditClick = (event) => {
      event.preventDefault();
    };

    const beforeUpload = (file) => {
      const isExist = filesList.find(
        (f) => f.name === file.name && f.path === file.webkitRelativePath
      );

      const isValidType =
        file.name.toLowerCase().endsWith(allowedTypes[0]) ||
        file.name.toLowerCase().endsWith(allowedTypes[1]);

      if (!isValidType) {
        setNotValidType([...notValidType, true]);
        return false;
      }

      if (!isExist && isValidType) {
        setFilesList((preState) => {
          return [
            ...preState,
            {
              name: file.name,
              path: file.webkitRelativePath,
              file: file,
            },
          ];
        });
        return true;
      }

      return true;
    };

    const onChange = (info) => {
      const { status } = info.file;

      if (status === "uploading") {
        setFilesLength((pre) => pre + 1);
      }

      return false;
    };

    function handleCancelUplaod() {
      setUploaded(false);
      setIsUploading(false);
      setFilesList([]);
      setNotValidType([]);
      setFilesLength(0);
      setFolderId(null);
      setProgress(0);
      setIsPaused(false);
      setUserPauseTheUpload(false);
      currentFileIndex.current = 0;

      if (cancelTokenSource.current)
        cancelTokenSource.current.cancel("Upload canceled: user action");
    }

    const buttonIcons = (
      <>
        {isPaused ? (
          <PlayCircleOutlined
            style={{
              fontSize: "25px",
              color: "#28a745",
              fontWeight: "bold",
            }}
          />
        ) : (
          <PauseCircleOutlined
            style={{
              fontSize: "25px",
              color: "#dc3545",
              fontWeight: "bold",
            }}
          />
        )}
      </>
    );

    useImperativeHandle(ref, () => ({
      cancelUpload: handleCancelUplaod,
    }));

    // console.log("progress", progress);
    // console.log("isUploading", isUploading);
    // console.log("isPaused", isPaused);
    // console.log("Uploaded", uploaded);

    return (
      <>
        <LostConnectionModal ref={modalRef} />
        <div className="folder-uploader-wrapper">
          <Dragger
            onClick={() => {
              setFilesList([]);
              setNotValidType([]);
              setFilesLength(0);
            }}
            disabled={isUploading}
            className="folder-uploader"
            directory
            webkitdirectory
            showUploadList={false}
            beforeUpload={beforeUpload}
            onChange={onChange}
            customRequest={() => false}
          >
            {!uploaded && (
              <div className="folder-uploader-container">
                <img src={uploadButton} alt="uploader" />
                <p>Upload video</p>
                <img src={uploadVideoIcon} alt="uploader" />
              </div>
            )}
            {uploaded && (
              <div className="folder-uploader-video-container">
                <button
                  className="folder-uploader-video-container-button"
                  onClick={handleEditClick}
                >
                  <img src={editIcon} alt="edit" />
                </button>
                <video autoPlay muted controls id={episodeIndex}></video>
              </div>
            )}
          </Dragger>

          {filesList.length > 0 && (
            <div className="progress-container">
              <Progress
                percent={progress}
                status={progress === 100 ? "success" : "active"}
              />
              {progress > 0 && !uploaded && (
                <>
                  <button
                    disabled={!isOnline}
                    className="progress-button"
                    onClick={isPaused ? handleResume : handlePause}
                  >
                    {buttonIcons}
                  </button>
                  <button
                    className="cancel-button danger"
                    onClick={handleCancelUplaod}
                  >
                    Cancel
                  </button>
                </>
              )}
            </div>
          )}
        </div>
      </>
    );
  }
);

export default FolderUploader;
