import React, { useRef, useState } from "react";
import {
  Button,
  Col,
  Form,
  Input,
  Progress,
  Row,
  Select,
  Space,
  Switch,
} from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { baseUrl } from "services";
import { toastError, toastSuccess } from "helpers/toasters";
import SubHeader from "modules/SubComponents/SubHeader";
import PlusButton from "modules/SubComponents/Buttons/PlusButton";
import PrimaryButton from "modules/SubComponents/Buttons/PrimaryButton";
import SecondaryEditor from "modules/SubComponents/Editor/SecondaryEditor";
import {
  customizeVideoDuration,
  getMaxIndexOfEpisodesOrParts,
  getSpecifiedEpisodes,
  getUnusedFilesForEpisode,
} from "helpers/helpfulFunctions";
import editIcon from "assets/icons/pen.svg";
import uploadImageIcon from "assets/icons/upImageIcon.svg";
import uploadButton from "assets/icons/uploadButton.svg";
import warningIcon from "assets/icons/warning.svg";

import { useSelector } from "react-redux";
import { useEffect } from "react";
import {
  addEpisodesToHeader,
  deleteEpisodeFromHeader,
  editEpisodeM3u8,
  getAllVideosDetailsForMovie,
  removeUnUsedFiles,
} from "Network";
import DeleteModal from "modules/Modals/DeleteModal";
import { dropdownStyle } from "helpers/helpfulFunctions";
import DraggerUploader from "modules/SubComponents/Upload/DraggerUploader";
import { v4 as uuidv4 } from "uuid";
import FolderUploader from "modules/SubComponents/Upload/FolderUploader";

function EditPartM3u8({ selectedHeaderId, renderMe, renderEditMovieHeader }) {
  const { languagesOnSystem, partsOnSystem } = useSelector(
    (state) => state?.staticData?.staticDataOnSystem
  );

  const [headerDataByPart, setHeaderDataByPart] = useState([]);
  const [isUploadingNow, setIsUploadingNow] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [maxIndex, setMaxIndex] = useState(null);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [currentParts, setCurrentParts] = useState([]);
  const [newParts, setNewParts] = useState([]);
  const [partsList, setPartsList] = useState([]);
  const [selectedPartData, setSelectedPartData] = useState(null);
  const [renderEditMovie, setRenderEditMovie] = useState(false);
  const [renderMeAfterActions, setRenderMeAfterActions] = useState(false);
  const [isFree, setIsFree] = useState(false);
  const [selectedAudio, setSelectedAudio] = useState([]);
  const [addAvailabityIndx, setAddAvailabilityIndx] = useState(0);
  const [isUploading, setIsUploading] = useState(false);

  const [parts, setParts] = useState([
    {
      videoHeaderId: "",
      id: "",
      isSeries: false,
      seriesPartNoId: "",
      title: "",
      imgPath: "",
      path: "",
      lowQualityVideoPath: "",
      isUploading: false,
      videoOptions: {},
      isFree: false,
      yearOfProduction: "",
      duration: "",
      "description.en": "",
      "description.ar": "",

      partErrors: [
        { hasError: false, error: "Please provide title" },
        { hasError: false, error: "Please select part" },
        {
          hasError: false,
          error: "Please provide years of production",
        },
        { hasError: false, error: "Please select video quality" },
        {
          hasError: false,
          error: "Please upload an image ",
          progressStatus: false,
          progressPercent: "",
        },
        {
          hasError: false,
          error: "Please upload a video ",
          progressStatus: false,
          progressPercent: "",
        },
        {
          hasError: false,
          error: "Please provide arabic and english description",
        },
      ],
    },
  ]);

  useEffect(() => {
    const isUploading = parts?.some((episode) => episode?.isUploading === true);
    if (isUploading) {
      setIsUploadingNow(true);
    } else {
      setIsUploadingNow(false);
    }
  }, [parts]);

  useEffect(() => {
    if (selectedHeaderId?.length > 0) {
      getAllHeaderDataAfterActions();
    }
    //eslint-disable-next-line
  }, [selectedHeaderId, renderMe]);

  useEffect(() => {
    if (headerDataByPart?.videos?.length > 0) {
      if (!selectedPartData) {
        console.log("🚀 ~ useEffect ~ selectedPartData:", selectedPartData);
        setSelectedPartData(headerDataByPart.videos[0]);
      }
      setMaxIndex(getMaxIndexOfEpisodesOrParts(headerDataByPart));
      let allParts = [...parts];
      allParts[0].videoHeaderId = headerDataByPart.videoHeaderId;
      setParts(allParts);
    } else {
      let allParts = [...parts];
      allParts[0].videoHeaderId = headerDataByPart.videoHeaderId;
      allParts[0].seriesPartNoId = partsOnSystem[0].indexId;
      allParts[0].isSeries = false;
      setParts(allParts);
    }
    //eslint-disable-next-line
  }, [headerDataByPart, renderMeAfterActions]);

  useEffect(() => {
    let allParts = [...parts];
    if (selectedPartData) {
      allParts[0].id = selectedPartData?.id;
      allParts[0].title = selectedPartData?.title;
      allParts[0].isFree = selectedPartData?.isFree;

      allParts[0].seriesPartNoId = selectedPartData?.episodePartId;
      allParts[0].imgPath = selectedPartData?.imgPath;
      allParts[0].path = selectedPartData?.path;
      allParts[0].yearOfProduction =
        selectedPartData?.yearOfProduction.toString();
      allParts[0].qualityId = selectedPartData?.qualityId;
      allParts[0].duration = selectedPartData?.duration;
      allParts[0]["description.en"] = selectedPartData?.description?.en;
      allParts[0]["description.ar"] = selectedPartData?.description?.ar;
      //   Here *
      //   allParts[0].videos.videos = selectedPartData?.videos;
      setParts(allParts);
    } //eslint-disable-next-line
  }, [selectedPartData]);

  useEffect(() => {
    if (partsOnSystem?.length > 0 && headerDataByPart?.videos?.length > 0) {
      setCurrentParts(
        getSpecifiedEpisodes(partsOnSystem, headerDataByPart?.videos)
      );
      setNewParts(
        getSpecifiedEpisodes(partsOnSystem, headerDataByPart?.videos, 1)
      );
    }
    //eslint-disable-next-line
  }, [headerDataByPart]);

  useEffect(() => {
    renderEditMovieHeader(renderEditMovie);
    //eslint-disable-next-line
  }, [renderEditMovie]);

  const observeUploading = (status) => {
    setIsUploading(status);
  };

  const handleGetVideoDuration = (episodeIndex, duration) => {
    let allEpisodes = [...parts];
    const formattedDuration = customizeVideoDuration(duration);
    allEpisodes[episodeIndex].duration = formattedDuration;
    setParts(allEpisodes);
  };

  const handleFreeVideoChange = (isChecked, partIndex) => {
    let allParts = [...parts];

    allParts[partIndex].isFree = isChecked;
    // allParts[partIndex].episodeErrors[2].hasError = false;
    setParts(allParts);
    // setIsFree(isChecked);
  };

  const getAllHeaderDataAfterActions = (partId) => {
    getAllVideosDetailsForMovie(
      {
        videoHeaderId: selectedHeaderId,
      },
      (success) => {
        if (success?.isSuccess) {
          //
          setIsFree(success.data[0]?.videos.isFree);
          setPartsList(success.data[0]?.videos);
          setHeaderDataByPart(success.data[0]);

          if (partId?.length > 0) {
            const dataForSelectedPart = success.data[0]?.videos?.filter(
              (part) => part?.episodePartId === partId
            )[0];
            setSelectedPartData(dataForSelectedPart);
          }
        }
      },
      (fail) => {
        toastError(fail.data.err_msg);
      }
    );
  };

  const addPart = (num) => {
    let allParts = [...parts];
    for (let i = 0; i < num; i++) {
      allParts.push({
        videoHeaderId: headerDataByPart.videoHeaderId,
        isSeries: false,
        seriesPartNoId:
          headerDataByPart?.videos?.length > 0
            ? num === 1
              ? partsOnSystem[headerDataByPart.videos.length + parts.length - 1]
                  .indexId
              : partsOnSystem[headerDataByPart.videos.length + i].indexId
            : partsOnSystem[parts?.length + i].indexId,
        title: "",
        imgPath: "",
        path: "",
        lowQualityVideoPath: "",
        isFree: false,
        isUploading: false,
        videoOptions: {},
        yearOfProduction: "",
        // qualityId: "",
        duration: "",
        "description.en": "",
        "description.ar": "",

        partErrors: [
          { hasError: false, error: "Please provide title" },
          { hasError: false, error: "Please select part" },
          {
            hasError: false,
            error: "Please provide years of production",
          },
          { hasError: false, error: "Please select video quality" },
          {
            hasError: false,
            error: "Please upload an image ",
            progressStatus: false,
            progressPercent: "",
          },
          {
            hasError: false,
            error: "Please upload a video ",
            progressStatus: false,
            progressPercent: "",
          },
          {
            hasError: false,
            error: "Please provide arabic and english description",
          },
        ],
      });
    }
    setParts(allParts);
  };

  const handleAddAnotherPart = (num = 1) => {
    if (partsList?.length > 0) {
      if (
        parts.length <=
        headerDataByPart?.castIds?.length - partsList?.length
      ) {
        addPart(num);
        setAddAvailabilityIndx((prev) => prev + 1);
      } else {
        toastError(
          `You can't add more than ${
            headerDataByPart?.castIds?.length === 1
              ? "one part"
              : headerDataByPart.castIds.length + " parts"
          }`
        );
      }
    } else {
      if (parts?.length < headerDataByPart?.castIds?.length) {
        addPart(num);
        setAddAvailabilityIndx((prev) => prev + 1);
      } else {
        toastError(
          `You can't add more than ${
            headerDataByPart?.castIds?.length === 1
              ? "one part"
              : headerDataByPart.castIds.length + " parts"
          }`
        );
      }
    }
  };

  const handleSelectPart = (partId, partIndex) => {
    if (partId?.length > 0) {
      let allParts = [...parts];
      if (headerDataByPart?.videos?.length > 0) {
        if (partIndex === 0) {
          const dataForSelectedEpisode = headerDataByPart?.videos?.filter(
            (partData) => partData?.episodePartId === partId
          )[0];
          setSelectedPartData(dataForSelectedEpisode);
        } else {
          if (allParts.some((newPart) => newPart?.seriesPartNoId === partId)) {
            toastError("this part was selected before!");
            allParts[partIndex].seriesPartNoId = "";
            setParts(allParts);
          } else {
            allParts[partIndex].seriesPartNoId = partId;
            allParts[partIndex].partErrors[1].hasError = false;
            setParts(allParts);
          }
        }
      } else {
        allParts[partIndex].seriesPartNoId = partId;
        allParts[partIndex].partErrors[1].hasError = false;
        setParts(allParts);
      }
    }
  };

  const handleChangeEpisodeTitle = (e, partIndex) => {
    let allParts = [...parts];
    allParts[partIndex].title = e.target.value;
    allParts[partIndex].partErrors[0].hasError = false;
    setParts(allParts);
  };

  const handleYearsOfProduction = (e, partIndex) => {
    const allParts = [...parts];
    const pattern = /^\d{4,4}$/;
    const value = e.target.value;

    if (pattern.test(value)) {
      allParts[partIndex].yearOfProduction = +value;
      allParts[partIndex].partErrors[2].hasError = false;
      setParts(allParts);
    } else {
      allParts[partIndex].yearOfProduction = +value;
      allParts[partIndex].partErrors[2].hasError = true;
      setParts(allParts);
    }
  };

  ///image config
  const handleSetImageFilePath = (imageFilePath, partIndex) => {
    if (imageFilePath?.length > 0) {
      let allParts = [...parts];
      allParts[partIndex].imgPath = imageFilePath;
      allParts[partIndex].partErrors[4].hasError = false;
      setParts(allParts);
    }
  };

  const handleShowImagesProgressBar = (show, partIndex) => {
    let allParts = [...parts];
    allParts[partIndex].partErrors[4].progressStatus = show;
    setParts(allParts);
  };

  const handleSetImageProgressPercent = (percent, partIndex) => {
    let allParts = [...parts];
    allParts[partIndex].partErrors[4].progressPercent = percent;
    setParts(allParts);
  };

  const handleSetVideoFilePathForM3u8 = (
    // filePath,
    // fileName,
    index,
    videoPath
  ) => {
    // console.log("🚀 ~ handleFreeVideoChange ~ videoPath:", videoPath);
    // console.log("🚀 ~ handleFreeVideoChange ~ fileName:", fileName);
    // console.log("🚀 ~ handleSetVideoFilePathForM3u8 ~ filePath:", filePath);
    let allParts = [...parts];
    allParts[index].path = videoPath;
    allParts[index].partErrors[5].hasError = false;
    setParts(allParts);
  };

  const handleDataFromTextEditor = (
    descriptionEn,
    descriptionAr,
    partIndex
  ) => {
    console.log("🚀 ~ descriptionAr:", descriptionAr);
    console.log("🚀 ~ descriptionEn:", descriptionEn);
    if (descriptionEn && descriptionAr) {
    }

    let allParts = [...parts];
    allParts[partIndex]["description.ar"] = descriptionAr;
    allParts[partIndex]["description.en"] = descriptionEn;
    allParts[partIndex].partErrors[6].hasError = false;
    setParts(allParts);
  };

  const resetPartFields = () => {
    let allParts = [...parts];
    allParts[0] = {
      videoHeaderId: headerDataByPart.videoHeaderId,
      isSeries: false,
      seriesPartNoId: "",
      title: "",
      imgPath: "",
      path: "",
      lowQualityVideoPath: "",
      isFree: false,
      isUploading: false,
      videoOptions: {},
      yearOfProduction: "",
      // qualityId: "",
      duration: "",
      "description.en": "",
      "description.ar": "",
      subtitles: {
        subtitles: [
          {
            language: "",
            path: "",
            languageId: "",
            languageName: "",
            oldPath: "",
            status: "",
            disableUploadFile: true,
          },
        ],
      },
      audios: {
        audios: [
          {
            language: "",
            path: "",
            languageId: "",
            languageName: "",
            oldPath: "",
            status: "",
          },
        ],
      },
      subtitleId: "",
      audioId: "",
      partErrors: [
        { hasError: false, error: "Please provide title" },
        { hasError: false, error: "Please select part" },
        {
          hasError: false,
          error: "Please provide years of production",
        },
        { hasError: false, error: "Please select video quality" },
        {
          hasError: false,
          error: "Please upload an image ",
          progressStatus: false,
          progressPercent: "",
        },
        {
          hasError: false,
          error: "Please upload a video ",
          progressStatus: false,
          progressPercent: "",
        },
        {
          hasError: false,
          error: "Please provide arabic and english description",
        },
        {
          subtitleLanguageErrors: [
            {
              hasError: false,
              error: "Please select subtitle language ",
            },
          ],
          subtitleFileErrors: [
            {
              hasError: false,
              error: "Please upload subtitle file ",
              progressStatus: false,
              progressPercent: "",
            },
          ],
        },
        {
          audioLanguageErrors: [
            {
              hasError: false,
              error: "Please select audio language ",
            },
          ],
          audioFileErrors: [
            {
              hasError: false,
              error: "Please upload audio file ",
              progressStatus: false,
              progressPercent: "",
            },
          ],
        },
      ],
    };
    setParts(allParts);
  };

  const closeThisEpisode = (partIndex) => {
    // if (partIndex > 0) {
    if (partIndex === 1) {
      let allParts = [...parts];
      const paths = getUnusedFilesForEpisode(allParts[partIndex]);
      if (paths?.length > 0) {
        removeUnUsedFiles(
          paths,
          (success) => {
            if (success?.isSuccess) {
              console.log("allParts before", allParts);
              allParts.splice(partIndex, 1);
              console.log("allParts after", allParts);
              // allParts.pop();
              setParts(allParts);
            } else {
              toastError(success.err_msg);
            }
          },
          (fail) => {
            if (fail?.data) {
              toastError(fail.data?.err_msg);
            } else {
              toastError();
            }
          }
        );
      } else {
        allParts.pop();
        setParts(allParts);
      }
    }
  };

  const handleShowDeleteVideoModal = () => {
    setDeleteModalVisible(true);
  };

  const deletePart = (id, isLastPart = false) => {
    setDeleteLoading(true);
    deleteEpisodeFromHeader(
      id,
      (success) => {
        if (success?.isSuccess) {
          toastSuccess(success.message);
          setDeleteLoading(false);
          setSelectedPartData(null);
          getAllHeaderDataAfterActions();
          onCancel();
          if (isLastPart) {
            resetPartFields();
          }
        } else {
          toastError(success.err_msg);
          onCancel();
        }
      },
      (fail) => {
        toastError(fail.err_msg);
        onCancel();
      }
    );
  };

  const handleDeleteVideoFromMovie = () => {
    let noPartSelectedError = "No part selected to delete";
    if (headerDataByPart?.videos?.length > 1) {
      if (parts[0]?.id?.length > 1) {
        deletePart(parts[0].id);
      } else {
        toastError(noPartSelectedError);
      }
    } else if (headerDataByPart?.videos?.length === 1) {
      if (parts[0]?.id?.length > 0) {
        deletePart(parts[0].id, true);
      } else {
        toastError(noPartSelectedError);
      }
    } else {
      toastError(noPartSelectedError);
    }
  };

  const onCancel = () => {
    setDeleteLoading(false);
    setDeleteModalVisible(false);
  };

  const validateAllEpisodes = (partIndex) => {
    if (parts[partIndex]?.title === "") {
      let allParts = [...parts];
      allParts[partIndex].partErrors[0].hasError = true;
      setParts(allParts);
    }
    if (parts[partIndex]?.seriesPartNoId === "") {
      let allParts = [...parts];
      allParts[partIndex].partErrors[1].hasError = true;
      setParts(allParts);
    }
    if (
      parts[partIndex]?.yearOfProduction === "" ||
      parts[partIndex]?.yearOfProduction === null ||
      parts[partIndex]?.yearOfProduction === 0
    ) {
      let allParts = [...parts];
      allParts[partIndex].partErrors[2].hasError = true;
      setParts(allParts);
    }
    // if (parts[partIndex]?.qualityId === "") {
    //   let allParts = [...parts];
    //   allParts[partIndex].partErrors[3].hasError = true;
    //   setParts(allParts);
    // }
    if (parts[partIndex]?.imgPath === "") {
      let allParts = [...parts];
      allParts[partIndex].partErrors[4].hasError = true;
      setParts(allParts);
    }
    if (parts[partIndex]?.path === "") {
      let allParts = [...parts];
      allParts[partIndex].partErrors[5].hasError = true;
      setParts(allParts);
    }
    if (
      parts[partIndex]?.["description.en"]?.length === 0 ||
      parts[partIndex]?.["description.ar"]?.length === 0
    ) {
      let allParts = [...parts];
      allParts[partIndex].partErrors[6].hasError = true;
      setParts(allParts);
    }
  };

  // Save
  const callEditPart = (partPayload, partIndex) => {
    const { partErrors, isUploading, videoOptions, ...partWithoutErrors } =
      partPayload;
    setTimeout(() => {
      editEpisodeM3u8(
        partWithoutErrors,
        (success) => {
          if (success?.isSuccess) {
            setLoadingSubmit(false);
            toastSuccess(success.message);
            getAllHeaderDataAfterActions(partWithoutErrors?.seriesPartNoId);
            setRenderEditMovie((prev) => prev + 1);
            closeThisEpisode(partIndex);
          } else {
            setLoadingSubmit(false);
            toastError(success.err_msg);
          }
        },
        (fail) => {
          if (fail?.data) {
            setLoadingSubmit(false);
            toastError(fail.data?.err_msg);
          } else {
            toastError();
            setLoadingSubmit(false);
          }
        }
      );
    }, 1000);
  };

  const callAddPart = (partPayload, partIndex) => {
    const { partErrors, isUploading, videoOptions, ...partWithoutErrors } =
      partPayload;
    const addPartPayload = [partWithoutErrors];
    setTimeout(() => {
      addEpisodesToHeader(
        addPartPayload,
        (success) => {
          if (success?.isSuccess) {
            setLoadingSubmit(false);
            toastSuccess(success.message);
            getAllHeaderDataAfterActions(addPartPayload[0]?.seriesPartNoId);
            setRenderEditMovie((prev) => prev + 1);
            closeThisEpisode(partIndex);
          } else {
            setLoadingSubmit(false);
            toastError(success.err_msg);
          }
        },
        (fail) => {
          if (fail?.data) {
            setLoadingSubmit(false);
            toastError(fail.data?.err_msg);
          } else {
            toastError();
            setLoadingSubmit(false);
          }
        }
      );
    }, 1000);
  };

  const handleOnFinish = (partIndex) => {
    // setLoadingSubmit(true);
    let allParts = [...parts];
    let partPayload = allParts[partIndex];
    validateAllEpisodes(partIndex);
    const checkAnyError = [];

    const selectedEpisodeError = parts[partIndex]?.partErrors;

    if (selectedEpisodeError) {
      const hasEpisodeError = selectedEpisodeError.some(
        (singleObject) => singleObject.hasError
      );
      if (hasEpisodeError) {
        checkAnyError.push(hasEpisodeError);
      }
    }

    const part = allParts[partIndex];

    if (checkAnyError?.length === 0) {
      setLoadingSubmit(true);
      if (partIndex > 0) {
        if (allParts[partIndex - 1]?.path) {
          callAddPart(partPayload, partIndex);
        } else {
          toastError("You must upload previous part first");
          console.log(part[0]);
        }
      } else if (headerDataByPart?.videos?.length > 0) {
        callEditPart(partPayload, partIndex);
      } else {
        console.log("🚀 partIndex:", partIndex);
        callAddPart(partPayload, partIndex);
      }
      // setLoadingSubmit(false);
    } else {
      toastError(`Please, fill all required fields`);
      setLoadingSubmit(false);
    }
  };

  return (
    <>
      <SubHeader bgColor="#27A2D9" title="Parts" />
      {parts.map((part, partIndex) => {
        const uniqueId = partIndex + uuidv4();
        return (
          <Form
            className="wd-100"
            onFinish={() => handleOnFinish(partIndex)}
            key={partIndex}
            disabled={partIndex > 1}
          >
            <div className="add-episodes-to-tvshows wd-100 mrg-y-30 pad-y-30">
              <div className=" wd-100 pad-x-10 ">
                {partIndex !== 0 &&
                  partIndex === parts?.length - 1 &&
                  isUploadingNow === false && (
                    <Row justify="end" className="mrg-ye-20">
                      <Col>
                        <div
                          className="removeEpisods pad-xe-30"
                          role={"button"}
                          onClick={() => closeThisEpisode(partIndex)}
                        >
                          <CloseOutlined
                            style={{
                              fontSize: "20px",
                              color: "#6F767E",
                            }}
                          />
                        </div>
                      </Col>
                    </Row>
                  )}
                <Row gutter={36}>
                  <Col xs={24} md={12} className="mrg-ye-20">
                    <div className="new-category">
                      <label className="view-tvshow-lbl-main">
                        Choose Part
                      </label>
                      <Select
                        disabled={isUploading}
                        placeholder="Choose Part"
                        className="search__searchField py-1 wd-100"
                        onChange={(partId) =>
                          handleSelectPart(partId, partIndex)
                        }
                        value={part?.seriesPartNoId || undefined}
                        dropdownStyle={dropdownStyle}
                      >
                        {headerDataByPart?.videos?.length > 0
                          ? partIndex === 0
                            ? currentParts.map(
                                ({ indexId, episode }, index) => (
                                  <Select.Option key={index} value={indexId}>
                                    {episode}
                                  </Select.Option>
                                )
                              )
                            : newParts.map(({ indexId, episode }, index) => {
                                if (partIndex - 1 === index) {
                                  return (
                                    <Select.Option key={index} value={indexId}>
                                      {episode}
                                    </Select.Option>
                                  );
                                }
                              })
                          : partsOnSystem.map(({ indexId, episode }, index) => {
                              if (index === partIndex) {
                                return (
                                  <Select.Option key={index} value={indexId}>
                                    {episode}
                                  </Select.Option>
                                );
                              }
                            })}
                      </Select>
                      {part?.partErrors[1]?.hasError && (
                        <div className="episode-errors">
                          {part?.partErrors[1]?.error}
                        </div>
                      )}
                    </div>
                  </Col>
                  <Col xs={24} md={12} className="mrg-ye-20">
                    <div className="new-actor__name new-category ">
                      <label className="view-tvshow-lbl-main" htmlFor="">
                        Part title
                      </label>
                      <Input
                        placeholder="Enter part title"
                        type="text"
                        className="search__searchField py-2"
                        onChange={(event) =>
                          handleChangeEpisodeTitle(event, partIndex)
                        }
                        value={part?.title || undefined}
                        // value={partIndex === 0 ? parts[0]?.title : part?.title}
                      />
                    </div>
                    {part?.partErrors[0]?.hasError && (
                      <div className="episode-errors">
                        {part?.partErrors[0]?.error}
                      </div>
                    )}
                  </Col>
                </Row>
                <Row gutter={36}>
                  <Col xs={24} md={12} className="mrg-ye-20">
                    <div className="new-category  ">
                      <label className="view-tvshow-lbl-main">
                        Year of production
                      </label>
                      <Input
                        placeholder="Year of production"
                        // type={"number"}
                        className="search__searchField py-2"
                        onChange={(e) => handleYearsOfProduction(e, partIndex)}
                        value={part?.yearOfProduction || undefined}
                      ></Input>
                      {part?.partErrors[2]?.hasError && (
                        <div className="episode-errors">
                          {part?.partErrors[2]?.error}
                        </div>
                      )}
                    </div>
                  </Col>
                </Row>
                <Row gutter={16} style={{ marginBottom: "2.5rem" }}>
                  <Col xs={24} md={8} style={{ height: "251px" }}>
                    <div className="upload-files upload-files__image-small">
                      <div className="upload-files__container upload-files__container--image-small relative">
                        {part?.imgPath?.length > 0 && (
                          <figure>
                            <img
                              className="upload-files__upload-file"
                              src={baseUrl + part?.imgPath}
                              alt="actorImage"
                              style={{
                                position: "absolute",
                                zIndex: "3",
                                width: "100%",
                              }}
                            />
                          </figure>
                        )}
                        <DraggerUploader
                          withCrop
                          aspect={2 / 1}
                          formDataType={"img"}
                          acceptedTypes={"image/*"}
                          setShowProgressBar={(show) =>
                            handleShowImagesProgressBar(show, partIndex)
                          }
                          setProgressPercent={(percent) =>
                            handleSetImageProgressPercent(percent, partIndex)
                          }
                          setFilePath={(filePath) =>
                            handleSetImageFilePath(filePath, partIndex)
                          }
                        >
                          {part?.imgPath?.length > 0 ? (
                            <div className="upload-edit-icon mrg-x-20">
                              <img role="button" src={editIcon} alt="edit" />
                            </div>
                          ) : (
                            <div
                              role="button"
                              className="upload-files__button  "
                              style={{
                                boxShadow: "none",
                                backgroundColor: "transparent",
                                justifyContent: "center",
                                gap: "30px",
                                margin: "auto",
                              }}
                            >
                              <div className="upload-files__icon">
                                <img src={uploadButton} alt="" />
                              </div>
                              <div className="upload-files__title">
                                {"Upload Image"}
                              </div>
                              <div className="upload-files__icon">
                                <img src={uploadImageIcon} alt="" />
                              </div>
                            </div>
                          )}
                        </DraggerUploader>
                      </div>
                      {part?.partErrors[4]?.hasError && (
                        <div className="episode-errors">
                          {part?.partErrors[4]?.error}
                        </div>
                      )}
                    </div>
                    {part?.partErrors[4]?.progressStatus && (
                      <Progress
                        style={{ marginTop: "1rem" }}
                        percent={part?.partErrors[4]?.progressPercent}
                      />
                    )}
                  </Col>
                  <Col xs={24} md={16}>
                    {/* <CompressedFileUploader
                      setFilePath={(filePath, fileName, videoPath) =>
                        handleSetVideoFilePathForM3u8(
                          filePath,
                          partIndex,
                          fileName,
                          videoPath
                        )
                      }
                      path={part.path}
                      episodeIndex={uniqueId}
                      part={part}
                    /> */}
                    {/* <DraggerFileUploader
                      setFilePath={(filePath, fileName, videoPath) =>
                        handleSetVideoFilePathForM3u8(
                          filePath,
                          partIndex,
                          fileName,
                          videoPath
                        )
                      }
                      srcVideo={part.path}
                      episodeIndex={uniqueId}
                      part={part}
                    /> */}
                    <FolderUploader
                      getVideoDuration={(duration) =>
                        handleGetVideoDuration(partIndex, duration)
                      }
                      observeUploading={observeUploading}
                      setFilePath={(videoPath) =>
                        handleSetVideoFilePathForM3u8(partIndex, videoPath)
                      }
                      episodeIndex={uniqueId}
                      srcVideo={part.path}
                    />
                    {part?.partErrors[5]?.hasError && (
                      <div className="episode-errors">
                        {part?.partErrors[5]?.error}
                      </div>
                    )}
                  </Col>
                </Row>
                {/*____________ Start description ______________*/}
                <Row>
                  <Col span={24}>
                    <div className="wd-100 mrg-x-auto">
                      <span className="view-tvshow-lbl-main">Description</span>

                      <SecondaryEditor
                        className="d-none"
                        putDescriptionEn={
                          partIndex === 0
                            ? selectedPartData?.description?.en
                              ? selectedPartData.description.en
                              : ""
                            : ""
                        }
                        putDescriptionAr={
                          partIndex === 0
                            ? selectedPartData?.description?.ar
                              ? selectedPartData.description.ar
                              : ""
                            : ""
                        }
                        getTextEditorData={(descriptionEn, descriptionAr) =>
                          handleDataFromTextEditor(
                            descriptionEn,
                            descriptionAr,
                            partIndex
                          )
                        }
                      />
                      {part?.partErrors[6]?.hasError && (
                        <div className="episode-errors">
                          {part?.partErrors[6]?.error}
                        </div>
                      )}
                    </div>
                  </Col>
                </Row>
                {/*____________ End description ______________*/}

                <Row justify="start">
                  <Col>
                    <label className="view-tvshow-lbl-main ">Free</label>
                  </Col>
                </Row>
                <Row justify="start">
                  <Col span={9}>
                    <p>User can watch this video without subscription</p>
                  </Col>
                  <Col>
                    <Switch
                      style={{
                        backgroundColor: part?.isFree ? "#31AA4A" : "", // Change the background color when checked
                        // Change the border color when checked
                      }}
                      checked={part?.isFree}
                      // Reflect the state of the 'isFree' variable
                      onChange={(checked) =>
                        handleFreeVideoChange(checked, partIndex)
                      } // Toggle the 'isFree' state on change
                    />
                  </Col>
                </Row>
                <div className="flex wd-100 justify-content-between align-items-end ">
                  <span className="view-tvshow-lbl wd-100  flex-1">
                    {partIndex === parts?.length - 1 && isUploadingNow
                      ? " You cannot add new parts until current uploads are completed"
                      : ""}
                  </span>
                  <Space
                    size={20}
                    className="config-video-buttons flex justify-end  wd-40 mrg-ys-30"
                  >
                    {partIndex === parts?.length - 1 && (
                      <div className="" style={{ width: "129px" }}>
                        <PlusButton
                          buttonName="Add Part"
                          styling="secondary"
                          action={() => handleAddAnotherPart()}
                          disableMe={isUploadingNow}
                        />
                      </div>
                    )}
                    {partIndex === 0 && (
                      <div className="" style={{ width: "129px" }}>
                        <Button
                          className="delete-video-button"
                          disabled={
                            headerDataByPart?.videos?.length === 0
                              ? true
                              : selectedPartData?.index === maxIndex
                              ? false
                              : true
                          }
                          onClick={() => handleShowDeleteVideoModal()}
                        >
                          Delete Part
                        </Button>
                      </div>
                    )}
                    <div
                      className="btn-save mrg-xe-10"
                      style={{ width: "129px" }}
                    >
                      <PrimaryButton
                        type={"submit"}
                        buttonName={
                          partIndex > 0
                            ? "Add"
                            : headerDataByPart?.videos?.length > 0
                            ? "Save"
                            : "Add"
                        }
                        loading={loadingSubmit}
                      />
                    </div>
                  </Space>{" "}
                </div>
              </div>
            </div>
          </Form>
        );
      })}
      <DeleteModal
        deleteModalVisible={deleteModalVisible}
        SubHeader={
          <SubHeader
            bgColor="#92979C"
            title="Delete Part"
            closeButton
            onCancel={onCancel}
          />
        }
        icon={warningIcon}
        msg="Are you sure you want to delete the part?"
        onCancel={onCancel}
        action={handleDeleteVideoFromMovie}
        secondaryBtnName="Cancel"
        primaryBtnName="Delete"
        loading={deleteLoading}
      />
    </>
  );
}
export default EditPartM3u8;
