import { Table } from "antd";
import { searchFunction } from "helpers/helpfulFunctions";
import { toastError, toastSuccess } from "helpers/toasters";
import AddCodeModal from "./AddCodeModal";
import Header from "modules/SubComponents/Header";
import SubHeader from "modules/SubComponents/SubHeader";
import moment from "moment";
import { addCode, getAllCodes } from "Network";
import { useEffect, useState } from "react";
function Code() {
  const [loading, setLoading] = useState(true);
  const [isModalLoading, setIsModalLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [renderMe, setRenderMe] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [searchVal, setSearchVal] = useState("");
  const [codes, setCodes] = useState([]);
  const [searchResult, setSearchResult] = useState([]);
  const columns = [
    { title: "CODE", key: "code", dataIndex: "code" },
    { title: "DURATION", key: "duration", dataIndex: "duration" },
    {
      title: "PRICE",
      key: "price",
      dataIndex: "price",
      render: (price) =>
        price !== undefined && price !== null ? `${price} LE` : "-",
    },

    {
      title: "EXPIRATION DATE",
      key: "expirationDate",
      dataIndex: "expirationDate",
      render: (_, record) => {
        if (record?.expirationDate) {
          return (
            <>{moment(record.expirationDate).format("DD-MM-YYYY")}</>
          );
        } else {
          return <>{"-"}</>;
        }
      },
    },
  ];

  useEffect(() => {
    const getData = () => {
      getAllCodes(
        (success) => {
          if (success?.isSuccesss) {
            // Reverse the array received from the backend
            const reversedCodes = success.data.reverse();
            setCodes(reversedCodes);
            setLoading(false);
          } else {
            toastError(success.err_msg);
            setLoading(false);
          }
        },
        (fail) => {
          if (fail?.data) {
            setLoading(false);
            toastError(fail.data?.err_msg);
          } else {
            toastError();
            setLoading(false);
          }
        },
      );
    };
    getData();
  }, [renderMe]);

  useEffect(() => {
    const searchColumns = [
      "code",
      "duration",
      "price",
      "expirationDate",
    ];
    if (searchVal?.length > 0) {
      setSearchResult(
        searchFunction(codes, searchVal, searchColumns),
      );
    }
    //eslint-disable-next-line
  }, [searchVal]);

  useEffect(() => {
    const searchColumns = [
      "code",
      "duration",
      "price",
      "expirationDate",
    ];
    setSearchResult(
      searchFunction(codes, searchValue, searchColumns),
    );
    //eslint-disable-next-line
  }, [searchValue]);

  const handleShowAddCodePopup = () => {
    setIsModalVisible(true);
  };
  const handleGetSearchValueFromHeader = (value) => {
    setSearchValue(value);
  };
  const onCancel = () => {
    setIsModalVisible(false);
  };

  const handleAddNewCode = (payload) => {
    if (payload) {
      if (!payload?.price) {
        delete payload.price;
      }
      setIsModalLoading(true);
      addCode(
        payload,
        (success) => {
          if (success?.isSuccesss) {
            toastSuccess(success.message);
            setIsModalLoading(false);
            setIsModalVisible(false);
            setRenderMe((prev) => !prev);
          } else {
            toastError(success.err_msg);
            setIsModalLoading(false);
          }
        },
        (fail) => {
          if (fail?.data) {
            toastError(fail.data?.err_msg);
            setIsModalLoading(false);
          } else {
            toastError();
            setIsModalLoading(false);
          }
        },
      );
    }
  };

  return (
    <section className='users '>
      <div className='users__all-contents '>
        <div className='users__content'>
          <div className='users__usersData'>
            <Header
              title='Codes'
              plusButtonName='Add Code'
              plusButtonAction={handleShowAddCodePopup}
              search='Find Code'
              searchVal={searchVal?.length > 0 ? searchVal : ""}
              getSearchedFromHeader={handleGetSearchValueFromHeader}
              exportAsExcel
              columnsForExport={columns}
              dataSource={searchValue ? searchResult : codes}
              reportName={`CODES`}
            />
            <SubHeader bgColor='red' title={"Codes Data"} />
            <div className='cast-data__table  grid-table '>
              <Table
                rowKey={(row) => row?._id}
                className=''
                columns={columns}
                dataSource={searchValue ? searchResult : codes}
                scroll={{ x: 600 }}
                loading={loading}
                rowClassName={(record, index) =>
                  index % 2 === 0 && "background-even-records "
                }
                pagination={{
                  defaultPageSize: "7",
                  responsive: true,
                  hideOnSinglePage: true,
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <AddCodeModal
        isModalVisible={isModalVisible}
        SubHeader={
          <SubHeader
            bgColor='red'
            title={"Generate Code"}
            closeButton
            onCancel={onCancel}
          />
        }
        isModalLoading={isModalLoading}
        onCancel={onCancel}
        primaryBtnName={"Add"}
        secondaryBtnName={"Cancel"}
        action={handleAddNewCode}
        // searchValue={searchValue?.length > 0 ? searchValue : ""}
      />
    </section>
  );
}

export default Code;
