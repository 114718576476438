import React, { Fragment, useState } from "react";
import {
  Button,
  Col,
  Form,
  Input,
  Progress,
  Row,
  Select,
  Space,
  Switch,
} from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { baseUrl } from "services";
import { toastError, toastSuccess } from "helpers/toasters";
import SubHeader from "modules/SubComponents/SubHeader";
import PlusButton from "modules/SubComponents/Buttons/PlusButton";
import PrimaryButton from "modules/SubComponents/Buttons/PrimaryButton";
import SecondaryEditor from "modules/SubComponents/Editor/SecondaryEditor";
import editIcon from "assets/icons/pen.svg";
import uploadImageIcon from "assets/icons/upImageIcon.svg";
import uploadButton from "assets/icons/uploadButton.svg";
import warningIcon from "assets/icons/warning.svg";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import {
  addEpisodesToHeader,
  deleteEpisodeFromHeader,
  editEpisodeM3u8,
  getAllVideosDetailsForHeader,
  removeUnUsedFiles,
} from "Network";
import {
  customizeVideoDuration,
  getMaxIndexOfEpisodesOrParts,
  getSpecifiedEpisodes,
  getUnusedFilesForEpisode,
} from "helpers/helpfulFunctions";
import DeleteModal from "modules/Modals/DeleteModal";
import { dropdownStyle } from "helpers/helpfulFunctions";
import DraggerUploader from "modules/SubComponents/Upload/DraggerUploader";
import { toast } from "react-toastify";
import FolderUploader from "modules/SubComponents/Upload/FolderUploader";

function EditEpisodeM3u8({
  selectedHeaderId,
  selectedSeasonId,
  seasonsDependsOnHeaderId,
  renderMeAfterEditHeader,
  renderHeaderAfterEditEpisode,
}) {
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [isUploadingNow, setIsUploadingNow] = useState(false);
  const [cantAddEpisodes, setCantAddEpisodes] = useState(false);
  const [maxIndex, setMaxIndex] = useState(null);
  const [renderMe, setRenderMe] = useState(false);
  const [headerDataBySeason, setHeaderDataBySeason] = useState([]);
  // const [selectedEpisodeToUpload, setSelectedEpisodeToUpload] = useState(null);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [currentEpisodes, setCurrentEpisodes] = useState([]);
  const [newEpisodes, setNewEpisodes] = useState([]);
  const [selectedEpisodeData, setSelectedEpisodeData] = useState(null);
  const [renderHeader, setRenderHeader] = useState(false);
  const [audioList, setAudioList] = useState([]);
  const [subtitleList, setSubtitleList] = useState([]);
  const [oldSubtitles, setOldSubtitles] = useState(null);
  const [oldAudios, setOldAudios] = useState(null);
  const [isFree, setIsFree] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [disableAddButton, setdisableAddButton] = useState(false);

  const [episodes, setEpisodes] = useState([
    {
      videoHeaderId: "",
      id: "",
      isSeries: true,
      seasonId: "",
      seriesPartNoId: "",
      title: "",
      imgPath: "",
      path: "",
      isFree: false,
      lowQualityVideoPath: "",
      isUploading: false,
      videoOptions: {},
      yearOfProduction: "",
      duration: "",
      "description.en": "",
      "description.ar": "",
      episodeErrors: [
        { hasError: false, error: "Please select season" },
        { hasError: false, error: "Please select episode" },
        {
          hasError: false,
          error: "Please provide years of production",
        },
        { hasError: false, error: "Please select video quality" },
        {
          hasError: false,
          error: "Please upload an image ",
          progressStatus: false,
          progressPercent: "",
        },
        {
          hasError: false,
          error: "Please upload a video ",
          progressStatus: false,
          progressPercent: "",
        },
        {
          hasError: false,
          error: "Please provide arabic and english description",
        },
        {
          hasError: false,
          error: "Please provide title",
        },
      ],
    },
  ]);

  const { languagesOnSystem, videoQualitiesOnSystem, episodesOnSystem } =
    useSelector((state) => state?.staticData?.staticDataOnSystem);

  useEffect(() => {
    const isUploading = episodes?.some(
      (episode) => episode?.isUploading === true
    );
    if (isUploading) {
      setIsUploadingNow(true);
    } else {
      setIsUploadingNow(false);
    }
  }, [episodes]);

  useEffect(() => {
    //remove languages which removed from header
    let allEpisodes = [...episodes];
    if (!oldSubtitles) {
      setOldSubtitles(subtitleList);
    } else {
      let filteredSubtitles = oldSubtitles.filter((oldObj) => {
        return !subtitleList.some((newObj) => oldObj === newObj);
      });
      setOldSubtitles(subtitleList);
      filteredSubtitles.forEach((removedSub) =>
        allEpisodes.map((episode) =>
          episode.subtitles?.subtitles.find((currSub) => {
            if (currSub?.languageId === removedSub) {
              currSub.language = "";
              currSub.path = "";
              currSub.languageId = "";
              currSub.languageName = "";
              currSub.disableUploadFile = true;
            }
          })
        )
      );
    }
    if (!oldAudios) {
      setOldAudios(audioList);
    } else {
      //find objects that deleted from oldAudios
      let filteredAudios = oldAudios.filter((oldObj) => {
        return !audioList.some((newObj) => oldObj === newObj);
      });
      setOldAudios(audioList);
      filteredAudios.forEach((removedSub) =>
        allEpisodes.map((episode) =>
          episode.audios?.audios.find((currSub) => {
            if (currSub?.languageId === removedSub) {
              currSub.language = "";
              currSub.path = "";
              currSub.languageId = "";
              currSub.languageName = "";
              currSub.disableUploadFile = true;
            }
          })
        )
      );
    }
    //eslint-disable-next-line
  }, [subtitleList, audioList]);

  useEffect(() => {
    if (selectedHeaderId?.length > 0 && selectedSeasonId?.length > 0) {
      getAllHeaderDataAfterActions();
    }
    //eslint-disable-next-line
  }, [selectedHeaderId, selectedSeasonId, renderMeAfterEditHeader]);

  useEffect(() => {
    if (selectedHeaderId?.length > 0 && selectedSeasonId?.length > 0) {
      checkPreviousAndNextSeasonIfHasEpisode(selectedSeasonId)
        .then((canAdd) => {})
        .catch((error) => {
          setCantAddEpisodes(true);
        });
    }

    //eslint-disable-next-line
  }, [selectedHeaderId, selectedSeasonId]);

  useEffect(() => {
    if (headerDataBySeason?.videos?.length > 0) {
      if (!selectedEpisodeData) {
        setSelectedEpisodeData(headerDataBySeason.videos[0]); //while edit any  episode we must updatenthe state with updated episode object
      }
      setMaxIndex(getMaxIndexOfEpisodesOrParts(headerDataBySeason));
      let allEpisodes = [...episodes];
      allEpisodes[0].videoHeaderId = headerDataBySeason.videoHeaderId;
      // allEpisodes[0].isFree = headerDataBySeason.isFree;
      allEpisodes[0].seasonId = headerDataBySeason.seasonId;
      setEpisodes(allEpisodes);
    } else {
      let allEpisodes = [...episodes];
      allEpisodes[0].videoHeaderId = headerDataBySeason.videoHeaderId;
      allEpisodes[0].isSeries = true;
      allEpisodes[0].seasonId = headerDataBySeason.seasonId;
      allEpisodes[0].seriesPartNoId = episodesOnSystem[0].indexId;
      setEpisodes(allEpisodes);
    }
    //eslint-disable-next-line
  }, [headerDataBySeason, renderMe]);

  useEffect(() => {
    if (selectedEpisodeData) {
      let allEpisodes = [...episodes];
      allEpisodes[0].id = selectedEpisodeData?.id;
      allEpisodes[0].isFree = selectedEpisodeData?.isFree;
      allEpisodes[0].title = selectedEpisodeData?.title;
      allEpisodes[0].seriesPartNoId = selectedEpisodeData?.episodePartId;
      allEpisodes[0].imgPath = selectedEpisodeData?.imgPath;
      allEpisodes[0].path = selectedEpisodeData?.path;
      allEpisodes[0].yearOfProduction =
        selectedEpisodeData?.yearOfProduction.toString();
      allEpisodes[0].qualityId = selectedEpisodeData?.qualityId;
      allEpisodes[0].duration = selectedEpisodeData?.duration;
      allEpisodes[0]["description.en"] = selectedEpisodeData?.description?.en;
      allEpisodes[0]["description.ar"] = selectedEpisodeData?.description?.ar;
      setEpisodes(allEpisodes);
    } //eslint-disable-next-line
  }, [selectedEpisodeData]);

  useEffect(() => {
    if (
      episodesOnSystem?.length > 0 &&
      headerDataBySeason?.videos?.length > 0
    ) {
      setCurrentEpisodes(
        getSpecifiedEpisodes(episodesOnSystem, headerDataBySeason?.videos)
      );
      setNewEpisodes(
        getSpecifiedEpisodes(episodesOnSystem, headerDataBySeason?.videos, 1)
      );
    }
  }, [episodesOnSystem, headerDataBySeason]);

  useEffect(() => {
    renderHeaderAfterEditEpisode();
    //eslint-disable-next-line
  }, [renderHeader]);

  const observeUploading = (status) => {
    setIsUploading(status);
  };

  const handleGetVideoDuration = (episodeIndex, duration) => {
    let allEpisodes = [...episodes];
    const formattedDuration = customizeVideoDuration(duration);
    allEpisodes[episodeIndex].duration = formattedDuration;
    setEpisodes(allEpisodes);
  };

  const handleFreeVideoChange = (isChecked, partIndex) => {
    let allEpisodes = [...episodes];

    allEpisodes[partIndex].isFree = isChecked;
    // allParts[partIndex].episodeErrors[2].hasError = false;
    setEpisodes(allEpisodes);
    // setIsFree(isChecked);
  };

  const getAllHeaderDataAfterActions = (episodeId) => {
    setdisableAddButton(true);
    getAllVideosDetailsForHeader(
      {
        videoHeaderId: selectedHeaderId,
        seasonId: selectedSeasonId,
      },
      (success) => {
        if (success?.isSuccess) {
          setHeaderDataBySeason(success.data[0]);
          setAudioList(success.data[0]?.audios);
          setSubtitleList(success.data[0]?.subtitles);
          if (episodeId?.length > 0) {
            const dataForSelectedEpisode = success.data[0]?.videos?.filter(
              (episodeData) => episodeData?.episodePartId === episodeId
            )[0];
            setSelectedEpisodeData(dataForSelectedEpisode);
          }
        }
        setdisableAddButton(false);
      },
      (fail) => {
        setdisableAddButton(false);
        toastError(fail.data.err_msg);
      }
    );
  };

  const handleAddAnotherEpisode = () => {
    if (headerDataBySeason) {
      let allEpisodes = [...episodes];

      allEpisodes.push({
        videoHeaderId: headerDataBySeason.videoHeaderId,
        isSeries: true,
        seasonId: headerDataBySeason.seasonId,
        seriesPartNoId:
          headerDataBySeason?.videos?.length > 0
            ? episodesOnSystem[
                headerDataBySeason.videos.length + episodes.length - 1
              ].indexId
            : episodesOnSystem[episodes.length].indexId,
        title: "",
        imgPath: "",
        path: "",
        isFree: false,
        lowQualityVideoPath: "",
        isUploading: false,
        videoOptions: {},
        yearOfProduction: "",
        duration: "",
        "description.en": "",
        "description.ar": "",
        episodeErrors: [
          { hasError: false, error: "Please select season" },
          { hasError: false, error: "Please select episode" },
          {
            hasError: false,
            error: "Please provide years of production",
          },
          { hasError: false, error: "Please select video quality" },
          {
            hasError: false,
            error: "Please upload an image ",
            progressStatus: false,
            progressPercent: "",
          },
          {
            hasError: false,
            error: "Please upload a video ",
            progressStatus: false,
            progressPercent: "",
          },
          {
            hasError: false,
            error: "Please provide arabic and english description",
          },

          {
            hasError: false,
            error: "Please provide title",
          },
        ],
      });
      // setFileProgress(allFilesPregress);
      setEpisodes(allEpisodes);
    } else {
      toastError("there are no data for this series");
    }
  };

  const getVideoDetailsBySeason = (videoHeaderId, seasonId) => {
    return new Promise((resolve, reject) => {
      getAllVideosDetailsForHeader(
        {
          videoHeaderId,
          seasonId,
        },
        (success) => {
          if (success?.isSuccess) {
            if (success.data[0].videos?.length > 0) {
              resolve(true);
            } else {
              reject(false);
            }
          } else {
            reject(success.err_msg);
          }
        },
        (fail) => {
          reject(fail.data?.err_msg);
        }
      );
    });
  };

  const showAttentionMessage = (msg) => {
    toast.warn(msg, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      style: { width: "600px" },
    });
  };

  const checkPreviousAndNextSeasonIfHasEpisode = (currentSeasonId) => {
    return new Promise((resolve, reject) => {
      // const prevSeasonError = "Previous season must has at least one episode";
      // const nextSeasonError = "Next season must not has any episode";
      const prevSeasonError =
        "You can't add episodes to this season before adding episodes to the previous season.";

      const nextSeasonError =
        "You can't add episodes to this season because episodes have already been added to the next season.";

      seasonsDependsOnHeaderId.forEach(({ id }, seasonIndex) => {
        if (id === currentSeasonId) {
          if (seasonIndex === 0) {
            if (seasonsDependsOnHeaderId?.length > 1) {
              const nextSeasonId = seasonsDependsOnHeaderId[seasonIndex + 1].id;
              getVideoDetailsBySeason(selectedHeaderId, nextSeasonId)
                .then((seasonHasEpisode) => {
                  // toastError(nextSeasonError);
                  showAttentionMessage(nextSeasonError);
                  reject(false);
                })
                .catch((error) => {
                  resolve(true);
                });
            } else {
              resolve(true);
            }
          } else if (
            seasonIndex > 0 &&
            seasonIndex < seasonsDependsOnHeaderId?.length - 1
          ) {
            const previousSeasonId =
              seasonsDependsOnHeaderId[seasonIndex - 1].id;
            const nextSeasonId = seasonsDependsOnHeaderId[seasonIndex + 1].id;
            getVideoDetailsBySeason(selectedHeaderId, nextSeasonId)
              .then((seasonHasEpisode) => {
                // toastError(nextSeasonError);
                showAttentionMessage(nextSeasonError);
                reject(false);
              })
              .catch((error) => {
                getVideoDetailsBySeason(selectedHeaderId, previousSeasonId)
                  .then((seasonHasEpisode) => {
                    resolve(true);
                  })
                  .catch((error) => {
                    // toastError(prevSeasonError);
                    showAttentionMessage(prevSeasonError);
                    reject(false);
                  });
              });
          } else {
            const previousSeasonId =
              seasonsDependsOnHeaderId[seasonIndex - 1].id;
            getVideoDetailsBySeason(selectedHeaderId, previousSeasonId)
              .then((selectedSeasonHasEpisode) => {
                resolve(true);
              })
              .catch((error) => {
                reject(false);
                // toastError(prevSeasonError);
                showAttentionMessage(prevSeasonError);
              });
          }
        }
      });
    });
  };

  const handleSelectEpisode = (episodeId, episodeIndex) => {
    if (headerDataBySeason?.videos?.length > 0) {
      if (episodeIndex === 0) {
        if (episodeId?.length > 0) {
          const dataForSelectedEpisode = headerDataBySeason?.videos?.filter(
            (episodeData) => episodeData?.episodePartId === episodeId
          )[0];
          setSelectedEpisodeData(dataForSelectedEpisode);
        }
      } else {
        let allEpisodes = [...episodes];
        if (
          allEpisodes.some(
            (newEpisode) => newEpisode?.seriesPartNoId === episodeId
          )
        ) {
          toastError("this episode was selected before!");
          allEpisodes[episodeIndex].seriesPartNoId = "";
          setEpisodes(allEpisodes);
        } else {
          allEpisodes[episodeIndex].seriesPartNoId = episodeId;
          allEpisodes[episodeIndex].episodeErrors[1].hasError = false;
          setEpisodes(allEpisodes);
        }
      }
    } else {
      if (episodeId?.length > 0) {
        let allEpisodes = [...episodes];
        allEpisodes[episodeIndex].seriesPartNoId = episodeId;
        allEpisodes[episodeIndex].episodeErrors[1].hasError = false;
        setEpisodes(allEpisodes);
      }
    }
  };

  const handleChangeEpisodeTitle = (event, episodeIndex) => {
    let allEpisodes = [...episodes];
    allEpisodes[episodeIndex].title = event.target.value;
    allEpisodes[episodeIndex].episodeErrors[7].hasError = false;
    setEpisodes(allEpisodes);
  };

  const handleYearsOfProduction = (e, episodeIndex) => {
    const allEpisodes = [...episodes];
    const pattern = /^[\d]{4,4}$/;
    const value = e.target.value;

    if (pattern.test(value)) {
      allEpisodes[episodeIndex].yearOfProduction = +value;
      allEpisodes[episodeIndex].episodeErrors[2].hasError = false;
      setEpisodes(allEpisodes);
    } else {
      allEpisodes[episodeIndex].yearOfProduction = +value;
      allEpisodes[episodeIndex].episodeErrors[2].hasError = true;
      setEpisodes(allEpisodes);
    }
  };

  const handleSetImageFilePath = (imageFilePath, episodeIndex) => {
    if (imageFilePath?.length > 0) {
      let allEpisodes = [...episodes];
      allEpisodes[episodeIndex].imgPath = imageFilePath;
      allEpisodes[episodeIndex].episodeErrors[4].hasError = false;
      setEpisodes(allEpisodes);
    }
  };

  const handleShowImagesProgressBar = (show, episodeIndex) => {
    let allEpisodes = [...episodes];
    allEpisodes[episodeIndex].episodeErrors[4].progressStatus = show;
    setEpisodes(allEpisodes);
  };

  const handleSetImageProgressPercent = (percent, episodeIndex) => {
    // let allFilesPregress = [...fileProgress];
    // allFilesPregress[episodeIndex].imageProgressPercent = percent;
    // setFileProgress(allFilesPregress);
    let allEpisodes = [...episodes];
    allEpisodes[episodeIndex].episodeErrors[4].progressPercent = percent;
    setEpisodes(allEpisodes);
  };

  const handleSetVideoFilePathForM3u8 = (
    // filePath,
    // fileName,
    index,
    videoPath
  ) => {
    let allEpisodes = [...episodes];
    allEpisodes[index].path = videoPath;
    allEpisodes[index].episodeErrors[5].hasError = false;
    setEpisodes(allEpisodes);
  };

  const handleDataFromTextEditor = (
    descriptionEn,
    descriptionAr,
    episodeIndex
  ) => {
    if (descriptionEn && descriptionAr) {
    }
    let allEpisodes = [...episodes];
    allEpisodes[episodeIndex]["description.ar"] = descriptionAr;
    allEpisodes[episodeIndex]["description.en"] = descriptionEn;
    allEpisodes[episodeIndex].episodeErrors[6].hasError = false;
    setEpisodes(allEpisodes);
  };

  const resetEpisodeFields = () => {
    let allEpisodes = [...episodes];
    // let allFileProgress = [...fileProgress];
    allEpisodes[0] = {
      videoHeaderId: headerDataBySeason.videoHeaderId,
      isSeries: true,
      seasonId: headerDataBySeason.seasonId,
      seriesPartNoId: episodesOnSystem[episodes?.length - 1].indexId,
      title: "",
      imgPath: "",
      path: "",
      lowQualityVideoPath: "",
      isUploading: false,
      videoOptions: {},
      yearOfProduction: "",
      duration: "",
      "description.en": "",
      "description.ar": "",

      episodeErrors: [
        { hasError: false, error: "Please select season" },
        { hasError: false, error: "Please select episode" },
        {
          hasError: false,
          error: "Please provide years of production",
        },
        { hasError: false, error: "Please select video quality" },
        {
          hasError: false,
          error: "Please upload an image ",
          progressStatus: false,
          progressPercent: "",
        },
        {
          hasError: false,
          error: "Please upload a video ",
          progressStatus: false,
          progressPercent: "",
        },
        {
          hasError: false,
          error: "Please provide arabic and english description",
        },
        {
          hasError: false,
          error: "Please provide title",
        },
      ],
    };

    setEpisodes(allEpisodes);
    // setFileProgress(allFileProgress);
  };

  const closeThisEpisode = (episodeIndex) => {
    if (episodeIndex > 0) {
      let allEpisodes = [...episodes];
      const paths = getUnusedFilesForEpisode(allEpisodes[episodeIndex]);
      if (paths?.length > 0) {
        removeUnUsedFiles(
          paths,
          (success) => {
            if (success?.isSuccess) {
              allEpisodes.splice(episodeIndex, 1);
              setEpisodes(allEpisodes);
            } else {
              toastError(success.err_msg);
            }
          },
          (fail) => {
            if (fail?.data) {
              toastError(fail.data?.err_msg);
            } else {
              toastError();
            }
          }
        );
      } else {
        allEpisodes.pop();
        setEpisodes(allEpisodes);
      }
    }
  };

  const handleShowDeleteVideoModal = () => {
    setDeleteModalVisible(true);
  };

  const deleteEpisode = (id, isLastEpisode = false) => {
    deleteEpisodeFromHeader(
      id,
      (success) => {
        if (success?.isSuccess) {
          toastSuccess(success.message);
          onCancel();
          renderHeaderAfterEditEpisode();
          getAllHeaderDataAfterActions(id);
          if (isLastEpisode) {
            setSelectedEpisodeData(null);
            resetEpisodeFields();
          }
        } else {
          toastError(success.err_msg);
          onCancel();
        }
      },
      (fail) => {
        if (fail?.data) {
          toastError(fail.data?.err_msg);
          onCancel();
        } else {
          toastError();
          onCancel();
        }
      }
    );
  };

  const handleDeleteVideoFromSeries = () => {
    let noPartSelectedError = "No part selected to delete";
    if (headerDataBySeason?.videos?.length > 1) {
      if (episodes[0]?.id?.length > 0) {
        setDeleteLoading(true);
        deleteEpisode(episodes[0]?.id);
      } else {
        toastError(noPartSelectedError);
      }
    } else {
      if (episodes[0]?.id?.length > 0) {
        setDeleteLoading(true);
        deleteEpisode(episodes[0]?.id, true);
      } else {
        toastError(noPartSelectedError);
      }
    }
  };

  const onCancel = () => {
    setDeleteLoading(false);
    setDeleteModalVisible(false);
  };

  const validateAllEpisodes = (episodeIndex) => {
    if (episodes[episodeIndex]?.title === "") {
      let allEpisodes = [...episodes];
      allEpisodes[episodeIndex].episodeErrors[7].hasError = true;
      setEpisodes(allEpisodes);
    }
    if (episodes[episodeIndex]?.seriesPartNoId === "") {
      let allEpisodes = [...episodes];
      allEpisodes[episodeIndex].episodeErrors[1].hasError = true;
      setEpisodes(allEpisodes);
    }
    if (
      episodes[episodeIndex]?.yearOfProduction === "" ||
      episodes[episodeIndex]?.yearOfProduction === null ||
      episodes[episodeIndex]?.yearOfProduction === 0
    ) {
      let allEpisodes = [...episodes];
      allEpisodes[episodeIndex].episodeErrors[2].hasError = true;
      setEpisodes(allEpisodes);
    }

    if (episodes[episodeIndex]?.imgPath === "") {
      let allEpisodes = [...episodes];
      allEpisodes[episodeIndex].episodeErrors[4].hasError = true;
      setEpisodes(allEpisodes);
    }
    if (episodes[episodeIndex]?.path === "") {
      let allEpisodes = [...episodes];
      allEpisodes[episodeIndex].episodeErrors[5].hasError = true;
      setEpisodes(allEpisodes);
    }
    if (
      episodes[episodeIndex]?.["description.en"]?.length === 0 ||
      episodes[episodeIndex]?.["description.ar"]?.length === 0
    ) {
      let allEpisodes = [...episodes];
      allEpisodes[episodeIndex].episodeErrors[6].hasError = true;
      setEpisodes(allEpisodes);
    }
  };

  const callEditEpisode = (episodePayload, episodeIndex) => {
    const {
      episodeErrors,
      isUploading,
      videoOptions,
      ...episodeWithoutErrors
    } = episodePayload;
    setTimeout(() => {
      editEpisodeM3u8(
        episodeWithoutErrors,
        (success) => {
          if (success?.isSuccess) {
            setLoadingSubmit(false);
            toastSuccess(success.message);
            getAllHeaderDataAfterActions(episodeWithoutErrors?.seriesPartNoId);
            setRenderHeader((prev) => !prev);
            closeThisEpisode(episodeIndex);
          } else {
            setLoadingSubmit(false);
            toastError(success.err_msg);
          }
        },
        (fail) => {
          if (fail?.data) {
            setLoadingSubmit(false);
            toastError(fail.data?.err_msg);
          } else {
            toastError();
            setLoadingSubmit(false);
          }
        }
      );
    }, 1000);
  };

  const callAddEpisode = (episodePayload, episodeIndex) => {
    const {
      episodeErrors,
      audios,
      subtitles,
      isUploading,
      videoOptions,
      ...episodeWithoutErrors
    } = episodePayload;
    const addEpisodePayload = [episodeWithoutErrors];
    setTimeout(() => {
      addEpisodesToHeader(
        addEpisodePayload,
        (success) => {
          if (success?.isSuccess) {
            setLoadingSubmit(false);
            toastSuccess(success.message);
            getAllHeaderDataAfterActions(addEpisodePayload[0]?.seriesPartNoId);
            setRenderHeader((prev) => !prev);
            closeThisEpisode(episodeIndex);
          } else {
            setLoadingSubmit(false);
            toastError(success.err_msg);
          }
        },
        (fail) => {
          if (fail?.data) {
            setLoadingSubmit(false);
            toastError(fail.data?.err_msg);
          } else {
            toastError();
            setLoadingSubmit(false);
          }
        }
      );
    }, 1000);
  };

  const handleOnFinish = (episodeIndex) => {
    let allEpisodes = [...episodes];
    let episodePayload = allEpisodes[episodeIndex];
    validateAllEpisodes(episodeIndex);
    const checkAnyError = [];
    const selectedEpisodeError = episodes[episodeIndex]?.episodeErrors;
    if (selectedEpisodeError) {
      const hasEpisodeError = selectedEpisodeError.some(
        (singleObject) => singleObject.hasError
      );
      if (hasEpisodeError) {
        checkAnyError.push(hasEpisodeError);
      }
    }

    if (checkAnyError?.length === 0) {
      setLoadingSubmit(true);
      if (headerDataBySeason?.videos?.length > 0 && episodeIndex === 0) {
        //edit episode
        console.log("EDIT");
        callEditEpisode(episodePayload, episodeIndex);
      } else {
        //add new episode in edit screen
        // check previous and next season if havealt least one episode
        // console.log("ADDDD");
        checkPreviousAndNextSeasonIfHasEpisode(selectedSeasonId)
          .then((canAdd) => {
            let prevEpisodeId = null;
            episodesOnSystem.find((item, index) => {
              if (item?.indexId === episodePayload?.seriesPartNoId) {
                if (index !== 0) {
                  prevEpisodeId = episodesOnSystem[index - 1].indexId;
                  const previousEpisodeIsFound = headerDataBySeason.videos.some(
                    (el) => el?.episodePartId === prevEpisodeId
                  );
                  if (previousEpisodeIsFound) {
                    callAddEpisode(episodePayload, episodeIndex);
                  } else {
                    toastError("You must upload previous episode frist");
                  }
                } else {
                  callAddEpisode(episodePayload, episodeIndex);
                }
              }
            });
          })
          .catch((error) => {
            setLoadingSubmit(false);
            setCantAddEpisodes(true);
          })
          .finally(() => {
            setLoadingSubmit(false);
          });
      }
    } else {
      toastError(`Please, fill all required fields`);
    }
  };

  return (
    <Fragment>
      <SubHeader bgColor="#27A2D9" title="Episodes" />
      {episodes.map((episode, episodeIndex) => (
        <Form
          className="wd-100"
          onFinish={() => handleOnFinish(episodeIndex)}
          key={episodeIndex}
        >
          <div className="add-episodes-to-tvshows wd-100 mrg-y-30 pad-y-30">
            <div className=" wd-100 pad-x-10 ">
              {episodeIndex !== 0 &&
                episodeIndex === episodes?.length - 1 &&
                isUploadingNow === false && (
                  <Row justify="end" className="mrg-ye-20">
                    <Col>
                      <div
                        className="removeEpisods pad-xe-30"
                        role={"button"}
                        onClick={() => closeThisEpisode(episodeIndex)}
                      >
                        <CloseOutlined
                          style={{
                            fontSize: "20px",
                            color: "#6F767E",
                          }}
                        />
                      </div>
                    </Col>
                  </Row>
                )}
              {/* Season */}
              <Row gutter={36}>
                <Col xs={24} md={12} className="mrg-ye-20">
                  <div className="new-category  ">
                    <label className="view-tvshow-lbl-main">Season</label>
                    <Select
                      placeholder="Select Season"
                      className="search__searchField py-1 wd-100"
                      disabled
                      value={episodes[0]?.seasonId || undefined}
                      dropdownStyle={dropdownStyle}
                    >
                      {seasonsDependsOnHeaderId?.map(({ id, name }, index) => (
                        <Select.Option key={index} value={id}>
                          {name}
                        </Select.Option>
                      ))}
                    </Select>
                    {episode?.episodeErrors[0]?.hasError && (
                      <div className="episode-errors">
                        {episode?.episodeErrors[0]?.error}
                      </div>
                    )}
                  </div>
                </Col>
                <Col xs={24} md={12} className="mrg-ye-20">
                  <div className="new-category">
                    <label className="view-tvshow-lbl-main">
                      Choose Episode
                    </label>
                    <Select
                      placeholder="Choose Episode"
                      className="search__searchField py-1 wd-100"
                      onChange={(episodeId) =>
                        handleSelectEpisode(episodeId, episodeIndex)
                      }
                      value={episode?.seriesPartNoId || undefined}
                      dropdownStyle={dropdownStyle}
                      disabled={
                        (headerDataBySeason?.videos?.length > 0 &&
                        episodeIndex === 0
                          ? false
                          : cantAddEpisodes) || isUploading
                      }
                    >
                      {headerDataBySeason?.videos?.length > 0
                        ? episodeIndex === 0
                          ? currentEpisodes.map(
                              ({ indexId, episode }, index) => (
                                <Select.Option key={index} value={indexId}>
                                  {episode}
                                </Select.Option>
                              )
                            )
                          : newEpisodes.map(({ indexId, episode }, index) => {
                              if (index === episodeIndex - 1) {
                                return (
                                  <Select.Option key={index} value={indexId}>
                                    {episode}
                                  </Select.Option>
                                );
                              }
                            })
                        : episodesOnSystem.map(
                            ({ indexId, episode }, index) => {
                              if (index === episodeIndex) {
                                return (
                                  <Select.Option key={index} value={indexId}>
                                    {episode}
                                  </Select.Option>
                                );
                              }
                            }
                          )}
                    </Select>
                    {episode?.episodeErrors[1]?.hasError && (
                      <div className="episode-errors">
                        {episode?.episodeErrors[1]?.error}
                      </div>
                    )}
                  </div>
                </Col>
              </Row>
              {/* Episode */}
              <Row gutter={36}>
                <Col xs={24} md={12} className="mrg-ye-20">
                  <div className="new-actor__name new-category ">
                    <label className="view-tvshow-lbl-main" htmlFor="">
                      Episode title
                    </label>
                    <Input
                      placeholder="Episode Title"
                      type="text"
                      className="search__searchField py-2"
                      onChange={(event) =>
                        handleChangeEpisodeTitle(event, episodeIndex)
                      }
                      value={episode?.title || undefined}
                      disabled={
                        headerDataBySeason?.videos?.length > 0 &&
                        episodeIndex === 0
                          ? false
                          : cantAddEpisodes
                      }
                    />
                  </div>
                  {episode?.episodeErrors[7]?.hasError && (
                    <div className="episode-errors">
                      {episode?.episodeErrors[7]?.error}
                    </div>
                  )}
                </Col>
                <Col xs={24} md={12} className="mrg-ye-20">
                  <div className="new-category  ">
                    <label className="view-tvshow-lbl-main">
                      Year of production
                    </label>
                    <Input
                      placeholder="Year of production"
                      type={"number"}
                      className="search__searchField py-2"
                      onChange={(e) => handleYearsOfProduction(e, episodeIndex)}
                      value={episode?.yearOfProduction || undefined}
                      disabled={
                        headerDataBySeason?.videos?.length > 0 &&
                        episodeIndex === 0
                          ? false
                          : cantAddEpisodes
                      }
                    ></Input>
                    {episode?.episodeErrors[2]?.hasError && (
                      <div className="episode-errors">
                        {episode?.episodeErrors[2]?.error}
                      </div>
                    )}
                  </div>
                </Col>
              </Row>
              <Row gutter={16} style={{ marginBottom: "2.5rem" }}>
                {" "}
                {/* Image */}
                <Col xs={24} md={8} style={{ height: "251px" }}>
                  <div className="upload-files upload-files__image-small">
                    <div className="upload-files__container upload-files__container--image-small relative">
                      {episode?.imgPath?.length > 0 && (
                        <figure>
                          <img
                            className="upload-files__upload-file"
                            src={baseUrl + episode?.imgPath}
                            alt="actorImage"
                            style={{
                              position: "absolute",
                              zIndex: "3",
                              width: "100%",
                            }}
                          />
                        </figure>
                      )}
                      <DraggerUploader
                        withCrop
                        aspect={2 / 1}
                        formDataType={"img"}
                        acceptedTypes={"image/*"}
                        setShowProgressBar={(show) =>
                          handleShowImagesProgressBar(show, episodeIndex)
                        }
                        setProgressPercent={(percent) =>
                          handleSetImageProgressPercent(percent, episodeIndex)
                        }
                        setFilePath={(filePath) =>
                          handleSetImageFilePath(filePath, episodeIndex)
                        }
                        disableMe={
                          headerDataBySeason?.videos?.length > 0 &&
                          episodeIndex === 0
                            ? false
                            : cantAddEpisodes
                        }
                      >
                        {episode?.imgPath?.length > 0 ? (
                          <div className="upload-edit-icon mrg-x-20">
                            <img role="button" src={editIcon} alt="edit" />
                          </div>
                        ) : (
                          <div
                            role="button"
                            className="upload-files__button  "
                            style={{
                              boxShadow: "none",
                              backgroundColor: "transparent",
                              justifyContent: "center",
                              gap: "30px",
                              margin: "auto",
                            }}
                          >
                            <div className="upload-files__icon">
                              <img src={uploadButton} alt="" />
                            </div>
                            <div className="upload-files__title">
                              {"Upload Image"}
                            </div>
                            <div className="upload-files__icon">
                              <img src={uploadImageIcon} alt="" />
                            </div>
                          </div>
                        )}
                      </DraggerUploader>
                    </div>
                    {episode?.episodeErrors[4]?.hasError && (
                      <div className="episode-errors">
                        {episode?.episodeErrors[4]?.error}
                      </div>
                    )}
                  </div>
                  {episode?.episodeErrors[4]?.progressStatus && (
                    <Progress
                      style={{ marginTop: "1rem" }}
                      percent={episode?.episodeErrors[4].progressPercent}
                    />
                  )}
                </Col>
                {/* Video */}
                <Col xs={24} md={16}>
                  {/* <CompressedFileUploader
                    setFilePath={(filePath, fileName, videoPath) =>
                      handleSetVideoFilePathForM3u8(
                        filePath,
                        episodeIndex,
                        fileName,
                        videoPath
                      )
                    }
                    path={episode.path}
                    // episodeIndex={uniqueId}
                    part={episode}
                    episodeIndex={episodeIndex}
                    disabled={
                      headerDataBySeason?.videos?.length > 0 &&
                      episodeIndex === 0
                        ? false
                        : cantAddEpisodes
                    }
                  /> */}
                  {/* <DraggerFileUploader
                    setFilePath={(filePath, fileName, videoPath) =>
                      handleSetVideoFilePathForM3u8(
                        filePath,
                        episodeIndex,
                        fileName,
                        videoPath
                      )
                    }
                    srcVideo={episode.path}
                    part={episode}
                    episodeIndex={episodeIndex}
                    disableMe={
                      headerDataBySeason?.videos?.length > 0 &&
                      episodeIndex === 0
                        ? false
                        : cantAddEpisodes
                    }
                  /> */}
                  <FolderUploader
                    observeUploading={observeUploading}
                    getVideoDuration={(duration) =>
                      handleGetVideoDuration(episodeIndex, duration)
                    }
                    setFilePath={(videoPath) =>
                      handleSetVideoFilePathForM3u8(episodeIndex, videoPath)
                    }
                    episodeIndex={episodeIndex}
                    srcVideo={episode.path}
                  />
                  {episode?.episodeErrors[5]?.hasError && (
                    <div className="episode-errors">
                      {episode?.episodeErrors[5]?.error}
                    </div>
                  )}
                </Col>
              </Row>
              {/* Description */}
              <Row>
                <Col span={24}>
                  <div className="wd-100 mrg-x-auto  mrg-y-20 ">
                    <span className="view-tvshow-lbl-main">Description</span>
                    <SecondaryEditor
                      className="d-none"
                      putDescriptionEn={
                        episodeIndex === 0
                          ? selectedEpisodeData?.description?.en
                            ? selectedEpisodeData.description.en
                            : ""
                          : ""
                      }
                      putDescriptionAr={
                        episodeIndex === 0
                          ? selectedEpisodeData?.description?.ar
                            ? selectedEpisodeData.description.ar
                            : ""
                          : ""
                      }
                      getTextEditorData={(descriptionEn, descriptionAr) =>
                        handleDataFromTextEditor(
                          descriptionEn,
                          descriptionAr,
                          episodeIndex
                        )
                      }
                      disableMe={
                        headerDataBySeason?.videos?.length > 0 &&
                        episodeIndex === 0
                          ? false
                          : cantAddEpisodes
                      }
                    />
                    {episode?.episodeErrors[6]?.hasError && (
                      <div className="episode-errors">
                        {episode?.episodeErrors[6]?.error}
                      </div>
                    )}
                  </div>
                </Col>
              </Row>
              <Row justify="start">
                <Col span={9}>
                  <p>User can watch this video without subscription</p>
                </Col>
                <Col>
                  <Switch
                    style={{
                      backgroundColor: episode?.isFree ? "#31AA4A" : "", // Change the background color when checked
                      // Change the border color when checked
                    }}
                    checked={episode?.isFree}
                    // Reflect the state of the 'isFree' variable
                    onChange={(checked) =>
                      handleFreeVideoChange(checked, episodeIndex)
                    } // Toggle the 'isFree' state on change
                  />
                </Col>
              </Row>
              <div className="flex justify-content-between  align-items-end wd-100 ">
                <span className="view-tvshow-lbl   flex-1">
                  {episodeIndex === episodes?.length - 1 && isUploadingNow
                    ? " You cannot add new episodes until current uploads are completed"
                    : ""}
                </span>
                <Space
                  size={20}
                  className="config-video-buttons flex justify-end  wd-40 mrg-ys-30 pad-x-20"
                >
                  {episodeIndex === episodes?.length - 1 && (
                    <div className="btn-save " style={{ width: "129px" }}>
                      <PlusButton
                        buttonName="Add Episode"
                        styling="secondary"
                        action={() => handleAddAnotherEpisode()}
                        disableMe={cantAddEpisodes || isUploadingNow}
                      />
                    </div>
                  )}
                  {episodeIndex === 0 && (
                    <div style={{ width: "129px" }}>
                      <Button
                        className="delete-video-button"
                        disabled={
                          selectedEpisodeData?.index === maxIndex ? false : true
                        }
                        onClick={() => handleShowDeleteVideoModal()}
                      >
                        Delete Episode
                      </Button>
                    </div>
                  )}
                  <div className="btn-save " style={{ width: "129px" }}>
                    <PrimaryButton
                      loading={loadingSubmit}
                      disableMe={
                        (headerDataBySeason?.videos?.length > 0 &&
                        episodeIndex === 0
                          ? false
                          : cantAddEpisodes) || disableAddButton
                      }
                      type={"submit"}
                      buttonName={
                        episodeIndex > 0
                          ? "Add"
                          : headerDataBySeason?.videos?.length > 0
                          ? "Save"
                          : "Add"
                      }
                    />
                  </div>
                </Space>
              </div>
            </div>
          </div>
        </Form>
      ))}
      <Row justify="end" className="mrg-ye-20">
        <Col>
          <div className="frist-button "></div>
        </Col>
      </Row>
      <DeleteModal
        deleteModalVisible={deleteModalVisible}
        SubHeader={
          <SubHeader
            bgColor="#92979C"
            title="Delete Episode"
            closeButton
            onCancel={onCancel}
          />
        }
        icon={warningIcon}
        msg="Are you sure you want to delete the episode?"
        onCancel={onCancel}
        action={handleDeleteVideoFromSeries}
        secondaryBtnName="Cancel"
        primaryBtnName="Delete"
        loading={deleteLoading}
      />
    </Fragment>
  );
}

export default EditEpisodeM3u8;
