import React, { useState } from "react";
import Routes from "modules/Routes/Routes";
import Navbar from "modules/Navbar/Navbar";
import SideBar from "modules/SideBar/SideBar";
import { useLocation } from "react-router-dom";

function General() {
  const { pathname } = useLocation();
  const notHaveNavSide = ["/"];
  const pagesHaveSearchInNavbar = [
    "/movies",
    "/tv-shows",
    "/anime",
    "/tv-programs",
    "/fun-store",
  ];

  return (
    <section className={"general-section "}>
      {!notHaveNavSide.includes(pathname) && (
        <div
          className={`${
            notHaveNavSide.includes(pathname) ? "d-none" : "side-bar"
          }`}
        >
          <SideBar />
        </div>
      )}
      <div
        className={`${
          notHaveNavSide.includes(pathname) ? "wd-100" : "all-content"
        }`}
      >
        {!notHaveNavSide.includes(pathname) && (
          <div className="nav-bar">
            <Navbar
              withSearch={
                pagesHaveSearchInNavbar.includes(pathname) ? true : false
              }
            />
          </div>
        )}
        <div className="component-wrapper">
          <div className="component ">
            <Routes />
          </div>
        </div>
      </div>
    </section>
  );
}

export default General;
