import React, { useState } from "react";

function SecondaryEditorForBanner({
  descriptionEn,
  descriptionAr,
  setDescriptionAr,
  setDescriptionEn,
}) {
  const [selectedEnglishLanguage, setSelectedEnglishLanguage] = useState(true);
  const [selectedArabicLanguage, setSelectedArabicLanguage] = useState(false);

  const handleSelectEnglishLang = () => {
    setSelectedEnglishLanguage(true);
    setSelectedArabicLanguage(false);
  };

  const handleSelectArabicLang = () => {
    setSelectedArabicLanguage(true);
    setSelectedEnglishLanguage(false);
  };

  return (
    <div className="editor wd-100 ">
      <div className="options  wd-100 flex justify-center">
        <div className="lang  ">
          <div
            role="button"
            className={
              selectedArabicLanguage ? "selected-lang" : "item-lang arabic "
            }
            onClick={handleSelectArabicLang}
          >
            AR
          </div>
          <div
            role="button"
            className={
              selectedEnglishLanguage ? "selected-lang" : "item-lang english "
            }
            onClick={handleSelectEnglishLang}
          >
            EN
          </div>
        </div>
      </div>
      {selectedEnglishLanguage && (
        <div className="content ">
          <textarea
            className="text-area"
            value={descriptionEn}
            onChange={(e) => setDescriptionEn(e.target.value)}
          ></textarea>
        </div>
      )}
      {selectedArabicLanguage && (
        <div className="content ">
          <textarea
            className="text-area"
            value={descriptionAr}
            onChange={(e) => setDescriptionAr(e.target.value)}
          ></textarea>
        </div>
      )}
    </div>
  );
}

export default SecondaryEditorForBanner;
