import HomeCategories from "../SubComponents/HomeCategories/HomeCategories";
import SubHeader from "modules/SubComponents/SubHeader";
import Header from "modules/SubComponents/Header";

function Home() {
  return (
    <div className="users ">
      <div className="users__all-contents ">
        <div className="users__content">
          <div className="users__usersData">
            <div className="home__header pad-x-25 wd-100">
              <Header title="Home Page" />
            </div>
            <div className="home__subHeader pad-x-20 wd-100">
              <SubHeader title="All Categories" bgColor="#F58234" />
            </div>
            <p className="home__title pad-x-50">
              Select the categories & their index that you want to see in the
              app's home page:
            </p>
            <HomeCategories />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
