import { useState } from "react";
import { Col, Input, Row, Spin, Switch } from "antd";
import { toastError } from "helpers/toasters";
import SecondaryEditor from "modules/SubComponents/Editor/SecondaryEditor";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { baseUrl } from "services";
import { getAllVideosDetailsForMovie } from "Network";
import Hls from "hls.js";
import { addTokenToMedia } from "helpers/addTokenToMedia";

function ViewPartM3u8({ headerId, partId }) {
  const [loading, setLoading] = useState(false);
  const [allMovieDataWithVideos, setAllMovieDataWithVideos] = useState([]);
  const [selectedHeaderId, setSelectedHeaderId] = useState(null);
  const [selectedPartId, setSelectedPartId] = useState(null);
  const [selectedPartData, setSelectedPartData] = useState([]);
  const [videoQualityName, setVideoQualityName] = useState(null);
  const { videoQualitiesOnSystem } = useSelector(
    (state) => state?.staticData?.staticDataOnSystem
  );

  useEffect(() => {
    if (headerId?.length > 0 && partId?.length > 0) {
      setLoading(true);
      setSelectedHeaderId(headerId);
      setSelectedPartId(partId);
    }
  }, [headerId, partId]);

  useEffect(() => {
    if (selectedHeaderId?.length > 0) {
      getAllVideosDetailsForMovie(
        { videoHeaderId: selectedHeaderId },
        (success) => {
          if (success?.isSuccess) {
            setAllMovieDataWithVideos(success.data[0]);
          }
        },
        (fail) => {
          toastError(fail.data.err_msg);
        }
      );
    }
  }, [selectedHeaderId]);

  useEffect(() => {
    if (
      allMovieDataWithVideos?.videos?.length > 0 &&
      selectedPartId?.length > 0
    ) {
      const selectedData = allMovieDataWithVideos.videos.filter(
        (partData) => partData?.id === selectedPartId
      );
      setSelectedPartData(selectedData[0]);
      setLoading(false);
    }
  }, [allMovieDataWithVideos, selectedPartId]);

  useEffect(() => {
    if (Hls.isSupported() && selectedPartData?.path) {
      const hlsInstance = new Hls();
      hlsInstance.loadSource(addTokenToMedia(baseUrl + selectedPartData.path));
      hlsInstance.attachMedia(document.getElementById(selectedPartData?.id));
    }
  }, [selectedPartData]);

  const handleDownloadFile = (filePath, fileName, fileType) => {
    const a = Object.assign(document.createElement("a"), {
      href: filePath,
      style: "display:none",
      download: `${fileName}.${fileType}`,
    });
    document.body.appendChild(a);
    a.click();
    // URL.revokeObjectURL(filePath);
    a.remove();
  };

  const handleDataFromTextEditor = (descriptionEn, descriptionAr) => {};

  return (
    <Spin className="wd-100" spinning={loading}>
      <div className="add-episodes-to-tvshows wd-100 mrg-y-30 pad-y-30 ">
        <div className=" wd-100 pad-x-10 ">
          <Row>
            <Col>
              <label className="view-tvshow-lbl-main mrg-ye-20">
                {selectedPartData?.episodePart}
              </label>
            </Col>
          </Row>
          <Row gutter={16} className="mrg-ye-20">
            {selectedPartData?.imgPath && (
              <>
                <Col xs={24} md={8}>
                  <div className="upload-files">
                    <div className="upload-files__container relative">
                      <figure>
                        <img
                          className="upload-files__upload-file"
                          src={baseUrl + selectedPartData?.imgPath}
                          alt="actorImage"
                        />
                      </figure>
                    </div>
                  </div>
                </Col>
                <Col xs={24} md={16}>
                  <div className="upload-files">
                    <div className="upload-files__container relative">
                      {selectedPartData?.videoPath && (
                        <video
                          id={selectedPartData?.id}
                          width="100%"
                          height="100%"
                          controls
                          muted
                          autoPlay
                        ></video>
                      )}
                    </div>
                  </div>
                </Col>
              </>
            )}
          </Row>
          <Row>
            <Col>
              <label className="view-tvshow-lbl-main ">Description</label>
            </Col>
          </Row>
          <Row className="mrg-ye-20">
            <Col span={24}>
              <div className="wd-100 mrg-x-auto  mrg-ye-20 ">
                {/* <span className="title-style">Description</span> */}
                <SecondaryEditor
                  className="d-none"
                  mode="view"
                  putDescriptionEn={selectedPartData?.description?.en}
                  putDescriptionAr={selectedPartData?.description?.ar}
                  getTextEditorData={(descriptionEn, descriptionAr) =>
                    handleDataFromTextEditor(descriptionEn, descriptionAr)
                  }
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={24} className="mrg-ye-20">
              <div className="new-actor__name new-category ">
                <label className="view-tvshow-lbl-main" htmlFor="">
                  Part title
                </label>
                <Input
                  type="text"
                  className="search__searchField py-2"
                  value={selectedPartData?.title}
                ></Input>
              </div>
            </Col>
          </Row>
          <Row gutter={36} className="mrg-ye-20">
            <Col xs={24} md={12} className="mrg-ye-10">
              <div className="new-category  ">
                <label className="view-tvshow-lbl-main ">
                  Year of production
                </label>
                <Input
                  type={"number"}
                  className="search__searchField py-2"
                  value={selectedPartData?.yearOfProduction}
                ></Input>
              </div>
            </Col>
            {/* <Col xs={24} md={12} className="mrg-ye-10">
              <div className="new-category">
                <label className="view-tvshow-lbl-main">Quality</label>
                <Input
                  className="search__searchField py-2"
                  value={videoQualityName?.videoQuality}
                ></Input>
              </div>
            </Col> */}
          </Row>
          <Row justify="start">
            <Col>
              <label className="view-tvshow-lbl-main ">Free</label>
            </Col>
          </Row>
          <Row justify="start">
            <Col span={10}>
              <p>User can watch this video without subscription</p>
            </Col>
            <Col>
              <Switch
                style={{
                  backgroundColor: selectedPartData?.isFree ? "#31AA4A" : "", // Change the background color when checked
                  // Change the border color when checked
                }}
                checked={selectedPartData.isFree}
                // value={selectedPartData.isFree}
                // value={selectedPartData.isFree}
                // // Reflect the state of the 'isFree' variable
                // Toggle the 'isFree' state on change
              />
            </Col>
          </Row>
        </div>
      </div>
    </Spin>
  );
}

export default ViewPartM3u8;
