import React, { useState, useEffect } from "react";
import {
  Col,
  Form,
  Pagination,
  Progress,
  Radio,
  Row,
  Select,
  Space,
} from "antd";
import { toastError, toastSuccess } from "helpers/toasters";
import PlusButton from "modules/SubComponents/Buttons/PlusButton";
import PrimaryButton from "modules/SubComponents/Buttons/PrimaryButton";
import Header from "modules/SubComponents/Header";
import { baseUrl } from "services";
import deleteIcon from "assets/icons/deleteAvatar.svg";
import editIcon from "assets/icons/pen.svg";
import DeleteModal from "modules/Modals/DeleteModal";
import SubHeader from "modules/SubComponents/SubHeader";
import warningIcon from "assets/icons/warning.svg";
import UploadImageToBanner from "assets/icons/uploadImageIcon.svg";
import uploadButton from "assets/icons/uploadButton.svg";
import CustomFileUploader from "modules/SubComponents/Upload/CustomFileUploader";
import {
  addBanner,
  deleteBannerById,
  editBanner,
  getAllBannersOnSystem,
  getAllEpisodsDependsOnSeason,
  getAllHeadersByGenreId,
  getAllPartsDependsOnMovies,
  getAllSeasonsDependsOnSeries,
} from "Network";
import SecondaryEditorForBanner from "modules/SubComponents/Editor/SecondaryEditorForBanner";
import { useSelector } from "react-redux";
import DraggerUploader from "modules/SubComponents/Upload/DraggerUploader";

const dropdownStyle = {
  border: " solid 1px #CEE2F6",
  borderRadius: "12px",
  boxShadow: "0px 8px 10px #0000001A ",
};

function Banner() {
  const { genresOnSystem } = useSelector(
    (state) => state?.staticData?.staticDataOnSystem
  );
  const [loadingSubmitBtn, setLoadingSubmitBtn] = useState(false);
  const [showSwitches, setShowSwitches] = useState(false);
  const [mode, setMode] = useState("add");
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedPage, setSelectedPage] = useState(null);
  const [selectedGenre, setSelectedGenre] = useState({});
  const [selectedBannerData, setSelectedBannerData] = useState();
  const [descriptionEn, setDescriptionEn] = useState();
  const [descriptionAr, setDescriptionAr] = useState();
  const [allBannersOnSystem, setAllBannersOnSystem] = useState([]);
  const [headersDataByGenre, setHeadersDataByGenre] = useState([]);
  const [filteredHeaders, setFilteredHeaders] = useState([]);
  const [allPartsByMovie, setAllPartsByMovie] = useState([]);
  const [allSeasonsBySeries, setAllSeasonsBySeries] = useState([]);
  const [allEpisodesBySeason, setAllEpisodesBySeason] = useState([]);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [selectedBannerIdForDeleting, setSelectedBannerIdForDeleting] =
    useState(null);
  const [bannerData, setBannerData] = useState({
    isSeries: true,
    bannerId: null,
    videoHeaderId: null,
    seasonId: null,
    seriesNumberId: null,
    imgPath: "",
    "description.en": null,
    "description.ar": null,
  });
  const [bannerErrors, setBannerErrors] = useState([
    { hasError: false, error: "Please select a movie", status: "" },
    {
      hasError: false,
      error: "Please select a part",
      status: "",
      disabled: true,
    },
    { hasError: false, error: "Please select a series", status: "" },
    {
      hasError: false,
      error: "Please select a season",
      status: "",
      disabled: true,
    },
    {
      hasError: false,
      error: "Please select an episode",
      status: "",
      disabled: true,
    },
    {
      hasError: false,
      error: "Please upload an image",
      status: "",
      progressStatus: false,
      progressPercent: "",
    },
    { hasError: false, error: "Please Provide english and arabic Description" },
  ]);
  const [form] = Form.useForm();

  useEffect(() => {
    if (selectedPage) {
      setCurrentPage(selectedPage);
    }
  }, [selectedPage]);

  useEffect(() => {
    setSelectedGenre(genresOnSystem[0]);
  }, [genresOnSystem]);

  useEffect(() => {
    let allBannerData = { ...bannerData };
    allBannerData["description.ar"] = descriptionAr;
    allBannerData["description.en"] = descriptionEn;
    setBannerData(allBannerData);
    //eslint-disable-next-line
  }, [descriptionAr, descriptionEn]);

  useEffect(() => {
    updateBannersAfterAnyAction();
  }, []);

  useEffect(() => {
    // setSelectedBannerData(allBannersOnSystem[0]);
    if (allBannersOnSystem?.length === 0) {
      setMode("add");
    }
  }, [allBannersOnSystem]);

  useEffect(() => {
    if (selectedGenre?.id) {
      let headersByGen = [];
      if (mode === "add") {
        if (
          selectedGenre.id === genresOnSystem[0].id ||
          selectedGenre.id === genresOnSystem[3].id
        ) {
          resetFields(true);
          setShowSwitches(false);
        } else if (selectedGenre.id === genresOnSystem[1].id) {
          resetFields(false);
          setShowSwitches(false);
        } else {
          resetFields(true);
          setShowSwitches(true);
        }
        getAllHeadersByGenreId(
          { genreId: selectedGenre.id },
          (success) => {
            success.data.forEach((header) => {
              if (header?.videos?.length > 0) {
                headersByGen.push({
                  label: header?.title,
                  value: header?._id,
                  isSeries: header?.isSeries,
                });
              }
            });
            setHeadersDataByGenre(headersByGen);
            if (
              selectedGenre.id === genresOnSystem[2]?.id ||
              selectedGenre.id === genresOnSystem[4]?.id
            ) {
              setShowSwitches(true);
              let headers = headersByGen?.filter(
                (seriesHeader) => seriesHeader?.isSeries
              );
              setFilteredHeaders(headers);
            }
          },
          (fail) => {
            if (fail?.data) {
              toastError(fail.data?.err_msg);
            }
          }
        );
      } else {
        if (
          selectedGenre.id === genresOnSystem[2].id ||
          selectedGenre.id === genresOnSystem[4].id
        ) {
          setShowSwitches(true);
        }
        getAllHeadersByGenreId(
          { genreId: selectedGenre.id },
          (success) => {
            success.data.forEach((header) =>
              headersByGen.push({
                label: header?.title,
                value: header?._id,
                isSeries: header?.isSeries,
              })
            );
            setHeadersDataByGenre(headersByGen);
          },
          (fail) => {
            if (fail?.data) {
              toastError(fail.data?.err_msg);
            }
          }
        );
      }
    } //eslint-disable-next-line
  }, [selectedGenre?.id]);

  useEffect(() => {
    if (mode === "edit" && headersDataByGenre) {
      if (bannerData.isSeries) {
        let seriesHeaders = headersDataByGenre.filter(
          (seriesHeader) => seriesHeader?.isSeries
        );
        setFilteredHeaders(seriesHeaders);
      } else {
        let movieHeaders = headersDataByGenre.filter(
          (seriesHeader) => !seriesHeader?.isSeries
        );
        setFilteredHeaders(movieHeaders);
      }
    }
  }, [bannerData.isSeries, mode, headersDataByGenre]);

  const updateBannersAfterAnyAction = (page = 1) => {
    getAllBannersOnSystem(
      (success) => {
        if (success?.isSuccess) {
          setAllBannersOnSystem(success.data);
          setSelectedBannerData(success.data[page - 1]);
        }
      },
      (fail) => {
        if (fail?.data?.error) {
          toastError(fail.data.err_msg);
        } else {
          toastError();
        }
      }
    );
  };

  const paginationOnChange = (page) => {
    setCurrentPage(page);
    setSelectedPage(page);
    setSelectedBannerData(allBannersOnSystem[page - 1]);
    resetFields(bannerData?.isSeries);
  };

  const getSeasonsBySeriesId = (seriesId) => {
    getAllSeasonsDependsOnSeries(
      { videoHeaderId: seriesId },
      (success) => {
        if (success?.isSuccess) {
          setAllSeasonsBySeries(success.data);
        }
      },
      (fail) => {
        if (fail?.data?.error) {
          toastError(fail.data.err_msg);
        } else {
          toastError();
        }
      }
    );
  };

  const getEpisodesBySeasonId = (headerId, seasonId) => {
    getAllEpisodsDependsOnSeason(
      { videoHeaderId: headerId, seasonId },
      (success) => {
        if (success?.isSuccess) {
          setAllEpisodesBySeason(success.data);
        }
      },
      (fail) => {
        if (fail?.data?.error) {
          toastError(fail.data.err_msg);
        } else {
          toastError();
        }
      }
    );
  };

  const getPartsByMovieId = (movieId) => {
    getAllPartsDependsOnMovies(
      { videoHeaderId: movieId },
      (success) => {
        if (success?.isSuccess) {
          setAllPartsByMovie(success.data);
        }
      },
      (fail) => {
        if (fail?.data?.error) {
          toastError(fail.data.err_msg);
        } else {
          toastError();
        }
      }
    );
  };

  const handleEditBanner = () => {
    setSelectedPage(currentPage);
    setMode("edit");
    let allBannerData = { ...bannerData };
    setSelectedGenre(
      genresOnSystem.find((gen) => gen?.name === selectedBannerData?.genre)
    );
    if (
      selectedBannerData?.genre === genresOnSystem[2]?.name ||
      selectedBannerData?.genre === genresOnSystem[4].name
    ) {
      setShowSwitches(true);
    } else {
      setShowSwitches(false);
    }
    if (selectedBannerData?.isSeries) {
      allBannerData.isSeries = true;
      allBannerData.seasonId = selectedBannerData?.seasonId;
      getSeasonsBySeriesId(selectedBannerData?.videoHeaderId);
      getEpisodesBySeasonId(
        selectedBannerData?.videoHeaderId,
        selectedBannerData?.seasonId
      );
      let headers = headersDataByGenre.filter(
        (seriesHeader) => seriesHeader?.isSeries
      );
      setFilteredHeaders(headers);
    } else {
      allBannerData.isSeries = false;
      getPartsByMovieId(selectedBannerData?.videoHeaderId);
      let headers = headersDataByGenre.filter(
        (seriesHeader) => !seriesHeader?.isSeries
      );
      setFilteredHeaders(headers);
    }
    allBannerData.bannerId = selectedBannerData?.bannerId;
    allBannerData.videoHeaderId = selectedBannerData?.videoHeaderId;
    allBannerData.seriesNumberId = selectedBannerData?.seriesNumberId;
    allBannerData.imgPath = selectedBannerData?.imgPath;
    allBannerData["description.ar"] = selectedBannerData?.description?.ar;
    allBannerData["description.en"] = selectedBannerData?.description?.en;
    setBannerData(allBannerData);
    setDescriptionAr(selectedBannerData?.description?.ar);
    setDescriptionEn(selectedBannerData?.description?.en);
    disableSelectComponents(false);
    hideErrorMasseges();
  };
  const handleAddAnotherBanner = () => {
    setMode("add");
    resetFields(selectedBannerData?.isSeries);
  };

  const hideErrorMasseges = () => {
    let allBannerErrors = [...bannerErrors];
    allBannerErrors.map((obj) => {
      obj.hasError = false;
      if (obj?.progressStatus) {
        obj.progressStatus = false;
      }
      return obj;
    });
    setBannerErrors(allBannerErrors);
  };

  const disableSelectComponents = (status) => {
    let allBannerErrors = [...bannerErrors];
    allBannerErrors.map((obj) => {
      if (obj.disabled === false) {
        obj.disabled = status;
      } else {
        obj.disabled = status;
      }
      return obj;
    });
    setBannerErrors(allBannerErrors);
  };

  const resetFields = (isSeries) => {
    setDescriptionAr("");
    setDescriptionEn("");
    setBannerData({
      isSeries: isSeries,
      bannerId: null,
      videoHeaderId: null,
      seasonId: null,
      seriesNumberId: null,
      imgPath: "",
      "description.en": null,
      "description.ar": null,
    });
    hideErrorMasseges();
  };
  const handleChooseGenre = (genre) => {
    setHeadersDataByGenre([]);
    setFilteredHeaders([]);
    setSelectedGenre(genre);
  };

  const handleOnChangeRadioGroup = (e) => {
    let allBannerData = { ...bannerData };
    if (headersDataByGenre?.length) {
      allBannerData.isSeries = e.target.value;
      setBannerData(allBannerData);
      if (e.target.value) {
        handleChooseSeries();
      } else {
        handleChooseMovies();
      }
    }
  };
  const handleChooseMovies = () => {
    setMode("add");
    disableSelectComponents(true);
    resetFields(false);
    let movies = headersDataByGenre.filter(
      (seriesHeader) => !seriesHeader?.isSeries
    );
    setFilteredHeaders(movies);
  };

  const handleChooseSeries = () => {
    setMode("add");
    disableSelectComponents(true);
    resetFields(true);
    let series = headersDataByGenre.filter(
      (seriesHeader) => seriesHeader?.isSeries
    );
    setFilteredHeaders(series);
  };

  const handleSelectMovieName = (videoHeaderId) => {
    if (videoHeaderId) {
      let allBannerData = { ...bannerData };
      allBannerData.videoHeaderId = videoHeaderId;
      allBannerData.seriesNumberId = "";
      allBannerData.isSeries = false;
      setBannerData(allBannerData);
      let allBannerErrors = [...bannerErrors];
      allBannerErrors[0].hasError = false;
      allBannerErrors[1].disabled = false;
      setBannerErrors(allBannerErrors);
      getPartsByMovieId(videoHeaderId);
    }
  };

  const handleSelectPartName = (partId) => {
    if (partId) {
      let allBannerData = { ...bannerData };
      allBannerData.seriesNumberId = partId;
      setBannerData(allBannerData);
      let allBannerErrors = [...bannerErrors];
      allBannerErrors[1].hasError = false;
      setBannerErrors(allBannerErrors);
    }
  };

  const handleSelectSeriesName = (seriesHeaderId) => {
    if (seriesHeaderId) {
      let allBannerData = { ...bannerData };
      allBannerData.videoHeaderId = seriesHeaderId;
      allBannerData.seasonId = "";
      allBannerData.seriesNumberId = "";
      allBannerData.isSeries = true;
      setBannerData(allBannerData);
      let allBannerErrors = [...bannerErrors];
      allBannerErrors[2].hasError = false;
      allBannerErrors[3].disabled = false;
      setBannerErrors(allBannerErrors);
      getSeasonsBySeriesId(seriesHeaderId);
    }
  };

  const handleSelectSeasonName = (seasonId) => {
    if (seasonId) {
      let allBannerData = { ...bannerData };
      allBannerData.seasonId = seasonId;
      allBannerData.seriesNumberId = "";
      setBannerData(allBannerData);
      let allBannerErrors = [...bannerErrors];
      allBannerErrors[3].hasError = false;
      allBannerErrors[4].disabled = false;
      setBannerErrors(allBannerErrors);
      getEpisodesBySeasonId(bannerData?.videoHeaderId, seasonId);
    }
  };

  const handleSelectEpisodeName = (episodeId) => {
    if (episodeId) {
      let allBannerData = { ...bannerData };
      allBannerData.seriesNumberId = episodeId;
      setBannerData(allBannerData);
      let allBannerErrors = [...bannerErrors];
      allBannerErrors[4].hasError = false;
      setBannerErrors(allBannerErrors);
    }
  };

  const handleSetImageFilePath = (filePath) => {
    if (filePath) {
      let allBannerData = { ...bannerData };
      allBannerData.imgPath = filePath;
      setBannerData(allBannerData);
      let allBannerErrors = [...bannerErrors];
      allBannerErrors[5].hasError = false;
      setBannerErrors(allBannerErrors);
    }
  };

  const handleShowImagesProgressBar = (status) => {
    let allBannerErrors = [...bannerErrors];
    allBannerErrors[5].progressStatus = status;
    setBannerErrors(allBannerErrors);
  };

  const handleSetImageProgressPercent = (percent) => {
    let allBannerErrors = [...bannerErrors];
    allBannerErrors[5].progressPercent = percent;
    setBannerErrors(allBannerErrors);
  };

  const showDeleteModal = (bannerId) => {
    setSelectedBannerIdForDeleting(bannerId);
    setDeleteModalVisible(true);
  };

  const handleDeleteBannerWithId = () => {
    deleteBannerById(
      { bannerId: selectedBannerIdForDeleting },
      (success) => {
        if (success?.isSuccess) {
          toastSuccess(success.message);
          resetFields(false);
          updateBannersAfterAnyAction();
          onCancel();
        } else {
          toastError(success.err_msg);
          onCancel();
        }
      },
      (fail) => {
        if (fail?.data) {
          toastError(fail.data?.err_msg);
          onCancel();
        } else {
          toastError();
          onCancel();
        }
      }
    );
  };

  const onCancel = () => {
    setLoadingSubmitBtn(false);
    setDeleteModalVisible(false);
  };

  const validateBanner = () => {
    let allBannerErrors = [...bannerErrors];
    if (bannerData?.isSeries) {
      if (!bannerData?.videoHeaderId) {
        allBannerErrors[2].hasError = true;
      }
      if (!bannerData?.seasonId) {
        allBannerErrors[3].hasError = true;
      }
      if (!bannerData?.seriesNumberId) {
        allBannerErrors[4].hasError = true;
      }

      setBannerErrors(allBannerErrors);
    } else {
      if (!bannerData?.videoHeaderId) {
        allBannerErrors[0].hasError = true;
      }
      if (!bannerData?.seriesNumberId) {
        allBannerErrors[1].hasError = true;
      }
      setBannerErrors(allBannerErrors);
    }
    if (!bannerData?.imgPath) {
      allBannerErrors[5].hasError = true;
    }
    setBannerErrors(allBannerErrors);
  };

  const handleFormOnFinish = () => {
    validateBanner();
    let bannerHasError = bannerErrors.some((obj, objIndex) => obj?.hasError);
    if (bannerHasError) {
      toastError("Please, Fill all required fields");
    } else {
      setLoadingSubmitBtn(true);
      if (mode === "add") {
        if (bannerData?.isSeries) {
          delete bannerData.bannerId;
          //add series banner
          addBanner(
            bannerData,
            (success) => {
              if (success?.isSuccess) {
                toastSuccess(success.message);
                updateBannersAfterAnyAction();
                resetFields(bannerData?.isSeries);
                setLoadingSubmitBtn(false);
              } else {
                toastError(success.err_msg);
                setLoadingSubmitBtn(false);
              }
            },
            (fail) => {
              if (fail?.data) {
                toastError(fail.data?.err_msg);
                setLoadingSubmitBtn(false);
              } else {
                toastError();
                setLoadingSubmitBtn(false);
              }
            }
          );
        } else {
          delete bannerData.bannerId;
          delete bannerData.seasonId;
          addBanner(
            bannerData,
            (success) => {
              if (success?.isSuccess) {
                toastSuccess(success.message);
                updateBannersAfterAnyAction();
                resetFields(bannerData?.isSeries);
                setLoadingSubmitBtn(false);
              } else {
                toastError(success.err_msg);
                setLoadingSubmitBtn(false);
              }
            },
            (fail) => {
              if (fail?.data) {
                toastError(fail.data?.err_msg);
                setLoadingSubmitBtn(false);
              } else {
                toastError();
                setLoadingSubmitBtn(false);
              }
            }
          );
        }
      } else {
        //edit
        if (bannerData?.isSeries) {
          editBanner(
            bannerData,
            (success) => {
              if (success?.isSuccess) {
                toastSuccess(success.message);
                updateBannersAfterAnyAction(selectedPage);
                resetFields(bannerData?.isSeries);
                setLoadingSubmitBtn(false);
              } else {
                toastError(success.err_msg);
                setLoadingSubmitBtn(false);
              }
            },
            (fail) => {
              if (fail?.data) {
                toastError(fail.data?.err_msg);
                setLoadingSubmitBtn(false);
              } else {
                toastError();
                setLoadingSubmitBtn(false);
              }
            }
          );
        } else {
          delete bannerData.seasonId;
          editBanner(
            bannerData,
            (success) => {
              if (success?.isSuccess) {
                toastSuccess(success.message);
                updateBannersAfterAnyAction(selectedPage);
                resetFields(bannerData?.isSeries);
                setLoadingSubmitBtn(false);
                setSelectedBannerData(allBannersOnSystem[selectedPage - 1]);
              } else {
                toastError(success.err_msg);
                setLoadingSubmitBtn(false);
              }
            },
            (fail) => {
              if (fail?.data) {
                toastError(fail.data?.err_msg);
                setLoadingSubmitBtn(false);
              } else {
                toastError();
                setLoadingSubmitBtn(false);
              }
            }
          );
        }
      }
    }
  };

  return (
    <>
      <Header title="Banner" />
      <div className="banner wd-100 flex dir-col items-start">
        {allBannersOnSystem?.length > 0 && (
          <div className="banner__banner-data wd-100 mrg-x-auto mrg-y-10 flex dir-col items-end  ">
            <div className="banner__view-banner wd-100 hg-50vh mrg-ye-20 radius-12 relative  ">
              <figure className="banner__figure">
                <img
                  className="banner__image"
                  src={baseUrl + selectedBannerData?.imgPath}
                  alt=""
                />
              </figure>
              <div className="banner__actions absolute flex  ">
                <div className="banner__actions-edit mrg-x-20">
                  <img
                    role="button"
                    src={editIcon}
                    alt=""
                    onClick={handleEditBanner}
                  />
                </div>
                <div className="banner__actions-delete mrg-xe-20">
                  <img
                    role="button"
                    src={deleteIcon}
                    alt=""
                    onClick={() =>
                      allBannersOnSystem?.length > 1 &&
                      showDeleteModal(selectedBannerData?.bannerId)
                    }
                  />
                </div>
              </div>
            </div>
            <div className="banner-pagination">
              <Pagination
                size="small"
                pageSize={1}
                defaultCurrent={1}
                current={currentPage}
                total={allBannersOnSystem?.length}
                onChange={paginationOnChange}
              />
            </div>
          </div>
        )}
        <Form form={form} onFinish={handleFormOnFinish} className="wd-100">
          <div className="banner__switches">
            {genresOnSystem.map((genre, genreIndex) => {
              return (
                <div
                  role={
                    mode === "edit" && allBannersOnSystem?.length > 0
                      ? ""
                      : "button"
                  }
                  onClick={
                    mode === "edit" && allBannersOnSystem?.length > 0
                      ? null
                      : () => handleChooseGenre(genre)
                  }
                  className={
                    genre?.id === selectedGenre?.id
                      ? "banner__radio mrg-x-3 banner__selected "
                      : "banner__radio mrg-x-3"
                  }
                  key={genre?.id}
                >
                  {genre?.name}
                </div>
              );
            })}
          </div>
          {showSwitches && (
            <Row className="mb-5 px-3">
              <Col span={24}>
                <div className="">
                  <Radio.Group
                    value={bannerData?.isSeries}
                    onChange={handleOnChangeRadioGroup}
                    className="radio-group-select-type d-flex  align-items-start"
                    size={"large"}
                    disabled={mode === "edit" ? true : false}
                  >
                    <Radio value={true} className="modal-title">
                      Series
                    </Radio>
                    <Radio value={false} size="" className="modal-title">
                      Movie
                    </Radio>
                  </Radio.Group>
                </div>
              </Col>
            </Row>
          )}

          {bannerData?.isSeries ? (
            <Row gutter={40} className="pad-x-20 mrg-ye-20">
              <Col span={12} className="mrg-ye-20">
                <div className="new-category">
                  <label className="title-style">Choose Series </label>
                  <Select
                    className="search__searchField py-1 wd-100"
                    onChange={handleSelectSeriesName}
                    value={bannerData?.videoHeaderId}
                    dropdownStyle={dropdownStyle}
                    status={
                      bannerErrors[2]?.hasError && bannerErrors[2]?.status
                    }
                    showSearch={true}
                    options={
                      selectedGenre?.id === genresOnSystem[2]?.id ||
                      selectedGenre?.id === genresOnSystem[4]?.id
                        ? filteredHeaders
                        : headersDataByGenre
                    }
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    filterSort={(optionA, optionB) =>
                      (optionA?.label ?? "")
                        .toLowerCase()
                        .localeCompare((optionB?.label ?? "").toLowerCase())
                    }
                  />
                  {bannerErrors[2]?.hasError && (
                    <div className="episode-errors">
                      {bannerErrors[2].error}
                    </div>
                  )}
                </div>
              </Col>
              <Col span={12}>
                <div className="new-category">
                  <label className="title-style">Choose Season </label>
                  <Select
                    className="search__searchField py-1 wd-100"
                    onChange={handleSelectSeasonName}
                    value={bannerData?.seasonId}
                    dropdownStyle={dropdownStyle}
                    disabled={bannerErrors[3]?.disabled}
                    status={
                      bannerErrors[3]?.hasError && bannerErrors[3]?.status
                    }
                  >
                    {allSeasonsBySeries?.map((season, index) => (
                      <Select.Option value={season?.id} key={index}>
                        {season?.name}
                      </Select.Option>
                    ))}
                  </Select>
                  {bannerErrors[3]?.hasError && (
                    <div className="episode-errors">
                      {bannerErrors[3].error}
                    </div>
                  )}
                </div>
              </Col>
              <Col span={24}>
                <div className="new-category">
                  <label className="title-style">Choose Episode </label>
                  <Select
                    className="search__searchField py-1 wd-100"
                    onChange={handleSelectEpisodeName}
                    value={bannerData?.seriesNumberId}
                    dropdownStyle={dropdownStyle}
                    disabled={bannerErrors[4]?.disabled}
                    status={
                      bannerErrors[4]?.hasError && bannerErrors[4]?.status
                    }
                  >
                    {allEpisodesBySeason?.map((episode, index) => (
                      <Select.Option value={episode?.id} key={index}>
                        {episode?.name}
                      </Select.Option>
                    ))}
                  </Select>
                  {bannerErrors[4]?.hasError && (
                    <div className="episode-errors">
                      {bannerErrors[4].error}
                    </div>
                  )}
                </div>
              </Col>
            </Row>
          ) : (
            <Row gutter={40} className="pad-x-20">
              <Col span={12} className="mrg-ye-20">
                <div className="new-category">
                  <label className="title-style">Choose Movie </label>
                  <Select
                    className="search__searchField py-1 wd-100"
                    onChange={handleSelectMovieName}
                    value={bannerData?.videoHeaderId}
                    dropdownStyle={dropdownStyle}
                    status={bannerErrors[0]?.haserror && bannerErrors[0].status}
                    showSearch={true}
                    options={
                      selectedGenre?.id === genresOnSystem[2]?.id ||
                      selectedGenre?.id === genresOnSystem[4]?.id
                        ? filteredHeaders
                        : headersDataByGenre
                    }
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    filterSort={(optionA, optionB) =>
                      (optionA?.label ?? "")
                        .toLowerCase()
                        .localeCompare((optionB?.label ?? "").toLowerCase())
                    }
                  />

                  {bannerErrors[0]?.hasError && (
                    <div className="episode-errors">
                      {bannerErrors[0].error}
                    </div>
                  )}
                </div>
              </Col>
              <Col span={12}>
                <div className="new-category">
                  <label className="title-style">Choose Part </label>
                  <Select
                    className="search__searchField py-1 wd-100"
                    onChange={handleSelectPartName}
                    value={bannerData?.seriesNumberId}
                    dropdownStyle={dropdownStyle}
                    disabled={bannerErrors[1]?.disabled}
                    status={bannerErrors[1]?.haserror && bannerErrors[1].status}
                  >
                    {allPartsByMovie?.map((part, index) => (
                      <Select.Option value={part?.id} key={index}>
                        {part?.name}
                      </Select.Option>
                    ))}
                  </Select>
                  {bannerErrors[1]?.hasError && (
                    <div className="episode-errors">
                      {bannerErrors[1].error}
                    </div>
                  )}
                </div>
              </Col>
            </Row>
          )}
          <div className="wd-100">
            <Col span={24}>
              <div className="upload-files pad-x-20">
                <div className="upload-files__container-banner relative">
                  {bannerData?.imgPath?.length > 0 && (
                    <figure>
                      <img
                        className="upload-files__upload-file"
                        src={baseUrl + bannerData.imgPath}
                        alt="actorImage"
                        style={{
                          position: "absolute",
                          zIndex: "3",
                          width: "100%",
                        }}
                      />
                    </figure>
                  )}
                  <DraggerUploader
                    withCrop
                    aspect={2 / 1}
                    formDataType={"img"}
                    acceptedTypes={"image/*"}
                    setShowProgressBar={(show) =>
                      handleShowImagesProgressBar(show)
                    }
                    setProgressPercent={(percent) =>
                      handleSetImageProgressPercent(percent)
                    }
                    setFilePath={(filePath) => handleSetImageFilePath(filePath)}
                  >
                    {bannerData?.imgPath?.length > 0 ? (
                      <div className="upload-edit-icon mrg-x-20">
                        <img role="button" src={editIcon} alt="edit" />
                      </div>
                    ) : (
                      <div
                        className="new-actor__upload-content"
                        style={{
                          width: "100%",
                        }}
                      >
                        <div className="new-actor__upload-image ">
                          <img
                            className="new-actor__upload-img"
                            src={UploadImageToBanner}
                            alt=""
                          />
                        </div>
                        <div
                          role="button"
                          className="new-actor__upload-button  "
                          style={{
                            boxShadow: "none",
                            backgroundColor: "transparent",
                            justifyContent: "center",
                            gap: "30px",
                          }}
                        >
                          <div className="new-actor__upload-icon">
                            <img src={uploadButton} alt="" />
                          </div>
                          <div className="new-actor__upload-title">
                            {"Upload image (270*400)"}
                          </div>
                        </div>
                      </div>
                    )}
                  </DraggerUploader>
                </div>
                {bannerErrors[5]?.hasError && (
                  <div className="episode-errors">{bannerErrors[5]?.error}</div>
                )}
                {bannerErrors[5]?.progressStatus && (
                  <Progress percent={bannerErrors[5].progressPercent} />
                )}
              </div>
            </Col>
          </div>
          <div className="wd-100 mrg-x-auto pad-x-20 mrg-y-20">
            <span
              className="description"
              style={{
                color: "#4C5056",
                fontSize: "16px",
                fontWeight: "medium",
              }}
            >
              Description
            </span>
            <SecondaryEditorForBanner
              descriptionAr={descriptionAr}
              setDescriptionAr={setDescriptionAr}
              descriptionEn={descriptionEn}
              setDescriptionEn={setDescriptionEn}
            />
            {bannerErrors[6]?.hasError && (
              <div className="episode-errors">{bannerErrors[6].error}</div>
            )}
          </div>
          <Row justify="end" className="mrg-xe-10 mrg-ye-30">
            <Col>
              <Space size={20}>
                {mode === "edit" && (
                  <div className="frist-button ">
                    <PlusButton
                      buttonName="Add Another"
                      styling="secondary"
                      action={handleAddAnotherBanner}
                      // disableMe={allBannersOnSystem?.length > 0 ? false : true}
                    />
                  </div>
                )}

                <div className="second-button mrg-xe-10 ">
                  <PrimaryButton
                    loading={loadingSubmitBtn}
                    buttonName={mode === "add" ? "Add" : "Save"}
                    type="submit"
                  />
                </div>
              </Space>
            </Col>
          </Row>
        </Form>
        <DeleteModal
          deleteModalVisible={deleteModalVisible}
          SubHeader={
            <SubHeader
              bgColor="#92979C"
              title="Delete Banner"
              closeButton
              onCancel={onCancel}
            />
          }
          icon={warningIcon}
          msg="Are you sure you want to delete the banner?"
          onCancel={onCancel}
          action={handleDeleteBannerWithId}
          secondaryBtnName="Cancel"
          primaryBtnName="Delete"
        />
      </div>
    </>
  );
}

export default Banner;
