import React, { useEffect, useState } from "react";

function SecondaryEditor({
  getTextEditorData,
  putDescriptionEn,
  putDescriptionAr,
  mode,
  disableMe,
}) {
  const [descriptionEn, setDescriptionEn] = useState("");
  const [descriptionAr, setDescriptionAr] = useState("");
  const [selectedEnglishLanguage, setSelectedEnglishLanguage] = useState(true);
  const [selectedArabicLanguage, setSelectedArabicLanguage] = useState(false);

  useEffect(() => {
    // if (putDescriptionAr && putDescriptionEn) {
    setDescriptionAr(putDescriptionAr);
    setDescriptionEn(putDescriptionEn);
    // } else {
    // }
  }, [putDescriptionEn, putDescriptionAr]);

  useEffect(() => {
    getTextEditorData(descriptionEn, descriptionAr);
    // eslint-disable-next-line
  }, [descriptionEn, descriptionAr]);

  const handleTextAreaEn = (e) => {
    if (!mode) {
      setDescriptionEn(e.target.value);
    }
  };

  const handleTextAreaAr = (e) => {
    if (!mode) {
      setDescriptionAr(e.target.value);
    }
  };

  const handleSelectEnglishLang = () => {
    setSelectedEnglishLanguage(true);
    setSelectedArabicLanguage(false);
  };

  const handleSelectArabicLang = () => {
    setSelectedArabicLanguage(true);
    setSelectedEnglishLanguage(false);
  };
  return (
    <div className="editor wd-100 ">
      <div className="options  wd-100 flex justify-center">
        <div className="lang  ">
          <div
            role="button"
            className={
              selectedArabicLanguage ? "selected-lang" : "item-lang arabic "
            }
            onClick={handleSelectArabicLang}
          >
            AR
          </div>
          <div
            role="button"
            className={
              selectedEnglishLanguage ? "selected-lang" : "item-lang english "
            }
            onClick={handleSelectEnglishLang}
          >
            EN
          </div>
        </div>
      </div>
      {selectedEnglishLanguage && (
        <div className="content ">
          <textarea
            className="text-area"
            value={descriptionEn}
            onChange={handleTextAreaEn}
            disabled={disableMe}
          ></textarea>
        </div>
      )}
      {selectedArabicLanguage && (
        <div className="content ">
          <textarea
            className="text-area"
            onChange={handleTextAreaAr}
            value={descriptionAr}
            disabled={disableMe}
          ></textarea>
        </div>
      )}
    </div>
  );
}

export default SecondaryEditor;
