import { createSlice } from "@reduxjs/toolkit";

export const staticDataSlice = createSlice({
  name: "staticDataOnSystem",
  initialState: {
    staticDataOnSystem: {},
  },
  reducers: {
    emptyStaticData: (state, action) => {
      state.staticDataOnSystem = {};
    },
    genresOnSystem: (state, action) => {
      state.staticDataOnSystem.genresOnSystem = action.payload;
    },
    seasonsOnSystem: (state, action) => {
      state.staticDataOnSystem.seasonsOnSystem = action.payload;
    },
    episodesOnSystem: (state, action) => {
      state.staticDataOnSystem.episodesOnSystem = action.payload;
    },
    partsOnSystem: (state, action) => {
      state.staticDataOnSystem.partsOnSystem = action.payload;
    },
    languagesOnSystem: (state, action) => {
      state.staticDataOnSystem.languagesOnSystem = action.payload;
    },
    addLanguageToSystem: (state, action) => {
      state.staticDataOnSystem.languagesOnSystem = [
        ...state.staticDataOnSystem.languagesOnSystem,
        action.payload,
      ];
    },
    updateLanguageOnSystem: (state, action) => {
      let copyOfLanguages = [...state.staticDataOnSystem.languagesOnSystem];
      copyOfLanguages.filter((lang, langIndex) => {
        if (lang?.id === action.payload.id) {
          copyOfLanguages[langIndex] = action.payload;
        }
        return lang;
      });
      state.staticDataOnSystem.languagesOnSystem = copyOfLanguages;
    },
    removeLanguageFromSystem: (state, action) => {
      let copyOfLanguages = [...state.staticDataOnSystem.languagesOnSystem];
      copyOfLanguages.filter((lang, langIndex) => {
        if (lang?.id === action.payload.id) {
          copyOfLanguages.splice(langIndex, 1);
        }
        return lang;
      });
      state.staticDataOnSystem.languagesOnSystem = copyOfLanguages;
    },
    videoQualitiesOnSystem: (state, action) => {
      state.staticDataOnSystem.videoQualitiesOnSystem = action.payload;
    },
    addQualityToStore: (state, action) => {
      state.staticDataOnSystem.videoQualitiesOnSystem = [
        ...state.staticDataOnSystem.videoQualitiesOnSystem,
        action.payload,
      ];
    },
    editQualityInStore: (state, action) => {
      let copyOfState = [...state.staticDataOnSystem.videoQualitiesOnSystem];
      copyOfState.filter((videoQuality, videoQualityIndex) => {
        if (videoQuality?._id === action.payload._id) {
          copyOfState[videoQualityIndex] = action.payload;
        }
        return videoQuality;
      });
      state.staticDataOnSystem.videoQualitiesOnSystem = copyOfState;
    },
    removeQualityFromStore: (state, action) => {
      let copyOfQualities = [
        ...state.staticDataOnSystem.videoQualitiesOnSystem,
      ];
      copyOfQualities.filter((quality, qualityIndex) => {
        if (quality?._id === action.payload._id) {
          copyOfQualities.splice(qualityIndex, 1);
        }
        return quality;
      });
      state.staticDataOnSystem.videoQualitiesOnSystem = copyOfQualities;
    },
  },
});
const { reducer, actions } = staticDataSlice;
export const {
  emptyStaticData,
  genresOnSystem,
  seasonsOnSystem,
  episodesOnSystem,
  partsOnSystem,
  languagesOnSystem,
  addLanguageToSystem,
  updateLanguageOnSystem,
  removeLanguageFromSystem,
  videoQualitiesOnSystem,
  addQualityToStore,
  editQualityInStore,
  removeQualityFromStore,
} = actions;
export default reducer;
