import { createSlice } from "@reduxjs/toolkit";

export const dynamicDataSlice = createSlice({
  name: "dynamicDataOnSystem",
  initialState: {
    dynamicDataOnSystem: {},
  },
  reducers: {
    emptyDynamicData: (state, action) => {
      state.dynamicDataOnSystem = {};
    },
    searchInputValue: (state, action) => {
      state.dynamicDataOnSystem.searchInputValue = action.payload;
    },
    emptySearchInputValue: (state, action) => {
      state.dynamicDataOnSystem.searchInputValue = null;
    },
    getCurrentPath: (state, action) => {
      state.dynamicDataOnSystem.currentPath = action.payload;
    },
    updateCurrentPath: (state, action) => {
      state.dynamicDataOnSystem.currentPath = action.payload;
    },
  },
});

const { reducer, actions } = dynamicDataSlice;
export const {
  searchInputValue,
  emptySearchInputValue,
  emptyDynamicData,
  getCurrentPath,
  updateCurrentPath,
} = actions;
export default reducer;
