import React, { useEffect, useState } from "react";
import { Button, Col, DatePicker, Form, Input, Row, Space } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { toastError, toastSuccess } from "helpers/toasters";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "modules/SubComponents/Header";
import checkedIcon from "assets/icons/checkedIcon.svg";
import notCheckedIcon from "assets/icons/notCheckedIcon.svg";
import PrimaryButton from "modules/SubComponents/Buttons/PrimaryButton";
import moment from "moment";
import ROUTES from "constants/NewRoutes";
import DeleteModal from "modules/Modals/DeleteModal";
import SubHeader from "modules/SubComponents/SubHeader";
import warningIcon from "assets/icons/warning.svg";
import {
  addQuestion,
  deleteQuestions,
  editQuestion,
  getAllQuestions,
  getQuestionDataById,
} from "Network";
// import TextArea from "antd/lib/input/TextArea";

function AddNewQuestion() {
  const [mode, setMode] = useState("add");
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  // const [addQuestionValidForToday, setaddQuestionValidForToday] =

  const { TextArea } = Input;
  //   useState(false);
  const [dimentions, setDimentions] = useState({
    winWidth: window.innerWidth,
    winHeight: window.innerHeight,
  });

  const [questions, setQuestions] = useState([
    {
      questionId: "",
      "question.ar": "",
      "question.en": "",
      activationDate: "",
      expirationDate: "",
      answers: [
        {
          answerId: "",
          "questionAnswer.ar": "",
          "questionAnswer.en": "",
          isCorrect: false,
        },
        {
          answerId: "",
          "questionAnswer.ar": "",
          "questionAnswer.en": "",
          isCorrect: false,
        },
      ],
    },
    {
      questionErrors: [
        { hasError: false, error: "Please select an appearance date" },
        { hasError: false, error: "Please select an expiration date " },
        { hasError: false, error: "Please enter the question " },
        {
          answerError: [
            { hasError: false, error: "Please enter the answer" },
            { hasError: false, error: "Please enter the answer" },
          ],
        },
      ],
    },
  ]);

  const location = useLocation();
  const { questionId, selectedPage, searchValue } = location?.state;

  const navigate = useNavigate();

  const formItemLayout = { labelCol: { span: 24 }, wrapperCol: { span: 24 } };

  // useEffect(() => {
  //   getAllQuestions(
  //     (success) => {
  //       const today = moment();
  //       // Get the date in 'YYYY-MM-DD' format
  //       const formattedToday = today.format("YYYY-MM-DD");

  //       // Check if any object in the array has activationDate equal to today's date
  //       const isActivationToday = success.data.some((item) => {
  //         // Assuming activationDate is in 'YYYY-MM-DD' format
  //         return moment(item.activationDate).isSame(formattedToday, "day");
  //       });

  //       setaddQuestionValidForToday(isActivationToday);
  //     },
  //     (fail) => {
  //       toastError(fail?.err_msg);
  //     },
  //   );
  // }, []);

  useEffect(() => {
    const detectWindowSize = () => {
      setDimentions({
        winWidth: window.innerWidth,
        winHeight: window.innerHeight,
      });
    };
    window.addEventListener("resize", detectWindowSize);
    return () => {
      window.removeEventListener("resize", detectWindowSize);
    };
  }, [dimentions]);

  useEffect(() => {
    let allQuestions = [...questions];
    if (questionId?.length > 0) {
      setMode("edit");
      getQuestionDataById(
        questionId,
        (success) => {
          if (success?.isSuccess) {
            allQuestions[0].questionId = success.data?.question?._id;
            allQuestions[0].activationDate =
              success.data?.question?.activationDate;
            allQuestions[0].expirationDate =
              success.data?.question?.expirationDate;
            allQuestions[0]["question.ar"] = success.data?.question?.question;
            allQuestions[0]["question.en"] = success.data?.question?.question;
            allQuestions[0].answers.length = 0;
            allQuestions[1].questionErrors[3].answerError.length = 0;
            success.data?.answers?.map((answer, answerIndex) => {
              allQuestions[0].answers.push({
                answerId: answer?._id,
                "questionAnswer.ar": answer?.answer,
                "questionAnswer.en": answer?.answer,
                isCorrect: answer?.isCorrect,
              });
              allQuestions[1].questionErrors[3].answerError.push({
                hasError: false,
                error: "Please enter the answer",
              });
            });
            //errors
            setQuestions(allQuestions);
          }
        },
        (fail) => {
          toastError(fail.data.err_msg);
        }
      );
    } //eslint-disable-next-line
  }, [questionId]);

  const handleActivationDateTimeOnChange = (event, selectedDate) => {
    let allQuestions = [...questions];
    if (selectedDate) {
      allQuestions[0].activationDate = selectedDate;
      allQuestions[1].questionErrors[0].hasError = false;
      setQuestions(allQuestions);
      // if (mode === "add") {
      //   // allQuestions[0].activationDate = moment(selectedDate).toString();
      //   allQuestions[0].activationDate = selectedDate;
      //   allQuestions[1].questionErrors[0].hasError = false;
      //   setQuestions(allQuestions);
      //   setDisableExpirationDate(false);
      // } else {
      //   if (moment(selectedDate).isBefore(allQuestions[0].expirationDate)) {
      //     allQuestions[0].activationDate = moment(selectedDate).toString();
      //     allQuestions[1].questionErrors[0].hasError = false;
      //     setQuestions(allQuestions);
      //   } else {
      //     toastError("activation date must be less than Appearence date");
      //   }
      // }
    }
  };

  // const handleQuestionOnChange = (event) => {
  //   let allQuestions = [...questions];
  //   let arabicWithNumbersAndSpecialCharsRegExp =
  //     /^[\u0621-\u064A0-9\s!"#$%&'()*+,-./:;،\\<=>?@\u061F[\]^_`{|}~]*$/;
  //   if (
  //     arabicWithNumbersAndSpecialCharsRegExp.test(event.target.value.trim())
  //   ) {
  //     if (event.target.value.trim().length <= 200) {
  //       allQuestions[0]["question.ar"] = event.target.value.trim();
  //       allQuestions[0]["question.en"] = event.target.value.trim();
  //       allQuestions[1].questionErrors[2].hasError = false;
  //       setQuestions(allQuestions);
  //     } else {
  //       allQuestions[1].questionErrors[2].hasError = true;
  //       allQuestions[1].questionErrors[2].error =
  //         "Maximum number of characters is 200";
  //       setQuestions(allQuestions);
  //     }
  //   } else {
  //     allQuestions[1].questionErrors[2].hasError = true;
  //     allQuestions[1].questionErrors[2].error = "Please enter Arabic letters";
  //     setQuestions(allQuestions);
  //   }
  // };

  const handleQuestionOnChange = (event) => {
    let allQuestions = [...questions];
    let arabicWithNumbersAndSpecialCharsRegExp =
      // /^[\u0621-\u064A0-9\s!"#$%&'()*+,-./:;،\\<=>?@\u061F[\]^_`{|}~]*$/;
      /^[\u0621-\u064A\u064B-\u065F0-9\s!"#$%&'()*+,-./:;،\\<=>?@\u061F[\]^_`{|}~]*$/;

    //     /^[\u0621-\u064A0-9\s!"#$%&'()*+,-./:;،\\<=>?@\u061F[\]^_`{|}~]*$/;
    let value = event.target.value.trimStart();
    if (arabicWithNumbersAndSpecialCharsRegExp.test(value)) {
      if (value.length <= 200) {
        allQuestions[0]["question.ar"] = value;
        allQuestions[0]["question.en"] = value;
        allQuestions[1].questionErrors[2].hasError = false;
        setQuestions(allQuestions);
      } else {
        allQuestions[1].questionErrors[2].hasError = true;
        allQuestions[1].questionErrors[2].error =
          "Maximum number of characters is 200";
        setQuestions(allQuestions);
      }
    } else {
      allQuestions[1].questionErrors[2].hasError = true;
      allQuestions[1].questionErrors[2].error = "Please enter Arabic letters";
      setQuestions(allQuestions);
    }
  };

  const handleAddAnotherAnswer = (answerIndex) => {
    if (answerIndex < 3) {
      let allQuestions = [...questions];
      allQuestions[0].answers.push({
        answerId: "",
        "questionAnswer.ar": "",
        "questionAnswer.en": "",
        isCorrect: false,
      });
      allQuestions[1].questionErrors[3].answerError.push({
        hasError: false,
        error: "Please enter the answer",
      });
      setQuestions(allQuestions);
    } else {
      toastError("Maximum answers are 4");
    }
  };

  // const handleAnswerOnChange = (event, answerIndex) => {
  //   let allQuestions = [...questions];
  //   let arabicWithNumbersAndSpecialCharsRegExp =
  //     /^[\u0621-\u064A0-9\s!"#$%&'()*+,-./:;،\\<=>?@\u061F[\]^_`{|}~]*$/;
  //   const maxLength = 50; // Maximum allowed characters for the answer

  //   if (arabicWithNumbersAndSpecialCharsRegExp.test(event.target.value)) {
  //     if (event.target.value.length <= maxLength) {
  //       allQuestions[0].answers[answerIndex]["questionAnswer.ar"] =
  //         event.target.value;
  //       allQuestions[0].answers[answerIndex]["questionAnswer.en"] =
  //         event.target.value;
  //       allQuestions[1].questionErrors[3].answerError[
  //         answerIndex
  //       ].hasError = false;
  //       setQuestions(allQuestions);
  //     } else {
  //       allQuestions[1].questionErrors[3].answerError[
  //         answerIndex
  //       ].hasError = true;
  //       allQuestions[1].questionErrors[3].answerError[
  //         answerIndex
  //       ].error = `Maximum number of characters is ${maxLength}`;
  //       setQuestions(allQuestions);
  //     }
  //   } else {
  //     allQuestions[1].questionErrors[3].answerError[
  //       answerIndex
  //     ].hasError = true;
  //     allQuestions[1].questionErrors[3].answerError[answerIndex].error =
  //       "Please enter Arabic letters";
  //     setQuestions(allQuestions);
  //   }
  // };

  const handleAnswerOnChange = (event, answerIndex) => {
    let allQuestions = [...questions];
    let arabicWithNumbersAndSpecialCharsRegExp =
      // /^[\u0621-\u064A0-9\s!"#$%&'()*+,-./:;،\\<=>?@\u061F[\]^_`{|}~]*$/;
      /^[\u0621-\u064A\u064B-\u065F0-9\s!"#$%&'()*+,-./:;،\\<=>?@\u061F[\]^_`{|}~]*$/;

    let value = event.target.value.trimStart();
    const maxLength = 50; // Maximum allowed characters for the answer

    const isSimilarToExistingAnswers = (newValue, currentAnswerIndex) => {
      if (newValue === "") return false;
      // Exclude the current answer being checked
      const existingAnswers = allQuestions[0].answers
        .filter((_, index) => {
          return index !== currentAnswerIndex;
        })
        .map((answer) => answer["questionAnswer.ar"]);

      return existingAnswers.some((answer) => {
        return answer.trim() === newValue;
      });
    };

    questions[0].answers?.forEach((answer, answerIndex) => {
      // let value = answer["questionAnswer.ar"].trim();
      console.log(isSimilarToExistingAnswers(value, answerIndex));
      if (!isSimilarToExistingAnswers(value, answerIndex)) {
        console.log(allQuestions[1]);
        allQuestions[1].questionErrors[3].answerError[
          answerIndex
        ].hasError = false;

        // allQuestions[1].questionErrors[3].answerError.forEach(
        //   (answerError, index) => {
        //     if (index === answerIndex) {
        //       answerError.hasError = false;
        //     }
        //   }
        // );
      }
      // else if (value === "") {
      //   allQuestions[1].questionErrors[3].answerError[
      //     answerIndex
      //   ].hasError = false;
      // }
    });

    if (arabicWithNumbersAndSpecialCharsRegExp.test(value)) {
      if (value.length <= maxLength) {
        allQuestions[0].answers[answerIndex]["questionAnswer.ar"] = value;
        allQuestions[0].answers[answerIndex]["questionAnswer.en"] = value;
        allQuestions[1].questionErrors[3].answerError[
          answerIndex
        ].hasError = false;
        setQuestions(allQuestions);
      } else {
        allQuestions[1].questionErrors[3].answerError[
          answerIndex
        ].hasError = true;
        allQuestions[1].questionErrors[3].answerError[
          answerIndex
        ].error = `Maximum number of characters is ${maxLength}`;
        setQuestions(allQuestions);
      }
    } else {
      allQuestions[1].questionErrors[3].answerError[
        answerIndex
      ].hasError = true;
      allQuestions[1].questionErrors[3].answerError[answerIndex].error =
        "Please enter Arabic letters";
      setQuestions(allQuestions);
    }
  };

  const handleChooseCorrectAnswer = (answerIndex) => {
    let allQuestions = [...questions];
    allQuestions[0].answers.forEach((anotherAnswer, anotherAnswerIndex) => {
      allQuestions[0].answers[anotherAnswerIndex].isCorrect = false;
    });
    allQuestions[0].answers[answerIndex].isCorrect = true;
    setQuestions(allQuestions);
  };

  const handleDeleteAnswer = (answerIndex) => {
    let allQuestions = [...questions];
    if (allQuestions[0]?.answers?.length > 2) {
      allQuestions[0].answers.splice(answerIndex, 1);
      allQuestions[1].questionErrors[3].answerError.splice(answerIndex, 1);
      setQuestions(allQuestions);
    } else {
      toastError("Minimum answers are 2");
    }
  };

  const handleDeleteQuestion = () => {
    deleteQuestions(
      { questionId },
      (success) => {
        if (success?.isSuccess) {
          toastSuccess(success.message);
          onCancel();
          navigate(ROUTES.QUESTIONS);
        } else {
          toastError(success.err_msg);
          onCancel();
        }
      },
      (fail) => {
        if (fail?.data) {
          toastError(fail.data?.err_msg);
          onCancel();
        } else {
          toastError();
          onCancel();
        }
      }
    );
  };

  const onCancel = () => {
    setDeleteModalVisible(false);
  };

  // const validateQuestionsErrors = () => {
  //   let allQuestions = [...questions];
  //   let questionHasErrors = [];
  //   // if (addQuestionValidForToday) {
  //   //   toastError("Not allowed to add more than one questions daily");
  //   //   return;
  //   // }
  //   // Function to check if the value is similar to any existing answers

  //   if (questions[0]?.activationDate === "") {
  //     allQuestions[1].questionErrors[0].hasError = true;
  //     setQuestions(allQuestions);
  //     questionHasErrors.push("activationDate");
  //   }

  //   // if (questions[0]?.expirationDate === "") {
  //   //   allQuestions[1].questionErrors[1].hasError = true;
  //   //   setQuestions(allQuestions);
  //   //   questionHasErrors.push("expirationDate");
  //   // }

  //   if (
  //     questions[0]["question.ar"].trim() === "" ||
  //     questions[0]["question.en"].trim() === ""
  //   ) {
  //     allQuestions[1].questionErrors[2].hasError = true;
  //     setQuestions(allQuestions);
  //     questionHasErrors.push("question");
  //   }

  //   if (questionHasErrors?.length > 0) {
  //     toastError("Please,fill all required data");
  //   } else {
  //     const hasCorrectAnswer = questions[0].answers.some(
  //       (answer, answerIndex) => answer?.isCorrect
  //     );
  //     if (hasCorrectAnswer) {
  //       // if (questions[0].expirationDate.isAfter(moment())) {
  //       setLoadingSubmit(true);
  //       if (mode === "add") {
  //         delete questions[0].questionId;
  //         delete questions[0].expirationDate;
  //         questions[0].answers.forEach((answer) => delete answer?.answerId);
  //         addQuestion(
  //           questions[0],
  //           (success) => {
  //             if (success?.isSuccess) {
  //               toastSuccess(success.message);
  //               setLoadingSubmit(false);
  //               navigate(ROUTES.QUESTIONS, {
  //                 state: {
  //                   currentPage: selectedPage,
  //                 },
  //               });
  //             } else {
  //               toastError(success.err_msg);
  //               setLoadingSubmit(false);
  //             }
  //           },
  //           (fail) => {
  //             if (fail?.data) {
  //               toastError(fail.data?.err_msg);
  //               setLoadingSubmit(false);
  //             } else {
  //               toastError();
  //               setLoadingSubmit(false);
  //             }
  //           }
  //         );
  //       } else {
  //         //call edit question api
  //         delete questions[0].expirationDate;
  //         editQuestion(
  //           questions[0],
  //           (success) => {
  //             if (success?.isSuccess) {
  //               toastSuccess(success.message);
  //               setLoadingSubmit(false);
  //               navigate(ROUTES.QUESTIONS, {
  //                 state: {
  //                   currentPage: selectedPage,
  //                   searchVal: searchValue,
  //                 },
  //               });
  //             } else {
  //               toastError(success.err_msg);
  //               setLoadingSubmit(false);
  //             }
  //           },
  //           (fail) => {
  //             if (fail?.data) {
  //               toastError(fail.data?.err_msg);
  //               setLoadingSubmit(false);
  //             } else {
  //               toastError();
  //               setLoadingSubmit(false);
  //             }
  //           }
  //         );
  //       }
  //     } else {
  //       toastError("Please, select the correct answer");
  //     }
  //   }
  // };

  const validateQuestionsErrors = () => {
    let allQuestions = [...questions];
    let questionHasErrors = [];

    // Function to check if the value is similar to any existing answers
    const isSimilarToExistingAnswers = (newValue, currentAnswerIndex) => {
      console.log("🚀 ~ isSimilarToExistingAnswers ~ newValue:", newValue);
      if (newValue === "") return false;
      const existingAnswers = allQuestions[0].answers
        .filter((_, index) => index !== currentAnswerIndex) // Exclude the current answer being checked
        .map((answer) => answer["questionAnswer.ar"]);
      return existingAnswers.some((answer) => answer.trim() === newValue);
    };

    // Validate activationDate
    if (questions[0]?.activationDate === "") {
      allQuestions[1].questionErrors[0].hasError = true;
      setQuestions(allQuestions);
      questionHasErrors.push("activationDate");
    }

    // Validate question in both Arabic and English
    if (
      questions[0]["question.ar"].trim() === "" ||
      questions[0]["question.en"].trim() === ""
    ) {
      allQuestions[1].questionErrors[2].hasError = true;
      setQuestions(allQuestions);
      questionHasErrors.push("question");
    }

    // Validate answers
    questions[0].answers?.forEach((answer, answerIndex) => {
      let value = answer["questionAnswer.ar"].trim();
      if (isSimilarToExistingAnswers(value, answerIndex)) {
        allQuestions[1].questionErrors[3].answerError[
          answerIndex
        ].hasError = true;
        allQuestions[1].questionErrors[3].answerError[answerIndex].error =
          "This answer is similar to an existing answer.";
        questionHasErrors.push(`answer ${answerIndex} similar`);
      } else if (value === "") {
        allQuestions[1].questionErrors[3].answerError[
          answerIndex
        ].hasError = true;
        allQuestions[1].questionErrors[3].answerError[answerIndex].error =
          "Please enter the answer.";
        questionHasErrors.push(`answer ${answerIndex} required`);
      }
    });

    console.log(allQuestions);

    setQuestions(allQuestions);
    // If there are errors, display an error toast and stop submission
    if (questionHasErrors?.length > 0) {
      toastError("Please, fill all required data or correct the errors.");
    } else {
      const hasCorrectAnswer = questions[0].answers.some(
        (answer) => answer?.isCorrect
      );

      if (hasCorrectAnswer) {
        setLoadingSubmit(true);
        if (mode === "add") {
          delete questions[0].questionId;
          delete questions[0].expirationDate;
          questions[0].answers.forEach((answer) => delete answer?.answerId);
          addQuestion(
            questions[0],
            (success) => {
              if (success?.isSuccess) {
                toastSuccess(success.message);
                setLoadingSubmit(false);
                navigate(ROUTES.QUESTIONS, {
                  state: {
                    currentPage: selectedPage,
                  },
                });
              } else {
                toastError(success.err_msg);
                setLoadingSubmit(false);
              }
            },
            (fail) => {
              toastError(fail.data?.err_msg || "Something went wrong");
              setLoadingSubmit(false);
            }
          );
        } else {
          delete questions[0].expirationDate;
          editQuestion(
            questions[0],
            (success) => {
              if (success?.isSuccess) {
                toastSuccess(success.message);
                setLoadingSubmit(false);
                navigate(ROUTES.QUESTIONS, {
                  state: {
                    currentPage: selectedPage,
                    searchVal: searchValue,
                  },
                });
              } else {
                toastError(success.err_msg);
                setLoadingSubmit(false);
              }
            },
            (fail) => {
              toastError(fail.data?.err_msg || "Something went wrong");
              setLoadingSubmit(false);
            }
          );
        }
      } else {
        toastError("Please, select the correct answer");
      }
    }
  };

  const handleDisabledDates = (current) => {
    return current && current.isBefore(moment().format("YYYY-MM-DD"));
  };

  const handleBackBtnOnClick = () => {
    navigate(ROUTES.QUESTIONS, {
      state: { currentPage: selectedPage, searchVal: searchValue },
    });
  };

  const onFinish = () => {
    validateQuestionsErrors();
  };

  return (
    <section className="users ">
      <div className="users__all-contents ">
        <div className="users__content">
          <div className="users__usersData">
            <Header
              title={`${questionId ? "Edit Question" : "Add New Question"}`}
            />
            <div className="users flex flex-1 mrg-ys-50">
              <Form
                className={"wd-100"}
                layout="vertical"
                {...formItemLayout}
                onFinish={onFinish}
              >
                <Row className="mrg-ye-40">
                  <Col xs={{ span: 24 }} lg={{ span: 23 }}>
                    <label className="global-label">Appearance Date</label>
                    <DatePicker
                      className={`search__searchField wd-100 pad-y-10 ${
                        questions[1]?.questionErrors[0]?.hasError && "bord-red"
                      }`}
                      onChange={handleActivationDateTimeOnChange}
                      value={
                        questions[0]?.activationDate?.length > 0 &&
                        moment(
                          new Date(questions[0]?.activationDate),
                          "YYYY-MM-DD hh:mm:ss"
                        )
                      }
                      inputReadOnly={true}
                      disabledDate={(current) => handleDisabledDates(current)}
                      allowClear={false} // Add this line to prevent text input
                    />
                    {questions[1]?.questionErrors[0]?.hasError && (
                      <div className="episode-errors">
                        {questions[1].questionErrors[0].error}
                      </div>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col xs={{ span: 24 }} lg={{ span: 23 }}>
                    <label className="global-label">Question</label>
                    <TextArea
                      className={`search__searchField wd-100 pad-y-5  ${
                        questions[1]?.questionErrors[2]?.hasError && "bord-red"
                      } `}
                      placeholder="Enter a Question"
                      onChange={handleQuestionOnChange}
                      value={questions[0]["question.en"]}
                    />
                    {questions[1]?.questionErrors[2]?.hasError && (
                      <div className="episode-errors">
                        {questions[1].questionErrors[2].error}
                      </div>
                    )}
                  </Col>
                </Row>
                {questions[0]?.answers.map((answer, answerIndex) => {
                  return (
                    <Row gutter={10} key={answerIndex} className="mrg-y-20">
                      <Col xs={{ span: 14 }}>
                        <label className="global-label">
                          {`Answer ${answerIndex + 1}`}
                        </label>
                        <Input
                          className={`search__searchField wd-100 pad-y-10 ${
                            questions[1]?.questionErrors[3]?.answerError[
                              answerIndex
                            ]?.hasError && "bord-red"
                          }`}
                          placeholder={`Answer ${answerIndex + 1}`}
                          onChange={(e) => handleAnswerOnChange(e, answerIndex)}
                          value={answer?.["questionAnswer.ar"]}
                          suffix={
                            answerIndex !== 0 && (
                              <CloseOutlined
                                style={{
                                  fontSize: "14px",
                                  fontWeight: "bold",
                                }}
                                role={"button"}
                                onClick={() => handleDeleteAnswer(answerIndex)}
                              />
                            )
                          }
                        />{" "}
                        {questions[1]?.questionErrors[3]?.answerError[
                          answerIndex
                        ]?.hasError && (
                          <div className="episode-errors">
                            {
                              questions[1].questionErrors[3].answerError[
                                answerIndex
                              ].error
                            }
                          </div>
                        )}
                      </Col>
                      <Col
                        xs={{ span: 4 }}
                        className="flex  items-end justify-center "
                      >
                        <label htmlFor=""></label>
                        <div className="checkbox-icon">
                          <img
                            role={"button"}
                            onClick={() =>
                              handleChooseCorrectAnswer(answerIndex)
                            }
                            src={
                              answer?.isCorrect === true
                                ? checkedIcon
                                : notCheckedIcon
                            }
                            alt=""
                            className="icon "
                          />
                        </div>
                      </Col>
                      {questions[0]?.answers?.length - 1 === answerIndex && (
                        <Col
                          xs={{ span: 5 }}
                          className="flex  items-end justify-center  "
                        >
                          <label htmlFor=""></label>
                          <div
                            style={{
                              width: "100%",
                            }}
                          >
                            <Button
                              className="new-plus-button"
                              onClick={() =>
                                handleAddAnotherAnswer(answerIndex)
                              }
                            >
                              {/* <PlusOutlined className="new-plus-button-icon" /> */}
                              {dimentions?.winWidth < 768
                                ? "+"
                                : "+ Add Answer"}
                            </Button>
                          </div>
                        </Col>
                      )}
                    </Row>
                  );
                })}
                <Space size={30} className="flex justify-end mrg-y-50">
                  <Button
                    className="secondary-popup-button"
                    onClick={handleBackBtnOnClick}
                  >
                    Back
                  </Button>
                  {mode === "edit" && (
                    <div style={{ width: "138px" }}>
                      <Button
                        className="delete-video-button"
                        onClick={() => setDeleteModalVisible(true)}
                      >
                        Delete
                      </Button>
                    </div>
                  )}
                  <PrimaryButton
                    loading={loadingSubmit}
                    type={"submit"}
                    buttonName={mode === "add" ? "Add" : "Save"}
                  />
                </Space>
                <DeleteModal
                  deleteModalVisible={deleteModalVisible}
                  // loading={deleteModalLoading}
                  SubHeader={
                    <SubHeader
                      bgColor="#F58234"
                      title="Delete Question"
                      closeButton
                      onCancel={onCancel}
                    />
                  }
                  icon={warningIcon}
                  msg="Are you sure you want to delete this Question?"
                  onCancel={onCancel}
                  action={handleDeleteQuestion}
                  secondaryBtnName="Cancel"
                  primaryBtnName="Delete"
                />
              </Form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default AddNewQuestion;
