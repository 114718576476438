import { removeUnUsedFiles } from "Network";
import { toastError } from "./toasters";

//function loop in array and take every object and loop it's value
export const searchFunction = (allData, searchValue, searchColumns) => {
  const result = [];
  allData.forEach((item) => {
    for (let key in item) {
      if (searchColumns?.includes(key)) {
        if (
          item[key] &&
          item[key].toString().toLowerCase().includes(searchValue.toLowerCase())
        ) {
          result?.push(item);
          break;
        }
      }
    }
  });
  return result;
};
//
export const roundsToTheNearestTwoDigits = (number) => {
  let numberToArrayOfStrings, endIndex, result;
  if (number >= 1000000) {
    numberToArrayOfStrings = (number / 1000000).toString().split("");
    endIndex = numberToArrayOfStrings.indexOf(".") + 3;
    result = numberToArrayOfStrings.slice(0, endIndex).join("");
    return result + "M";
  } else if (number >= 1000 && number < 1000000) {
    numberToArrayOfStrings = (number / 1000).toString().split("");
    endIndex = numberToArrayOfStrings.indexOf(".") + 3;
    result = numberToArrayOfStrings.slice(0, endIndex).join("");
    return result + "K";
  } else {
    return number;
  }
};

//convert video duration format
export const customizeVideoDuration = (oldDuration) => {
  if (oldDuration) {
    const duration = new Date(oldDuration * 1000).toISOString().substr(11, 8);
    return duration;
  } else return "";
};

//get seasons axcept which were added before
export const getSpecifiedSeasons = (allSeasonsOnSystem, seasonsByHeaderId) => {
  const specifiedSeasons = allSeasonsOnSystem.filter(
    (elem) => !seasonsByHeaderId.find((item) => elem.indexId === item.id)
  );
  return specifiedSeasons;
};
//get episodes which added before and new episode which will be add
export const getSpecifiedEpisodes = (
  allEpisodesOnSystem,
  episodesBySeason,
  mode
) => {
  if (mode) {
    const specifiedEpisodes = allEpisodesOnSystem.filter(
      (elem) =>
        !episodesBySeason.find((item) => elem.indexId === item.episodePartId)
    );
    return specifiedEpisodes;
  } else {
    const specifiedAnotherEpisodes = allEpisodesOnSystem.filter((elem) =>
      episodesBySeason.find((item) => elem?.indexId === item?.episodePartId)
    );
    return specifiedAnotherEpisodes;
  }
};

//get the last episode/part index to delete last one only
export const getMaxIndexOfEpisodesOrParts = (allData) => {
  const allIndexes = [];
  allData?.videos?.map((item) => {
    allIndexes.push(item?.index);
  });
  let lastIndex = allIndexes.reduce((a, b) => Math.max(a, b), -Infinity);
  return lastIndex;
};

export const compareTwoArraysOfObjects = (oldArray, newArray) => {
  if (oldArray?.length !== newArray?.length) {
    return false;
  }
  for (let i = 0; i < oldArray.length; i++) {
    let obj1 = oldArray[i];
    let obj1FristProp = Object.keys(obj1)[0];
    let obj2 = newArray?.find(
      (ob) => ob[obj1FristProp] === obj1[obj1FristProp]
    );
    if (!obj2) {
      return false;
    } else {
      for (let prop in obj1) {
        if (obj1[prop] !== obj2[prop]) {
          return false;
        }
      }
    }
  }
  return true;
};

export const getUnusedFilesForEpisode = (episode) => {
  let unusedFiles = [];
  if (episode?.imgPath?.length > 0) {
    unusedFiles.push(episode.imgPath);
  }
  if (episode?.path?.length > 0) {
    unusedFiles.push(episode.path);
  }
  episode?.subtitles?.subtitles?.forEach((subtitle) => {
    if (subtitle?.path?.length > 0) {
      unusedFiles.push(subtitle.path);
    }
  });
  episode?.audios?.audios?.forEach((audio) => {
    if (audio?.path) {
      unusedFiles.push(audio.path);
    }
  });
  return unusedFiles;
};

export const dropdownStyle = {
  border: " solid 1px #CEE2F6",
  borderRadius: "12px",
  boxShadow: "0px 8px 10px #0000001A ",
};
