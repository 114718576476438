import { Input, Select, Space } from "antd";
import React, { useState } from "react";
import PlusButton from "./Buttons/PlusButton";
import magnifyingGlass from "assets/icons/search.svg";
import { useEffect } from "react";
import { dropdownStyle } from "helpers/helpfulFunctions";
import SecondaryButton from "./Buttons/SecondaryButton";
import PrimaryButton from "./Buttons/PrimaryButton";
import { ButtonExportToExcel } from "./Buttons/ButtonExportToExcel";

function Header({
  title,
  search,
  plusButtonName,
  getSearchedFromHeader,
  action,
  plusButtonAction,
  secondaryBtnName,
  primaryBtnName,
  primaryBtnAction,
  secondaryBtnAction,
  mrgxs,
  breakpointData,
  searchVal,
  exportAsExcel,
  columnsForExport,
  dataSource,
  reportName,

  // isAnswerFilters = false,
  // months = false,
  // years = false,
  // exportAsExcel = false,
}) {
  const [searchValue, setSearchValue] = useState(null);
  useEffect(() => {
    if (searchVal?.length > 0) {
      setSearchValue(searchVal);
    }
  }, [searchVal]);

  const handleSearchOnChange = (e) => {
    setSearchValue(e.target.value);
    getSearchedFromHeader(e.target.value);
  };
  // const handleChangeYears = (e) => {
  //
  // };
  // const handleChangeMonths = (e) => {
  //
  // };
  return (
    <div className="header ">
      <div className={`header__title  mrg-xs-${mrgxs}`}>{title}</div>
      <Space size={10}>
        {search && (
          <div className="header__search ">
            <div className="search">
              <div className="search__form-input ">
                <Input
                  type="text"
                  className="search__searchField py-2"
                  placeholder={search}
                  value={searchValue}
                  onChange={handleSearchOnChange}
                  prefix={
                    <button className="search__button">
                      <img src={magnifyingGlass} alt="search" />
                    </button>
                  }
                />
              </div>
            </div>
          </div>
        )}
        {exportAsExcel && (
          <ButtonExportToExcel
            columns={columnsForExport}
            dataSource={dataSource}
            reportName={reportName}
          />
        )}
        {plusButtonName && (
          <div className="header__plus-button ">
            <PlusButton
              action={plusButtonAction}
              buttonName={plusButtonName}
              breakpointData={breakpointData}
            />
          </div>
        )}

        {/* {isAnswerFilters && (
          <>
            <Select
              className="select-secondary-style"
              onChange={handleChangeYears}
              dropdownStyle={dropdownStyle}
              options={years}
              placeholder="YEAR"
            />
            <Select
              className="select-secondary-style"
              onChange={handleChangeMonths}
              dropdownStyle={dropdownStyle}
              options={months}
              placeholder="MONTH"
            />
          </>
        )}
        {exportAsExcel && (
          <>
            <Space size={20}>
            <PrimaryButton
              buttonName={primaryBtnName}
              onClick={primaryBtnAction}
            />
            <SecondaryButton
              buttonName={secondaryBtnName}
              onClick={secondaryBtnAction}
            />
            </Space>
          </>
        )} */}
      </Space>
    </div>
  );
}

export default Header;
