import React, { Fragment, useEffect, useState } from "react";
import { toastError, toastSuccess } from "helpers/toasters";
import {
  deleteHeaderFromTvShows,
  getAllCategoryDataByGenre,
  getAllSeasonsDependsOnSeries,
} from "Network";
import editCategoryIcon from "assets/icons/pen.svg";
import viewCategoryIcon from "assets/icons/eye.svg";
import deleteCategoryIcon from "assets/icons/deleteAvatar.svg";
import DeleteModal from "modules/Modals/DeleteModal";
import SubHeader from "../SubComponents/SubHeader";
import warningIcon from "assets/icons/warning.svg";
import EditModal from "./EditModal";
import { baseUrl } from "services";
import { useNavigate } from "react-router-dom";
import ROUTES from "constants/NewRoutes";
import { Spin } from "antd";
import { useSelector } from "react-redux";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const CategoriesDependsOnGenre = ({ selectedGenre, genreName }) => {
  const [isSpinning, setIsSpinning] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const [deleteModalLoading, setDeleteModalLoading] = useState(false);
  const [searchResult, setSearchResult] = useState([]);
  const [allCategories, setAllCategories] = useState([]);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [endModalError, setEndModalError] = useState(true);
  const [headerId, setHeaderId] = useState(null);
  const [selectedHeader, setSelectedHeader] = useState(null);
  const navigate = useNavigate();
  const { searchInputValue } = useSelector(
    (state) => state?.dynamicData?.dynamicDataOnSystem
  );

  useEffect(() => {
    let catResult = [];
    if (searchInputValue?.length) {
      setIsSpinning(true);
      allCategories.forEach((cat) => {
        if (cat?.headers?.length > 0) {
          const res = cat.headers.filter((header) =>
            header?.title.toLowerCase().includes(searchInputValue.toLowerCase())
          );
          if (res?.length > 0) {
            catResult.push({
              category: cat?.category,
              categoryId: cat?.categoryId,
              genre: cat?.genre,
              headers: res,
            });
          }
        }
      });
      setSearchResult(catResult);
      setIsSpinning(false);
    }
  }, [searchInputValue, allCategories]);

  useEffect(() => {
    if (selectedGenre) {
      getAllCategoryByGenreAfterActions(selectedGenre);
    }
  }, [selectedGenre, isDeleted]);

  const getAllCategoryByGenreAfterActions = (selectedGenre) => {
    setIsSpinning(true);
    getAllCategoryDataByGenre(
      { genreId: selectedGenre },
      (success) => {
        if (success?.isSuccess) {
          setAllCategories(success?.data);
          setIsSpinning(false);
        }
      },
      (fail) => {
        if (fail?.data?.error) {
          toastError(fail.data.err_msg);
        }
      }
    );
  };

  const handleClickEditIcon = (header) => {
    console.log("🚀 ~ handleClickEditIcon ~ header:", header);
    setSelectedHeader(header);
    if (header?.isSeries) {
      setHeaderId(header?._id);
      setEditModalVisible(true);
    } else {
      navigate(ROUTES.EDITMOVIEHEADER, {
        state: {
          selectedHeaderId: header?._id,
          genreId: selectedGenre,
          genreName,
        },
      });
    }
  };

  const handleClickViewIcon = (header) => {
    setSelectedHeader(header);
    if (header?.isSeries) {
      getAllSeasonsDependsOnSeries(
        { videoHeaderId: header?._id },
        (success) => {
          if (success?.isSuccess) {
            navigate(ROUTES.VIEWTVSHOWS, {
              state: {
                selectedHeaderId: header?._id,
                selectedSeasonId: success.data[0].id,
                seasonsDependsOnHeaderId: success.data,
                genreId: selectedGenre,
                genreName,
              },
            });
          }
        },
        (fail) => {
          if (fail?.data) {
            toastError(fail.data.err_msg);
          } else {
            toastError();
          }
        }
      );
    } else {
      navigate(ROUTES.VIEWMOVIE, {
        state: {
          selectedHeaderId: header?._id,
          genreId: selectedGenre,
          genreName,
        },
      });
    }
  };
  const handleClickDeleteIcon = (category, header) => {
    setSelectedHeader(header);
    setHeaderId(header._id);
    setDeleteModalVisible(true);
  };
  const handleDeleteHeaderFromTvShows = () => {
    setDeleteModalLoading(true);
    deleteHeaderFromTvShows(
      { headerId },
      (success) => {
        if (success?.isSuccess) {
          toastSuccess(success.message);
          setDeleteModalLoading(false);
          setIsDeleted((prev) => !prev);
          deleteOnCancel();
        } else {
          toastError(success.err_msg);
          setDeleteModalLoading(false);
        }
      },
      (fail) => {
        if (fail?.data) {
          toastError(fail.data?.msg_err);
          setDeleteModalLoading(false);
        } else {
          toastError();
          setDeleteModalLoading(false);
        }
      }
    );
  };
  const deleteOnCancel = () => {
    setEndModalError(false);
    setDeleteModalVisible(false);
  };
  const editModalOnCancel = () => {
    if (selectedHeader?.isSeries) {
      setEditModalVisible(false);
    }
  };

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 1200 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 1200, min: 991 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 991, min: 768 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 768, min: 0 },
      items: 2,
    },
  };
  return (
    <Fragment>
      <Spin
        tip="Loading..."
        size="large"
        spinning={isSpinning}
        className="my-5"
      >
        {searchInputValue?.length > 0 ? (
          searchResult?.length > 0 ? (
            searchResult.map((category, catIndex) => {
              return (
                <div className={"category category-by-genre "} key={catIndex}>
                  <div className="category__subtitle mrg-ye-10 ">
                    {category?.category}
                  </div>
                  <div className={"category__cards"}>
                    <Carousel
                      infinite={true}
                      responsive={responsive}
                      draggable={true}
                      keyBoardControl={true}
                      containerClass="carousel-container"
                    >
                      {category?.headers?.map((header, index) => {
                        return (
                          <div className="category__card " key={index}>
                            <figure className="category__card-figure">
                              <img
                                className="category__card-image "
                                src={baseUrl + header?.imgPath}
                                alt="category"
                              />
                            </figure>
                            <div className="category__card-details ">
                              <span className="category__card-title  ">
                                {header?.title}
                              </span>
                              <div className="category__card-actions mrg-ys-20 ">
                                <div
                                  role={"button"}
                                  className="category__card-actions-edit"
                                  onClick={() => handleClickEditIcon(header)}
                                >
                                  <img src={editCategoryIcon} alt="" />
                                </div>
                                <div
                                  role={"button"}
                                  className="category__card-actions-view"
                                  onClick={() => handleClickViewIcon(header)}
                                >
                                  <img src={viewCategoryIcon} alt="" />
                                </div>
                                <div
                                  role={"button"}
                                  className="category__card-actions-delete"
                                  onClick={() =>
                                    handleClickDeleteIcon(category, header)
                                  }
                                >
                                  <img src={deleteCategoryIcon} alt="" />
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </Carousel>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="category__not-found ">
              Sorry, We couldn’t find any results
            </div>
          )
        ) : (
          allCategories?.map((category, catIndex) => {
            if (category?.headers?.length > 0) {
              return (
                <div className={"category category-by-genre "} key={catIndex}>
                  <div className="category__subtitle mrg-ye-10 ">
                    {category?.category}
                  </div>
                  <div className={"category__cards"}>
                    <Carousel
                      containerClass="carousel-container"
                      keyBoardControl={true}
                      responsive={responsive}
                      infinite={true}
                      draggable={true}
                      arrows={true}
                      centerMode={true}
                      renderDotsOutside={true}
                      // showDots={true}
                      slidesToSlide={3}
                      // additionalTransfrom={1}
                      // transitionEasing
                      // transitionDuration
                      // customButtonGroup={}
                      // customTransition={}
                      // deviceType="mobile"
                      // focusOnSelect={true}
                      // itemClass={}
                      // sliderClass
                      // minimumTouchDrag
                      // renderButtonGroupOutside={false}
                      // swipeable={true}
                    >
                      {category?.headers?.map((header, index) => {
                        return (
                          <div className="category__card " key={index}>
                            <figure className="category__card-figure">
                              <img
                                className="category__card-image "
                                src={baseUrl + header?.imgPath}
                                alt="category"
                              />
                            </figure>
                            <div className="category__card-details ">
                              <span className="category__card-title  ">
                                {header?.title}
                              </span>
                              <div className="category__card-actions mrg-ys-20 ">
                                <div
                                  role={"button"}
                                  className="category__card-actions-edit"
                                  onClick={() => handleClickEditIcon(header)}
                                >
                                  <img src={editCategoryIcon} alt="" />
                                </div>
                                <div
                                  role={"button"}
                                  className="category__card-actions-view"
                                  onClick={() => handleClickViewIcon(header)}
                                >
                                  <img src={viewCategoryIcon} alt="" />
                                </div>
                                <div
                                  role={"button"}
                                  className="category__card-actions-delete"
                                  onClick={() =>
                                    handleClickDeleteIcon(category, header)
                                  }
                                >
                                  <img src={deleteCategoryIcon} alt="" />
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </Carousel>
                  </div>
                </div>
              );
            }
          })
        )}
        <DeleteModal
          deleteModalVisible={deleteModalVisible}
          SubHeader={
            <SubHeader
              bgColor="#92979C"
              title={`Delete ${
                genreName === "TV Shows"
                  ? "Series"
                  : genreName === "Movies"
                  ? "Movie"
                  : genreName === "TV Programs"
                  ? "TV Program"
                  : genreName
              }`}
              closeButton
              onCancel={deleteOnCancel}
            />
          }
          icon={warningIcon}
          msg={`Are you sure you want to delete this ${
            genreName === "Series"
              ? "Series"
              : genreName === "Movies"
              ? "Movie"
              : genreName === "TV Programs"
              ? "TV Program"
              : genreName
          }?`}
          primaryBtnName="Delete"
          secondaryBtnName="Cancel"
          onCancel={deleteOnCancel}
          action={handleDeleteHeaderFromTvShows}
          loading={deleteModalLoading}
          endModalError={endModalError}
        />

        <EditModal
          editModalVisible={editModalVisible}
          genreName={genreName}
          SubHeader={
            <SubHeader
              bgColor="#F58234"
              title="Choose season to edit"
              closeButton
              onCancel={editModalOnCancel}
            />
          }
          selectedHeaderId={headerId}
          genreId={selectedGenre}
          primaryBtnName="Select"
          secondaryBtnName="Cancel"
          editOnCancel={editModalOnCancel}
        />
      </Spin>
    </Fragment>
  );
};

export default CategoriesDependsOnGenre;
