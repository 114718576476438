import axios from "axios";

// export const baseUrl = "https://preprod-api.amusethekidz.com/";
 export const baseUrl = "https://api.amusethekidz.com/";
//export const baseUrl = "http://ec2-54-194-113-34.eu-west-1.compute.amazonaws.com:5001/";
// "http://ec2-54-194-113-34.eu-west-1.compute.amazonaws.com:6011/";
//   // "http://ec2-54-195-141-64.eu-west-1.compute.amazonaws.com:5001/";
// "http://ec2-54-194-113-34.eu-west-1.compute.amazonaws.com:5001/";

let token = null;
export function getToken(newToken) {
  token = newToken;
}

function handleLogout() {
  window.location.href = "/logout";
}

export function getResource(path, onSuccess, onFail, reqAuth = true) {
  let requestData = {
    method: "get",
    url: baseUrl + path,
    headers: {},
  };

  if (reqAuth) {
    requestData.headers = {
      Authorization: "Bearer " + token,
    };
  }

  axios(requestData)
    .then((res) => {
      onSuccess(res.data);
    })
    .catch((fail) => {
      if (fail?.response && fail.response.status === 401) {
        handleLogout();
      } else {
        onFail(fail.response);
      }
    });
}

export async function postResourceAsync(
  path,
  data,
  reqAuth = true,
  multiPart = false
) {
  try {
    let requestData = {
      method: "post",
      url: baseUrl + path,
      headers: {},
      data,
    };
    if (token && reqAuth) {
      requestData.headers = {
        ...requestData.headers,
        Authorization: "Bearer " + token,
      };
    }

    if (multiPart) {
      requestData.headers = {
        ...requestData.headers,
        "content-type": "multipart/form-data",
      };
    }

    const response = await axios(requestData);
    return response.data;
  } catch (error) {
    if (error?.response && error.response.status === 401) {
      handleLogout();
    } else {
      throw error.response;
    }
  }
}

export function postResource(
  path,
  data,
  onSuccess,
  onFail,
  reqAuth = true,
  multiPart = false
) {
  let requestData = {
    method: "post",
    url: baseUrl + path,
    headers: {},
    data,
  };
  if (token && reqAuth) {
    requestData.headers = {
      ...requestData.headers,
      Authorization: "Bearer " + token,
    };
  }

  if (multiPart) {
    requestData.headers = {
      ...requestData.headers,
      "content-type": "multipart/form-data",
    };
  }
  axios(requestData)
    .then((res) => {
      onSuccess(res.data);
    })
    .catch((fail) => {
      if (fail?.response && fail.response.status === 401) {
        handleLogout();
      } else {
        onFail(fail.response);
      }
    });
}

export function putResource(
  path,
  data,
  onSuccess,
  onFail,
  reqAuth = true,
  multipart = false
) {
  let requestData = {
    method: "put",
    url: baseUrl + path,
    headers: {},
    data,
  };

  if (token && reqAuth) {
    requestData.headers = {
      ...requestData.headers,
      Authorization: "Bearer " + token,
    };
  }

  if (multipart) {
    requestData.headers = {
      ...requestData.headers,
      "content-type": "multipart/form-data",
    };
  }

  axios(requestData).then(
    (res) => {
      onSuccess(res.data);
    },
    (fail) => {
      if (fail?.response && fail.response.status === 401) {
        handleLogout();
      } else {
        onFail(fail.response);
      }
    }
  );
}

export function patchResource(
  path,
  data,
  onSuccess,
  onFail,
  reqAuth = true,
  multipart = false
) {
  let requestData = {
    method: "patch",
    url: baseUrl + path,
    headers: {},
    data,
  };

  if (token && reqAuth) {
    requestData.headers = {
      ...requestData.headers,
      Authorization: "Bearer " + token,
    };
  }

  if (multipart) {
    requestData.headers = {
      ...requestData.headers,
      "content-type": "multipart/form-data",
    };
  }

  axios(requestData).then(
    (res) => {
      onSuccess(res.data);
    },
    (fail) => {
      if (fail?.response && fail.response.status === 401) {
        handleLogout();
      } else {
        onFail(fail.response);
      }
    }
  );
}

export function deleteResource(path, onSuccess, onFail, reqAuth = true) {
  let requestData = {
    method: "delete",
    url: baseUrl + path,
    headers: {},
  };

  if (token && reqAuth) {
    requestData.headers = {
      Authorization: "Bearer " + token,
    };
  }

  axios(requestData).then(
    (res) => {
      onSuccess(res.data);
    },
    (fail) => {
      if (fail?.response && fail.response.status === 401) {
        handleLogout();
      } else {
        onFail(fail.response);
      }
    }
  );
}

export function deleteResourceWithData(
  path,
  data,
  onSuccess,
  onFail,
  reqAuth = true
) {
  let requestData = {
    method: "delete",
    url: baseUrl + path,
    headers: {},
    data,
  };

  if (token && reqAuth) {
    requestData.headers = {
      Authorization: "Bearer " + token,
    };
  }

  axios(requestData).then(
    (res) => {
      onSuccess(res.data);
    },
    (fail) => {
      if (fail?.response && fail.response.status === 401) {
        handleLogout();
      } else {
        onFail(fail.response);
      }
    }
  );
}

export function uploadFileService(
  method,
  path,
  formDataType,
  data,
  onProgress,
  onSuccess,
  onFail,
  cancelToken
) {
  let requestData = {
    method: method,
    url: baseUrl + path,
    formDataType,
    headers: {
      Authorization: "Bearer " + token,
      "Content-type": "application/octet-stream",
    },
    data,
    onUploadProgress: (progressEvent) => {
      onProgress(progressEvent);
    },
    cancelToken,
  };

  axios(requestData)
    .then((res) => {
      onSuccess(res.data);
    })
    .catch((fail) => {
      if (fail?.response && fail.response.status === 401) {
        handleLogout();
      } else {
        onFail(fail.response);
      }
    });
}
