import { Button, Col, Form, Input, Row, Select, Space } from "antd";
import ROUTES from "constants/NewRoutes";
import { toastError, toastSuccess } from "helpers/toasters";
import Header from "modules/SubComponents/Header";
import SubHeader from "modules/SubComponents/SubHeader";
import { addAdmin, editAdmin } from "Network";
import React, { useState } from "react";
import { useEffect } from "react";
import { Container } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

function AddEditAdmin() {
  const [loading, setLoading] = useState(false);
  const [sameSuperAdmin, setSameSuperAdmin] = useState(false);
  const [passwordIsChanged, setPasswordIsChanged] = useState(null);
  const location = useLocation();
  const {
    adminName,
    email,
    isSuperAdmin,
    adminId,
    mode,
    selectedPage,
    searchVal,
  } = location?.state;
  const navigate = useNavigate();

  const { authorization } = useSelector((state) => state?.auth);

  const [form] = Form.useForm();

  useEffect(() => {
    if (authorization?.email === email) {
      setSameSuperAdmin(true);
    } //eslint-disable-next-line
  }, [email]);
  useEffect(() => {
    if (mode === "edit") {
      form.setFieldsValue({
        adminName,
        adminEmail: email,
        isSuperAdmin,
      });
    } //eslint-disable-next-line
  }, [mode]);

  const handleBackBtnOnClick = () => {
    navigate(ROUTES.ADMINS, {
      state: { currentPage: selectedPage, searchVal },
    });
  };
  const onFinish = (values) => {
    if (values) {
      let payload = {
        adminId,
        adminName: values.adminName,
        email: values.adminEmail,
        isSuperAdmin: values.isSuperAdmin,
        password: values.adminPassword,
      };

      setLoading(true);
      if (mode === "add") {
        delete payload.adminId;
        addAdmin(
          payload,
          (success) => {
            if (success?.isSuccess) {
              toastSuccess(success.message);
              setLoading(false);
              navigate(ROUTES.ADMINS);
            } else {
              toastError(success.err_msg);
              setLoading(false);
            }
          },
          (fail) => {
            if (fail?.data) {
              toastError(fail.data.err_msg);
              setLoading(false);
            } else {
              toastError();
              setLoading(false);
            }
          }
        );
      } else {
        if (!payload?.password) {
          delete payload.password;
          editAdmin(
            payload,
            (success) => {
              if (success?.isSuccess) {
                toastSuccess(success.message);
                navigate(ROUTES.ADMINS, {
                  state: { currentPage: selectedPage, searchVal },
                });
                setLoading(false);
              } else {
                toastError(success.err_msg);
              }
            },
            (fail) => {
              if (fail?.data) {
                toastError(fail.data.err_msg);
                setLoading(false);
              } else {
                toastError();
              }
            }
          );
        } else {
          editAdmin(
            payload,
            (success) => {
              if (success?.isSuccess) {
                toastSuccess(success.message);
                navigate(ROUTES.ADMINS, {
                  state: { currentPage: selectedPage, searchVal },
                });
                setLoading(false);
              } else {
                toastError(success.err_msg);
              }
            },
            (fail) => {
              if (fail?.data) {
                toastError(fail.data.err_msg);
                setLoading(false);
              } else {
                toastError();
              }
            }
          );
        }
      }
    }
  };
  const dropdownStyle = {
    border: " solid 1px #CEE2F6",
    borderRadius: "12px",
    boxShadow: "0px 8px 10px #0000001A ",
  };
  return (
    <div className="users__usersData">
      <Header title="Admins" />
      <SubHeader
        bgColor="#F58234"
        title={
          mode === "add"
            ? "Add Admin"
            : mode === "edit"
            ? "Edit Admin"
            : mode === "view"
            ? "View Admin"
            : "Admins"
        }
      />

      <Form
        onFinish={onFinish}
        className="mrg-ys-30"
        form={form}
        // initialValues={adminData}
      >
        <Container>
          <Row gutter={[64, 16]}>
            <Col span={12}>
              <div className="new-category">
                <label htmlFor="">Admin Name</label>
                <Form.Item
                  className="wd-100"
                  name="adminName"
                  rules={[
                    { required: true, message: "Please enter the admin name" },
                  ]}
                >
                  <Input type={"text"} className="search__searchField py-2" />
                </Form.Item>
              </div>
            </Col>
            <Col span={12}>
              <div className="new-category">
                <label htmlFor="">Email</label>
                <Form.Item
                  className="wd-100"
                  name="adminEmail"
                  rules={[
                    {
                      required: true,
                      message: "Please enter the email",
                    },
                    { type: "email", message: "Please enter  a valid email" },
                  ]}
                >
                  <Input type={"email"} className="search__searchField py-2" />
                </Form.Item>
              </div>{" "}
            </Col>
            <Col span={12}>
              <div className="new-category ">
                <label htmlFor="">Admin Role</label>
                <Form.Item
                  className="wd-100"
                  name="isSuperAdmin"
                  rules={[
                    { required: true, message: "Please select the admin role" },
                  ]}
                >
                  <Select
                    placeholder="Choose Role"
                    options={[
                      { label: "Super Admin", value: true },
                      { label: "Admin", value: false },
                    ]}
                    className="search__searchField py-1 "
                    dropdownStyle={dropdownStyle}
                    disabled={sameSuperAdmin}
                  />
                </Form.Item>
              </div>{" "}
            </Col>
            <Col span={12}>
              <div className="new-category ">
                <label htmlFor="">Password</label>
                <Form.Item
                  className="wd-100"
                  name="adminPassword"
                  rules={
                    mode === "add"
                      ? [
                          {
                            required: true,
                            message: "Please enter the password",
                          },
                          {
                            min: 4,
                            message: "Password must be at least 4 digit",
                          },
                        ]
                      : passwordIsChanged?.length > 0 && [
                          {
                            min: 4,
                            message: "Password must be at least 4 digit",
                          },
                        ]
                  }
                >
                  <Input.Password
                    className="search__searchField py-2"
                    placeholder={mode === "add" ? "" : "*****************"}
                    onChange={(e) => setPasswordIsChanged(e.target.value)}
                    value={passwordIsChanged}
                  />
                </Form.Item>
              </div>{" "}
            </Col>
          </Row>
          <Row className="mt-5">
            <Col className="wd-100">
              <Space size={20} className=" wd-100 centering">
                <Form.Item className="wd-100">
                  <Button
                    className="secondary-popup-button"
                    onClick={handleBackBtnOnClick}
                  >
                    Back
                  </Button>
                </Form.Item>
                <Form.Item className="wd-100">
                  <Button
                    className="primary-popup-button"
                    htmlType="submit"
                    loading={loading}
                  >
                    {mode === "add" ? "Add Admin" : "Save Changes"}
                  </Button>
                </Form.Item>
              </Space>
            </Col>
          </Row>
        </Container>
      </Form>
    </div>
  );
}

export default AddEditAdmin;
