import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import Navbar from "modules/Navbar/Navbar";
import SideBar from "modules/SideBar/SideBar";
import Header from "modules/SubComponents/Header";
import { useNavigate, useLocation } from "react-router-dom";
import SecondaryButton from "modules/SubComponents/Buttons/SecondaryButton";
import PrimaryButton from "modules/SubComponents/Buttons/PrimaryButton";
import { Col, Form, Row } from "antd";
import { editMessage } from "Network";
import { toastError, toastSuccess } from "helpers/toasters";
import ROUTES from "constants/NewRoutes";
import { useSelector } from "react-redux";

function ContactUsRespond() {
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [answer, setAnswer] = useState("");
  const [respondError, setRespondError] = useState("");

  const navigate = useNavigate();
  const {
    state: { row, selectedPage },
  } = useLocation();
  const { authorization } = useSelector((state) => state.auth);

  useEffect(() => {
    if (row) {
      setName(row?.name);
      setEmail(row?.email);
      setPhone(row?.phone);
      setMessage(row?.message);
      setAnswer(row?.answer);
    } else {
      navigate(ROUTES.CONTACTUS, {
        state: { currentPage: selectedPage },
      });
    }
    //eslint-disable-next-line
  }, [row, navigate]);

  const onCancel = () => {
    navigate(ROUTES.CONTACTUS, {
      state: { currentPage: selectedPage },
    });
  };

  const handleChangeTextArea = (e) => {
    if (!row?.isAnswered) {
      setAnswer(e.target.value);
      setRespondError(false);
    }
  };
  const data = {
    messageId: row?._id,
    answer: answer,
    answeredBy: authorization?.firstName,
  };

  const handleOnFinish = () => {
    if (answer?.length > 0 && answer !== "none") {
      if (row?.answer !== answer) {
        setLoading(true);
        editMessage(
          data,
          (success) => {
            if (success?.isSuccess) {
              toastSuccess(success.message);
              setLoading(false);
              onCancel();
            } else {
              toastError(success.err_msg);
              setLoading(false);
            }
          },
          (fail) => {
            if (fail?.data) {
              toastError(fail.data?.err_msg);
              setLoading(false);
            } else {
              toastError();
              setLoading(false);
            }
          }
        );
      }
    } else {
      setRespondError(true);
    }
  };
  return (
    <section className="users ">
      <div className="users__all-contents ">
        <div className="users__content">
          <div className="users__usersData">
            <Header title="Contact Us" />
            <div className="contact-us ">
              <div className="contact-us__data">
                <div className="contact-us__data-info ">
                  <p className="contact-us__title">Name</p>
                  <p className="contact-us__value">{name}</p>
                </div>
                <div className="contact-us__data-info ">
                  <p className="contact-us__title">Email</p>
                  <p className="contact-us__value">{email}</p>
                </div>
                <div className="contact-us__data-info ">
                  <p className="contact-us__title">Mobile</p>
                  <p className="contact-us__value">{phone}</p>
                </div>
                <div className="contact-us__data-info ">
                  <p className="contact-us__title">Message</p>
                  <p className="contact-us__value">{message}</p>
                </div>
              </div>
              <Row justify="start" className="wd-100">
                <Col>
                  <span
                    style={{
                      color: "#356AB3",
                      fontSize: "20px",
                      fontWeight: "bold",
                    }}
                  >
                    Respond
                  </span>
                </Col>
              </Row>
              <Form onFinish={handleOnFinish} className="wd-100">
                <div className="editor wd-100 ">
                  <div className="options  wd-100 flex justify-center"></div>
                  <div className="content ">
                    <textarea
                      className="text-area"
                      value={answer === "none" ? "" : answer}
                      onChange={handleChangeTextArea}
                    ></textarea>
                  </div>
                </div>
                {respondError && (
                  <div className="episode-errors">Please provide respond</div>
                )}

                <div className="contact-us__buttons wd-100 mt-4 flex justify-end">
                  <Row
                    gutter={row?.isAnswered ? 0 : 20}
                    justify="end"
                    className="mb-3"
                  >
                    <Col>
                      <SecondaryButton buttonName="Back" onClick={onCancel} />
                    </Col>
                    <Col>
                      {!row?.isAnswered && (
                        <PrimaryButton
                          loading={loading}
                          buttonName="Send"
                          type="submit"
                        />
                      )}
                    </Col>
                  </Row>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ContactUsRespond;
