import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Col, Row, Space, Switch, Table } from "antd";
import { editUser, getAllUsersData } from "Network";
import Header from "../SubComponents/Header";
import SubHeader from "../SubComponents/SubHeader";
import { toastError, toastSuccess } from "helpers/toasters";
import eye from "assets/icons/eye.svg";
import ROUTES from "constants/NewRoutes";
import { searchFunction } from "helpers/helpfulFunctions";
import { useSelector } from "react-redux";
import addFreeDaysIcon from "assets/icons/addFreeDays.svg";
import DeleteModal from "modules/Modals/DeleteModal";
import warrningIcon from "assets/icons/warning.svg";
import AddFreeDaysModal from "./AddFreeDaysModal";

function Users() {
  const [renderMe, setRenderMe] = useState(false);
  const [allDataSource, setAllDataSource] = useState([]);
  const [searchResult, setSearchResult] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [loading, setLoading] = useState(true);
  const [loadingUserStatusModal, setLoadingUserStatusModal] = useState(false);
  const [selectedPage, setSelectedPage] = useState();
  const [selectedRowData, setSelectedRowData] = useState({});
  const [addModalVisible, setAddModalVisible] = useState(false);
  const [userId, setUserId] = useState(null);
  const [isUser, setIsUser] = useState(null);
  const [editUserStatusVisible, setEditUserStatusVisible] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { currentPage, searchVal } = location?.state;
  const auth = useSelector((state) => state.auth);
  const { isSuperAdmin } = auth?.authorization;

  useEffect(() => {
    window.addEventListener("beforeunload", function (event) {
      const navTiming = performance.getEntriesByType("navigation")[0];
      if (navTiming.type === "reload") {
        navigate(ROUTES.USERS, {
          state: {
            currentPage: 1,
            searchVal: "",
          },
        });
      }
    });
  }, []);

  useEffect(() => {
    if (currentPage) {
      setSelectedPage(currentPage);
    } else {
      setSelectedPage(1);
    }
  }, [currentPage]);

  useEffect(() => {
    if (searchVal) {
      setSearchValue(searchVal);
      const searchColumns = ["name", "email", "phone"];
      setSearchResult(searchFunction(allDataSource, searchVal, searchColumns));
    }
  }, [searchVal, allDataSource]);

  useEffect(() => {
    getAllUsersData(
      (success) => {
        if (success?.data) {
          setLoading(false);
          setAllDataSource(success?.data);
        }
      },
      (fail) => {
        toastError(fail.err_msg);
      },
    );
  }, [renderMe]);

  useEffect(() => {
    const searchColumns = ["name", "email", "phone"];
    setSearchResult(searchFunction(allDataSource, searchValue, searchColumns));
    //eslint-disable-next-line
  }, [searchValue]);

  const columns = [
    {
      key: "id",
      title: "NAME",
      dataIndex: "name",
      width: 300,
      ellipsis: true,
    },
    {
      key: "id",
      title: "MOBILE NUMBER",
      dataIndex: "phone",
    },
    {
      key: "id",
      title: "PRICING PLAN",
      dataIndex: "pricingPlan",
      width: "30%",
      render: (_, record) => {
        if (record?.pricingPlan?.subscribed) {
          return (
            <Row className='d-flex  justify-content-center align-items-center  wd-100'>
              {/* {record?.pricingPlan?.daysLeft > 0 && ( */}
              <Col span={24}>
                <span>{`Days Left : ${record.pricingPlan.daysLeft}`}</span>
              </Col>
              {/* )} */}

              {record?.pricingPlan?.freeDays > 0 && (
                <Col span={24}>
                  <span>{`Free Days : ${record?.pricingPlan?.freeDays}`}</span>
                </Col>
              )}
              {record?.pricingPlan?.packages?.length > 0 && (
                <Col span={24}>
                  <span>
                    {`Packages : ${
                      record.pricingPlan.packages.length === 1
                        ? record.pricingPlan.packages[0]
                        : record.pricingPlan.packages.slice(0, 2).join(",")
                    }`}
                  </span>
                </Col>
              )}
            </Row>
          );
        } else {
          return <span>not subscribed</span>;
        }
      },
    },
    {
      key: "id",
      title: "CREATED DATE",
      dataIndex: "createdDate",
    },
    {
      key: "id",
      title: "ACTION",
      dataIndex: "action",
      render: (action, record) => {
        return (
          <Space size={10}>
            <img
              role={"button"}
              src={eye}
              alt=''
              onClick={() => handleEyeOnClick(record)}
            />
            {isSuperAdmin && (
              <>
                {" "}
                {/* <img
                  role={"button"}
                  src={addFreeDaysIcon}
                  onClick={() => handleAddFreeDaysForUser(record)}
                  alt='add'
                /> */}
                <Switch
                  // className="admin-status-switch"
                  style={{
                    backgroundColor: `${
                      record?.isActive ? "rgb(49, 170, 74)" : "gray"
                    }`,
                    minWidth: "35px",
                  }}
                  checked={record?.isActive}
                  onChange={() => handleStatusOnChange(record)}
                />
              </>
            )}
          </Space>
        );
      },
    },
  ];

  const onCancel = () => {
    setRenderMe((prev) => !prev);
    setEditUserStatusVisible(false);
    setLoadingUserStatusModal(false);
    setAddModalVisible(false);
  };

  const handleAddFreeDaysForUser = (record) => {
    setSelectedRowData(record);
    setUserId(record?.id);
    setIsUser(true);
    setAddModalVisible(true);
  };
  const handleAddfreeDaysForAll = () => {
    setAddModalVisible(true);
    setIsUser(false);
  };
  const handleEditUserStatus = () => {
    //call an api for edit user status
    setLoadingUserStatusModal(true);
    const data = {
      userId: selectedRowData?.id,
      isActive: !selectedRowData?.isActive,
    };
    editUser(
      data,
      (success) => {
        if (success?.isSuccess) {
          toastSuccess(success.message);
          onCancel();
        } else {
          toastError(success.err_msg);
          onCancel();
        }
      },
      (fail) => {
        if (fail?.data) {
          toastError(fail.data?.err_msg);
          onCancel();
        } else {
          toastError();
          onCancel();
        }
      },
    );
  };
  const handleStatusOnChange = (record) => {
    setSelectedRowData(record);
    setEditUserStatusVisible(true);
  };

  function handleEyeOnClick(record) {
    navigate(ROUTES.USERSINFORMATION, {
      state: { userId: record.id, record, selectedPage, searchValue },
    });
  }

  const handlePageOnChange = (page) => {
    setSelectedPage(page);
  };
  const handleGetSearchValueFromHeader = (value) => {
    setSearchValue(value);
  };

  return (
    <section className='users'>
      <div className='users__all-contents '>
        <div className='users__content'>
          <div className='users__usersData'>
            <Header
              title='Users'
              search='Find User'
              getSearchedFromHeader={handleGetSearchValueFromHeader}
              searchVal={searchVal}
              // plusButtonName={isSuperAdmin ? "Add free days" : null}
              // plusButtonAction={handleAddfreeDaysForAll}
            />
            <SubHeader bgColor='#356ab3' title='Users Data' />
            <div className='users__table grid-table mrg-ye-50 '>
              <Table
                className=''
                rowKey={(row) => row?.id}
                columns={columns}
                dataSource={searchValue ? searchResult : allDataSource}
                pagination={{
                  defaultPageSize: "7",
                  responsive: true,
                  hideOnSinglePage: true,
                  onChange: handlePageOnChange,
                  current: selectedPage,
                }}
                rowClassName={(record, index) =>
                  index % 2 === 0 && "background-even-records"
                }
                scroll={{ x: 600 }}
                loading={loading}
              />
            </div>
          </div>
        </div>
      </div>
      <AddFreeDaysModal
        addModalVisible={addModalVisible}
        userId={userId?.length > 0 ? userId : null}
        isUser={isUser}
        selectedRowData={selectedRowData}
        SubHeader={
          <SubHeader
            bgColor='#F58234'
            title={`Add free days to ${
              isUser ? `( ${selectedRowData?.name} )` : "all users"
            }`}
            closeButton
            onCancel={onCancel}
          />
        }
        onCancel={onCancel}
        secondaryBtnName='Cancel'
        primaryBtnName='Add'
      />
      <DeleteModal
        deleteModalVisible={editUserStatusVisible}
        loading={loadingUserStatusModal}
        SubHeader={
          <SubHeader
            bgColor='#356ab3'
            title='Edit User Status'
            closeButton
            onCancel={onCancel}
          />
        }
        icon={warrningIcon}
        msg={`Are you sure you want to ${
          selectedRowData?.isActive ? "deactivate" : "activate"
        } this user? `}
        onCancel={onCancel}
        action={handleEditUserStatus}
        secondaryBtnName='Cancel'
        primaryBtnName={`${
          selectedRowData?.isActive ? "Deactivate" : "Activate"
        }`}
      />
    </section>
  );
}

export default Users;
