import React, { Fragment, useEffect, useState } from "react";
import { Button, Col, Divider, Form, Input, Progress, Row, Select } from "antd";
import { dropdownStyle } from "helpers/helpfulFunctions";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { toastError, toastSuccess } from "helpers/toasters";
import {
  editMovieHeader,
  editMovieHeaderM3u8,
  getAllActors,
  getAllCategoriesByGenreId,
  getAllVideosDetailsForMovie,
} from "Network";
import ROUTES from "constants/NewRoutes";
import SecondaryPlusButton from "modules/SubComponents/Buttons/SecondaryPlusButton";
import Header from "modules/SubComponents/Header";
import CustomFileUploader from "modules/SubComponents/Upload/CustomFileUploader";
import editIcon from "assets/icons/pen.svg";
import PrimaryButton from "modules/SubComponents/Buttons/PrimaryButton";
import { baseUrl } from "services";
import EditPart from "./EditPart";
import DraggerUploader from "modules/SubComponents/Upload/DraggerUploader";
import EditPartM3u8 from "./EditPartM3u8";

function EditMovieHeaderM3u8() {
  const location = useLocation();
  const { genreId, selectedHeaderId, genreName } = location?.state;
  const [allMovieDataWithVideos, setAllMovieDataWithVideos] = useState([]);
  const [title, setTitle] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [filePath, setFilePath] = useState("");
  const [categoriesByGenreId, setCategoriesByGenreId] = useState([]);
  const [progressPercent, setProgressPercent] = useState(0);
  const [showProgressBar, setShowProgressBar] = useState(false);
  const [allActorsOnSystem, setAllActorsOnSystem] = useState([]);
  const [partsCast, setPartsCast] = useState([]);
  const [partsCount, setPartsCount] = useState(null);
  const [renderMe, setRenderMe] = useState(false);
  const [renderEditPart, setRenderEditPart] = useState(false);
  const [partsCastList, setPartsCastList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [editHeaderErrors, setEditHeaderErrors] = useState([
    {
      hasError: false,
      error: "Please provide title",
    },
    {
      hasError: false,
      error: "Please select genre",
    },
    {
      hasError: false,
      error: "Please select category",
    },
    {
      parts: [],
    },
    {
      casts: [],
    },
    {
      hasError: false,
      error: "Please upload an  image",
    },
  ]);

  const { languagesOnSystem, partsOnSystem } = useSelector(
    (state) => state?.staticData?.staticDataOnSystem
  );

  console.log("🌎partsOnSystem", partsOnSystem);

  const navigate = useNavigate();

  //get Movie data
  useEffect(() => {
    console.log("🚀 ~ EditMovieHeaderM3u8 ~ partsOnSystem:", partsOnSystem);
    if (selectedHeaderId?.length > 0) {
      getAllHeaderDataAfterActions();
    }
    // else {
    //   navigate(ROUTES.MOVIES, {
    //     state: { pathName },
    //   });
    // }
    //eslint-disable-next-line
  }, [selectedHeaderId, renderMe]);

  //fill movie data
  useEffect(() => {
    if (allMovieDataWithVideos?.title?.length > 0) {
      setTitle(allMovieDataWithVideos?.title);
    }
    // if (allMovieDataWithVideos?.genreId?.length > 0) {
    //   setSelectedGenre(allMovieDataWithVideos?.genreId);
    // }
    if (allMovieDataWithVideos?.categoryId?.length > 0) {
      setSelectedCategory(allMovieDataWithVideos?.categoryId);
    }
    if (allMovieDataWithVideos?.imgPath?.length > 0) {
      setFilePath(allMovieDataWithVideos?.imgPath);
    }

    // here i added partName and it's index from parts on system array to current array
    // in order to sort it by partIndex
    if (allMovieDataWithVideos?.castIds?.length > 0) {
      const allPartsCast = allMovieDataWithVideos.castIds.map((part) => {
        let allCastIds = [];
        part?.casts?.forEach((cast) => allCastIds.push(cast?._id));
        return { ...part, castIds: allCastIds };
      });

      allPartsCast.map((currPart, currPartIndex) => {
        partsOnSystem.find((oldItem, oldItemIndex) => {
          if (currPart?.partId === oldItem?.indexId) {
            // currPart.episode = oldItem.episode;
            currPart.partIndex = oldItemIndex;
          }
        });
      });
      //sort ascending
      allPartsCast.sort((a, b) => a.partIndex - b.partIndex);
      setPartsCast(allPartsCast);
      let allMovieErrors = [...editHeaderErrors];
      allPartsCast.map((partError, partErrorIndex) => {
        allMovieErrors[3].parts.push({
          hasError: false,
          error: "Please select part",
        });
        allMovieErrors[4].casts.push({
          hasError: false,
          error: "Please select casts",
        });
      });
      setEditHeaderErrors(allMovieErrors);
    } //eslint-disable-next-line
  }, [allMovieDataWithVideos, partsOnSystem]);

  //get all actors and categories
  useEffect(() => {
    let allCasts = [];
    getAllActors(
      (success) => {
        if (success?.isSuccess) {
          success.data.forEach((cast) =>
            allCasts.push({ label: cast?.name, value: cast?.id })
          );
          setAllActorsOnSystem(allCasts);
        }
      },
      (fail) => {
        if (fail.data) {
          toastError(fail.data.err_msg);
        }
        toastError();
      }
    );
    getAllCategoriesByGenreId(
      { genreId },
      (success) => {
        if (success?.isSuccess) {
          setCategoriesByGenreId(success?.data);
        }
      },
      (fail) => {
        if (fail?.data?.error) {
          toastError(fail.data.err_msg);
        }
      }
    ); //eslint-disable-next-line
  }, []);

  // get category by genre
  // useEffect(() => {
  //   if (selectedGenre?.length > 0) {
  //     getAllCategoriesByGenreId(
  //       { genreId: selectedGenre },
  //       (success) => {
  //         if (success?.isSuccess) {
  //           setCategoriesByGenreId(success?.data);
  //         }
  //       },
  //       (fail) => {
  //         if (fail?.data?.error) {
  //           toastError(fail.data.err_msg);
  //         }
  //       }
  //     );
  //   }
  // }, [selectedGenre]);

  const handleRenderEditMovieHeader = (render) => {
    setRenderMe(render);
  };

  const getAllHeaderDataAfterActions = () => {
    getAllVideosDetailsForMovie(
      { videoHeaderId: selectedHeaderId },
      (success) => {
        if (success?.isSuccess) {
          setAllMovieDataWithVideos(success.data[0]);
        }
      },
      (fail) => {
        toastError(fail.data.err_msg);
      }
    );
  };

  const handleChangeTitle = (e) => {
    let allMovieErrors = [...editHeaderErrors];
    setTitle(e.target.value);
    allMovieErrors[0].hasError = false;
    setEditHeaderErrors(allMovieErrors);
  };

  const handleSelectCategory = (categoryId) => {
    if (categoryId?.length > 0) {
      setSelectedCategory(categoryId);
      let allMovieErrors = [...editHeaderErrors];
      allMovieErrors[2].hasError = false;
      setEditHeaderErrors(allMovieErrors);
    }
  };

  const handleSelectPart = (partId, partCastIndex) => {
    if (partId?.length > 0) {
      let allPartsCast = [...partsCast];
      let allMovieErrors = [...editHeaderErrors];
      const partIsFound = allPartsCast.some((part) => part?.partId === partId);
      if (partIsFound) {
        toastError("this part was added before!");
        allPartsCast[partCastIndex].partId = "";
        setPartsCast(allPartsCast);
        allMovieErrors[3].parts[partCastIndex].hasError = true;
        setEditHeaderErrors(allMovieErrors);
      } else {
        allPartsCast[partCastIndex].partId = partId;
        setPartsCast(allPartsCast);
        allMovieErrors[3].parts[partCastIndex].hasError = false;
        setEditHeaderErrors(allMovieErrors);
      }
    }
  };

  const handleChangeCasts = (castsIds, partCastIndex) => {
    // if (castsIds?.length > 0) {
    let allPartsCast = [...partsCast];
    let allMovieErrors = [...editHeaderErrors];
    allPartsCast[partCastIndex].castIds = castsIds;
    setPartsCast(allPartsCast);
    allMovieErrors[4].casts[partCastIndex].hasError = false;
    setEditHeaderErrors(allMovieErrors);
    // } else {
    //   toastError("You can't remove all casts for this part");
    // }
  };

  const handleAddAnotherPart = () => {
    let allPartsCast = [...partsCast];
    allPartsCast.push({
      partId: partsOnSystem[allPartsCast?.length].indexId,
      castIds: [],
      isAdded: true,
    });
    setPartsCast(allPartsCast);
    let allHeaderError = [...editHeaderErrors];
    allHeaderError[3].parts.push({
      hasError: false,
      error: "Please select part",
    });
    allHeaderError[4].casts.push({
      hasError: false,
      error: "Please select casts ",
    });
    setEditHeaderErrors(allHeaderError);
  };

  const handleDeletePartCasts = (partCastIndex) => {
    let allPartsCast = [...partsCast];
    let allEditHeaderErrors = [...editHeaderErrors];
    allPartsCast.splice(partCastIndex, 1);
    allPartsCast.forEach((part, partIndex) => {
      if (partIndex >= partCastIndex) {
        part.partId = partsOnSystem[partIndex].indexId;
      }
    });
    setPartsCast(allPartsCast);
    allEditHeaderErrors[3].parts.splice(partCastIndex, 1);
    allEditHeaderErrors[4].casts.splice(partCastIndex, 1);
    setEditHeaderErrors(allEditHeaderErrors);
  };

  const handleSetFilePath = (fileP) => {
    let allMovieErrors = [...editHeaderErrors];
    if (fileP?.length > 0) {
      setFilePath(fileP);
      allMovieErrors[5].hasError = false;
      setEditHeaderErrors(allMovieErrors);
    }
  };

  const validateHeader = () => {
    let allHeaderErrors = [...editHeaderErrors];
    if (title === "") {
      allHeaderErrors[0].hasError = true;
      setEditHeaderErrors(allHeaderErrors);
    }

    if (selectedCategory === "") {
      allHeaderErrors[2].hasError = true;
      setEditHeaderErrors(allHeaderErrors);
    }
    if (filePath === "") {
      allHeaderErrors[5].hasError = true;
      setEditHeaderErrors(allHeaderErrors);
    }

    partsCast.map((partCast, partCastIndex) => {
      if (partCast?.partId?.length === 0) {
        allHeaderErrors[3].parts[partCastIndex].hasError = true;
        setEditHeaderErrors(allHeaderErrors);
      }
    });
  };

  const handleOnFinish = () => {
    validateHeader();
    const checkPartError = [];
    const headerHasError = editHeaderErrors.some(
      (objError) => objError?.hasError
    );
    if (headerHasError) {
      checkPartError.push(headerHasError);
    }
    const partIdHasError = editHeaderErrors[3].parts.some(
      (partError, partErrorIndex) => partError?.hasError
    );
    if (partIdHasError) {
      checkPartError.push(partIdHasError);
    }
    const castsIdHasError = editHeaderErrors[4].casts.some(
      (castError, castErrorIndex) => castError?.hasError
    );
    if (castsIdHasError) {
      checkPartError.push(castsIdHasError);
    }
    if (checkPartError?.length === 0) {
      setLoading(true);
      const data = {
        videoHeaderId: location?.state?.selectedHeaderId,
        isSeries: false,
        title,
        genreId,
        categoryId: selectedCategory,
        partsCast,
        imgPath: filePath,
      };
      //call api to edit movie header
      editMovieHeaderM3u8(
        data,
        (success) => {
          if (success?.isSuccess) {
            toastSuccess(success.message);
            setLoading(false);
            setPartsCastList(data.partsCast);
            getAllHeaderDataAfterActions();
            setRenderEditPart((prev) => !prev);
            setPartsCount(data?.partsCast?.length);
          } else {
            toastError(success.err_msg);
            setLoading(false);
          }
        },
        (fail) => {
          if (fail?.data) {
            toastError(fail.data?.err_msg);
            setLoading(false);
          } else {
            toastError();
            setLoading(false);
          }
        }
      );
    } else {
      toastError("Please fill all required fields");
    }
  };

  return (
    <section className="users">
      <div className="users__all-contents ">
        <div className="users__content">
          <div className="tv-shows wd-100 ">
            <Header
              title={
                genreName === "Movies" ? genreName : genreName + " | Movie"
              }
            />
            <Form
              onFinish={handleOnFinish}
              className="tvshows-form wd-100 pad-x-20 "
            >
              <Row gutter={[24, 24]} className="mrg-ye-20">
                <Col span={24}>
                  <div className="new-actor__name new-category ">
                    <label className="view-tvshow-lbl" htmlFor="">
                      Title
                    </label>
                    <Input
                      placeholder="Enter the title"
                      type="text"
                      className="search__searchField py-2"
                      value={title || undefined}
                      onChange={handleChangeTitle}
                    />
                  </div>
                  {editHeaderErrors[0]?.hasError && (
                    <div className="episode-errors">
                      {editHeaderErrors[0]?.error}
                    </div>
                  )}
                </Col>
                {/* <Col className="gutter-row" xs={24} md={12}>
                    <div className="new-actor__name new-category  ">
                      <label className="users__parent-info-label " htmlFor="">
                        Choose Genre
                      </label>
                      <Select
                        className="search__searchField py-1 wd-100"
                        value={selectedGenre}
                        onChange={handleSelectGenre}
                        dropdownStyle={dropdownStyle}
                      >
                        {genresOnSystem?.map(({ id, name }, index) => {
                          return (
                            <Select.Option key={index} value={id}>
                              {name}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </div>
                    {editHeaderErrors[1]?.hasError && (
                      <div className="episode-errors">
                        {editHeaderErrors[1]?.error}
                      </div>
                    )}
                  </Col> */}
                <Col className="gutter-row" span={24}>
                  <div className="new-actor__name new-category">
                    <label className="view-tvshow-lbl" htmlFor="">
                      Choose Category
                    </label>
                    <Select
                      placeholder="Choose Category"
                      className="search__searchField py-1 wd-100"
                      value={selectedCategory || undefined}
                      onChange={handleSelectCategory}
                      dropdownStyle={dropdownStyle}
                    >
                      {categoriesByGenreId?.map(({ id, name }, index) => {
                        return (
                          <Select.Option key={index} value={id}>
                            {name}
                          </Select.Option>
                        );
                      })}
                    </Select>
                    {editHeaderErrors[2]?.hasError && (
                      <div className="episode-errors">
                        {editHeaderErrors[2]?.error}
                      </div>
                    )}
                  </div>
                </Col>
              </Row>
              <Row gutter={[24, 16]}>
                {partsCast.map((partCast, partCastIndex) => {
                  return (
                    <Fragment key={partCastIndex}>
                      <Col span={10}>
                        <div className="new-actor__name new-category select-genre">
                          <label className="view-tvshow-lbl" htmlFor="">
                            Part
                          </label>
                          <Select
                            placeholder="Choose part"
                            className="search__searchField py-1 wd-100"
                            value={partCast?.partId || undefined}
                            dropdownStyle={dropdownStyle}
                            onChange={(partId) =>
                              handleSelectPart(partId, partCastIndex)
                            }
                          >
                            {partCast?.isAdded
                              ? partsOnSystem.map((newPart, newPartIndex) => {
                                  if (newPartIndex === partCastIndex) {
                                    return (
                                      <Select.Option
                                        key={newPartIndex}
                                        value={newPart?.indexId}
                                      >
                                        {newPart?.episode}
                                      </Select.Option>
                                    );
                                  }
                                })
                              : partsOnSystem.map(
                                  ({ indexId, episode }, partIndex) => {
                                    if (partCast?.partId === indexId) {
                                      return (
                                        <Select.Option
                                          key={partIndex}
                                          value={indexId}
                                        >
                                          {episode}
                                        </Select.Option>
                                      );
                                    }
                                  }
                                )}
                          </Select>
                          {editHeaderErrors[3]?.parts[partCastIndex]
                            ?.hasError && (
                            <div className="episode-errors">
                              {editHeaderErrors[3].parts[partCastIndex].error}
                            </div>
                          )}
                        </div>
                      </Col>
                      <Col span={10}>
                        <div className="new-actor__name new-category  select-genre">
                          <label className="view-tvshow-lbl" htmlFor="">
                            Cast of this part
                          </label>
                          <Select
                            placeholder=" Choose casts of this part"
                            mode="multiple"
                            maxTagCount={2}
                            maxTagTextLength={8}
                            className="search__searchField py-1 wd-100"
                            //forEach here to check if cast id is exist in allActors on system or not
                            value={partCast?.castIds || undefined}
                            onChange={(castsIds) =>
                              handleChangeCasts(castsIds, partCastIndex)
                            }
                            dropdownStyle={dropdownStyle}
                            options={allActorsOnSystem}
                            filterOption={(input, option) =>
                              (option?.label ?? "")
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                            filterSort={(optionA, optionB) =>
                              (optionA?.label ?? "")
                                .toLowerCase()
                                .localeCompare(
                                  (optionB?.label ?? "").toLowerCase()
                                )
                            }
                          />
                          {/* <>
                              {allActorsOnSystem?.map(({ id, name }, index) => (
                                <Select.Option key={index} value={id}>
                                  {name}
                                </Select.Option>
                              ))}
                            </>
                          </Select> */}
                          {editHeaderErrors[4]?.casts[partCastIndex]
                            ?.hasError && (
                            <div className="episode-errors">
                              {editHeaderErrors[4].casts[partCastIndex].error}
                            </div>
                          )}
                        </div>
                      </Col>
                      <Col span={4}>
                        <div className="mrg-xe-20">
                          <label htmlFor=""></label>
                          <Button
                            className="delete-video-button"
                            onClick={() => handleDeletePartCasts(partCastIndex)}
                            disabled={
                              partCastIndex <
                              allMovieDataWithVideos?.castIds?.length
                                ? true
                                : false
                            }
                          >
                            Delete
                          </Button>
                        </div>
                      </Col>
                    </Fragment>
                  );
                })}
              </Row>
              <Row justify="end" className="mrg-ys-30">
                <Col>
                  <SecondaryPlusButton
                    buttonName={"Add Another Part"}
                    action={handleAddAnotherPart}
                  />
                </Col>
              </Row>
              <Row gutter={[24, 24]} className="mrg-ye-20">
                <Col span={24}>
                  <div className="upload-files ">
                    <div className="upload-files__container  relative">
                      <figure>
                        <img
                          className="upload-files__upload-file"
                          src={baseUrl + filePath}
                          alt="actorImage"
                          style={{
                            position: "absolute",
                            zIndex: "3",
                            width: "100%",
                          }}
                        />
                      </figure>
                      <DraggerUploader
                        aspect={2 / 1}
                        withCrop
                        formDataType={"img"}
                        acceptedTypes={"image/*"}
                        setShowProgressBar={setShowProgressBar}
                        setProgressPercent={setProgressPercent}
                        setFilePath={(fileP) => handleSetFilePath(fileP)}
                      >
                        <div className="upload-edit-icon mrg-x-20">
                          <img role="button" src={editIcon} alt="edit" />
                        </div>
                      </DraggerUploader>
                    </div>
                    {editHeaderErrors[5]?.hasError && (
                      <div className="episode-errors">
                        {editHeaderErrors[5]?.error}
                      </div>
                    )}
                  </div>
                  {showProgressBar && <Progress percent={progressPercent} />}
                </Col>
              </Row>
              <Row justify="end">
                <Col>
                  <PrimaryButton
                    type={"submit"}
                    buttonName="Save"
                    loading={loading}
                  />
                </Col>
              </Row>
              <Divider
                style={{
                  backgroundColor: "#CEE2F6",
                  height: "2px",
                  opacity: 0.5,
                }}
              />
            </Form>
            <EditPartM3u8
              selectedHeaderId={location?.state?.selectedHeaderId}
              renderMe={renderEditPart}
              renderEditMovieHeader={handleRenderEditMovieHeader}
              partsCastList={partsCastList}
              partsCount={partsCount}
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default EditMovieHeaderM3u8;
