import React, { useState } from "react";
import Header from "modules/SubComponents/Header";
import Langs from "./Langs";
// import { Button, Tabs } from "antd";
// import { Container } from "react-bootstrap";
// import VideoQualities from "./VideoQualities";

function Languages() {
  // const [uploads, setUploads] = useState([
  //   { id: 1, name: "Languages" },
  //   { id: 2, name: "Video quality" },
  // ]);

  // const [selectedUploadBtn, setSelectedUploadBtn] = useState(1);
  // const handleSelectUploadBtn = (selectedId) => {
  //   if (selectedId) {
  //     setSelectedUploadBtn(selectedId?.id);
  //   }
  // };

  return (
    <section className="users ">
      <div className="users__all-contents ">
        <div className="users__content">
          <div className="uploads-title wd-100 pad-xs-20">
            <Header title="Languages" />
          </div>
          <div className="uploads">
            <div className="mrg-ys-50"></div>
            <Langs />
            {/* <Tabs
                tabPosition="top"
                size={"large"}
                tabBarGutter={10}
                type="line"
              >
                {uploads.map((upload, uploadIndex) => (
                  <Tabs.TabPane
                    key={uploadIndex}
                    tab={
                      <Button
                        className={
                          selectedUploadBtn === upload?.id
                            ? "selected-btn-tab"
                            : "btn-tab"
                        }
                        onClick={() => handleSelectUploadBtn(upload)}
                      >
                        {upload?.name}
                      </Button>
                    }
                  >
                    {selectedUploadBtn === 1 && <Languages />}
                    {selectedUploadBtn === 2 && <VideoQualities />}
                  </Tabs.TabPane>
                ))}
              </Tabs> */}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Languages;
