import { Button, Col, Form, Modal, Radio, Row, Space } from "antd";
import React from "react";
import { useState } from "react";

function SeriesOrMovieModal({
  seriesMovieModalVisible,
  subHeader,
  primaryBtnName,
  secondaryBtnName,
  onCancel,
  action,
}) {
  const [isSeries, setIsSeries] = useState(false);

  const handleOnChangeRadioGroup = (e) => {
    setIsSeries(e.target.value);
  };

  const handleFormOnFinish = () => {
    action(isSeries);
  };

  if (seriesMovieModalVisible) {
    return (
      <Modal
        visible={seriesMovieModalVisible}
        title={subHeader}
        className="custom-modal"
        footer={[]}
        closable={false}
      >
        <Form onFinish={handleFormOnFinish}>
          <Row justify="start ">
            <Col span={24} className="mb-2 ">
              <span className="modal-title ">Do you want to add new:</span>
            </Col>
            <Col span={24}>
              <div className="radio-group-container px-5">
                <Radio.Group
                  value={isSeries}
                  onChange={handleOnChangeRadioGroup}
                  className="radio-group-select-type d-flex flex-column align-items-start"
                  size={"large"}
                >
                  <Radio value={false} size="" className="modal-title">
                    Movie
                  </Radio>
                  <Radio value={true} className="modal-title">
                    Series
                  </Radio>
                </Radio.Group>
              </div>
            </Col>
          </Row>
          <Row justify="center" className=" m-5">
            <Col span={24}>
              <Space size={20}>
                <Button className="secondary-popup-button" onClick={onCancel}>
                  {secondaryBtnName}
                </Button>
                <Button className="primary-popup-button" htmlType="submit">
                  {primaryBtnName}
                </Button>
              </Space>
            </Col>
          </Row>
        </Form>
      </Modal>
    );
  }
}

export default SeriesOrMovieModal;
