export const ar = {
  avatarDeletedSuccessfully: "تم حذف الافاتار بنجاح",
  avatarIsNotfound: "الافاتار غير موجود",
  signin: "تسجيل الدخول",
  email: "ادخل الايميل الخاص بك",
  password: "كلمة المرور",
  providePassword: "من فضلك ادخل كلمة المرور",
  passwordMustBeAtLeastSixCharacters: "كلمة المرور لا بد الا تقل عن 6 ",
  pleaseProvideValidEmail: "من فضلك ادخل صيغه صحيحة للايميل",
  forgetPassword: "نسيت كلمة المرور ؟",
  subscribeNow: "اشترك الان",
  dontHaveAccount: "ليس لديك حساب",
  loginSuccessfully: "تم تسجيل الدخول بنجاح",
  // signup: "تسجيل دخول",
  // mobileNumber: "رقم الهاتف",
  // password: "الرقم السري",
  // forgetPassword: "نسيت كلمة المرور ؟",
};
