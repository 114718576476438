import React, { useEffect, useState } from "react";
import { Col, Modal, Row, Select } from "antd";
import { useNavigate } from "react-router-dom";
import { toastError } from "helpers/toasters";
import PrimaryButton from "modules/SubComponents/Buttons/PrimaryButton";
import SecondaryButton from "modules/SubComponents/Buttons/SecondaryButton";
import { getAllSeasonsDependsOnSeries } from "Network";
import ROUTES from "constants/NewRoutes";
import { dropdownStyle } from "helpers/helpfulFunctions";

function EditModal({
  editModalVisible,
  SubHeader,
  primaryBtnName,
  secondaryBtnName,
  editOnCancel,
  selectedHeaderId,
  genreId,
  genreName,
}) {
  const [seasonsDependsOnHeaderId, setSeasonsDependsOnHeaderId] = useState([]);
  const [selectedSeasonId, setSelectedSeasonId] = useState(null);
  const [seasonError, setSeasonError] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setSeasonError(false);
  }, [editModalVisible]);

  useEffect(() => {
    if (selectedHeaderId?.length > 0) {
      getAllSeasonsDependsOnSeries(
        { videoHeaderId: selectedHeaderId },
        (success) => {
          if (success?.isSuccess) {
            setSeasonsDependsOnHeaderId(success.data);
          }
        },
        (fail) => {
          if (fail?.data) {
            toastError(fail.data.err_msg);
          } else {
            toastError();
          }
        }
      );
    } else {
    }
  }, [selectedHeaderId]);

  const handleSelectSeason = (seasonId) => {
    if (seasonId?.length > 0) {
      setSelectedSeasonId(seasonId);
      setSeasonError(false);
    }
  };

  const endEditModal = () => {
    setSeasonError(false);
    editOnCancel();
  };
  const goToEditHeaderData = () => {
    if (selectedSeasonId === null) {
      setSeasonError(true);
    } else {
      setSeasonError(false);
      navigate(ROUTES.EDITTVSHOW, {
        state: {
          selectedHeaderId,
          selectedSeasonId,
          genreId,
          seasonsDependsOnHeaderId,
          genreName,
        },
      });
    }
  };
  return (
    <Modal
      visible={editModalVisible}
      title={SubHeader}
      className="msg-modal text-center"
      footer={[]}
      closable={false}
    >
      <Row className="mrg-ys-50 mrg-ye-100">
        <Col span={24} className="mrg-ye-20">
          <div className="new-category  ">
            <label className="title-style">Choose Season</label>
            <Select
              dropdownStyle={dropdownStyle}
              onChange={(seasonId) => handleSelectSeason(seasonId)}
              className="search__searchField py-1 wd-100"
            >
              {seasonsDependsOnHeaderId?.map(({ id, name }, index) => (
                <Select.Option key={index} value={id}>
                  {name}
                </Select.Option>
              ))}
            </Select>
            {seasonError && (
              <div className="episode-errors">Please select season!</div>
            )}
          </div>
        </Col>
      </Row>
      <div className="popups-buttons px-5 mrg-ys-100">
        <SecondaryButton buttonName={secondaryBtnName} onClick={endEditModal} />
        <PrimaryButton
          buttonName={primaryBtnName}
          onClick={goToEditHeaderData}
        />
      </div>
    </Modal>
  );
}

export default EditModal;
