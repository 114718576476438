const { Modal } = require("antd");
const { useState, useImperativeHandle, forwardRef } = require("react");

const LostConnectionModal = forwardRef((props, ref) => {
  const [open, setOpen] = useState(false);
  useImperativeHandle(ref, () => ({
    openModal: () => setOpen(true),
    closeModal: () => setOpen(false),
  }));

  return (
    <Modal
      footer={null}
      open={open}
      onCancel={() => setOpen(false)}
      centered={false}
      mask={false}
      style={{ top: "20px" }}
    >
      <p className="text-center" style={{ fontSize: "1rem", lineHeigth: "2" }}>
        <strong style={{ color: "#dc3545", fontSize: "1.2rem" }}>
          Upload paused!
        </strong>
        <br />
        Please check your network connection.
        <br />
      </p>
    </Modal>
  );
});

export default LostConnectionModal;
