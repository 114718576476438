import { Table } from "antd";
import ROUTES from "constants/NewRoutes";
import { toastError } from "helpers/toasters";
import Navbar from "modules/Navbar/Navbar";
import SideBar from "modules/SideBar/SideBar";
import Header from "modules/SubComponents/Header";
import SubHeader from "modules/SubComponents/SubHeader";
import { readMessages } from "Network";
import React, { useState } from "react";
import { useEffect } from "react";
import { Container } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import Moment from "react-moment";

function ContactUs() {
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const { currentPage } = location?.state;
  const [selectedPage, setSelectedPage] = useState();

  useEffect(() => {
    window.addEventListener("beforeunload", function (event) {
      const navTiming = performance.getEntriesByType("navigation")[0];
      if (navTiming.type === "reload") {
        navigate(ROUTES.CONTACTUS, {
          state: {
            currentPage: 1,
          },
        });
      }
    });
  }, []);

  useEffect(() => {
    if (currentPage) {
      setSelectedPage(currentPage);
    } else {
      setSelectedPage(1);
    }
  }, [currentPage]);

  useEffect(() => {
    readMessages(
      (success) => {
        if (success?.isSuccess) {
          setDataSource(success.data);
          setLoading(false);
        }
      },
      (fail) => {
        toastError(fail.data.err_msg);
        setLoading(false);
      }
    );
  }, []);

  const handleRowOnClick = (row) => {
    navigate(ROUTES.CONTACTUSRESPOND, {
      state: { row, selectedPage },
    });
  };

  const columns = [
    {
      title: "NAME",
      key: "Name",
      dataIndex: "name",
    },
    {
      title: "EMAIL",
      key: "Email",
      dataIndex: "email",
    },
    {
      title: "MOBILE",
      key: "mobile",
      dataIndex: "phone",
    },
    {
      title: "MESSAGE",
      key: "message",
      dataIndex: "message",
      render: (message) => {
        if (message?.length > 20) {
          const newMessage = message.slice(0, 20) + "...";
          return newMessage;
        } else {
          return message;
        }
      },
    },
    {
      title: "ANSWERED BY",
      key: "answerdBy",
      dataIndex: "answeredBy",
    },
    {
      title: "ANSWER ",
      key: "replied",
      dataIndex: "answer",
      render: (answer) => {
        if (answer?.length > 20) {
          const newAnswer = answer.slice(0, 20) + "...";
          return newAnswer;
        } else {
          return answer;
        }
      },
    },
    {
      title: "Last Update",
      key: "repliedAt",
      dataIndex: "updatedAt",
      render: (updatedAt, r) => {
        return <Moment format="DD - MM - YYYY">{updatedAt}</Moment>;
      },
    },
  ];

  const handlePageOnChange = (page) => {
    setSelectedPage(page);
  };
  return (
    <section className="users ">
      <div className="users__all-contents ">
        <div className="users__content">
          <div className="users__usersData">
            <Header title="Contact Us" />
            <SubHeader title="New Messages" titleColor="#356AB3" />
            <div className="users__table grid-table ">
              <Table
                rowKey={(row) => row?._id}
                className=""
                columns={columns}
                dataSource={dataSource}
                rowClassName={(record, index) =>
                  index % 2 === 0 && "background-even-records"
                }
                scroll={{ x: 600 }}
                loading={loading}
                onRow={(row, index) => {
                  return { onClick: () => handleRowOnClick(row) };
                }}
                pagination={{
                  defaultPageSize: "7",
                  responsive: true,
                  hideOnSinglePage: true,
                  onChange: handlePageOnChange,
                  current: selectedPage,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ContactUs;
