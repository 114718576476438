import { useEffect, useState } from "react";
import { getToken } from "services";
import { useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import { Helmet } from "react-helmet";
import { QueryClient, QueryClientProvider } from "react-query";
import i18next from "localization/i18next";
import "render-smooth-image-react/build/style.css";
import "react-toastify/dist/ReactToastify.css";
import "./App.scss";
import General from "modules/GeneralComponent/General";

const queryClient = new QueryClient();

function App() {
  const { lang, dir } = useSelector((state) => state.lang);
  const { authorization } = useSelector((state) => state.auth);
  // const isAuth = authorization?.token;
  getToken(authorization?.token);

  const [lostConnection, setLostConnection] = useState(false);

  useEffect(() => {
    i18next.init({
      lng: lang,
      fallbackLng: lang,
    });
  }, [lang, dir]);

  return (
    <QueryClientProvider client={queryClient}>
      <>
        <Helmet>
          <html lang={lang} dir={dir}></html>
        </Helmet>
        <ToastContainer />
        {/* <AddEpisodeToHeader /> */}
        <General />
      </>
    </QueryClientProvider>
  );
}

export default App;
