import { Space, Table } from "antd";
import Header from "modules/SubComponents/Header";
import SubHeader from "modules/SubComponents/SubHeader";
import React, { useState } from "react";
import warningIcon from "assets/icons/warning.svg";
import DeleteModal from "modules/Modals/DeleteModal";
import { deleteThisActor, getAllActors } from "Network";
import { useEffect } from "react";
import { toastError, toastSuccess } from "helpers/toasters";
import deleteIcon from "assets/icons/deleteAvatar.svg";
import editIcon from "assets/icons/pen.svg";
import { baseUrl } from "services";
import { useLocation, useNavigate } from "react-router-dom";
import ROUTES from "constants/NewRoutes";
import { searchFunction } from "helpers/helpfulFunctions";
import DetectWindowSize from "helpers/DetectWindowSize";

function Cast() {
  const [loading, setLoading] = useState(true);
  const [breakpointData, setBreakpointData] = useState(null);
  const [searchResult, setSearchResult] = useState([]);
  const [deletedActorId, setDeletedActorId] = useState(null);
  const [allActors, setAllActors] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
   
  if (location?.state)
    var { searchVal, currentPage } = location?.state;
  //from edit component
  const [selectedPage, setSelectedPage] = useState();

  useEffect(() => {
    window.addEventListener("beforeunload", function (event) {
      const navTiming = performance.getEntriesByType("navigation")[0];
      if (navTiming.type === "reload") {
        navigate(ROUTES.CAST, {
          state: {
            currentPage: 1,
            searchVal: "",
          },
        });
      }
    });
  }, []);

  useEffect(() => {
    if (currentPage) {
      setSelectedPage(currentPage);
    } else {
      setSelectedPage(1);
    }
  }, [currentPage]);

  useEffect(() => {
    if (searchVal?.length > 0) {
      setSearchValue(searchVal);
      const searchColumns = ["nameAr", "nameEn"];
      setSearchResult(
        searchFunction(allActors, searchVal, searchColumns),
      );
    }
  }, [searchVal, allActors]);

  useEffect(() => {
    getAllActorsOnSystem();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    const searchColumns = ["nameAr", "nameEn"];
    setSearchResult(
      searchFunction(allActors, searchValue, searchColumns),
    );
    //eslint-disable-next-line
  }, [searchValue]);

  const getAllActorsOnSystem = (search) => {
    getAllActors(
      (success) => {
        if (success?.isSuccess) {
          let arrangedActors = success.data?.reverse();
          setAllActors(arrangedActors);
          if (search?.length > 0) {
            const searchColumns = ["nameAr", "nameEn"];
            setSearchResult(
              searchFunction(success.data, search, searchColumns),
            );
            setLoading(false);
          }
          setLoading(false);
        }
      },
      (fail) => {
        toastError();
        setLoading(false);
      },
    );
  };
  function onCancel() {
    setDeleteModalVisible(false);
  }

  function showDeleteModal(event, actorId) {
    event.stopPropagation();
    setDeleteModalVisible(true);
    setDeletedActorId(actorId);
  }

  function handleEditActor(event, { id, imgPath, nameEn, nameAr }) {
    event.stopPropagation();
    navigate(ROUTES.EDITACTOR, {
      state: {
        actorId: id,
        nameEn,
        nameAr,
        imgPath,
        mode: "edit",
        selectedPage,
        searchVal: searchValue?.length > 0 ? searchValue : "",
      },
    });
  }
  function handleDeleteActor() {
    deleteThisActor(
      { actorId: deletedActorId },
      (success) => {
        if (success?.isSuccess) {
          toastSuccess(success.message);
          onCancel();
          getAllActorsOnSystem(searchValue);
        } else {
          toastError(success.err_msg);
          onCancel();
        }
      },
      (fail) => {
        if (fail?.data) {
          toastError(fail.data?.err_msg);
          onCancel();
        } else {
          toastError();
          onCancel();
        }
      },
    );
  }
  const handleAddActorButton = () => {
    navigate(ROUTES.NEWACTOR, {
      state: {
        selectedPage,
        searchVal: searchValue?.length > 0 ? searchValue : "",
      },
    });
  };

  const columns = [
    {
      title: "ACTOR IMAGE",
      dataIndex: "imgPath",
      render: (_, image) => {
        return (
          <div className='cast-img'>
            <figure className='cast-img-figure'>
              <img
                className='cast-img-path'
                src={baseUrl + image.imgPath}
                alt='Actor'
              />
            </figure>
          </div>
        );
      },
    },
    {
      title: "ACTOR NAME",
      dataIndex: "name",
    },
    {
      title: "ACTION",
      dataIndex: "action",
      render: (action, record) => {
        return (
          <Space size={30} className='cast-actions  '>
            <div className='cast-actions-edit '>
              <img
                role='button'
                src={editIcon}
                alt=''
                onClick={(event) => handleEditActor(event, record)}
              />
            </div>
            <div className='cast-actions-delete '>
              <img
                role='button'
                src={deleteIcon}
                alt=''
                onClick={(event) =>
                  showDeleteModal(event, record?.id)
                }
              />
            </div>
          </Space>
        );
      },
    },
  ];

  const handleGetSearchValueFromHeader = (value) => {
    setSearchValue(value);
  };
  const handlePageOnChange = (page) => {
    setSelectedPage(page);
  };
  return (
    <section className='users '>
      <DetectWindowSize
        getBreakPointData={(data) => setBreakpointData(data)}
      />
      <div className='users__all-contents '>
        <div className='users__content'>
          <div className='users__usersData'>
            <Header
              windowSize={breakpointData}
              title='Cast'
              plusButtonName='Add New Actor'
              plusButtonAction={handleAddActorButton}
              search='Find Actor'
              searchVal={searchVal?.length > 0 ? searchVal : ""}
              getSearchedFromHeader={handleGetSearchValueFromHeader}
            />
            <SubHeader bgColor='#F58234' title='Cast Data' />
            <div className='cast-data__table grid-table wd-95 mb-5 '>
              <Table
                loading={loading}
                rowKey={(row) => row?.id}
                pagination={{
                  defaultPageSize: "7",
                  responsive: true,
                  hideOnSinglePage: true,
                  onChange: handlePageOnChange,
                  current: selectedPage,
                }}
                className=''
                columns={columns}
                dataSource={
                  searchValue?.length > 0 ? searchResult : allActors
                }
                rowClassName={(record, index) =>
                  index % 2 === 0 && "background-even-records "
                }
                scroll={{ x: 600 }}
                onRow={(record, rowIndex) => {
                  return {
                    onClick: (event) => {
                      navigate(ROUTES.EDITACTOR, {
                        state: {
                          actorId: record?.id,
                          nameEn: record?.nameEn,
                          nameAr: record?.nameAr,
                          imgPath: record?.imgPath,
                          pathName: location?.pathname,
                          mode: "view",
                          selectedPage,
                          searchVal:
                            searchValue?.length > 0
                              ? searchValue
                              : "",
                        },
                      });
                    },
                  };
                }}
              />
            </div>
          </div>
          {/* </Container> */}
        </div>
      </div>
      <DeleteModal
        deleteModalVisible={deleteModalVisible}
        SubHeader={
          <SubHeader
            bgColor='#92979C'
            title='Delete Actor'
            closeButton
            onCancel={onCancel}
          />
        }
        icon={warningIcon}
        msg='Are you sure you want to delete this Actor?'
        onCancel={onCancel}
        action={handleDeleteActor}
        secondaryBtnName='Cancel'
        primaryBtnName='Delete'
      />
    </section>
  );
}

export default Cast;
