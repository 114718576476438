import React, { Fragment, useEffect, useState } from "react";
import { Button, Form, Input, Table } from "antd";
import {
  CaretRightOutlined,
  EllipsisOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { toastError, toastSuccess } from "helpers/toasters";
import DeleteModal from "modules/Modals/DeleteModal";
import PrimaryButton from "modules/SubComponents/Buttons/PrimaryButton";
import SecondaryButton from "modules/SubComponents/Buttons/SecondaryButton";
import SubHeader from "modules/SubComponents/SubHeader";
import {
  addNewLanguageToSystem,
  deleteLanguageFromSystem,
  editLanguageOnSystem,
  getAllLanguageEnArOnSystem,
} from "Network";
import warningIcon from "assets/icons/warning.svg";
import { useDispatch } from "react-redux";
import {
  addLanguageToSystem,
  removeLanguageFromSystem,
  updateLanguageOnSystem,
} from "store/staticData";

function Langs() {
  const [mode, setMode] = useState();
  const [renderMe, setRenderMe] = useState(true);
  const [showViewLanguages, setShowViewLanguages] = useState(true);
  const [showAddLanguage, setShowAddLanguages] = useState(false);
  const [allLanguagesOnSystem, setAllLanguagesOnSystem] = useState([]);
  const [selectedLanguageId, setSelectedLanguageId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [englishLanguage, setEnglishLanguage] = useState(null);
  const [arabicLanguage, setArabicLanguage] = useState(null);
  const [arabicLanguageError, setArabicLanguageError] = useState({
    hasError: false,
    error: "من فضلك ادخل اللغة بالعربية",
  });
  const [englishLanguageError, setEnglishLanguageError] = useState({
    hasError: false,
    error: "Please provide language in english ",
  });
  const [loadingModal, setLoadingModal] = useState(false);
  const [deleteModalVisible, setDeleteModalVisable] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    getAllLanguagesOnSystemAfterActions();
  }, [renderMe]);

  const columns = [
    {
      key: "id",
      dataIndex: "englishName",
      width: "32%",
      align: "start",
    },
    {
      key: "id",
      dataIndex: "arabicName",
      width: "32%",
      align: "start",
    },
    {
      key: "id",
      width: "35%",
      align: "right",
      dataIndex: "Action",
      // render: (_, row) => {
      //   return (
      //     <div className="option-row ">

      //       <div
      //         className="upload-languages-options"
      //         role={"button"}
      //         onClick={() => handleShowActions(row?.id)}
      //       >
      //         <div className="upload-languages-options-abs">
      //           {selectedLanguageId === row?.id && showActions && (
      //             <div className="edit-delete ">
      //               <div
      //                 className="edit"
      //                 role={"button"}
      //                 onClick={() => handleEditLanguage(row)}
      //               >
      //                 Edit
      //               </div>
      //               <div
      //                 className="edit"
      //                 role={"button"}
      //                 onClick={handleShowDeleteLModal}
      //               >
      //                 Delete
      //               </div>
      //               <CaretRightOutlined className="right-caret " />
      //             </div>
      //           )}
      //         </div>

      //         <EllipsisOutlined />
      //       </div>
      //     </div>
      //   );
      // },
    },
  ];

  const getAllLanguagesOnSystemAfterActions = () => {
    getAllLanguageEnArOnSystem(
      (success) => {
        if (success?.isSuccess) {
          setAllLanguagesOnSystem(success.data);
          setLoading(false);
        }
      },
      (fail) => {
        if (fail.data) {
          toastError(fail.data.err_msg);
        }
        toastError();
      }
    );
  };
  const handleAddNewLanguage = () => {
    setMode("add");
    setArabicLanguage(null);
    setEnglishLanguage(null);
    setShowViewLanguages(false);
    setShowAddLanguages(true);
  };

  // const handleEditLanguage = (row) => {
  //   if (row) {
  //     setMode("edit");
  //     setShowViewLanguages(false);
  //     setShowAddLanguages(true);
  //     setSelectedLanguageId(row?.id);
  //     setArabicLanguage(row?.arabicName);
  //     setEnglishLanguage(row?.englishName);
  //   }
  // };
  const handleDeleteLanguage = () => {
    setLoadingModal(true);
    deleteLanguageFromSystem(
      selectedLanguageId,
      (success) => {
        if (success?.isSuccess) {
          toastSuccess(success.msg);
          setRenderMe((prev) => !prev);
          modalOnCancel();
          dispatch(removeLanguageFromSystem({ id: selectedLanguageId }));
        }
      },
      (fail) => {
        toastError();
        modalOnCancel();
      }
    );
  };

  // const handleShowDeleteLModal = () => {
  //   setDeleteModalVisable(true);
  // };

  const modalOnCancel = () => {
    setLoadingModal(false);
    setDeleteModalVisable(false);
  };

  // const handleShowActions = (rowId) => {
  //   if (rowId === selectedLanguageId) {
  //     setShowActions((prev) => !prev);
  //   } else {
  //     setShowActions(true);
  //     setSelectedLanguageId(rowId);
  //   }
  // };
  //add language
  const handleOnChangeEn = (e) => {
    setEnglishLanguage(e.target.value);
    validateEnglishLangError(false);
    // let englishRegExp = /^[a-z0-9]{[a-z]s}*$/i;
    // if (mode === "edit") {
    //   if (englishRegExp.test(e.target.value)) {
    //     setEnglishLanguage(e.target.value);
    //     validateEnglishLangError(false);
    //   } else {
    //     // setEnglishLanguage("");
    //     validateEnglishLangError(true);
    //   }
    // } else {
    //   if (englishRegExp.test(e.target.value)) {
    //     setEnglishLanguage(e.target.value);
    //     validateEnglishLangError(false);
    //   } else {
    //     setEnglishLanguage("");
    //     validateEnglishLangError(true);
    //   }
    // }
  };
  const handleOnChangeAr = (e) => {
    setArabicLanguage(e.target.value);
    validateArabicLangError(false);
    // let arabicRegExp = /^[\u0621-\u064A\s\p{N}]+$/;
    // if (arabicRegExp.test(e.target.value)) {
    // setArabicLanguage(e.target.value);
    // validateArabicLangError(false);
    // } else {
    //   validateArabicLangError(true);
    // }
  };
  const validateEnglishLangError = (bool) => {
    let englishLangError = { ...englishLanguageError };
    englishLangError.hasError = bool;
    setEnglishLanguageError(englishLangError);
  };
  const validateArabicLangError = (bool) => {
    let arabicLangError = { ...arabicLanguageError };
    arabicLangError.hasError = bool;
    setArabicLanguageError(arabicLangError);
  };
  const handleCancelAddButton = () => {
    setShowViewLanguages(true);
    setShowAddLanguages(false);
    validateEnglishLangError(false);
    validateArabicLangError(false);
  };
  const handleAddLanguageOnFinish = () => {
    if (mode === "add") {
      if (!englishLanguage) {
        validateEnglishLangError(true);
      }
      if (!arabicLanguage) {
        validateArabicLangError(true);
      }
      if (englishLanguage && arabicLanguage) {
        const data = {
          "name.en": englishLanguage,
          "name.ar": arabicLanguage,
        };
        addNewLanguageToSystem(
          data,
          (success) => {
            if (success?.isSuccess) {
              toastSuccess(success.message);
              setArabicLanguage(null);
              setEnglishLanguage(null);
              setShowViewLanguages(true);
              setShowAddLanguages(false);
              setRenderMe((prev) => !prev);
              dispatch(
                addLanguageToSystem({
                  id: success.data.languageId,
                  name: data["name.en"],
                })
              );
            }
          },
          (fail) => {
            toastError(fail?.err_msg);
          }
        );
      }
    } else {
      if (!englishLanguage) {
        validateEnglishLangError(true);
      }
      if (!arabicLanguage) {
        validateArabicLangError(true);
      }
      if (englishLanguage && arabicLanguage) {
        if (mode === "edit") {
          const editedData = {
            languageId: selectedLanguageId,
            arabicName: arabicLanguage,
            englishName: englishLanguage,
          };
          editLanguageOnSystem(
            editedData,
            (success) => {
              if (success?.isSuccess) {
                toastSuccess(success.message);
                setShowViewLanguages(true);
                setShowAddLanguages(false);
                setRenderMe((prev) => !prev);
                dispatch(
                  updateLanguageOnSystem({
                    id: selectedLanguageId,
                    name: englishLanguage,
                  })
                );
              }
            },
            (fail) => {
              toastError(fail.err_msg);
            }
          );
        }
      }
    }
  };

  return (
    <>
      {showViewLanguages && (
        <Fragment>
          {false && (
            <div className="add-new-language flex justify-end">
              <div
                className="tv-header-plus-button  "
                style={{ width: "170px" }}
              >
                <Button
                  className="new-plus-button-tv "
                  onClick={handleAddNewLanguage}
                >
                  <PlusOutlined className="new-plus-button-icon-tv" />
                  Add Language
                </Button>
              </div>
            </div>
          )}

          <SubHeader bgColor="#D9CA25" title="Languages" />
          <div className=" wd-100 mrg-ye-75 ">
            <Table
              showHeader={false}
              size={"small"}
              rowKey={(row) => row?.id}
              columns={columns}
              dataSource={allLanguagesOnSystem}
              rowClassName={(record, index) =>
                index % 2 === 0 && "background-even-records"
              }
              scroll={{ x: 600 }}
              loading={loading}
            />
          </div>

          <DeleteModal
            deleteModalVisible={deleteModalVisible}
            SubHeader={
              <SubHeader
                title={"Delete Language"}
                bgColor={"#D9CA25"}
                closeButton
                onCancel={modalOnCancel}
              />
            }
            icon={warningIcon}
            msg={"Are you sure you want to Delete this Language?"}
            primaryBtnName={"Delete"}
            secondaryBtnName={"Cancel"}
            loading={loadingModal}
            action={handleDeleteLanguage}
            onCancel={modalOnCancel}
          />
        </Fragment>
      )}
      {showAddLanguage && (
        <Fragment>
          <Form onFinish={handleAddLanguageOnFinish} className="">
            <div className="add-lang-en mrg-ye-75">
              <SubHeader bgColor="#D9CA25" title="Languages" />
              <label htmlFor="" className="users__parent-info-label">
                Language Name
              </label>
              <Input
                type={"text"}
                placeholder="English"
                className="search__searchField py-2 px-3"
                value={englishLanguage}
                // value={
                //   mode === "edit" ? editedEnglishLanguage : englishLanguage
                // }
                onChange={handleOnChangeEn}
              ></Input>
              {englishLanguageError?.hasError && (
                <div className="episode-errors">
                  {englishLanguageError?.error}
                </div>
              )}
            </div>
            <div className="add-lang-en mrg-ye-75">
              <SubHeader bgColor="#D9CA25" title="اللغة" rtl={true} />
              <div className="lang-name wd-100 flex justify-end">
                <label
                  htmlFor=""
                  className="users__parent-info-label mrg-x-20 "
                >
                  اسم اللغه
                </label>
              </div>

              <Input
                dir="rtl"
                type={"text"}
                placeholder="عربي"
                className="search__searchField py-2 px-3"
                value={arabicLanguage}
                onChange={handleOnChangeAr}
              ></Input>
              {arabicLanguageError?.hasError && (
                <div className="episode-errors-ltr">
                  {arabicLanguageError?.error}
                </div>
              )}
            </div>
            <div className="wd-100 flex justify-end">
              <div className="cancel-btn mrg-xe-30">
                <SecondaryButton
                  buttonName={"Cancel"}
                  onClick={handleCancelAddButton}
                />
              </div>
              <div className="">
                <PrimaryButton
                  buttonName={mode === "add" ? "Add" : "Edit"}
                  type={"submit"}
                />
              </div>
            </div>
          </Form>
        </Fragment>
      )}
    </>
  );
}

export default Langs;
