import { useState } from "react";
import { Excel } from "antd-table-saveas-excel";
import { Button } from "antd";
import moment from "moment";
// import { addAdminLogs } from "../network";
// import { useSelector } from "react-redux";

export function ButtonExportToExcel({
  reportName,
  columns,
  dataSource,
  actionAfterExport,
}) {
  const [isLoading, setIsLoading] = useState(false);

  // const { _id } = useSelector((state) => state.auth.authData);
  const excel = new Excel();

  function onClickHandler() {
    let arr = [];
    // setIsLoading(true);

    // Generate a unique identifier using timestamp or a unique number
    const uniqueIdentifier = moment().format("MMM-DD-YYYY-HH-mm-ss"); // Using timestamp as an example

    const sheetName = `${reportName}_${uniqueIdentifier}`;

    excel.addSheet(sheetName).addColumns(columns);
    dataSource.forEach((item) => {
      arr.push(item);
    });
    excel.addDataSource([...arr]);

    if (arr.length === dataSource.length) {
      excel.saveAs(sheetName + ".xlsx");
      setIsLoading(false);

      // Additional actions after successful export
      // For example, logging or executing a callback function
      if (actionAfterExport) {
        actionAfterExport();
      }
    }
  }

  return (
    <Button
      loading={isLoading}
      onClick={onClickHandler}
      className='secondary-popup-button '
    >
      Export As Excel
    </Button>
  );
}
