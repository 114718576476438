import React, { useState } from "react";
import { Form, Input, Modal, Radio, Row, Col, Space, InputNumber } from "antd";
import SecondaryButton from "modules/SubComponents/Buttons/SecondaryButton";
import PrimaryButton from "modules/SubComponents/Buttons/PrimaryButton";
import { toastError, toastSuccess } from "helpers/toasters";
import { addNewPackage } from "Network";

const AddPackageModal = ({
  addModalVisible,
  SubHeader,
  onCancel,
  primaryBtnName,
  secondaryBtnName,
}) => {
  const [loading, setLoading] = useState(false);

  const handleOnCancel = () => {
    setLoading(false);
    onCancel();
  };

  const handleOnFinish = (values) => {
    setLoading(true);
    if (values?.monthlyPrice && values?.noOfMonths) {
      const data = {
        amount: +values.monthlyPrice,
        noOfMonths: +values.noOfMonths,
        discount: +values.discount,
        isActive: values.isActive,
      };

      addNewPackage(
        data,
        (success) => {
          if (success?.isSuccesss) {
            toastSuccess(success.message);
            setLoading(false);
            onCancel();
          } else {
            toastError(success.err_msg);
            setLoading(false);
          }
        },
        (fail) => {
          if (fail?.data) {
            toastError(fail.data?.err_msg);
            setLoading(false);
          } else {
            toastError();
            setLoading(false);
          }
        }
      );
    }
  };
  return (
    addModalVisible && (
      <Modal
        visible={addModalVisible}
        title={SubHeader}
        footer={[]}
        closable={false}
        className="msg-modal text-center"
      >
        <Form
          onFinish={handleOnFinish}
          className="package-form"
          labelCol={{
            span: 16,
          }}
          wrapperCol={{
            span: 24,
          }}
          layout="vertical"
          initialValues={{ isActive: true }}
        >
          <Row gutter={[16, 8]}>
            <Col span={12}>
              <Form.Item
                label="Monthly Price"
                name="monthlyPrice"
                className="w-100 px-1"
                rules={[
                  {
                    required: true,
                    message: "Please enter the monthly price",
                  },
                ]}
              >
                <Input
                  type="number"
                  className="search__searchField py-2"
                  placeholder="EGP200"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Number Of Months"
                name="noOfMonths"
                className="w-100 px-1"
                rules={[
                  {
                    required: true,
                    message: "Please enter the number of months",
                  },
                ]}
              >
                <Input
                  type="number"
                  className="search__searchField py-2"
                  placeholder="Number of months"
                />
              </Form.Item>
            </Col>
            <Col span={22}>
              <Form.Item
                label="discount"
                name="discount"
                className="w-100 px-1"
                initialValue={0}
              >
                <Input
                  type="number"
                  className="search__searchField py-2"
                  placeholder="Provide discount by percentage"
                />
              </Form.Item>
            </Col>
            <Col span={1} className="package-discount">
              <Form.Item label>
                <span
                  style={{
                    fontSize: "20px",
                    color: "black",
                    marginBlockStart: "10px",
                  }}
                >
                  %
                </span>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Item label="Status" name="isActive">
                <Radio.Group>
                  <Radio value={true} defaultChecked={true}>
                    activate
                  </Radio>
                  <Radio value={false}>deactivate</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
          <Space className="flex justify-end" size={"middle"}>
            <SecondaryButton
              buttonName={secondaryBtnName}
              onClick={handleOnCancel}
            />
            <PrimaryButton
              loading={loading}
              buttonName={primaryBtnName}
              type="submit"
            />
          </Space>
        </Form>
      </Modal>
    )
  );
};

export default AddPackageModal;
