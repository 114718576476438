import { urlReplacement } from "./encodeUrl";

export const addTokenToMedia = (path) => {
  const dataFromLocalStorage = localStorage.getItem(
    "persist:Amuse-adminPanel",
  );
  const { token } = JSON.parse(
    JSON.parse(dataFromLocalStorage).auth,
  ).authorization;
  return urlReplacement(`${path}?token=${token}`);
};
