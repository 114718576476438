import React, { useEffect, useState } from "react";
import { Col, Form, Input, Modal, Row, Space } from "antd";
import PrimaryButton from "../SubComponents/Buttons/PrimaryButton";
import SecondaryButton from "../SubComponents/Buttons/SecondaryButton";
import { addNewCategory, editCategory } from "Network";
import { toastError, toastSuccess } from "helpers/toasters";
import Select from "antd/es/select";
import { useSelector } from "react-redux";

function AddEditCategoryModal({
  addModalVisible,
  SubHeader,
  primaryBtnName,
  secondaryBtnName,
  onCancel,
  action,
  stopModalLoading,
  oldData = null,
  searchValue,
  selectedPage,
}) {
  const [loading, setLoading] = useState(false);
  const { genresOnSystem } = useSelector(
    (state) => state?.staticData?.staticDataOnSystem
  );

  let selectedGenreId = genresOnSystem?.find(
    (gen) => gen?.name === oldData?.genre
  )?.id;

  useEffect(() => {
    if (stopModalLoading) {
      setLoading(false);
    }
  }, [stopModalLoading]);

  const handleOnCancel = () => {
    setLoading(false);
    onCancel(searchValue, selectedPage);
  };

  function handleOnFinish(values) {
    if (
      values?.categoryNameEn?.length > 0 &&
      values?.categoryNameAr?.length > 0 &&
      values?.selectedGenre?.length > 0
    ) {
      setLoading(true);
      if (oldData) {
        const editPayload = {
          categoryId: oldData.id,
          nameAr: values.categoryNameAr,
          nameEn: values.categoryNameEn,
          genreId: values.selectedGenre,
        };
        if (
          values?.categoryNameEn === oldData.nameEn &&
          values?.categoryNameAr === oldData.nameAr &&
          values?.selectedGenre === selectedGenreId
        ) {
          toastError("No changes");
          setLoading(false);
        } else {
          editCategory(
            editPayload,
            (success) => {
              if (success?.isSuccess) {
                toastSuccess(success.message);
                setLoading(false);
                onCancel(searchValue, selectedPage);
              } else {
                toastError(success.err_msg);
                setLoading(false);
              }
            },
            (fail) => {
              if (fail?.data) {
                toastError(fail.data?.err_msg);
                setLoading(false);
                onCancel(searchValue, selectedPage);
              } else {
                toastError();
                setLoading(false);
                onCancel(searchValue, selectedPage);
              }
            }
          );
        }
      } else {
        const addPayload = {
          nameAr: values.categoryNameAr,
          nameEn: values.categoryNameEn,
          genreId: values.selectedGenre,
        };
        addNewCategory(
          addPayload,
          (success) => {
            if (success?.isSuccess) {
              toastSuccess(success.message);
              setLoading(false);
              onCancel();
            } else {
              toastError(success.message);
              setLoading(false);
            }
          },
          (fail) => {
            if (fail?.data) {
              toastError(fail.data?.err_msg);
              setLoading(false);
            } else {
              toastError();
              setLoading(false);
            }
          }
        );
      }
    }
  }

  return (
    addModalVisible && (
      <Modal
        title={SubHeader}
        visible={addModalVisible}
        className="msg-modal text-center"
        footer={false}
        closable={false}
      >
        <Form
          className="edit-actor__content  "
          onFinish={handleOnFinish}
          initialValues={{
            categoryNameEn: oldData?.nameEn,
            categoryNameAr: oldData?.nameAr,
            selectedGenre: selectedGenreId,
          }}
        >
          <Row gutter={24}>
            <Col span={12}>
              {" "}
              <div className="new-actor__name new-category mb-5">
                <label className="users__parent-info-label mb-2" htmlFor="">
                  Category Name
                </label>
                <Form.Item
                  className=" new-category__form-item "
                  name="categoryNameEn"
                  rules={[
                    {
                      required: true,
                      message: "Please enter the category name ",
                    },
                    {
                      pattern: /^[^\u0600-\u06FF]*$/,
                      message: "Please enter category name in english",
                    },
                  ]}
                >
                  <Input
                    type="text"
                    className="search__searchField py-2"
                    placeholder="Name"
                  />
                </Form.Item>
              </div>
            </Col>
            <Col span={12}>
              {" "}
              <div className="new-actor__name new-category mb-5">
                <div className="lang-name wd-100 flex justify-end">
                  <label className="users__parent-info-label mb-2" htmlFor="">
                    اسم الفئه
                  </label>
                </div>
                <Form.Item
                  className=" new-category__form-item "
                  name="categoryNameAr"
                  rules={[
                    {
                      required: true,
                      message: "من فضلك ادخل اسم الفئه",
                    },
                    {
                      pattern: /^[^a-zA-Z]*$/,
                      message: "من فضلك ادخل اسم الفئه باللغه العربيه",
                    },
                  ]}
                  style={{ direction: "rtl" }}
                >
                  <Input
                    dir="rtl"
                    type="text"
                    className="search__searchField py-2"
                    placeholder="الاسم"
                  />
                </Form.Item>
              </div>
            </Col>
            <Col span={24}>
              {" "}
              <div className="new-actor__name new-category  select-genre">
                <label className="users__parent-info-label mb-2" htmlFor="">
                  Choose a Genre
                </label>
                <Form.Item
                  name={"selectedGenre"}
                  className=" new-category__form-item "
                  rules={[
                    { required: true, message: "Please select a genre name" },
                  ]}
                >
                  <Select
                    selectName="selectedLanguage"
                    className="search__searchField py-1"
                    placeholder="Genre"
                    style={{
                      color: "#9A9FA5",
                      width: "100%",
                    }}
                    allowClear
                    disabled={oldData ? true : false}
                  >
                    {genresOnSystem?.map((genre, index) => (
                      <Select.Option value={genre?.id} key={index}>
                        {genre?.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
            </Col>
          </Row>
          <Row className="mrg-ys-20 mrg-x-auto">
            <Space size={20}>
              <SecondaryButton
                buttonName={secondaryBtnName}
                onClick={handleOnCancel}
              />
              <PrimaryButton
                buttonName={primaryBtnName}
                loading={loading}
                onClick={action}
                type="submit"
              />
            </Space>
          </Row>
        </Form>
      </Modal>
    )
  );
}

export default AddEditCategoryModal;
