const ROUTES = {
  SIGNIN: "/",
  LOGOUT: "/logout",
  NOT_FOUND: "*",
  HOME: "/home",
  DASHBOARD: "/dashboard",
  USERS: "/users",
  ADMINS: "/admins",
  ADDEDITADMIN: "/add-edit-admin",
  USERSINFORMATION: "/user-information",
  CAST: "/cast",
  NEWACTOR: "/new-actor",
  VIEWACTOR: "/view-actor",
  EDITACTOR: "/edit-actor",
  CATEGORIES: "/categories",
  GENRES: "/genres",
  CONTACTUS: "/contact-us",
  CONTACTUSRESPOND: "/contact-us-respond",
  SUBSCRIPTIONS: "/subscriptions",
  CROPIMAGES: "/crop-images",
  EDITOR: "/editor",
  BANNER: "/banner",
  LANGUAGES: "/languages",
  TVSHOWS: "/tv-shows",
  ADDNEWHEADER: "/add-new-header",
  EDITTVSHOW: "/edit-header",
  VIEWTVSHOWS: "/view-header",
  MOVIES: "/movies",
  ADDNEWMOVIE: "/add-new-movie",
  EDITMOVIEHEADER: "/edit-movie-header",
  VIEWMOVIE: "/view-movie",
  ANIME: "/anime",
  TVPROGRAMS: "/tv-programs",
  FUNSTORE: "/fun-store",
  QUESTIONS: "/questions",
  ANSWERS: "/answers",
  ADDNEWQUESTION: "/add-new-question",
  CODE: "/code",
};

export default ROUTES;
