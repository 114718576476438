import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import ROUTES from "constants/NewRoutes";
import Cast from "../Cast/Cast";
import SignIn from "modules/Authentications/SignIn";
import Users from "modules/Users/Users";
import UsersInformation from "modules/Users/UsersInformation";
import NewActor from "modules/Cast/NewActor";
import Categories from "modules/Categories/Categories";
import ContactUs from "modules/ContactUs/ContactUs";
import ContactUsRespond from "modules/ContactUs/ContactUsRespond";
import Logout from "modules/Authentications/Logout";
import Dashboard from "modules/Dashboard/Dashboard";
import Genres from "modules/Genres/Genres";
import Subscriptions from "modules/Subscriptions/Subscriptions";
import Banner from "modules/Banner/Banner";
import Home from "modules/Home/Home";
import TVShows from "modules/TVShows/TVShows";
import AddNewHeader from "modules/TVShows/AddNewHeader";
import EditHeader from "modules/TVShows/EditHeader";
import ViewTvShows from "modules/TVShows/ViewTvShows";
import AddNewMovie from "modules/Movies/AddNewMovie";
import AddNewM3U8Movie from "modules/Movies/AddNewM3U8Movie";
import EditMovieHeader from "modules/Movies/EditMovieHeader";
import ViewMovie from "modules/Movies/ViewMovie";
import Questions from "modules/Questions/Questions";
import AddNewQuestion from "modules/Questions/AddNewQuestion";
import Languages from "modules/Languages/Languages";
import Admins from "modules/Admins/Admins";
import AddEditAdmin from "modules/Admins/AddEditAdmin";
import EditActor from "modules/Cast/EditActor";
import Code from "modules/OFFERS/Code";
import Answers from "modules/Questions/Answers";
import EditMovieHeaderM3u8 from "modules/Movies/EditMovieHeaderM3u8";
import AddNewHeaderM3u8 from "modules/TVShows/AddNewHeaderM3u8";
import EditHeaderM3u8 from "modules/TVShows/EditHeaderM3u8";
import ViewMovieM3u8 from "modules/Movies/ViewMovieM3u8";
import ViewTvShowsM3u8 from "modules/TVShows/ViewTvShowsM3u8";

function RoutesFile() {
  const { authorization } = useSelector((state) => state.auth);
  const isAuthorized = authorization?.token;
  return (
    <Routes>
      <Route
        path={ROUTES.SIGNIN}
        element={
          isAuthorized ? <Navigate to={ROUTES.DASHBOARD} replace /> : <SignIn />
        }
      />
      <Route
        exact
        path={ROUTES.HOME}
        element={
          isAuthorized ? <Home /> : <Navigate to={ROUTES.SIGNIN} replace />
        }
      />
      <Route
        path={ROUTES.DASHBOARD}
        element={
          isAuthorized ? <Dashboard /> : <Navigate to={ROUTES.SIGNIN} replace />
        }
      />
      <Route
        path={ROUTES.ADMINS}
        element={
          isAuthorized ? <Admins /> : <Navigate to={ROUTES.SIGNIN} replace />
        }
      />
      <Route
        path={ROUTES.ADDEDITADMIN}
        element={
          isAuthorized ? (
            <AddEditAdmin />
          ) : (
            <Navigate to={ROUTES.SIGNIN} replace />
          )
        }
      />
      <Route
        path={ROUTES.CAST}
        element={
          isAuthorized ? <Cast /> : <Navigate to={ROUTES.SIGNIN} replace />
        }
      />
      <Route
        path={ROUTES.NEWACTOR}
        element={
          isAuthorized ? <NewActor /> : <Navigate to={ROUTES.SIGNIN} replace />
        }
      />
      {/* <Route
        path={ROUTES.VIEWACTOR}
        element={
          isAuthorized ? <ViewActor /> : <Navigate to={ROUTES.SIGNIN} replace />
        }
      /> */}
      <Route
        path={ROUTES.EDITACTOR}
        element={
          isAuthorized ? <EditActor /> : <Navigate to={ROUTES.SIGNIN} replace />
        }
      />
      <Route
        path={ROUTES.CATEGORIES}
        element={
          isAuthorized ? (
            <Categories />
          ) : (
            <Navigate to={ROUTES.SIGNIN} replace />
          )
        }
      />
      <Route
        path={ROUTES.LANGUAGES}
        element={
          isAuthorized ? <Languages /> : <Navigate to={ROUTES.SIGNIN} replace />
        }
      />
      <Route
        path={ROUTES.TVSHOWS}
        element={
          isAuthorized ? <TVShows /> : <Navigate to={ROUTES.SIGNIN} replace />
        }
      />
      <Route
        path={ROUTES.ADDNEWHEADER}
        element={
          isAuthorized ? (
            // <AddNewHeader />
            <AddNewHeaderM3u8 />
          ) : (
            <Navigate to={ROUTES.SIGNIN} replace />
          )
        }
      />
      <Route
        path={ROUTES.EDITTVSHOW}
        element={
          isAuthorized ? (
            // <EditHeader />
            <EditHeaderM3u8 />
          ) : (
            <Navigate to={ROUTES.SIGNIN} replace />
          )
        }
      />

      <Route
        path={ROUTES.VIEWTVSHOWS}
        element={
          isAuthorized ? (
            // <ViewTvShows />
            <ViewTvShowsM3u8 />
          ) : (
            <Navigate to={ROUTES.SIGNIN} replace />
          )
        }
      />
      <Route
        path={ROUTES.MOVIES}
        element={
          isAuthorized ? <TVShows /> : <Navigate to={ROUTES.SIGNIN} replace />
        }
      />
      <Route
        path={ROUTES.ADDNEWMOVIE}
        element={
          isAuthorized ? (
            // <AddNewMovie />
            <AddNewM3U8Movie />
          ) : (
            <Navigate to={ROUTES.SIGNIN} replace />
          )
        }
      />
      <Route
        path={ROUTES.EDITMOVIEHEADER}
        element={
          isAuthorized ? (
            // <EditMovieHeader />
            <EditMovieHeaderM3u8 />
          ) : (
            <Navigate to={ROUTES.SIGNIN} replace />
          )
        }
      />
      <Route
        path={ROUTES.VIEWMOVIE}
        element={
          isAuthorized ? (
            //  <ViewMovie />
            <ViewMovieM3u8 />
          ) : (
            <Navigate to={ROUTES.SIGNIN} replace />
          )
        }
      />
      <Route
        path={ROUTES.ANIME}
        element={
          isAuthorized ? <TVShows /> : <Navigate to={ROUTES.SIGNIN} replace />
        }
      />
      <Route
        path={ROUTES.TVPROGRAMS}
        element={
          isAuthorized ? <TVShows /> : <Navigate to={ROUTES.SIGNIN} replace />
        }
      />
      <Route
        path={ROUTES.FUNSTORE}
        element={
          isAuthorized ? <TVShows /> : <Navigate to={ROUTES.SIGNIN} replace />
        }
      />
      <Route
        path={ROUTES.GENRES}
        element={
          isAuthorized ? <Genres /> : <Navigate to={ROUTES.SIGNIN} replace />
        }
      />
      <Route
        path={ROUTES.CONTACTUS}
        element={
          isAuthorized ? <ContactUs /> : <Navigate to={ROUTES.SIGNIN} replace />
        }
      />
      <Route
        path={ROUTES.CONTACTUSRESPOND}
        element={
          isAuthorized ? (
            <ContactUsRespond />
          ) : (
            <Navigate to={ROUTES.SIGNIN} replace />
          )
        }
      />
      <Route
        path={ROUTES.USERS}
        element={
          isAuthorized ? <Users /> : <Navigate to={ROUTES.SIGNIN} replace />
        }
      />
      <Route
        path={ROUTES.QUESTIONS}
        element={isAuthorized ? <Questions /> : <Navigate to={ROUTES.SIGNIN} />}
      />
      <Route
        path={ROUTES.ANSWERS}
        element={isAuthorized ? <Answers /> : <Navigate to={ROUTES.SIGNIN} />}
      />
      <Route
        path={ROUTES.ADDNEWQUESTION}
        element={
          isAuthorized ? <AddNewQuestion /> : <Navigate to={ROUTES.SIGNIN} />
        }
      />
      <Route
        path={ROUTES.SUBSCRIPTIONS}
        element={
          isAuthorized ? (
            <Subscriptions />
          ) : (
            <Navigate to={ROUTES.SIGNIN} replace />
          )
        }
      />
      <Route
        path={ROUTES.BANNER}
        element={
          isAuthorized ? <Banner /> : <Navigate to={ROUTES.SIGNIN} replace />
        }
      />
      <Route
        path={ROUTES.USERSINFORMATION}
        element={
          isAuthorized ? (
            <UsersInformation />
          ) : (
            <Navigate to={ROUTES.SIGNIN} replace />
          )
        }
      />
      <Route
        path={ROUTES.CODE}
        element={
          isAuthorized ? <Code /> : <Navigate to={ROUTES.SIGNIN} replace />
        }
      />
      <Route path={ROUTES.LOGOUT} element={<Logout />} />

      {/* <Route path={ROUTES.NOT_FOUND} element={<PageNotFound />} /> */}
    </Routes>
  );
}

export default RoutesFile;
