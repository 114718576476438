import React, { useState, useEffect } from "react";
import Header from "modules/SubComponents/Header";
import SubHeader from "modules/SubComponents/SubHeader";
import subscribersIcon from "assets/icons/subscribers.svg";
import viewsIcon from "assets/icons/views.svg";
import incomeIcon from "assets/icons/income.svg";
import tvShowsIcon from "assets/icons/tvShowsIcon.svg";
import MovieViewsIcon from "assets/icons/viewsIcon.svg";
import {
  allViewsOnSystem,
  getNumberOfMoviesOnSystem,
  getNumberOfMovieViewsOnSystem,
  getNumberOfSeriesOnSystem,
  getNumberOfSeriesViewsOnSystem,
  getSystemCategoryByPercentage,
  getSystemHourlyViews,
  getSystemTrendingShows,
  getSystemWeaklyViews,
} from "Network";
import { toastError } from "helpers/toasters";
import BarChart from "./BarChart";
import DoughnutChart from "./DoughnutChart";

import {
  Chart as ChartJS,
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import LineChart from "./LineChart";
import { baseUrl } from "services";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { roundsToTheNearestTwoDigits } from "helpers/helpfulFunctions";
import { Table } from "antd";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  ChartDataLabels
);

export const UserContext = React.createContext();

function Dashboard() {
  const [loading, setLoading] = useState(true);
  const [totalSubscribers, setTotalSubscribers] = useState(null);
  const [totalValueOfIncome, setTotalValueOfIncome] = useState(null);
  const [totalNumOfViews, setTotalNumOfViews] = useState(null);
  const [weaklyViewsDaysCount, setWeaklyViewsDaysCount] = useState("7");
  const [barDataSet, setBarDataset] = useState([]);
  const [barLables, setBarLables] = useState([]);
  const [categoryDataSet, setCategoryDataSet] = useState([]);
  const [categoryColors, setCategoryColors] = useState([]);
  const [categoryLables, setCategoryLables] = useState([]);
  const [categoryPercentageLables, setCategoryPercentageLables] = useState([]);
  const [categoryPercentage, setCategoryPercentage] = useState([]);
  const [hourlyViewsDaysCount, setHourlyViewsDaysCount] = useState("7");
  const [lineDataSet, setLineDataSet] = useState([]);
  const [lineLables, setLineLables] = useState([]);
  const [trendingViewsDaysCount, setTrendingShowsDaysCount] = useState("7");
  const [trendingShowsData, setTrendingShowsData] = useState([]);
  const [seriesNumber, setSeriesNumber] = useState(null);
  const [seriesViews, setSeriesViews] = useState(null);
  const [moviesNumber, setMoviesNumber] = useState(null);
  const [movieViews, setMovieViews] = useState(null);

  useEffect(() => {
    allViewsOnSystem(
      (success) => {
        if (success?.isSuccess) {
          setTotalSubscribers(
            roundsToTheNearestTwoDigits(success.data?.totalSubscriber)
          );
          setTotalValueOfIncome(
            roundsToTheNearestTwoDigits(success.data?.totalValueOfIncome)
          );
          setTotalNumOfViews(
            roundsToTheNearestTwoDigits(success.data?.allViews)
          );
        }
      },
      (fail) => {
        if (fail?.data) {
          toastError(fail.data.err_msg);
        } else {
          toastError();
        }
      }
    );
  }, []);

  useEffect(() => {
    getNumberOfSeriesViewsOnSystem(
      (success) => {
        if (success?.isSuccess) {
          setSeriesViews(
            roundsToTheNearestTwoDigits(success?.data?.allSeriesViews)
          );
        }
      },
      (fail) => {
        toastError(fail?.data?.err_msg);
      }
    );
    getNumberOfMovieViewsOnSystem(
      (success) => {
        if (success?.isSuccess) {
          setMovieViews(
            roundsToTheNearestTwoDigits(success?.data?.allMoviesViews)
          );
        }
      },
      (fail) => {
        toastError(fail?.data?.err_msg);
      }
    );
  }, []);

  useEffect(() => {
    getNumberOfSeriesOnSystem(
      (success) => {
        if (success?.isSuccess) {
          setSeriesNumber(
            roundsToTheNearestTwoDigits(success?.data?.numberOfSeries)
          );
        }
      },
      (fail) => {
        toastError(fail?.data?.err_msg);
      }
    );
    getNumberOfMoviesOnSystem(
      (success) => {
        if (success?.isSuccess) {
          setMoviesNumber(
            roundsToTheNearestTwoDigits(success?.data?.numberOfMovies)
          );
        }
      },
      (fail) => {
        toastError(fail?.data?.err_msg);
      }
    );
  }, []);

  useEffect(() => {
    getSystemWeaklyViews(
      { weaklyViewsDaysCount },
      (success) => {
        if (success?.isSuccess) {
          setBarDataset(success?.data?.map((item) => item.views)); //index
          setBarLables(success?.data?.map((item) => item.day));
        }
      },
      (fail) => {
        if (fail?.data) {
          toastError(fail.data.err_msg);
        } else {
          toastError();
        }
      }
    );
  }, [weaklyViewsDaysCount]);

  useEffect(() => {
    getSystemCategoryByPercentage(
      (success) => {
        if (success?.isSuccess) {
          setCategoryDataSet(success.data);
          setCategoryColors(success.data?.map((item) => item?.color));
          setCategoryLables(success.data?.map((item) => item?.name));
          setCategoryPercentage(
            success.data?.map((item) =>
              Math.floor(item?.percentage.split("%")[0])
            )
          );
          setCategoryPercentageLables(
            success.data?.map((item) => item?.percentage)
          );
        }
      },
      (fail) => {
        if (fail?.data) {
          toastError(fail.data.err_msg);
        } else {
          toastError();
        }
      }
    );
  }, []);

  useEffect(() => {
    if (hourlyViewsDaysCount > 0) {
      getSystemHourlyViews(
        { hourlyViewsDaysCount },
        (success) => {
          if (success?.isSuccess) {
            setLineDataSet(success.data?.map((item, index) => item?.views));
            setLineLables(success.data?.map((item, index) => item?.hour));
          }
        },
        (fail) => {
          if (fail?.data) {
            toastError(fail.data.err_msg);
          } else {
            toastError();
          }
        }
      );
    } else {
      toastError("please enter a valid number");
    }
  }, [hourlyViewsDaysCount]);

  useEffect(() => {
    getSystemTrendingShows(
      { trendingViewsDaysCount },
      (success) => {
        if (success?.isSuccess) {
          setTrendingShowsData(success.data);
          setLoading(false);
        }
      },
      (fail) => {
        if (fail?.data) {
          toastError(fail.data.err_msg);
          setLoading(false);
        } else {
          toastError();
        }
      }
    );
  }, [trendingViewsDaysCount]);

  const barData = {
    labels: barLables,
    datasets: [
      {
        data: barDataSet,
        backgroundColor: [
          "#006AF5",
          "#37557B",
          "#356AB3",
          "#477BBF",
          "#84A0C5",
          "#B6D4FC",
          "#4D97F9",
        ],
        barThickness: 40,
        borderColor: "none",
        borderWidth: 0,
        borderRadius: 2,
      },
    ],
  };
  const lineData = {
    labels: lineLables,
    datasets: [
      {
        label: "Hourly Views",
        data: lineDataSet,
        backgroundColor: "#2A85FF",
        borderColor: "#356AB3",
        borderWidth: 4,
        borderRadius: 2,
        borderCapStyle: "round",
        tension: ".4",
        fill: false,
        pointStyle: "circle",
        pointBorderColor: "#2A85FF",
        pointBorderWidth: "7",
        drawActiveElementsOnTop: "true",
        showLine: true,
      },
    ],
  };
  const categoryData = {
    labels: categoryLables,
    datasets: [
      {
        label: categoryPercentageLables,
        data: categoryPercentage,
        backgroundColor: categoryColors,
        cutout: "55%",
        borderJoinStyle: "miter",
        circumference: 360,
        rotation: 180,
        borderRadius: 0,
        spacing: 4,
        weight: 20,
        // borderColor: "#fff",
        // borderWidth: 5,
      },
    ],
  };
  const getBarsChartOptions = (barTitle) => {
    const options = {
      responsive: true,
      scales: {
        yAxes: {
          grid: { display: false, color: "red" },
          title: {
            display: false,
            text: barTitle,
            color: "#12819c",
            font: {
              size: 16,
            },
          },
        },
        xAxes: {
          grid: { display: false, color: "red" },
          title: {
            display: false,
            text: barTitle,
            color: "#12819c",
            font: {
              size: 16,
            },
          },
        },
      },
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          xAlign: "center",
          yAlign: "bottom",
        },
      },
    };
    return options;
  };
  const getLineChartOptions = (barTitle) => {
    const options = {
      responsive: true,
      scales: {
        yAxes: {
          grid: { display: true, color: "#D0D2D4" },
          title: {
            display: false,
            text: barTitle,
            color: "#12819c",
            font: {
              size: 16,
            },
          },
        },
        xAxes: {
          grid: { display: true, color: "#D0D2D4" },
          title: {
            display: false,
            text: barTitle,
            color: "#12819c",
            font: {
              size: 16,
            },
          },
        },
      },
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          xAlign: "left",
          yAlign: "top",
        },
      },
    };
    return options;
  };

  const getDoughnutChartOptions = (barTitle) => {
    const options = {
      responsive: true,
      plugins: {
        legend: {
          display: false,
          position: "bottom",
          style: { display: "block" },
        },
        datalabels: {
          color: "#FFFFFF",
          font: {
            size: 10,
          },
          opacity: 0.74,
          anchor: "center",
          align: "center",
        },
        tooltip: {
          xAlign: "center",
          yAlign: "bottom",
        },
      },
    };
    return options;
  };
  const trendingShowsColumns = [
    {
      title: "",
      key: "videoImgPath",
      dataIndex: "videoImgPath",
      render: (image, record) => {
        return (
          <div className="cast-img">
            <figure className="cast-img-figure">
              <img
                className="cast-img-path"
                src={baseUrl + record?.videoImgPath}
                alt="Actor"
              />
            </figure>
          </div>
        );
      },
    },
    {
      title: "TITLE",
      key: "videoTitle",
      dataIndex: "videoTitle",
    },
    {
      title: "GENRE",
      key: "videoGenre",
      dataIndex: "videoGenre",
    },
    {
      title: "CATEGORY",
      key: "videoCategory",
      dataIndex: "videoCategory",
    },
    {
      title: "NO.OF VIEWS",
      key: "countOfViews",
      dataIndex: "countOfViews",
    },
  ];

  function getWeaklyViewsDaysCount(days) {
    const reg = /[0-9]/;
    if (reg.test(days)) {
      setWeaklyViewsDaysCount(days);
    } else {
    }
  }
  function getHourlyViewsDaysCount(days) {
    setHourlyViewsDaysCount(days);
  }
  function getTrendingShowsDaysCount(days) {
    setTrendingShowsDaysCount(days);
  }
  return (
    <section className="users ">
      <div className="users__all-contents ">
        <div className="users__content">
          <div className="dashboard ">
            <div className="dashboard__section ">
              <Header title="Dashboard" />
            </div>
            <div className="dashboard__section ">
              <div className=" dashboard__overview wd-100 flex dir-col pad-x-25  ">
                <div className="dashboard__overview-header wd-100 ">
                  <SubHeader bgColor="#F58234" title="Overview" />
                </div>
                <div className="dashboard__overview-data wd-100 mrg-ys-20 flex dir-row justify-between items-center ">
                  <div className="dashboard__overview-card ">
                    <div className="dashboard__card-icon ">
                      <img src={subscribersIcon} alt="subscribers" />
                    </div>
                    <div className="dashboard__card-title ">
                      <span>Total Subscribers</span>
                    </div>
                    <div className="dashboard__card-quantity ">
                      <span>{totalSubscribers}</span>
                    </div>
                  </div>
                  <div className="dashboard__overview-card ">
                    <div className="dashboard__card-icon ">
                      <img src={incomeIcon} alt="subscribers" />
                    </div>
                    <div className="dashboard__card-title ">
                      <span>Total value of income</span>
                    </div>
                    <div className="dashboard__card-quantity ">
                      <span>{totalValueOfIncome}</span>
                    </div>
                  </div>
                  <div className="dashboard__overview-card ">
                    <div className="dashboard__card-icon ">
                      <img src={viewsIcon} alt="subscribers" />
                    </div>
                    <div className="dashboard__card-title ">
                      <span>Total Num. of views</span>
                    </div>
                    <div className="dashboard__card-quantity ">
                      <span>{totalNumOfViews}</span>
                    </div>
                  </div>
                </div>
                {/* movie overview */}
                <div className="tv-shows__overview justify-around ">
                  <div className="tv-shows__card ">
                    <div className="tv-shows__overview-card-title  ">
                      <div className="tv-shows__overview-card-title-icon ">
                        <img src={tvShowsIcon} alt="" />
                      </div>
                      <div className="tv-shows__overview-card-title-data">
                        Movies
                      </div>
                    </div>
                    <div className="tv-shows__overview-card-details">
                      {moviesNumber}
                    </div>
                    <div className="tv-shows__overview-color tv-shows-color"></div>
                  </div>
                  <div className="tv-shows__card card-views">
                    <div className="tv-shows__overview-card-title">
                      <div className="tv-shows__overview-card-title-icon">
                        <img src={MovieViewsIcon} alt="" />
                      </div>
                      <div className="tv-shows__overview-card-title-data">
                        Views
                      </div>
                    </div>
                    <div className="tv-shows__overview-card-details">
                      {movieViews}
                    </div>
                    <div className="tv-shows__overview-color views"></div>
                  </div>
                </div>
                {/* series overview */}
                <div className="tv-shows__overview justify-around ">
                  <div className="tv-shows__card ">
                    <div className="tv-shows__overview-card-title  ">
                      <div className="tv-shows__overview-card-title-icon ">
                        <img src={tvShowsIcon} alt="" />
                      </div>
                      <div className="tv-shows__overview-card-title-data">
                        Series
                      </div>
                    </div>
                    <div className="tv-shows__overview-card-details">
                      {seriesNumber}
                    </div>
                    <div className="tv-shows__overview-color tv-shows-color"></div>
                  </div>
                  <div className="tv-shows__card card-views">
                    <div className="tv-shows__overview-card-title">
                      <div className="tv-shows__overview-card-title-icon">
                        <img src={MovieViewsIcon} alt="" />
                      </div>
                      <div className="tv-shows__overview-card-title-data">
                        Views
                      </div>
                    </div>
                    <div className="tv-shows__overview-card-details">
                      {seriesViews}
                    </div>
                    <div className="tv-shows__overview-color views"></div>
                  </div>
                </div>
              </div>
            </div>

            <div className="dashboard__section   ">
              <div className="dashboard__weakly-views wd-100 flex justify-between ">
                <div className="dashboard__bar-chart  ">
                  <div className="dashboard__view-subHeader  mrg-ys-5 mrg-ye-15 ">
                    <SubHeader
                      bgColor="#27A2D9"
                      title="Weakly views"
                      titleColor="#1A1D1F"
                      history="weakly-views"
                      handleHistory={getWeaklyViewsDaysCount}
                      method="days"
                    />
                  </div>
                  <div className="dashboard__view-chart ">
                    <BarChart
                      data={barData}
                      options={getBarsChartOptions("Weakly Views")}
                    />
                  </div>
                </div>
                <div
                  className="
                    dashboard__doughnut-chart flex dir-col items-center content-start pad-25"
                >
                  <div className="dashboard__doughnut  relative  ">
                    <p className="dash-cat absolute">Categories</p>
                    <DoughnutChart
                      className="absolute"
                      data={categoryData}
                      options={getDoughnutChartOptions("Alaa")}
                    />
                  </div>
                  <div className="dashboard__doughnut-data wd-100  flex dir-col items-start ">
                    <div className="dashboard__doughnut-data-content ">
                      {categoryDataSet &&
                        categoryDataSet?.map((item, index) => {
                          return (
                            <div
                              className="dashboard__category-titles"
                              key={index}
                            >
                              <div
                                className="dashboard__title-color"
                                style={{ backgroundColor: item?.color }}
                              ></div>
                              <div className="dashboard__title-name">
                                {item?.name}
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="dashboard__section  ">
              <div className="dashboard__hourly-views  ">
                <div className="dashboard__view-subHeader  ">
                  <SubHeader
                    bgColor="#31AA4A"
                    title="Views By Hours"
                    titleColor="#1A1D1F"
                    history="hourly-views"
                    handleHistory={getHourlyViewsDaysCount}
                    method="hours"
                  />
                </div>
                <div className="dashboard__view-chart  ">
                  <LineChart
                    data={lineData}
                    options={getLineChartOptions("")}
                  />{" "}
                </div>
              </div>
            </div>
            <div className="dashboard__section  ">
              <div className="dashboard__hourly-views ">
                <div className="dashboard__trending   wd-100 ">
                  <div className="dashboard__view-subHeader  ">
                    <SubHeader
                      bgColor="gradient"
                      title="Trending Shows"
                      titleColor="#1A1D1F"
                      history="trending-shows"
                      handleHistory={getTrendingShowsDaysCount}
                      method="days"
                    />
                  </div>
                  <div className="dashboard__table  ">
                    <Table
                      rowKey={(row) => row?.videoId}
                      pagination={{
                        defaultPageSize: "7",
                        responsive: true,
                        hideOnSinglePage: true,
                      }}
                      className="general-table"
                      columns={trendingShowsColumns}
                      dataSource={trendingShowsData}
                      rowClassName={(record, index) =>
                        index % 2 === 0 && "background-even-records "
                      }
                      scroll={{ x: true }}
                      loading={loading}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Dashboard;
