import { Col, DatePicker, Form, Input, Modal, Row } from "antd";
import React, { useState } from "react";
import moment from "moment";
import SecondaryButton from "modules/SubComponents/Buttons/SecondaryButton";
import PrimaryButton from "modules/SubComponents/Buttons/PrimaryButton";
import { useEffect } from "react";
import { addFreeDaysForUsers } from "Network";
import { toastError, toastSuccess } from "helpers/toasters";

function AddFreeDaysModal({
  addModalVisible,
  userId,
  isUser,
  SubHeader,
  primaryBtnName,
  secondaryBtnName,
  onCancel,
}) {
  const [loading, setLoading] = useState(false);
  const [freeDaysPayload, setFreeDaysPayload] = useState({
    startingDate: "",
    duration: "",
    allUsers: null,
    userId: "",
  });
  const [startingDateError, setStartingDateError] = useState(false);
  const [durationError, setDurationError] = useState(false);

  useEffect(() => {
    let allFreeDaysData = { ...freeDaysPayload };
    allFreeDaysData.startingDate = "";
    allFreeDaysData.duration = "";
    if (isUser && userId) {
      allFreeDaysData.allUsers = false;
      allFreeDaysData.userId = userId;
    } else {
      allFreeDaysData.allUsers = true;
      delete allFreeDaysData.userId;
    }
    setFreeDaysPayload(allFreeDaysData);
    setStartingDateError(false);
    setDurationError(false);
    //eslint-disable-next-line
  }, [addModalVisible]);

  const handleDisabledDates = (current) => {
    return current && current.isBefore(moment().format("YYYY-MM-DD"));
  };

  const handleStartingDateOnChange = (date) => {
    let allFreeDaysData = { ...freeDaysPayload };
    if (date) {
      allFreeDaysData.startingDate = moment(date).toISOString();
      setFreeDaysPayload(allFreeDaysData);
    }
    setStartingDateError(false);
  };
  const handleOnChangeDuration = (e) => {
    let allFreeDaysData = { ...freeDaysPayload };
    allFreeDaysData.duration = +e.target.value;
    setFreeDaysPayload(allFreeDaysData);
    setDurationError(false);
  };

  const addFreeDays = (payload) => {
    addFreeDaysForUsers(
      payload,
      (success) => {
        if (success?.isSuccess) {
          toastSuccess(success.message);
          setLoading(false);
          onCancel();
        } else {
          toastError(success.err_msg);
          setLoading(false);
        }
      },
      (fail) => {
        if (fail?.data) {
          toastError(fail.data?.err_msg);
          setLoading(false);
        } else {
          toastError();
          setLoading(false);
        }
      }
    );
  };

  const onFinish = () => {
    let hasError = [];
    if (freeDaysPayload?.startingDate === "") {
      setStartingDateError(true);
      hasError.push("dateError");
    }
    if (freeDaysPayload?.duration === "") {
      setDurationError(true);
      hasError.push("durationError");
    }
    if (hasError?.length === 0) {
      let payload = { ...freeDaysPayload };
      setLoading(true);
      //call api and determind if only for one user or for all users
      addFreeDays(payload);
    }
  };
  return (
    addModalVisible && (
      <Modal
        // visible={addModalVisible}
        open={addModalVisible}
        title={SubHeader}
        footer={false}
        closable={false}
      >
        <Row justify={"center"}>
          <Col>
            <span
              style={{
                fontSize: "25px",
                fontWeight: "regular",
                color: "#000000",
                letterSpacing: "-.5px",
              }}
            >
              Choose the starting date and duration
            </span>
          </Col>
        </Row>
        <Form onFinish={onFinish} className="mrg-x-auto mrg-y-20  ">
          <Row gutter={36} justify={"center"}>
            <Col span={12}>
              <label className="free-days-label">Starting Date</label>
              <DatePicker
                onChange={handleStartingDateOnChange}
                className={`search__searchField wd-100 pad-y-10`}
                disabledDate={(current) => handleDisabledDates(current)}
              />
              {startingDateError && (
                <span className="episode-errors">
                  Please select starting date
                </span>
              )}
            </Col>
            <Col span={12}>
              <label className="free-days-label">{`Duration (Number of Days)`}</label>
              <Input
                type={"number"}
                className="search__searchField wd-100 pad-y-10"
                onChange={handleOnChangeDuration}
                value={freeDaysPayload?.duration}
              />
              {durationError && (
                <span className="episode-errors">Please enter duration</span>
              )}{" "}
            </Col>
          </Row>
          <Row>
            <div className="popups-buttons px-5 mrg-ys-100">
              <SecondaryButton
                buttonName={secondaryBtnName}
                onClick={onCancel}
              />
              <PrimaryButton
                loading={loading}
                buttonName={primaryBtnName}
                type="submit"
              />
            </div>
          </Row>
        </Form>
      </Modal>
    )
  );
}

export default AddFreeDaysModal;
