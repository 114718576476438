import React, { useState } from "react";
import { Modal } from "antd";
import PrimaryButton from "modules/SubComponents/Buttons/PrimaryButton";
import SecondaryButton from "modules/SubComponents/Buttons/SecondaryButton";
import { useEffect } from "react";

function DeleteModal({
  deleteModalVisible,
  SubHeader,
  categoryHasVideos,
  icon,
  msg,
  primaryBtnName,
  secondaryBtnName,
  onCancel,
  action,
  loading,
  selectedPage,
  searchValue,
  primarybtnappear,
}) {
  const showPrimarybtn = primarybtnappear !== false;

  const [loadingModal, setLoadingModal] = useState();
  useEffect(() => {
    setLoadingModal(loading);
  }, [loading]);

  const handleOnCancel = () => {
    if (selectedPage || searchValue) {
      onCancel(searchValue, selectedPage);
    } else {
      onCancel();
    }
  };
  return (
    deleteModalVisible && (
      <Modal
        visible={deleteModalVisible}
        title={SubHeader}
        className='msg-modal text-center'
        footer={[]}
        closable={false}
      >
        {!categoryHasVideos && <img src={icon} alt='icon' />}
        <div className='popups-msg '>{msg}</div>
        {
          <div
            className={categoryHasVideos ? "d-none" : "popups-buttons px-5 "}
          >
            <SecondaryButton
              buttonName={secondaryBtnName}
              onClick={handleOnCancel}
            />
            {showPrimarybtn && (
              <PrimaryButton
                buttonName={primaryBtnName}
                loading={loadingModal}
                onClick={action}
              />
            )}
          </div>
        }
      </Modal>
    )
  );
}
export default DeleteModal;
