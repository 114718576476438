import { Space, Table } from "antd";
import Header from "modules/SubComponents/Header";
import SubHeader from "modules/SubComponents/SubHeader";
import React, { useState, useEffect } from "react";
import DeleteModal from "modules/Modals/DeleteModal";
import warningIcon from "assets/icons/warning.svg";
import deleteIcon from "assets/icons/deleteAvatar.svg";
import editIcon from "assets/icons/pen.svg";
import {
  deleteCategoryById,
  getAllCategories,
  getAllCategoriesForHome,
} from "Network";
import { toastError, toastSuccess } from "helpers/toasters";
// import EditCategoryModal from "modules/Categories/EditCategoryModal";
import { searchFunction } from "helpers/helpfulFunctions";
import { useLocation } from "react-router-dom";
import AddEditCategoryModal from "modules/Categories/AddEditCategoryModal";

function Categories() {
  const [categoryId, setCategoryId] = useState(null);
  const [categoryData, setCategoryData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [categoryHasVideos, setCategoryHasVideos] = useState(false);
  const [stopModalLoading, setStopModalLoading] = useState(false);
  const [renderMe, setRenderMe] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [searchVal, setSearchVal] = useState("");
  const [deleteModalLoading, setDeleteModalLoading] = useState();
  const [dataSource, setDataSource] = useState([]);
  const [searchResult, setSearchResult] = useState([]);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [addModalVisible, setAddModalVisible] = useState(false);
  const location = useLocation();
  const [selectedPage, setSelectedPage] = useState();

  useEffect(() => {
    if (location?.state?.selectedPage) {
      setSelectedPage(location.state.selectedPage);
    } else {
      setSelectedPage(1);
    }
  }, [location]);

  useEffect(() => {
    getAllCategoriesOnSystem();
  }, [renderMe]);

  useEffect(() => {
    const searchColumns = ["nameEn", "nameAr", "genre"];
    if (searchVal?.length > 0) {
      setSearchResult(searchFunction(dataSource, searchVal, searchColumns));
    }
    //eslint-disable-next-line
  }, [searchVal]);

  useEffect(() => {
    const searchColumns = ["nameEn", "nameAr", "genre"];
    setSearchResult(searchFunction(dataSource, searchValue, searchColumns));
    //eslint-disable-next-line
  }, [searchValue]);

  const columns = [
    {
      title: "CATEGORY NAME",
      key: "categoryName",
      dataIndex: "nameEn",
    },
    { title: "GENRE", key: "genre", dataIndex: "genre" },
    {
      title: "ACTION",
      dataIndex: "action",
      render: (_, record) => {
        return (
          <Space size={30} className="cast-actions  ">
            <div className="cast-actions-delete ">
              <img
                role="button"
                src={deleteIcon}
                alt=""
                onClick={() => handleShowDeleteModal(record)}
              />
            </div>
            <div className="cast-actions-edit ">
              <img
                role="button"
                src={editIcon}
                alt=""
                onClick={() => handleShowEditModal(record)}
              />
            </div>
          </Space>
        );
      },
    },
  ];

  const getAllCategoriesOnSystem = (search) => {
    getAllCategories(
      (success) => {
        if (success?.isSuccess) {
          setDataSource(success.data);
          setLoading(false);
          if (search.length > 0) {
            const searchColumns = ["nameEn", "nameAr", "genre"];
            setSearchResult(
              searchFunction(success.data, search, searchColumns)
            );
          }
        } else {
          toastError(success.err_msg);
          setLoading(false);
        }
      },
      (fail) => {
        toastError(fail.err_msg);
        setLoading(false);
      }
    );
  };
  function onCancel(search = "", currentPage = 1) {
    setStopModalLoading(true);
    setDeleteModalVisible(false);
    setAddModalVisible(false);
    setEditModalVisible(false);
    setDeleteModalLoading(false);
    setCategoryHasVideos(false);
    // setRenderMe((prev) => !prev);
    setSelectedPage(currentPage);
    setSearchVal(search);
    if (search?.length > 0) {
      getAllCategoriesOnSystem(search);
    } else {
      getAllCategoriesOnSystem();
    }
  }

  function handleDeleteCategory() {
    setDeleteModalLoading(true);
    deleteCategoryById(
      { categoryId },
      (success) => {
        if (success?.isSuccess) {
          toastSuccess(success.message);
          getAllCategoriesOnSystem(searchValue);
          onCancel(searchValue, selectedPage);
        } else {
          setCategoryHasVideos(true);
        }
      },
      (fail) => {
        if (fail?.data) {
          toastError(fail.data?.err_msg);
          onCancel(searchValue, selectedPage);
        }
        toastError();
        onCancel(searchValue, selectedPage);
      }
    );
  }

  function handleShowEditModal(record) {
    setAddModalVisible(true);
    setCategoryData(record);
  }

  function handleShowDeleteModal(record) {
    setDeleteModalVisible(true);
    setCategoryId(record?.id);
  }

  function handleShowAddModal() {
    setStopModalLoading(false);
    setCategoryData(null);
    setAddModalVisible(true);
  }
  const handleGetSearchValueFromHeader = (value) => {
    setSearchValue(value);
  };
  const handlePageOnChange = (page) => {
    setSelectedPage(page);
  };
  return (
    <section className="users ">
      <div className="users__all-contents ">
        <div className="users__content">
          <div className="users__usersData">
            <Header
              title="Categories"
              plusButtonName="Add New Category"
              plusButtonAction={handleShowAddModal}
              search="Find a category"
              searchVal={searchValue?.length > 0 ? searchValue : ""}
              getSearchedFromHeader={handleGetSearchValueFromHeader}
            />
            <div className="cast-data__table  grid-table ">
              <Table
                rowKey={(row) => row?.id}
                className=""
                columns={columns}
                dataSource={searchValue ? searchResult : dataSource}
                rowClassName={(record, index) =>
                  index % 2 === 0 && "background-even-records "
                }
                scroll={{ x: 600 }}
                loading={loading}
                pagination={{
                  defaultPageSize: "7",
                  responsive: true,
                  hideOnSinglePage: true,
                  onChange: handlePageOnChange,
                  current: selectedPage,
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <DeleteModal
        deleteModalVisible={deleteModalVisible}
        loading={deleteModalLoading}
        categoryHasVideos={categoryHasVideos}
        SubHeader={
          <SubHeader
            bgColor="#F58234"
            title="Delete Category"
            closeButton
            onCancel={(searchValue, selectedPage) =>
              onCancel(searchValue, selectedPage)
            }
          />
        }
        icon={warningIcon}
        msg={
          categoryHasVideos
            ? "You cannot delete this category because there are some videos added to this category"
            : "Are you sure that you want to delete this category?"
        }
        onCancel={(searchValue, selectedPage) =>
          onCancel(searchValue, selectedPage)
        }
        action={handleDeleteCategory}
        secondaryBtnName="Cancel"
        primaryBtnName="Delete"
        searchValue={searchValue?.length > 0 ? searchValue : ""}
        selectedPage={selectedPage}
      />
      <AddEditCategoryModal
        addModalVisible={addModalVisible}
        // searchValue={searchValue?.length > 0 ? searchValue : ""}
        selectedPage={selectedPage}
        SubHeader={
          <SubHeader
            bgColor="#F58234"
            title={categoryData ? "Edit Category" : "Add New Category"}
            searchValue={searchValue?.length > 0 ? searchValue : ""}
            selectedPage={selectedPage}
            closeButton
            onCancel={(searchValue, selectedPage) =>
              onCancel(searchValue, selectedPage)
            }
          />
        }
        stopModalLoading={stopModalLoading}
        onCancel={(searchValue, selectedPage) =>
          onCancel(searchValue, selectedPage)
        }
        primaryBtnName={categoryData ? "Save Changes" : "Add"}
        secondaryBtnName={categoryData ? "Discard" : "Cancel"}
        oldData={categoryData}
        searchValue={searchValue?.length > 0 ? searchValue : ""}
        // selectedPage={selectedPage}
      />
    </section>
  );
}

export default Categories;
