import React from "react";
import { Button, Form } from "antd";
import { PlusOutlined } from "@ant-design/icons";

function SecondaryPlusButton({ buttonName, action, styling, type }) {
  return (
    <Form.Item>
      <Button
        onClick={action}
        htmlType={type ? type : "button"}
        className="secondary-plus-button"
        icon={<PlusOutlined />}
      >
        {buttonName}
      </Button>
    </Form.Item>
  );
}

export default SecondaryPlusButton;
