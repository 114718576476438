import { Button, Col, Divider, Form, Input, Progress, Row, Select } from "antd";
import SecondaryPlusButton from "modules/SubComponents/Buttons/SecondaryPlusButton";
import Header from "modules/SubComponents/Header";
import CustomFileUploader from "modules/SubComponents/Upload/CustomFileUploader";
import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { baseUrl } from "services";
import uploadButton from "assets/icons/uploadButton.svg";
import editIcon from "assets/icons/pen.svg";
import uploadImageIcon from "assets/icons/upImageIcon.svg";
import PrimaryButton from "modules/SubComponents/Buttons/PrimaryButton";
import { toastError, toastSuccess } from "helpers/toasters";
// import AddPartToMovie from "./AddPartToMovie";
import AddPartM3u8 from "./AddPartM3u8";
import {
  addHeaderToTVShows,
  editMovieHeader,
  getAllActors,
  getAllCategoriesByGenreId,
  getAllVideosDetailsForMovie,
} from "Network";
import { useLocation } from "react-router-dom";
import { dropdownStyle } from "helpers/helpfulFunctions";
import DraggerUploader from "modules/SubComponents/Upload/DraggerUploader";
import AddPartToMovie from "./AddPartToMovie";

function AddNewM3U8Movie() {
  const { languagesOnSystem, partsOnSystem } = useSelector(
    (state) => state?.staticData?.staticDataOnSystem
  );
  const [mode, setMode] = useState("add");
  const [addMovie, setAddMovie] = useState([
    {
      videoHeaderId: "",
      title: "",
      genreId: "",
      categoryId: "",
      partsCast: [{ partId: partsOnSystem[0].indexId, castIds: [] }],
      imgPath: "",
      isSeries: false,
    },
  ]);
  const [addMovieErrors, setAddMovieErrors] = useState([
    {
      hasError: false,
      error: "Please provide title",
    },
    {
      hasError: false,
      error: "Please select genre",
    },
    {
      hasError: false,
      error: "Please select category",
    },
    {
      parts: [{ hasError: false, error: "Please select part" }],
    },
    {
      casts: [{ hasError: false, error: "Please select casts" }],
    },
    {
      hasError: false,
      error: "Please upload an  image",
    },
  ]);
  const [allMovieDataWithVideos, setAllMovieDataWithVideos] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isMovieAdded, setIsMovieAdded] = useState(false);
  const [movieAddedId, setMovieAddedId] = useState(null);
  const [audioList, setAudioList] = useState([]);
  const [subtitleList, setSubtitleList] = useState([]);
  const [partsCastList, setPartsCastList] = useState([]);
  const [partsCount, setPartsCount] = useState(null);
  const [categoriesByGenreId, setCategoriesByGenreId] = useState([]);
  const [allActorsOnSystem, setAllActorsOnSystem] = useState([]);
  const [progressPercent, setProgressPercent] = useState(0);
  const [showProgressBar, setShowProgressBar] = useState(false);

  const location = useLocation();
  const { genreId, genreName } = location?.state;

  useEffect(() => {
    let allMovieData = [...addMovie];
    let allCasts = [];
    getAllActors(
      (success) => {
        if (success?.isSuccess) {
          success.data.forEach((cast) =>
            allCasts.push({ label: cast?.name, value: cast?.id })
          );
          setAllActorsOnSystem(allCasts);
        }
      },
      (fail) => {
        if (fail.data) {
          toastError(fail.data.err_msg);
        }
        toastError();
      }
    );

    getAllCategoriesByGenreId(
      { genreId },
      (success) => {
        if (success?.isSuccess) {
          setCategoriesByGenreId(success?.data);
        }
      },
      (fail) => {
        if (fail?.data?.error) {
          toastError(fail.data.err_msg);
        }
      }
    );

    allMovieData[0].genreId = genreId;
    setAddMovie(allMovieData);

    //eslint-disable-next-line
  }, []);

  const handleChangeTitle = (e) => {
    let allMovie = [...addMovie];
    allMovie[0].title = e.target.value;
    setAddMovie(allMovie);
    let allMovieErrors = [...addMovieErrors];
    allMovieErrors[0].hasError = false;
    setAddMovieErrors(allMovieErrors);
  };

  const handleSelectCategory = (categoryId) => {
    if (categoryId?.length > 0) {
      let allMovie = [...addMovie];
      allMovie[0].categoryId = categoryId;
      setAddMovie(allMovie);
      let allMovieErrors = [...addMovieErrors];
      allMovieErrors[2].hasError = false;
      setAddMovieErrors(allMovieErrors);
    }
  };

  const handleSelectPart = (partId, partCastIndex) => {
    // if (partId?.length > 0) {
    let allMovies = [...addMovie];
    const partIsFound = allMovies[0].partsCast.some(
      (part) => part.partId === partId
    );
    if (partIsFound) {
      toastError("This part was selected before!");
      allMovies[0].partsCast[partCastIndex].partId = "";
      setAddMovie(allMovies);
    } else {
      allMovies[0].partsCast[partCastIndex].partId = partId;
      setAddMovie(allMovies);
      let allMovieErrors = [...addMovieErrors];
      allMovieErrors[3].parts[partCastIndex].hasError = false;
      setAddMovieErrors(allMovieErrors);
    }
    // }
  };

  const handleChangeCasts = (castsId, partCastIndex) => {
    if (castsId?.length > 0) {
      let allMovie = [...addMovie];
      allMovie[0].partsCast[partCastIndex].castIds = castsId;
      setAddMovie(allMovie);
      let allMovieErrors = [...addMovieErrors];
      allMovieErrors[4].casts[partCastIndex].hasError = false;
      setAddMovieErrors(allMovieErrors);
    }
  };

  const handleAddAnotherPart = () => {
    let allMovie = [...addMovie];
    allMovie[0].partsCast.push({
      partId: partsOnSystem[allMovie[0].partsCast.length].indexId,
      castIds: [],
    });

    console.log(allMovie[0].partsCast);
    setAddMovie(allMovie);
    let allMovieErrors = [...addMovieErrors];

    allMovieErrors[3].parts.push({
      hasError: false,
      error: "Please select part",
    });
    allMovieErrors[4].casts.push({
      hasError: false,
      error: "Please select casts",
    });
    setAddMovieErrors(allMovieErrors);
  };

  const handleDeletePartCasts = (partCastIndex) => {
    let allMovieData = [...addMovie];
    let allMovieDataErrors = [...addMovieErrors];
    if (allMovieData[0].partsCast?.length > 1) {
      allMovieData[0].partsCast.splice(partCastIndex, 1);
      allMovieData[0].partsCast.forEach((part, partIndex) => {
        if (partIndex >= partCastIndex) {
          part.partId = partsOnSystem[partIndex].indexId;
        }
      });
      setAddMovie(allMovieData);
      allMovieDataErrors[3].parts.splice(partCastIndex, 1);
      allMovieDataErrors[4].casts.splice(partCastIndex, 1);
      setAddMovieErrors(allMovieDataErrors);
    }
  };

  const handleSetFilePath = (filePath) => {
    if (filePath?.length > 0) {
      let allMovie = [...addMovie];
      allMovie[0].imgPath = filePath;
      setAddMovie(allMovie);
      let allMovieErrors = [...addMovieErrors];
      allMovieErrors[5].hasError = false;
      setAddMovieErrors(allMovieErrors);
    }
  };

  const getAllHeaderDataAfterActions = (videoHeaderId) => {
    getAllVideosDetailsForMovie(
      { videoHeaderId },
      (success) => {
        if (success?.isSuccess) {
          setAllMovieDataWithVideos(success.data[0]);
        }
      },
      (fail) => {
        toastError(fail.data.err_msg);
      }
    );
  };

  const validateAddMovie = () => {
    let allMovieErrors = [...addMovieErrors];

    if (addMovie[0]?.title === "") {
      allMovieErrors[0].hasError = true;
      setAddMovieErrors(allMovieErrors);
    }
    if (addMovie[0]?.categoryId === "") {
      allMovieErrors[2].hasError = true;
      setAddMovieErrors(allMovieErrors);
    }
    if (addMovie[0]?.imgPath === "") {
      allMovieErrors[5].hasError = true;
      setAddMovieErrors(allMovieErrors);
    }

    addMovie[0]?.partsCast.map((partCast, partCastIndex) => {
      if (partCast?.partId?.length === 0) {
        allMovieErrors[3].parts[partCastIndex].hasError = true;
        setAddMovieErrors(allMovieErrors);
      }
    });
  };

  const handleOnFinish = () => {
    const checkAddMovieError = [];
    let allMovieData = [...addMovie];
    validateAddMovie();
    let movieHasError = addMovieErrors.some(
      (movieError) => movieError?.hasError
    );
    if (movieHasError) {
      checkAddMovieError.push(movieHasError);
    }
    let partsHasError = addMovieErrors[3]?.parts.some(
      (partError) => partError?.hasError
    );
    if (partsHasError) {
      checkAddMovieError.push(partsHasError);
    }
    let castsHasError = addMovieErrors[4]?.casts.some(
      (castError) => castError?.hasError
    );
    if (castsHasError) {
      checkAddMovieError.push(castsHasError);
    }

    if (checkAddMovieError?.length > 0) {
      toastError("Please, fill all required fields");
    } else {
      setLoading(true);

      const data = addMovie[0];
      if (mode === "add") {
        delete data.videoHeaderId;
        addHeaderToTVShows(
          data,
          (success) => {
            if (success?.isSuccess) {
              toastSuccess(success.message);
              setMovieAddedId(success?.data?.video?._id);
              getAllHeaderDataAfterActions(success?.data?.video?._id);
              allMovieData[0].videoHeaderId = success?.data?.video?._id;
              setAddMovie(allMovieData);
              setMode("edit");
              setIsMovieAdded(true);
              setPartsCastList(data.partsCast);
              setPartsCount(data?.partsCast?.length);
              setLoading(false);
            } else {
              toastError(success.err_msg);
              setLoading(false);
            }
          },
          (fail) => {
            if (fail.data) {
              toastError(fail?.data?.err_msg);
              setLoading(false);
            } else {
              toastError();
              setLoading(false);
            }
          }
        );
      } else {
        editMovieHeader(
          data,
          (success) => {
            if (success?.isSuccess) {
              toastSuccess(success.message);
              setPartsCastList(data?.partsCast);
              getAllHeaderDataAfterActions(success?.data?.videoHeader?._id);
              setPartsCount(data?.partsCast?.length);
              setLoading(false);
            } else {
              toastError(success.err_msg);
              setLoading(false);
            }
          },
          (fail) => {
            if (fail?.data) {
              toastError(fail.data.err_msg);
              setLoading(false);
            } else {
              toastError();
              setLoading(false);
            }
          }
        );
      }
    }
  };

  return (
    <section className="users">
      <div className="users__all-contents ">
        <div className="users__content">
          <div className="tv-shows wd-100 ">
            <Header
              title={
                genreName === "Movies" ? genreName : genreName + " | Movie"
              }
            />
            <Form
              onFinish={handleOnFinish}
              className="tvshows-form wd-100 pad-x-20 "
            >
              <Row gutter={[24, 24]} className="mrg-ye-20">
                <Col span={24}>
                  <div className="new-actor__name new-category ">
                    <label className="view-tvshow-lbl" htmlFor="">
                      Title
                    </label>
                    <Input
                      placeholder="Enter the title"
                      type="text"
                      className="search__searchField py-2"
                      onChange={handleChangeTitle}
                    />
                  </div>
                  {addMovieErrors[0]?.hasError && (
                    <div className="episode-errors">
                      {addMovieErrors[0]?.error}
                    </div>
                  )}
                </Col>
                <Col className="gutter-row" span={24}>
                  <div className="new-actor__name new-category">
                    <label className="view-tvshow-lbl" htmlFor="">
                      Choose Category
                    </label>
                    <Select
                      // disabled={categoryDisabled}
                      placeholder="Choose Category"
                      className="search__searchField py-1 wd-100"
                      onChange={handleSelectCategory}
                      dropdownStyle={dropdownStyle}
                    >
                      {categoriesByGenreId?.map(({ id, name }, index) => {
                        return (
                          <Select.Option key={index} value={id}>
                            {name}
                          </Select.Option>
                        );
                      })}
                    </Select>
                    {addMovieErrors[2]?.hasError && (
                      <div className="episode-errors">
                        {addMovieErrors[2]?.error}
                      </div>
                    )}
                  </div>
                </Col>
                {addMovie[0]?.partsCast.map((partCast, partCastIndex) => (
                  <Fragment key={partCastIndex}>
                    <Col span={10}>
                      <div className="new-actor__name new-category select-genre">
                        <label className="view-tvshow-lbl " htmlFor="">
                          Choose Part
                        </label>
                        <Select
                          placeholder="Choose Part"
                          className="search__searchField py-1 wd-100"
                          onChange={(partId) =>
                            handleSelectPart(partId, partCastIndex)
                          }
                          dropdownStyle={dropdownStyle}
                          value={partCast?.partId || undefined}
                        >
                          {partsOnSystem?.map(({ indexId, episode }, index) => {
                            if (partCastIndex === index) {
                              return (
                                <Select.Option key={index} value={indexId}>
                                  {episode}
                                </Select.Option>
                              );
                            }
                          })}
                        </Select>
                      </div>
                      {addMovieErrors[3]?.parts[partCastIndex].hasError && (
                        <div className="episode-errors">
                          {addMovieErrors[3]?.parts[partCastIndex].error}
                        </div>
                      )}
                    </Col>
                    <Col span={10}>
                      <div className="new-actor__name new-category  select-genre">
                        <label className="view-tvshow-lbl " htmlFor="">
                          Cast of this part
                        </label>
                        <Select
                          mode="multiple"
                          maxTagCount={2}
                          maxTagTextLength={8}
                          placeholder="Choose casts of this Part"
                          className="search__searchField py-1 wd-100"
                          onChange={(castsIds) =>
                            handleChangeCasts(castsIds, partCastIndex)
                          }
                          value={partCast?.castIds || undefined}
                          dropdownStyle={dropdownStyle}
                          // options={arr2222}
                          options={allActorsOnSystem}
                          filterOption={(input, option) =>
                            (option?.label ?? "")
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          filterSort={(optionA, optionB) =>
                            (optionA?.label ?? "")
                              .toLowerCase()
                              .localeCompare(
                                (optionB?.label ?? "").toLowerCase()
                              )
                          }
                        />
                      </div>
                      {addMovieErrors[4]?.casts[partCastIndex].hasError && (
                        <div className="episode-errors">
                          {addMovieErrors[4]?.casts[partCastIndex].error}
                        </div>
                      )}
                    </Col>
                    <Col span={4}>
                      <div className="mrg-xe-20">
                        <label htmlFor=""></label>
                        <Button
                          className="delete-video-button"
                          onClick={() => handleDeletePartCasts(partCastIndex)}
                          disabled={
                            mode === "edit" && partCastIndex < partsCount
                              ? true
                              : partCastIndex === 0
                              ? // : addMovie[0]?.partsCast?.length === 1
                                true
                              : false
                          }
                        >
                          Delete
                        </Button>
                      </div>
                    </Col>
                  </Fragment>
                ))}
              </Row>
              <Row justify="end">
                <Col>
                  <SecondaryPlusButton
                    buttonName={"Add Another Part"}
                    action={handleAddAnotherPart}
                  />
                </Col>
              </Row>
              <Row gutter={[24, 24]} className="mrg-ye-20">
                <Col span={24}>
                  <div className="upload-files">
                    <div className="upload-files__container ">
                      {addMovie[0]?.imgPath && (
                        <figure>
                          <img
                            className="upload-files__upload-file"
                            src={baseUrl + addMovie[0]?.imgPath}
                            alt="actorImage"
                            style={{
                              position: "absolute",
                              zIndex: "3",
                              width: "100%",
                            }}
                          />
                        </figure>
                      )}
                      <DraggerUploader
                        aspect={4 / 3}
                        withCrop
                        formDataType={"img"}
                        acceptedTypes={"image/*"}
                        setShowProgressBar={setShowProgressBar}
                        setProgressPercent={setProgressPercent}
                        setFilePath={handleSetFilePath}
                      >
                        {!addMovie[0]?.imgPath ? (
                          <div
                            role="button"
                            className="upload-files__button"
                            style={{
                              boxShadow: "none",
                              backgroundColor: "transparent",
                              justifyContent: "center",
                              gap: "30px",
                              opacity: addMovie[0]?.imgPath ? "0" : "1",
                              margin: "auto",
                            }}
                          >
                            <div className="upload-files__icon">
                              <img src={uploadButton} alt="" />
                            </div>
                            <div className="upload-files__title">
                              {"Upload Image"}
                            </div>
                            <div className="upload-files__icon">
                              <img src={uploadImageIcon} alt="" />
                            </div>
                          </div>
                        ) : (
                          <div className="upload-edit-icon mrg-x-20">
                            <img role="button" src={editIcon} alt="edit" />
                          </div>
                        )}
                      </DraggerUploader>
                    </div>
                    {addMovieErrors[5]?.hasError && (
                      <div className="episode-errors">
                        {addMovieErrors[5]?.error}
                      </div>
                    )}
                  </div>
                  {showProgressBar && <Progress percent={progressPercent} />}
                </Col>
              </Row>
              <Row justify="end">
                <Col>
                  <PrimaryButton
                    type={"submit"}
                    buttonName={mode === "edit" ? "Save" : "Add"}
                    // disableMe={disableSaveBtn}
                    loading={loading}
                  />
                </Col>
              </Row>
              <Divider
                style={{
                  backgroundColor: "#CEE2F6",
                  height: "2px",
                  opacity: 0.5,
                }}
              />
            </Form>
            {isMovieAdded && (
              // <AddPartToMovie
              <AddPartM3u8
                headerAddedId={movieAddedId}
                audioList={audioList}
                subtitleList={subtitleList}
                partsCastList={partsCastList}
                genreId={genreId}
                genreName={genreName}
                partsCount={partsCount}
              />
            )}
          </div>
        </div>
      </div>
    </section>
  );
}

export default AddNewM3U8Movie;
