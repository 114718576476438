import React, { Fragment, useRef, useState } from "react";
import { Button, Checkbox, Form, Radio, Space } from "antd";
import SecondaryButton from "../Buttons/SecondaryButton";
import { baseUrl } from "services";
import { toastError, toastSuccess } from "helpers/toasters";
import {
  addHomeCategory,
  deleteHomeCategory,
  editHomeCategory,
  getAllCategoriesForHome,
} from "Network";
import { useEffect } from "react";
import DeleteModal from "modules/Modals/DeleteModal";
import SubHeader from "../SubHeader";
import warningIcon from "assets/icons/warning.svg";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

function HomeCategories() {
  const [deleteModalLoading, setDeleteModalLoading] = useState(false);
  const [renderMe, setRenderMe] = useState(false);
  const [deletedCategoryId, setDeletedCategoryId] = useState(null);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [categoriesData, setCategoriesData] = useState([]);
  const indexesRef = useRef(null);

  useEffect(() => {
    categoriesData.length = 0;
    let allCategoriesData = [...categoriesData];
    getAllCategoriesForHome(
      (success) => {
        if (success?.isSuccess) {
          let categoriesHaveVideos = success.data?.filter(
            (catHasVid) => catHasVid?.headers?.length > 0
          );
          categoriesHaveVideos.forEach((cat) => {
            allCategoriesData.push({
              categoryId: cat?.categoryId,
              categoryName: cat?.categoryName,
              genreName: cat?.genreName,
              existInHome: cat?.existInHome,
              willBeInHome: false,
              showIndexes: false,
              homeCategoryId: cat?.homeCategoryId ? cat.homeCategoryId : "",
              homeCategoryIndex: cat?.homeCategoryIndex
                ? cat.homeCategoryIndex
                : "",
              headers: cat?.headers,
              indexBtnIsDisable: !cat?.existInHome,
              submitBtnIsDisable: !cat?.existInHome,
              submitBtnIsLoading: false,
            });
          });
          setCategoriesData(allCategoriesData);
        }
      },
      (fail) => {
        if (fail?.data?.error) {
          toastError(fail.data.err_msg);
        }
      }
    ); //eslint-disable-next-line
  }, [renderMe]);

  const onCancel = () => {
    setDeleteModalVisible(false);
    setDeleteModalLoading(false);
    setRenderMe((prev) => !prev);
  };

  const handelDeleteCategoryFromHomePage = () => {
    setDeleteModalLoading(true);
    deleteHomeCategory(
      { deletedCategoryId },
      (success) => {
        if (success?.isSuccess) {
          toastSuccess(success.message);
          setDeleteModalLoading(false);
          onCancel();
        } else {
          toastError(success.err_msg);
          setDeleteModalLoading(false);
        }
      },
      (fail) => {
        if (fail?.data) {
          toastError(fail.data?.err_msg);
          setDeleteModalLoading(false);
        } else {
          toastError();
          setDeleteModalLoading(false);
        }
      }
    );
  };

  const handleCheckboxOnChange = (e, category, categoryIndex) => {
    let allCategoriesData = [...categoriesData];
    if (category?.existInHome) {
      allCategoriesData.forEach((allCat) => {
        allCat.showIndexes = false;
      });
      setDeletedCategoryId(category?.categoryId);
      setDeleteModalVisible(true);
    } else {
      if (e.target.checked) {
        allCategoriesData[categoryIndex].indexBtnIsDisable = false;
        allCategoriesData[categoryIndex].willBeInHome = true;
      } else {
        allCategoriesData[categoryIndex].willBeInHome = false;
        allCategoriesData[categoryIndex].homeCategoryIndex = "";
        allCategoriesData[categoryIndex].indexBtnIsDisable = true;
        allCategoriesData[categoryIndex].submitBtnIsDisable = true;
        allCategoriesData.forEach((allCat) => {
          allCat.showIndexes = false;
        });
        // allCategoriesData[categoryIndex].showIndexes = false;
      }
    }
    setCategoriesData(allCategoriesData);
  };

  const handleIndexButtonOnClick = (categoryIndex) => {
    let allCategoriesData = [...categoriesData];
    allCategoriesData.forEach((cat, catIndex) => {
      if (categoryIndex !== catIndex) {
        cat.showIndexes = false;
      }
    });
    allCategoriesData[categoryIndex].showIndexes =
      !allCategoriesData[categoryIndex].showIndexes;
    setCategoriesData(allCategoriesData);
  };

  const handleSelectIndex = (e, categoryIndex) => {
    let allCategoriesData = [...categoriesData];
    allCategoriesData[categoryIndex].homeCategoryIndex = e.target.value;
    allCategoriesData[categoryIndex].submitBtnIsDisable = false;
    setCategoriesData(allCategoriesData);
  };

  const handleOnFinish = (category, categoryIndex) => {
    if (category) {
      let allCategoriesData = [...categoriesData];
      allCategoriesData[categoryIndex].submitBtnIsLoading = true;
      setCategoriesData(allCategoriesData);
      if (!category.existInHome) {
        //add
        const data = {
          categoryId: category.categoryId,
          index: category.homeCategoryIndex,
        };
        addHomeCategory(
          data,
          (success) => {
            allCategoriesData[categoryIndex].submitBtnIsLoading = false;
            setCategoriesData(allCategoriesData);
            if (success?.isSuccess) {
              toastSuccess(success.message);
              setRenderMe((prev) => !prev);
            } else {
              toastError(success.err_msg);
            }
          },
          (fail) => {
            allCategoriesData[categoryIndex].submitBtnIsLoading = false;
            setCategoriesData([...allCategoriesData]);
            if (fail?.data) {
              toastError(fail.data.err_msg);
            } else {
              toastError();
            }
          }
        );
      } else {
        const data = {
          categoryId: category.categoryId,
          homeCategoryId: category.homeCategoryId,
          index: category.homeCategoryIndex,
        };
        editHomeCategory(
          data,
          (success) => {
            allCategoriesData[categoryIndex].submitBtnIsLoading = false;
            setCategoriesData(allCategoriesData);
            if (success?.isSuccess) {
              toastSuccess(success.message);
              setRenderMe((prev) => !prev);
            } else {
              toastError(success.err_msg);
            }
          },
          (fail) => {
            allCategoriesData[categoryIndex].submitBtnIsLoading = false;
            setCategoriesData([...allCategoriesData]);
            if (fail?.data) {
              toastError(fail.data.message);
            } else {
              toastError();
            }
          }
        );
      }
    }
  };
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 1200 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 1200, min: 991 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 991, min: 768 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 768, min: 0 },
      items: 2,
    },
  };
  // const responsive = {
  //   superLargeDesktop: {
  //     breakpoint: { max: 4000, min: 3000 },
  //     items: 5,
  //   },
  //   desktop: {
  //     breakpoint: { max: 3000, min: 1024 },
  //     items: 3,
  //   },
  //   tablet: {
  //     breakpoint: { max: 1024, min: 464 },
  //     items: 2,
  //   },
  //   mobile: {
  //     breakpoint: { max: 464, min: 0 },
  //     items: 1,
  //   },
  // };

  // function useOnClickOutside(ref, handler) {
  //   useEffect(() => {
  //     function handleClickOutside(event) {
  //       if (ref.current && !ref.current.contains(event.target)) {
  //         setShowIndexes(false);
  //       }
  //     }
  //     document.addEventListener("mousedown", handleClickOutside);
  //     document.addEventListener("touchstart", handleClickOutside);
  //     return () => {
  //       document.removeEventListener("mousedown", handleClickOutside);
  //       document.removeEventListener("touchstart", handleClickOutside);
  //     };
  //   }, [ref, handler]);
  // }

  // useOnClickOutside(indexesRef);
  return (
    <Fragment>
      {categoriesData?.map((category, categoryIndex) => {
        return (
          <Form
            onFinish={() => handleOnFinish(category, categoryIndex)}
            className="contents"
            key={categoryIndex}
          >
            <div className="contents__options ">
              <span className="contents__subtitle ">
                {`${category?.categoryName} ( ${category?.genreName} ) `}
              </span>
              <div className="contents__setting  " ref={indexesRef}>
                <Space size={20}>
                  <Checkbox
                    onChange={(e) =>
                      handleCheckboxOnChange(e, category, categoryIndex)
                    }
                    checked={
                      category?.existInHome
                        ? category.existInHome
                        : category.willBeInHome
                    }
                  ></Checkbox>

                  <SecondaryButton
                    id={category?.categoryId}
                    buttonName="Index"
                    btnWidth="78px"
                    onClick={() => handleIndexButtonOnClick(categoryIndex)}
                    disableMe={category?.indexBtnIsDisable ? true : false}
                  />
                </Space>

                {category?.showIndexes && (
                  <div className="contents__indexes  ">
                    <div
                      className="contents__indexes-container"
                      id="index-scroll"
                    >
                      <Radio.Group
                        onChange={(e) => handleSelectIndex(e, categoryIndex)}
                        value={category?.homeCategoryIndex}
                      >
                        {categoriesData?.map((item, idx) => (
                          <div className="contents__index " key={idx}>
                            <div className={`contents__radio`}>
                              <Radio value={idx + 1}>{idx + 1}</Radio>
                            </div>
                          </div>
                        ))}
                      </Radio.Group>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <Carousel
              infinite={true}
              responsive={responsive}
              draggable={true}
              keyBoardControl={true}
              containerClass="carousel-container"
            >
              {category?.headers?.map((card, index) => {
                return (
                  <div className="contents__card " key={index}>
                    <figure className="contents__card-figure   ">
                      <img
                        className="contents__card-image "
                        src={baseUrl + card?.imgPath}
                        alt="category"
                      />
                    </figure>
                    <div className="contents__card-details ">
                      <span className="contents__card-title  ">
                        {card?.title}
                      </span>
                      <span className="contents__card-title   "></span>
                    </div>
                  </div>
                );
              })}
            </Carousel>
            <div className="contents__save-index">
              <Button
                htmlType={"submit"}
                loading={category.submitBtnIsLoading}
                className="primary-popup-button "
                disabled={category?.submitBtnIsDisable}
              >
                Save
              </Button>
            </div>
          </Form>
        );
      })}
      <DeleteModal
        deleteModalVisible={deleteModalVisible}
        loading={deleteModalLoading}
        SubHeader={
          <SubHeader
            title="Delete Category"
            bgColor="#F58234"
            closeButton
            onCancel={onCancel}
          />
        }
        icon={warningIcon}
        msg="Are you sure you want to delete this category from homepage?"
        onCancel={onCancel}
        action={handelDeleteCategoryFromHomePage}
        secondaryBtnName="Cancel"
        primaryBtnName="Delete"
      />
    </Fragment>
  );
}

export default HomeCategories;
