import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import deleteIcon from "assets/icons/deleteAvatar.svg";
import editIcon from "assets/icons/pen.svg";
import Header from "modules/SubComponents/Header";
import { Space, Table } from "antd";
import { toastError, toastSuccess } from "helpers/toasters";
import { deleteQuestions, getAllQuestions } from "Network";
import { searchFunction } from "helpers/helpfulFunctions";
import DeleteModal from "modules/Modals/DeleteModal";
import SubHeader from "modules/SubComponents/SubHeader";
import warningIcon from "assets/icons/warning.svg";
import ROUTES from "constants/NewRoutes";
import moment from "moment";

function Questions() {
  const [loadingQuestions, setLoadingQuestions] = useState(true);
  const [selectedPage, setSelectedPage] = useState();
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [deleteModalLoading, setDeleteModalLoading] = useState(false);
  const [recallGetQuestions, setRecallGetQuestions] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [searchResult, setSearchResult] = useState([]);
  const [questionId, setQuestionId] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const { currentPage, searchVal } = location?.state || {};

  useEffect(() => {
    window.addEventListener("beforeunload", function (event) {
      const navTiming = performance.getEntriesByType("navigation")[0];
      if (navTiming.type === "reload") {
        navigate(ROUTES.QUESTIONS, {
          state: {
            currentPage: 1,
            searchVal: "",
          },
        });
      }
    });
  }, []);

  useEffect(() => {
    if (currentPage) {
      setSelectedPage(currentPage);
    } else {
      setSelectedPage(1);
    }
  }, [currentPage]);

  useEffect(() => {
    getQuestions();
  }, [recallGetQuestions]);

  useEffect(() => {
    if (searchVal?.length > 0) {
      setSearchValue(searchVal);
      getQuestions(searchVal);
    }
  }, [searchVal]);

  useEffect(() => {
    if (dataSource && searchValue) {
      const searchColumns = ["question", "activationDate", "expirationDate"];
      setSearchResult(searchFunction(dataSource, searchValue, searchColumns));
    }
  }, [dataSource, searchValue]);

  const getQuestions = (search) => {
    getAllQuestions(
      (success) => {
        if (success?.isSuccess) {
          setDataSource(success.data);
          setLoadingQuestions(false);
          if (search?.length > 0) {
            const searchColumns = [
              "question",
              "activationDate",
              "expirationDate",
            ];
            setSearchResult(
              searchFunction(success.data, search, searchColumns)
            );
          }
        }
      },
      (fail) => {
        toastError(fail?.err_msg);
      }
    );
  };

  const handleShowDeleteModal = (rowId) => {
    setDeleteModalVisible(true);
    setQuestionId(rowId);
  };

  const handleEditQuestion = (record) => {
    navigate(ROUTES.ADDNEWQUESTION, {
      state: {
        questionId: record?._id,
        selectedPage,
        searchValue,
      },
    });
  };

  const handleAddQuestion = () => {
    navigate(ROUTES.ADDNEWQUESTION, {
      state: { selectedPage },
    });
  };

  const onCancel = () => {
    setDeleteModalVisible(false);
    setDeleteModalLoading(false);
    setRecallGetQuestions((prev) => !prev);
  };

  const handleDeleteQuestion = () => {
    if (questionId) {
      setDeleteModalLoading(true);
      deleteQuestions(
        { questionId },
        (success) => {
          if (success?.isSuccess) {
            toastSuccess(success.message);
            setDeleteModalLoading(false);
            onCancel();
          } else {
            toastError(success.err_msg);
            setDeleteModalLoading(false);
            setDeleteModalVisible(false);
          }
        },
        (fail) => {
          if (fail?.data) {
            toastError(fail.data?.err_msg);
            setDeleteModalLoading(false);
            setDeleteModalVisible(false);
          } else {
            toastError();
            setDeleteModalLoading(false);
            setDeleteModalVisible(false);
          }
        }
      );
    } else {
      toastError("Please,choose question frist ");
    }
  };

  const handlePageOnChange = (page) => {
    setSelectedPage(page);
  };

  const handleGetSearchValueFromHeader = (value) => {
    setSearchValue(value);
  };

  const columns = [
    {
      title: "THE QUESTIONS",
      key: "question",
      dataIndex: "question",
      ellipsis: true, // Add this line to enable ellipsis
      width: 385,
    },
    {
      title: "APPEARANCE DATE",
      key: "activationDate",
      dataIndex: "activationDate",
      render: (_, r) =>
        moment(r?.activationDate).utcOffset(0).format("YYYY-MM-DD HH:mm:ss"),
    },
    {
      title: "EXPIRATION DATE",
      key: "expirationDate",
      dataIndex: "expirationDate",
      render: (_, r) => {
        return moment(r?.expirationDate)
          .utcOffset(0)
          .format("YYYY-MM-DD HH:mm:ss");
      },
    },
    {
      title: "ACTION",
      dataIndex: "action",
      width: "fit-content",
      render: (_, record) => {
        let activationDate = moment(record?.activationDate).startOf("day");
        let currentDate = moment().startOf("day");

        // Check if the activation date is in the future
        if (activationDate.isAfter(currentDate)) {
          return (
            <Space size={20} className="cast-actions">
              <div className="cast-actions-delete">
                <img
                  role="button"
                  src={deleteIcon}
                  alt=""
                  onClick={() => handleShowDeleteModal(record?._id)}
                />
              </div>
              <div className="cast-actions-edit">
                <img
                  role="button"
                  src={editIcon}
                  alt=""
                  onClick={() => handleEditQuestion(record)}
                />
              </div>
            </Space>
          );
        } else {
          return null; // Return null if the activation date is not in the future
        }
      },
    },
  ];

  return (
    <section className="users ">
      <div className="users__all-contents ">
        <div className="users__content">
          <div className="users__usersData">
            <Header
              title="Questions"
              plusButtonName="Add New Question"
              plusButtonAction={handleAddQuestion}
              search="Find a Question"
              getSearchedFromHeader={handleGetSearchValueFromHeader}
              searchVal={searchVal}
            />
            <div className="cast-data__table  grid-table ">
              <Table
                rowKey={(row) => row?._id}
                columns={columns}
                dataSource={searchValue ? searchResult : dataSource}
                rowClassName={(record, index) =>
                  index % 2 === 0 && "background-even-records "
                }
                scroll={{ x: 600 }}
                loading={loadingQuestions}
                pagination={{
                  responsive: true,
                  hideOnSinglePage: false,
                  onChange: handlePageOnChange,
                  current: selectedPage,
                  pageSizeOptions: ["10", "20", "50", "100"],
                  showSizeChanger: true,
                  defaultPageSize: 10,
                }}
              />
            </div>
          </div>
        </div>
      </div>
      {/* modal for delete a Category */}
      <DeleteModal
        deleteModalVisible={deleteModalVisible}
        loading={deleteModalLoading}
        SubHeader={
          <SubHeader
            bgColor="#F58234"
            title="Delete Question"
            closeButton
            onCancel={onCancel}
          />
        }
        icon={warningIcon}
        msg="Are you sure you want to delete this Question?"
        onCancel={onCancel}
        action={handleDeleteQuestion}
        secondaryBtnName="Cancel"
        primaryBtnName="Delete"
      />
    </section>
  );
}

export default Questions;
