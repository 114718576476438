import { Upload } from "antd";
import { customUploadFile } from "Network";
import { toastError } from "helpers/toasters";
import ImgCrop from "antd-img-crop";
import axios from "axios";
import { useState } from "react";
import { useSelector } from "react-redux";

function DraggerUploader({
  setProgressPercent,
  setShowProgressBar,
  setFilePath,
  children,
  acceptedTypes,
  formDataType,
  withCrop,
  aspect,
  disableMe,
  selectedEpisodeIndex,
  episodeUploadingOptions,
  // setVideoOptions,
  ...rest
}) {
  const [cancelToken, setCancelToken] = useState(null);
  const { authorization } = useSelector((state) => state.auth);
  const token = authorization?.token;

  const { Dragger } = Upload;

  const uploadMe = (file) => {
    const chunkSize = 1024 * 1024;
    const data = new FormData();
    data.append(formDataType, file);
    setShowProgressBar(true);
    const source = axios.CancelToken.source();
    setCancelToken(source);

    // if (formDataType === "video") {
    // 	customUploadVideo(formDataType, file);
    // } else {
    customUploadFile(
      { formDataType },
      data,
      (progress) => {
        const { loaded, total } = progress;
        let progressData = (loaded / total) * 100;
        if (progress) {
          if (formDataType === "video") {
            setProgressPercent(progressData);
          } else {
            setProgressPercent(Math.round(progressData));
          }
        }
      },
      (success) => {
        if (success?.isSuccess) {
          if (formDataType === "img") {
            setFilePath(success.data?.filePath);
          } else if (formDataType === "video") {
            setFilePath(
              success.data?.filePath,
              success.data?.lowQualityfilePath,
              success.data?.fileName,
              success.data?.duration
            );
          } else {
            setFilePath(success.data?.filePath, success.data?.fileName);
          }
        }
      },
      (fail) => {
        if (fail) {
          toastError(fail?.data?.err_msg);
        } else {
          // toastError("An error occured during upload");
        }
      }
      // source.token
    );
    // }
  };

  // function customUploader(options) {
  //   const { file } = options;
  //   if (formDataType === "video") {
  //     if (file?.type === "video/mp4") {
  //       uploadMe(file);
  //     } else {
  //       toastError(
  //         "You can not upload a video in a format other than the .MP4"
  //       );
  //     }
  //   } else if (formDataType === "audio") {
  //     if (file?.type === "audio/mpeg") {
  //       uploadMe(file);
  //     } else {
  //       toastError(
  //         "You can not upload an audio in a format other than the .MP3"
  //       );
  //     }
  //   } else {
  //     uploadMe(file);
  //   }
  // }

  function customUploader(options) {
    const { file } = options;
    const allowedTypes = ["image/jpeg", "image/jpg", "image/png"];

    if (!allowedTypes.includes(file.type)) {
      toastError("Only .jpeg, .jpg, and .png files are allowed");
      return;
    } else {
      uploadMe(file);
    }
  }

  const handleBeforeUpload = () => {
    if (
      episodeUploadingOptions?.isUploading &&
      episodeUploadingOptions?.episodeIndex === selectedEpisodeIndex
    ) {
      if (cancelToken) {
        cancelToken.cancel();
        setProgressPercent(0);
        setFilePath("", "", "");
      }
      //Here we must abort previous uploading
      // if (episodeUploadingOptions?.videoOPtions) {
      // episodeUploadingOptions.videoOPtions?.cancel();
      // setProgressPercent(0);
      // setFilePath("", "", "");
      // }
    }
  };

  return (
    <div style={{ width: "100%", height: "100%", borderRadius: "12px" }}>
      {withCrop ? (
        <ImgCrop zoomSlider={true} rotateSlider={false} aspect={2 / 1}>
          <Dragger
            {...(acceptedTypes && { accept: acceptedTypes })}
            customRequest={customUploader}
            showUploadList={false}
            disabled={disableMe}
            {...rest}
            style={{ borderRadius: "12px" }}
            accept="image/png, image/jpeg, image/jpg"
          >
            {children}
          </Dragger>
        </ImgCrop>
      ) : (
        <>
          <Dragger
            {...(acceptedTypes && { accept: acceptedTypes })}
            customRequest={customUploader}
            showUploadList={false}
            disabled={disableMe}
            beforeUpload={handleBeforeUpload}
            accept="image/png, image/jpeg, image/jpg"
            {...rest}
          >
            {children}
          </Dragger>
        </>
      )}
    </div>
  );
}

export default DraggerUploader;

// import { Upload } from "antd";
// import { customUploadFile } from "Network";
// import { toastError } from "helpers/toasters";
// import ImgCrop from "antd-img-crop";
// import axios from "axios";
// import { useState } from "react";
// import { baseUrl } from "services";
// import { useSelector } from "react-redux";
// import Resumable from "resumablejs";

// function DraggerUploader({
//   setProgressPercent,
//   setShowProgressBar,
//   setFilePath,
//   children,
//   acceptedTypes,
//   formDataType,
//   withCrop,
//   aspect,
//   disableMe,
//   selectedEpisodeIndex,
//   episodeUploadingOptions,
//   // setVideoOptions,
// }) {
//   const [cancelToken, setCancelToken] = useState(null);
//   const { authorization } = useSelector((state) => state.auth);
//   const token = authorization?.token;
//   const [dragging, setDragging] = useState(false);

//   const uploadMe = (file) => {
//     const chunkSize = 1024 * 1024;
//     const data = new FormData();
//     data.append(formDataType, file);
//     setShowProgressBar(true);
//     const source = axios.CancelToken.source();
//     setCancelToken(source);

//     customUploadFile(
//       { formDataType },
//       data,
//       (progress) => {
//         const { loaded, total } = progress;
//         let progressData = (loaded / total) * 100;
//         if (progress) {
//           if (formDataType === "video") {
//             setProgressPercent(progressData);
//           } else {
//             setProgressPercent(Math.round(progressData));
//           }
//         }
//       },
//       (success) => {
//         if (success?.isSuccess) {
//           if (formDataType === "img") {
//             setFilePath(success.data?.filePath);
//           } else if (formDataType === "video") {
//             setFilePath(
//               success.data?.filePath,
//               success.data?.lowQualityfilePath,
//               success.data?.fileName,
//               success.data?.duration
//             );
//           } else {
//             setFilePath(success.data?.filePath, success.data?.fileName);
//           }
//         }
//       },
//       (fail) => {
//         if (fail) {
//           toastError(fail?.data?.err_msg);
//         } else {
//           toastError("An error occurred during upload");
//         }
//       }
//       // source.token
//     );
//   };

//   function customUploader(options) {
//     const { file } = options;
//     if (formDataType === "video") {
//       if (file?.type === "video/mp4") {
//         uploadMe(file);
//       } else {
//         toastError("You cannot upload a video in a format other than .MP4");
//       }
//     } else if (formDataType === "audio") {
//       if (file?.type === "audio/mpeg") {
//         uploadMe(file);
//       } else {
//         toastError("You cannot upload an audio in a format other than .MP3");
//       }
//     } else {
//       uploadMe(file);
//     }
//   }

//   const handleBeforeUpload = () => {
//     if (
//       episodeUploadingOptions?.isUploading &&
//       episodeUploadingOptions?.episodeIndex === selectedEpisodeIndex
//     ) {
//       if (cancelToken) {
//         cancelToken.cancel();
//         setProgressPercent(0);
//         setFilePath("", "", "");
//       }
//     }
//   };

//   const handleDragOver = (e) => {
//     e.preventDefault();
//     e.stopPropagation();
//     setDragging(true);
//   };

//   const handleDragEnter = (e) => {
//     e.preventDefault();
//     e.stopPropagation();
//     setDragging(true);
//   };

//   const handleDragLeave = (e) => {
//     e.preventDefault();
//     e.stopPropagation();
//     setDragging(false);
//   };

//   const handleDrop = (e) => {
//     e.preventDefault();
//     e.stopPropagation();
//     setDragging(false);
//     const files = e.dataTransfer.files;
//     if (files && files.length > 0) {
//       customUploader({ file: files[0] });
//     }
//   };

//   return (
//     <div
//       style={{
//         overflow: "hidden",
//         // width: "100%",
//         // height: "100%",
//         display: "flex",
//         flexDirection: "column",
//         alignItems: "center",
//         justifyContent: "center",
//         backgroundColor: dragging ? "#e0e0e0" : "#f0f0f0",
//         borderRadius: "12px",
//         border: dragging ? "2px dashed #007bff" : "2px solid transparent",
//       }}
//       onDragOver={handleDragOver}
//       onDragEnter={handleDragEnter}
//       onDragLeave={handleDragLeave}
//       onDrop={handleDrop}
//     >
//       {withCrop ? (
//         <ImgCrop zoomSlider={true} rotateSlider={false} aspect={aspect}>
//           <Upload
//             {...(acceptedTypes && { accept: acceptedTypes })}
//             customRequest={customUploader}
//             showUploadList={false}
//             disabled={disableMe}
//           >
//             {children}
//           </Upload>
//         </ImgCrop>
//       ) : (
//         <>
//           <Upload
//             {...(acceptedTypes && { accept: acceptedTypes })}
//             customRequest={customUploader}
//             showUploadList={false}
//             disabled={disableMe}
//             beforeUpload={handleBeforeUpload}
//           >
//             {children}
//           </Upload>
//         </>
//       )}
//     </div>
//   );
// }

// export default DraggerUploader;
