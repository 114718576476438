import React, { useEffect, useState } from "react";
import { Button, Col, Input, Row, Select, Tabs } from "antd";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { baseUrl } from "services";
import { toastError } from "helpers/toasters";
import {
  getAllCategoryDataByGenre,
  getAllEpisodsDependsOnSeason,
  getAllVideosDetailsForHeader,
} from "Network";
import Header from "modules/SubComponents/Header";
import SubHeader from "modules/SubComponents/SubHeader";
import ViewEpisodeM3u8 from "./ViewEpisodeM3u8";
import { dropdownStyle } from "helpers/helpfulFunctions";

function ViewTvShowsM3u8() {
  const location = useLocation();
  const {
    selectedHeaderId,
    selectedSeasonId,
    seasonsDependsOnHeaderId,
    genreId,
    genreName,
  } = location?.state;

  const { languagesOnSystem, seasonsOnSystem } = useSelector(
    (state) => state?.staticData?.staticDataOnSystem
  );

  const [allHeaderDataWithVideos, setAllHeaderDataWithVideos] = useState(null);
  const [allEpisodsDependsOnSeason, setAllEpisodsDependsOnSeason] = useState(
    []
  );
  console.log(
    "🚀 ~ ViewTvShowsM3u8 ~ allHeaderDataWithVideos:",
    allHeaderDataWithVideos
  );

  const [allSeasonActors, setAllSeasonActors] = useState();
  const [actors, setActors] = useState();
  const [selectedSeasonBtn, setSelectedSeasonBtn] = useState(null);
  const [categoryName, setCategoryName] = useState(null);

  useEffect(() => {
    if (selectedSeasonId?.length > 0 && selectedHeaderId?.length > 0) {
      getEpisodesByHeaderIdAndSeasonId(selectedHeaderId, selectedSeasonId);
    }
  }, [selectedHeaderId, selectedSeasonId]);

  useEffect(() => {
    if (selectedSeasonId?.length > 0) {
      setSelectedSeasonBtn(selectedSeasonId);
    }
  }, [selectedSeasonId]);

  useEffect(() => {
    if (selectedHeaderId?.length > 0 && selectedSeasonBtn?.length > 0) {
      getAllVideosDetailsForHeader(
        { videoHeaderId: selectedHeaderId, seasonId: selectedSeasonBtn },
        (success) => {
          if (success?.isSuccess) {
            setAllHeaderDataWithVideos(success.data[0]);
          }
        },
        (fail) => {
          toastError(fail.data.err_msg);
        }
      );
    }
    //eslint-disable-next-line
  }, [selectedHeaderId, selectedSeasonBtn]);

  useEffect(() => {
    if (genreId?.length > 0) {
      getAllCategoryDataByGenre(
        { genreId },
        (success) => {
          if (success?.isSuccess) {
            const categoriesByGenre = success.data.filter(
              (category) =>
                category?.categoryId === allHeaderDataWithVideos.categoryId
            );
            setCategoryName(categoriesByGenre[0]?.category);
          }
        },
        (fail) => {
          if (fail?.data?.error) {
            toastError(fail.data.err_msg);
          }
        }
      );
    }
  }, [allHeaderDataWithVideos, genreId]);

  useEffect(() => {
    setAllSeasonActors([]);
    setActors([]);
    if (allHeaderDataWithVideos?.castIds?.length > 0) {
      let selectedSeasonCasts = allHeaderDataWithVideos.castIds.filter(
        (cast) => cast?.seasonId === selectedSeasonBtn
      );
      let actors = [];
      let acts = [];
      if (selectedSeasonCasts[0]?.casts?.length > 0) {
        selectedSeasonCasts[0].casts.map((cast) => {
          actors.push({ label: cast?.name, value: cast?._id });
          acts.push(cast?._id);
        });

        setAllSeasonActors(actors);
        setActors(acts);
      }
    }
  }, [allHeaderDataWithVideos, selectedSeasonBtn]);

  const handleSelectSeasonBtn = (season) => {
    setSelectedSeasonBtn(season?.id);
    getEpisodesByHeaderIdAndSeasonId(selectedHeaderId, season?.id);
  };

  const getEpisodesByHeaderIdAndSeasonId = (videoHeaderId, seasonId) => {
    getAllEpisodsDependsOnSeason(
      { videoHeaderId, seasonId },
      (success) => {
        if (success?.isSuccess) {
          setAllEpisodsDependsOnSeason(success.data);
        }
      },
      (fail) => {
        if (fail?.data?.error) {
          toastError(fail.data.err_msg);
        } else {
          toastError();
        }
      }
    );
  };

  return (
    <section className="users">
      <div className="users__all-contents ">
        <div className="users__content">
          <div className="tv-shows wd-100 ">
            <Header
              title={
                genreName === "Series" ? genreName : genreName + " | Series"
              }
            />
            <div className="tvshows-form wd-100 pad-x-20 ">
              <Row gutter={[24, 24]} className="mrg-ye-20">
                <Col span={24}>
                  <div className="new-actor__name new-category ">
                    <label className="users__parent-info-label" htmlFor="">
                      Title
                    </label>
                    <Input
                      type="text"
                      className="search__searchField py-2"
                      value={allHeaderDataWithVideos?.title}
                    />
                  </div>
                </Col>
                <Col className="gutter-row" span={24}>
                  <div className="new-actor__name new-category">
                    <label className="users__parent-info-label" htmlFor="">
                      Category
                    </label>
                    <Input
                      type="text"
                      className="search__searchField py-2"
                      value={categoryName}
                    />
                  </div>
                </Col>
                <Col xs={24} md={12}>
                  <div className="new-actor__name new-category select-genre">
                    <label className="users__parent-info-label " htmlFor="">
                      Choose Season
                    </label>
                    <Select
                      open={false}
                      className="search__searchField py-1 wd-100"
                      value={allHeaderDataWithVideos?.seasonId}
                      dropdownStyle={dropdownStyle}
                    >
                      {seasonsOnSystem?.map(({ indexId, season }, index) => (
                        <Select.Option key={index} value={indexId}>
                          {season}
                        </Select.Option>
                      ))}
                    </Select>
                  </div>
                </Col>
                <Col xs={24} md={12}>
                  <div className="new-actor__name new-category  select-genre">
                    <label className="users__parent-info-label " htmlFor="">
                      Cast of this season
                    </label>
                    <Select
                      mode="multiple"
                      maxTagCount={2}
                      maxTagTextLength={8}
                      type="text"
                      className="search__searchField py-1 wd-100"
                      value={actors}
                      options={allSeasonActors}
                      dropdownStyle={dropdownStyle}
                    />
                  </div>
                </Col>
              </Row>

              <Row>
                <Col>
                  <SubHeader bgColor="#27A2D9" title="Image" noPadding />
                </Col>
                <Col span={24}>
                  <div className="upload-files mrg-ye-30">
                    <div className="upload-files__container  relative">
                      <figure>
                        <img
                          className="upload-files__upload-file"
                          src={baseUrl + allHeaderDataWithVideos?.imgPath}
                          alt="actorImage"
                        />
                      </figure>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <div className="mrg-ye-30">
                    <Tabs
                      tabPosition="top"
                      size={"large"}
                      tabBarGutter={10}
                      type="line"
                      // destroyInactiveTabPane={true}
                    >
                      {seasonsDependsOnHeaderId?.map((season, index) => (
                        <Tabs.TabPane
                          key={season?.id}
                          tab={
                            <Button
                              className={
                                selectedSeasonBtn === season?.id
                                  ? "selected-btn-tab"
                                  : "btn-tab"
                              }
                              onClick={() => handleSelectSeasonBtn(season)}
                            >
                              {season?.name}
                            </Button>
                          }
                        >
                          <Tabs destroyInactiveTabPane={true}>
                            {allEpisodsDependsOnSeason.map(
                              (episode, episodeIndex) => (
                                <Tabs.TabPane
                                  key={episodeIndex}
                                  tab={episode?.name}
                                  defaultActiveKey={
                                    allEpisodsDependsOnSeason[0]?.id
                                  }
                                >
                                  <ViewEpisodeM3u8
                                    headerId={
                                      allHeaderDataWithVideos?.videoHeaderId
                                    }
                                    seasonId={selectedSeasonBtn}
                                    episodeId={episode?.id}
                                  />
                                </Tabs.TabPane>
                              )
                            )}
                          </Tabs>
                        </Tabs.TabPane>
                      ))}
                    </Tabs>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ViewTvShowsM3u8;
