export const en = {
  signin: "Sign In",
  email: "Email",
  password: "Password",
  provideYourEmail: "Please, enter your email",
  providePassword: "Please, enter your password",
  passwordMustBeAtLeastSixCharacters: "Password must be at least 6 characters",
  pleaseProvideValidEmail: "Please provide valid email",
  forgetPassword: "Forget Password?",
  subscribeNow: "Subscribe Now!",
  dontHaveAccount: "Dont have an account?",
  loginSuccessfully: "You are logged in successfully",
};
