import React, { useEffect, useState } from "react";
import Header from "modules/SubComponents/Header";
import { Button, Modal, Space, Switch, Table } from "antd";
import { toastError } from "helpers/toasters";
import { declareWinner, getAllAnswers } from "Network";
import warrningIcon from "assets/icons/warning.svg";

import moment from "moment";
import SubHeader from "modules/SubComponents/SubHeader";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import DeleteModal from "modules/Modals/DeleteModal";

const DUMMY_DATA = [
  {
    _id: {
      phone: "201278316977",
      month: "2024-01",
    },
    phone: "201278316977",
    isWinner: false,
    allAnswersCount: 17,
    correctAnswersCount: 5,
  },
  {
    _id: {
      phone: "201210504987",
      month: "2024-01",
    },
    phone: "201210504987",
    isWinner: false,
    allAnswersCount: 7,
    correctAnswersCount: 1,
  },
  {
    _id: {
      phone: "201118979603",
      month: "2023-12",
    },
    phone: "201118979603",
    isWinner: null,
    allAnswersCount: 3,
    correctAnswersCount: 2,
  },
];
function Answers() {
  const [isWarningModalVisible, setIsWarningModalVisible] = useState(false);
  const [selectedPage, setSelectedPage] = useState();
  const [renderMe, setRenderMe] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  // const [allDataSource, setAllDataSource] = useState([]);
  const [filterDate, setFilterDate] = useState(null);
  const [loadingTable, setLoadingTable] = useState(false);
  const columns = [
    {
      title: "PHONE",
      key: "phone",
      dataIndex: "phone",
    },
    {
      title: "ALL ANSWERS",
      key: "phone",
      dataIndex: "allAnswersCount",
      align: "center",
    },
    {
      title: "CORRECT ANSWERS",
      key: "phone",
      dataIndex: "correctAnswersCount",
      align: "center",
    },
    {
      title: "DATE",
      key: "phone",
      dataIndex: "_id",
      render: (text) => moment(text.month).format("YYYY-MM"), // Assuming text is in YYYY-MM format
    },
    {
      title: "STATUS",
      dataIndex: "isWinner",
      render: (_, record) => {
        return (
          <Space size={10}>
            <Switch
              style={{
                backgroundColor: `${
                  record?.isWinner ? "rgb(49, 170, 74)" : "gray"
                }`,
                minWidth: "35px",
              }}
              checked={record?.isWinner} // Update checked property here
              onChange={() => handleStatusOnChange(record)}
            />
            <span
              style={{ display: "inline-block", width: "60px" }}
              className={`${record?.isWinner ? "winner" : "loser"}`}
            >
              {record?.isWinner ? "WINNER" : "LOSER"}
            </span>
          </Space>
        );
      },
      // sorter: (a, b) => {
      //   // Convert "Winner" to 1 and "Loser" to 0 for sorting
      //   const statusA = a.isWinner ? 1 : 0;
      //   const statusB = b.isWinner ? 1 : 0;
      //   return statusA - statusB;
      // },
      // sortDirections: ["ascend", "descend"],
      filters: [
        { text: "Winner", value: true },
        { text: "Loser", value: false },
      ],
      onFilter: (value, record) => {
        if (value === false) {
          return !record.isWinner; // Includes both false and null
        }
        return record.isWinner === value;
      },
    },
  ];
  const columnsToExport = [
    {
      title: "PHONE",
      key: "phone",
      dataIndex: "phone",
    },
    {
      title: "ALL ANSWERS",
      key: "phone",
      dataIndex: "allAnswersCount",
      align: "center",
    },
    {
      title: "CORRECT ANSWERS",
      key: "phone",
      dataIndex: "correctAnswersCount",
      align: "center",
    },
    {
      title: "DATE",
      key: "phone",
      dataIndex: "_id",
      render: (text) => moment(text.month).format("YYYY-MM"), // Assuming text is in YYYY-MM format
    },
    {
      title: "isWinner",
      key: "phone",
      dataIndex: "isWinner",
    },
  ];
  const sortDataSource = (data) => {
    return data.sort((a, b) => {
      // Sort by allAnswersCount in descending order
      if (b.allAnswersCount !== a.allAnswersCount) {
        return b.allAnswersCount - a.allAnswersCount;
      }
      // If allAnswersCount is the same, sort by grade (correctAnswers/allAnswers) in descending order
      const gradeA = a.correctAnswersCount / a.allAnswersCount;
      const gradeB = b.correctAnswersCount / b.allAnswersCount;
      return gradeB - gradeA;
    });
  };
  let requestedBody = {};
  const handleWarningOk = () => {
    setIsWarningModalVisible(false);
  };
  const handleWarningCancel = () => {
    setIsWarningModalVisible(false);
  };

  const getAnswers = () => {
    setLoadingTable(true);

    getAllAnswers(
      requestedBody,
      (success) => {
        if (success?.isSuccess) {
          // setDataSource(sortDataSource(success.usersAnswers));
          setDataSource(success.usersAnswers);

          // setDataSource(sortDataSource(DUMMY_DATA));
        } else {
          toastError(success.err_msg);
        }
        setLoadingTable(false);
      },
      (fail) => {
        setLoadingTable(false);

        toastError(fail?.data?.err_msg || "Failed to fetch data");
      }
    );
  };
  useEffect(() => {
    getAnswers();
  }, []);

  const handlePageOnChange = (page) => {
    setSelectedPage(page);
  };
  const handleExportAsExcel = () => {};

  const handleApply = (payload) => {
    setLoadingTable(true);

    let updatedRequestedBody = {};

    if (payload && (payload.year || payload.month)) {
      const { year, month } = payload;
      updatedRequestedBody = {
        year: year,
        month: month || undefined,
      };
    }

    // If no date is selected, send an empty payload
    if (!payload || (!payload.year && !payload.month)) {
      updatedRequestedBody = {};
    }

    setFilterDate(payload || null); // Set filterDate state with payload

    // Call the API with the updated requestedBody
    getAllAnswers(
      updatedRequestedBody,
      (success) => {
        setLoadingTable(false);
        if (success?.isSuccess) {
          setDataSource(success.usersAnswers);
        }
      },
      (fail) => {
        toastError(fail?.data?.err_msg || "Failed to fetch data");
        setLoadingTable(false);
      }
    );
  };

  const handleStatusOnChange = (record) => {
    const updatedRecord = { ...record }; // Create a copy of the record
    const newIsWinnerValue = !updatedRecord.isWinner; // Toggle the isWinner value

    // Check if the user is already a winner, then show the modal
    if (updatedRecord.isWinner && !newIsWinnerValue) {
      setIsWarningModalVisible(true);
      return; // Prevent further execution
    }

    updatedRecord.isWinner = newIsWinnerValue; // Update the copy with the new value
    const updatedRecordMonth = moment(updatedRecord._id.month).format(
      "YYYY-MM"
    );

    const isExistingWinnerThisMonth = dataSource.some(
      (item) =>
        item.isWinner && // Check if the item is a winner
        moment(item._id.month).format("YYYY-MM") === updatedRecordMonth && // Check if it's the same month
        item._id.phone !== updatedRecord._id.phone // Exclude the current record from comparison
    );

    if (newIsWinnerValue && isExistingWinnerThisMonth) {
      // Show error toast when attempting to set a new winner if one already exists for this month
      toastError("The winner of this month was set before");
      return; // Prevent further execution
    } else {
      setSelectedRecord(record);
      setIsModalVisible(true);
    }
    // Prepare the payload for the API request
  };

  const handleOk = () => {
    setConfirmLoading(true);

    // Check if the record is the current winner before allowing the status change to loser

    // Simulate API call or declareWinner function here
    // Example: Using setTimeout to simulate an API call (replace with your actual API call)

    // Uncomment below and replace with actual API call if available
    declareWinner(
      {
        phone: selectedRecord.phone,
        isWinner: true,
        date: selectedRecord._id.month,
      },
      () => {
        setIsModalVisible(false);
        setConfirmLoading(false);
        getAnswers();
      },
      (error) => {
        console.error("Error updating isWinner property:", error);
        // Handle error if needed
        toastError(error.data.err_msg);

        setConfirmLoading(false);
      }
    );
  };

  const getAnswersWithFilter = () => {
    setLoadingTable(true);

    let updatedRequestedBody = {};

    if (filterDate && (filterDate.year || filterDate.month)) {
      const { year, month } = filterDate;
      updatedRequestedBody = {
        year: year,
        month: month || undefined,
      };
    }

    // Call the API with the updated requestedBody only if there's a filterDate
    if (Object.keys(updatedRequestedBody).length > 0) {
      getAllAnswers(
        updatedRequestedBody,
        (success) => {
          setLoadingTable(false);
          if (success?.isSuccess) {
            setDataSource(success.usersAnswers);
            setLoadingTable(false);
          }
        },
        (fail) => {
          toastError(fail?.data?.err_msg || "Failed to fetch data");
          setLoadingTable(false);
        }
      );
    }
  };

  // const sortData = (data) => {
  //   data.sort((a, b) => {
  //     const dateA = new Date(a?.date);
  //     const dateB = new Date(b?.date);
  //     return dateB - dateA;
  //   });
  //   setDataSource(data);
  // };
  return (
    <section className="users ">
      <div className="users__all-contents ">
        <div className="users__content">
          <div className="users__usersData">
            <Header title="Answers" />
            <SubHeader
              bgColor="#F58234"
              title={"DATE:"}
              isAnswerFilters={true}
              primaryBtnName="Apply"
              secondaryBtnName="Export as Excel"
              primaryBtnAction={handleApply}
              // secondaryBtnAction={handleExportAsExcel}
              exportAsExcel
              columnsForExport={columnsToExport}
              dataSource={dataSource}
              reportName={`Competitors`}
            />
            <div className="cast-data__table  grid-table ">
              <Table
                rowKey={(row) => row?._id}
                columns={columns}
                dataSource={dataSource}
                rowClassName={(record, index) =>
                  index % 2 === 0 && "background-even-records"
                }
                scroll={{ x: 600 }}
                loading={loadingTable} // Set the loading state for the table
                pagination={{
                  defaultPageSize: "7",
                  responsive: true,
                  hideOnSinglePage: true,
                  onChange: handlePageOnChange,
                  current: selectedPage,
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <DeleteModal
        deleteModalVisible={isWarningModalVisible}
        // loading={loadingActivateModal}
        // SubHeader={
        //   <SubHeader
        //     bgColor='#F58234'
        //     title='Edit Admin Status'
        //     closeButton
        //     onCancel={() => {
        //       setIsWarningModalVisible(false);
        //     }}
        //   />
        // }
        primarybtnappear={false}
        icon={warrningIcon}
        msg={" Not allowed, this person has already won!"}
        onCancel={() => {
          setIsWarningModalVisible(false);
        }}
        // action={}
        secondaryBtnName="OK"
        // primaryBtnName={`${
        //   selectedRowData?.isActive ? "Deactivate" : "Activate"
        // }`}
      />
      {/* <Modal
        title='Warning'
        open={isWarningModalVisible}
        onOk={handleWarningOk}
        onCancel={() => {
          setIsWarningModalVisible(false);
        }} // Set onCancel to null to remove the cancel button
        footer={[
          <div style={{ textAlign: "center" }}>
            <Button
              key='ok'
              style={{ width: "100px" }}
              onClick={handleWarningOk}
            >
              OK
            </Button>
          </div>,
        ]}
        style={{ display: "flex", alignItems: "center" }}
      >
        <ExclamationCircleOutlined
          style={{ color: "orange", marginRight: "10px" }}
        />
        Not allowed, this person has already won!
      </Modal> */}
      <Modal
        title="Confirmation"
        open={isModalVisible}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={() => {
          setIsModalVisible(false);
        }}
      >
        Are you sure that this person is the winner for the selected month?
      </Modal>
    </section>
  );
}

export default Answers;
