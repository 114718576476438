import React, { useState } from "react";
import accountIcon from "assets/avatars/accountIcon.svg";
import notificationIconRed from "assets/icons/notification-red.svg";
import notificationIconLight from "assets/icons/notification-light.svg";
import Search from "modules/SubComponents/Search";

function Navbar({ withSearch, getSearchedValueFromNavbar }) {
  const [showNotification, setShowNotification] = useState(false);

  const handleToggleNotification = () => {
    setShowNotification((prev) => !prev);
  };
  return (
    <nav className="navbar">
      <div className="navbar__search">
        {withSearch === true && <Search placeholder="Search" />}
      </div>
      <div className="navbar__manage-acount">
        <div
          className="navbar__notification"
          role={"button"}
          onClick={handleToggleNotification}
        >
          <img
            className="navbar__notIcon"
            src={showNotification ? notificationIconLight : notificationIconRed}
            alt=""
          />
          {showNotification && (
            <div className="navbar__notification-card">
              {/* api to show all notification */}
            </div>
          )}
        </div>
        <div className="navbar__account">
          <img className="navbar__accountIcon" src={accountIcon} alt="" />
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
