import React from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import ROUTES from "constants/NewRoutes";
import amuseLogo from "assets/icons/amuse-logo.svg";
import homeIcon from "assets/icons/homePageIcon.svg";
import dashboardIcon from "assets/icons/dashboard.svg";
import bannerIcon from "assets/icons/banner.svg";
import moviesIcon from "assets/icons/movies.svg";
import tvShowIcon from "assets/icons/tvshow.svg";
import animeIcon from "assets/icons/animeIcon.svg";
import tvProgramsIcon from "assets/icons/tvProgramsIcon.svg";
import funStoreIcon from "assets/icons/funStoreIcon.svg";
import castIcon from "assets/icons/cast.svg";
import categoriesIcon from "assets/icons/categories.svg";
import languageIcon from "assets/icons/languageIcon.svg";
import usersIcon from "assets/icons/users.svg";
import subscribtionsIcon from "assets/icons/subscriptions.svg";
import contactUsIcon from "assets/icons/contactus.svg";
import logoutIcon from "assets/icons/logout.svg";
import questionIcon from "assets/icons/questionIcon.svg";
import answersIcon from "assets/icons/answersIcon.svg";
import adminIcon from "assets/icons/adminIcon.svg";
import { updateCurrentPath } from "store/dynamicData";

function SideBar() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { authorization } = useSelector((state) => state.auth);
  const { genresOnSystem } = useSelector(
    (state) => state?.staticData?.staticDataOnSystem
  );
  const { currentPath } = useSelector(
    (state) => state?.dynamicData?.dynamicDataOnSystem
  );

  const handleCatOnClick = ({ link, genreId, name }) => {
    window.scrollTo(0, 0);
    dispatch(updateCurrentPath(link));
    if (genreId) {
      navigate(`/${link}`, {
        state: { name, genreId },
      });
    } else {
      navigate(`/${link}`, {
        state: { name, currentPage: 1, searchVal: "" },
      });
    }
  };
  const { isSuperAdmin } = authorization;
  const handleItemClick = (item) => {
    navigate(`/${item.link}`);
    handleCatOnClick(item);
  };
  const categoriesData = [
    { icon: adminIcon, name: "Admins", link: "admins" },
    { icon: homeIcon, name: "Home Page", link: "home" },
    { icon: dashboardIcon, name: "Dashboard", link: "dashboard" },
    { icon: bannerIcon, name: "Banner", link: "banner" },
    {
      icon: moviesIcon,
      name: genresOnSystem?.length && genresOnSystem[1]?.name,
      link: "movies",
      genreId: genresOnSystem?.length && genresOnSystem[1]?.id,
    },
    {
      icon: tvShowIcon,
      name: genresOnSystem?.length && genresOnSystem[0]?.name,
      link: "tv-shows",
      genreId: genresOnSystem?.length && genresOnSystem[0]?.id,
    },
    {
      icon: animeIcon,
      name: genresOnSystem?.length && genresOnSystem[2]?.name,
      link: "anime",
      genreId: genresOnSystem?.length && genresOnSystem[2]?.id,
    },
    {
      icon: tvProgramsIcon,
      name: genresOnSystem?.length && genresOnSystem[3]?.name,
      link: "tv-programs",
      genreId: genresOnSystem?.length && genresOnSystem[3]?.id,
    },
    {
      icon: funStoreIcon,
      name: genresOnSystem?.length && genresOnSystem[4]?.name,
      link: "fun-store",
      genreId: genresOnSystem?.length && genresOnSystem[4]?.id,
    },
    { icon: castIcon, name: "Cast", link: "cast" },
    { icon: categoriesIcon, name: "Categories", link: "categories" },
    { icon: languageIcon, name: "Languages", link: "languages" },
    { icon: usersIcon, name: "Users", link: "users" },
    { icon: subscribtionsIcon, name: "Subscribtions", link: "subscriptions" },
    { icon: questionIcon, name: "Questions", link: "questions" },
    { icon: answersIcon, name: "Answers", link: "answers" },
    { icon: contactUsIcon, name: "Contact Us", link: "contact-us" },
    { icon: contactUsIcon, name: "Codes", link: "code" },
  ];

  const superAdminPages = ["Admins", "Codes"];
  return (
    <div className="sidebar flex dir-col items-center">
      <div className="logo ">
        <Link to={ROUTES.DASHBOARD}>
          <img className="amuse-logo " src={amuseLogo} alt="amuseLogo" />
        </Link>
      </div>
      {/* start account details */}
      <div className="account ">
        <div className="icon ">
          <img src={usersIcon} alt="" />
        </div>
        <div className="details ">
          <div className="admin">admin</div>
          <div className="account-name">{`${authorization?.adminName}`}</div>
        </div>
        <div className="logout ">
          <Link to={ROUTES.LOGOUT}>
            <img src={logoutIcon} alt="" />
          </Link>
        </div>
      </div>
      {/* end account details */}
      {/* start list of  categories */}
      <div className="list mt-3">
        {categoriesData?.map((item, index) => {
          const isSelected = location.pathname.includes(item.link);

          if (isSuperAdmin) {
            return (
              <div
                role="button"
                // onClick={() => handleCatOnClick(item)}
                onClick={() => handleItemClick(item)}
                className={`category ${isSelected ? "selected" : ""}`}
                key={index}
              >
                <div className="cat-icon">
                  <img src={item.icon} alt={item.name} />
                </div>
                <div className="category-name">{item.name}</div>
              </div>
            );
          } else {
            if (!superAdminPages.includes(item?.name)) {
              return (
                <div
                  role="button"
                  // onClick={() => handleCatOnClick(item)}
                  onClick={() => handleItemClick(item)}
                  className={`category ${isSelected ? "selected" : ""}`}
                  key={index}
                >
                  <div className="cat-icon">
                    <img src={item.icon} alt={item.name} />
                  </div>
                  <div className="category-name">{item.name}</div>
                </div>
              );
            }
          }
        })}
        {}
      </div>
      {/* end list of  categories */}
    </div>
  );
}

export default SideBar;
