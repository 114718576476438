import {
  postResource,
  getResource,
  putResource,
  deleteResource,
  patchResource,
  uploadFileService,
  // getResourceAsyncAwait,
  deleteResourceWithData,
  postResourceAsync,
} from "services";
//sign api
export const signinAdmin = ({ email, password }, onSuccess, onFail) => {
  const data = { email, password };
  const path = "api/admins/login";
  postResource(path, data, onSuccess, onFail);
};
//Home Page
export function getAllCategoriesForHome(onSuccess, onFail) {
  const path = "api/homeCategory/getAllcategories?lang=en";
  getResource(path, onSuccess, onFail);
}
export function addHomeCategory(data, onSuccess, onFail) {
  const path = "api/homeCategory/add";
  postResource(path, data, onSuccess, onFail);
}
export function editHomeCategory(data, onSuccess, onFail) {
  const path = "api/homeCategory/add";
  postResource(path, data, onSuccess, onFail);
}
export function deleteHomeCategory({ deletedCategoryId }, onSuccess, onFail) {
  const path = `api/homeCategory/delete?categoryId=${deletedCategoryId}`;
  deleteResource(path, onSuccess, onFail);
}
//users and user accounts api's
export function getAllUsersData(onSuccess, onFail) {
  const path = "api/users?lang=en";
  getResource(path, onSuccess, onFail);
}
export const editUser = ({ userId, isActive }, onSuccess, onFail) => {
  const data = { isActive };
  const path = `api/user/adminEdit?userId=${userId}`;
  putResource(path, data, onSuccess, onFail);
};
export function getUserAccountsDataWithId({ userId }, onSuccess, onFail) {
  const path = `api/userAccounts/user?userId=${userId}`;
  getResource(path, onSuccess, onFail);
}
export function addFreeDaysForUsers(data, onSuccess, onFail) {
  const path = `api/user/addFreeDays`;
  postResource(path, data, onSuccess, onFail);
}
//avatar api's
export function getAllAvatars(data, onSuccess, onFail) {
  const path = "api/user/avatars";
  getResource(path, data, onSuccess, onFail);
}
export function deleteSelectedAvatar({ avatarId }, onSuccess, onFail) {
  const path = `api/avatar/delete?avatarId=${avatarId}`;
  deleteResource(path, onSuccess, onFail);
}
export const uploadAvatarFile = (data, onSuccess, onFail) => {
  const path = "api/user/upload/avatar";
  postResource(path, data, onSuccess, onFail, true, true);
};
//casts api's

export function getAllActors(onSuccess, onFail) {
  const path = "api/casts";
  getResource(path, onSuccess, onFail);
}
export function deleteThisActor({ actorId }, onSuccess, onFail) {
  const path = `api/cast/delete/${actorId}`;
  deleteResource(path, onSuccess, onFail);
}
export function addAnActor({ nameAr, nameEn, imgPath }, onSuccess, onFail) {
  const data = {
    "name.ar": nameAr,
    "name.en": nameEn,
    imgPath,
  };
  const path = "api/cast";
  postResource(path, data, onSuccess, onFail);
}
export function editActor(
  { nameAr, nameEn, imgPath, actorId },
  onSuccess,
  onFail
) {
  const data = {
    "name.ar": nameAr,
    "name.en": nameEn,
    imgPath,
  };
  const path = `api/cast/edit/${actorId}`;
  putResource(path, data, onSuccess, onFail);
}
//upload image api
export function uploadImage(data, onSuccess, onFail) {
  const path = "api/img/upload";
  postResource(path, data, onSuccess, onFail, true, true);
}
export function customUploadFile(
  { formDataType },
  data,
  onProgress,
  onSuccess,
  onFail,
  cancelToken
) {
  const path = `api/${formDataType}/upload`;
  uploadFileService(
    "post",
    path,
    formDataType,
    data,
    onProgress,
    onSuccess,
    onFail,
    cancelToken
  );
}
//categories  api's
export function getAllCategories(onSuccess, onFail) {
  const path = "api/categories?lang=en";
  getResource(path, onSuccess, onFail);
}
export function getAllCategoriesByGenreId({ genreId }, onSuccess, onFail) {
  const path = `api/categories/${genreId}`;
  getResource(path, onSuccess, onFail);
}
export function deleteCategoryById({ categoryId }, onSuccess, onFail) {
  const path = `api/category/delete/${categoryId}`;
  deleteResource(path, onSuccess, onFail);
}
export function addNewCategory({ nameAr, nameEn, genreId }, onSuccess, onFail) {
  const data = {
    "name.ar": nameAr,
    "name.en": nameEn,
    genreId,
  };
  const path = "api/category";
  postResource(path, data, onSuccess, onFail);
}
export function editCategory(
  { nameAr, nameEn, genreId, categoryId },
  onSuccess,
  onFail
) {
  const data = {
    "name.ar": nameAr,
    "name.en": nameEn,
    genreId,
    categoryId,
  };
  const path = `api/category/edit/${categoryId}`;
  putResource(path, data, onSuccess, onFail);
}
//Genres  api's
export function getAllGenresOnSystem(onSuccess, onFail) {
  const path = "api/genres?lang=en";
  getResource(path, onSuccess, onFail);
}
export function deleteThisGenre({ genreId }, onSuccess, onFail) {
  const path = `api/genre/delete/${genreId}`;
  deleteResource(path, onSuccess, onFail);
}
//Contact us  api's
export function readMessages(onSuccess, onFail) {
  const path = "api/contactUs";
  getResource(path, onSuccess, onFail);
}
export function editMessage(
  { messageId, answer, answeredBy },
  onSuccess,
  onFail
) {
  const data = { answer, answeredBy };
  const path = `api/contactUs/${messageId}`;
  patchResource(path, data, onSuccess, onFail);
}
//Packages  api's
export function getAllPackages(onSuccess, onFail) {
  const path = "api/packagesForAdmin";
  getResource(path, onSuccess, onFail);
}

export function addNewPackage(
  { amount, noOfMonths, discount, isActive },
  onSuccess,
  onFail
) {
  const data = { amount, noOfMonths, discount, isActive };
  const path = "api/package";
  postResource(path, data, onSuccess, onFail);
}
export function editPackage({ id, isActive }, onSuccess, onFail) {
  const data = { id, isActive };
  const path = `api/package/edit/${id}`;
  putResource(path, data, onSuccess, onFail);
}
//Dashboard api's
export function allViewsOnSystem(onSuccess, onFail) {
  const path = `api/allViews`;
  getResource(path, onSuccess, onFail);
}
export function getSystemCategoryByPercentage(onSuccess, onFail) {
  const path = "api/systemStatistics/genres";
  getResource(path, onSuccess, onFail);
}
export function getSystemWeaklyViews(
  { weaklyViewsDaysCount },
  onSuccess,
  onFail
) {
  const path = `api/systemViews/weekly?daysCount=${weaklyViewsDaysCount}`;
  getResource(path, onSuccess, onFail);
}

export function getSystemHourlyViews(
  { hourlyViewsDaysCount },
  onSuccess,
  onFail
) {
  const path = `api/systemViews/hourly?daysCount=${hourlyViewsDaysCount}`;
  getResource(path, onSuccess, onFail);
}
export function getSystemTrendingShows(
  { trendingViewsDaysCount },
  onSuccess,
  onFail
) {
  const path = `api/videoViews?daysCount=${trendingViewsDaysCount}`;
  getResource(path, onSuccess, onFail);
}
//Banner
export function getAllBannersOnSystem(onSuccess, onFail) {
  const path = `api/getBannersForAdmin`;
  // const path = `api/getbanner`;
  getResource(path, onSuccess, onFail);
}
// banner--movies  api's
export function getAllMoviesOnSystem(onSuccess, onFail) {
  const path = `api/video-header?isSeries=false`;
  getResource(path, onSuccess, onFail);
}
export function getAllPartsDependsOnMovies(
  { videoHeaderId },
  onSuccess,
  onFail
) {
  const path = `api/partsHeaderId?videoHeaderId=${videoHeaderId}`;
  getResource(path, onSuccess, onFail);
}
//banner--series  api's

export function getAllHeadersByGenreId({ genreId }, onSuccess, onFail) {
  const path = `api/video-headerbyGenre?genreId=${genreId}`;
  getResource(path, onSuccess, onFail);
}
export function getAllSeriesOnSystem(onSuccess, onFail) {
  const path = `api/video-header?isSeries=${true}`;
  getResource(path, onSuccess, onFail);
}

export function getAllSeasonsDependsOnSeries(
  { videoHeaderId },
  onSuccess,
  onFail
) {
  const path = `api/seasonsHeaderId?videoHeaderId=${videoHeaderId}`;
  getResource(path, onSuccess, onFail);
}

export function getAllEpisodsDependsOnSeason(
  { seasonId, videoHeaderId },
  onSuccess,
  onFail
) {
  const path = `api/episodesSeasonId?seasonId=${seasonId}&videoHeaderId=${videoHeaderId}`;
  getResource(path, onSuccess, onFail);
}
//addBanner
export function addBanner(data, onSuccess, onFail) {
  const path = `api/addbanner`;
  postResource(path, data, onSuccess, onFail);
}
//editBanner
export function editBanner(data, onSuccess, onFail) {
  const path = `api/updatebanner`;
  putResource(path, data, onSuccess, onFail);
}
//delete banner
export function deleteBannerById({ bannerId }, onSuccess, onFail) {
  const path = `api/deletebanner?bannerId=${bannerId}`;
  deleteResource(path, onSuccess, onFail);
}
//TV Shows api's

export function getAllCategoryDataByGenre({ genreId }, onSuccess, onFail) {
  const path = `api/video/getCategoriesByGenreForAdmin?genreId=${genreId}`;
  getResource(path, onSuccess, onFail);
}
export function getAllSeasonsOnSystem(onSuccess, onFail) {
  const path = `api/season?lang=en`;
  getResource(path, onSuccess, onFail);
}
export function getAllEpisodesOnSystem(onSuccess, onFail) {
  const path = `api/series-number?isSeries=1&lang=en`;
  getResource(path, onSuccess, onFail);
}
export function getAllPartsOnSystem(onSuccess, onFail) {
  const path = `api/series-number?isSeries=2&lang=en`;
  getResource(path, onSuccess, onFail);
}
export function getAllLanguageOnSystem(onSuccess, onFail) {
  const path = `api/languages?lang=en`;
  getResource(path, onSuccess, onFail);
}
export function getAllLanguageEnArOnSystem(onSuccess, onFail) {
  const path = `api/languagesForAdmin`;
  getResource(path, onSuccess, onFail);
}
export function getAllVideoQualities(onSuccess, onFail) {
  const path = "api/videoQualities";
  getResource(path, onSuccess, onFail);
}
export function uploadVideo(data, onSuccess, onFail) {
  const path = "api/video/upload";
  postResource(path, data, onSuccess, onFail, true, true);
}
export function uploadVideoM3u8(data, onSuccess, onFail) {
  const path = "api/video/m3u8/upload";
  postResource(path, data, onSuccess, onFail, true, true);
}
export function uploadAudio(data, onSuccess, onFail) {
  const path = "api/audio/upload";
  postResource(path, data, onSuccess, onFail, true, true);
}
export function uploadSubtitle(data, onSuccess, onFail) {
  const path = "api/subtitle/upload";
  postResource(path, data, onSuccess, onFail, true, true);
}
export function addHeaderToTVShows(data, onSuccess, onFail) {
  // const path = `api/video-header/add`;
  const path = `api/video-header/m3u8/add`;
  postResource(path, data, onSuccess, onFail);
}
export function deleteHeaderFromTvShows({ headerId }, onSuccess, onFail) {
  const path = `api/video-header?id=${headerId}`;
  deleteResource(path, onSuccess, onFail);
}
export function deleteSeasonFromTvShows({ seasonId }, onSuccess, onFail) {
  const path = `api/season?id=${seasonId}`;
  deleteResource(path, onSuccess, onFail);
}
export function deleteEpisodeFromHeader(id, onSuccess, onFail) {
  const path = `api/video/delete?id=${id}`;
  deleteResource(path, onSuccess, onFail);
}
// export async function getAllActorsAsyncAwait() {
//   const path = "api/casts";
//   try {
//     const response = await getResourceAsyncAwait(path);
//     return response;
//   } catch (error) {
//     throw error;
//   }
// }
// export async function getAllLanguagesAsyncAwait() {
//   const path = "api/languages?lang=en";
//   try {
//     const response = await getResourceAsyncAwait(path);
//     return response;
//   } catch (error) {
//     throw error;
//   }
// }
export function getSubtitleIdForThisEpisode(data, onSuccess, onFail) {
  const path = "api/subtitle";
  postResource(path, data, onSuccess, onFail);
}
export async function getAudioIdForThisEpisodeAsync(data) {
  const path = "api/audio";
  try {
    const response = await postResourceAsync(path, data);
    return response;
  } catch (error) {
    throw error;
  }
}

export async function getSubtitleIdForThisEpisodeAsync(data) {
  const path = "api/subtitle";
  try {
    const response = await postResourceAsync(path, data);
    return response;
  } catch (error) {
    throw error;
  }
}
export function getSubtitleIdForEditEpisode(data, onSuccess, onFail) {
  const path = "api/subtitle";
  putResource(path, data, onSuccess, onFail);
}

//get audio id from  audio array
export function getAudioIdForThisEpisode(data, onSuccess, onFail) {
  const path = "api/audio";
  postResource(path, data, onSuccess, onFail);
}
export function getAudioIdForEditEpisode(data, onSuccess, onFail) {
  const path = "api/audio";
  putResource(path, data, onSuccess, onFail);
}
export function addEpisodesToHeader(data, onSuccess, onFail) {
  // const path = "api/video/add";
  const path = "api/video/m3u8/add";
  postResource(path, data, onSuccess, onFail);
}
//get all videos details dependes on videoHeaderId and seasonId
// export async function getAllVideosDetailsForHeaderAsyncAwait({
//   videoHeaderId,
//   seasonId,
// }) {
//   const path = `api/videoHeaderDetails?videoHeaderId=${videoHeaderId}&seasonId=${seasonId}`;
//   try {
//     const response = await getResourceAsyncAwait(path);
//     return response;
//   } catch (error) {
//     throw error;
//   }
// }
export function getAllVideosDetailsForHeader(
  { videoHeaderId, seasonId },
  onSuccess,
  onFail
) {
  const path = `api/videoHeaderDetails?videoHeaderId=${videoHeaderId}&seasonId=${seasonId}`;
  getResource(path, onSuccess, onFail);
}
//edit header
export function editTvHeader(
  {
    videoHeaderId,
    isSeries,
    title,
    genreId,
    categoryId,
    seasonsCast,
    imgPath,
    subtitles,
    audios,
  },
  onSuccess,
  onFail
) {
  const data = {
    isSeries,
    title,
    genreId,
    categoryId,
    seasonsCast,
    imgPath,
    subtitles,
    audios,
  };
  // const path = `api/video-header?id=${videoHeaderId}`;
  const path = `api/m3u8/video-header?id=${videoHeaderId}`;
  putResource(path, data, onSuccess, onFail);
}

export function editEpisode(data, onSuccess, onFail) {
  const path = `api/video`;
  putResource(path, data, onSuccess, onFail);
}

export function editEpisodeM3u8(data, onSuccess, onFail) {
  const path = `api/m3u8/video`;
  putResource(path, data, onSuccess, onFail);
}

export function getNumberOfSeriesOnSystem(onSuccess, onFail) {
  const path = "api/series/count";
  getResource(path, onSuccess, onFail);
}
export function getNumberOfSeriesViewsOnSystem(onSuccess, onFail) {
  const path = "api/seriesViews";
  getResource(path, onSuccess, onFail);
}
// Movies
export function getAllVideosDetailsForMovie(
  { videoHeaderId },
  onSuccess,
  onFail
) {
  const path = `api/videoHeaderDetails?videoHeaderId=${videoHeaderId}`;
  getResource(path, onSuccess, onFail);
}

export function editMovieHeader(
  {
    videoHeaderId,
    isSeries,
    title,
    genreId,
    categoryId,
    partsCast,
    imgPath,
    subtitles,
    audios,
  },
  onSuccess,
  onFail
) {
  const data = {
    isSeries,
    title,
    genreId,
    categoryId,
    partsCast,
    imgPath,
    subtitles,
    audios,
  };
  const path = `api/video-header?id=${videoHeaderId}`;
  putResource(path, data, onSuccess, onFail);
}
export function editMovieHeaderM3u8(
  { videoHeaderId, isSeries, title, genreId, categoryId, partsCast, imgPath },
  onSuccess,
  onFail
) {
  const data = {
    isSeries,
    title,
    genreId,
    categoryId,
    partsCast,
    imgPath,
  };
  const path = `api/m3u8/video-header?id=${videoHeaderId}`;
  putResource(path, data, onSuccess, onFail);
}
export function getNumberOfMoviesOnSystem(onSuccess, onFail) {
  const path = "api/movies/count";
  getResource(path, onSuccess, onFail);
}
export function getNumberOfMovieViewsOnSystem(onSuccess, onFail) {
  const path = "api/moviesViews";
  getResource(path, onSuccess, onFail);
}
//uploads .. language and video quality
export function addNewLanguageToSystem(data, onSuccess, onFail) {
  const path = `api/language`;
  postResource(path, data, onSuccess, onFail);
}
export function editLanguageOnSystem(
  { languageId, arabicName, englishName },
  onSuccess,
  onFail
) {
  const data = {
    languageId,
    "name.en": englishName,
    "name.ar": arabicName,
  };
  const path = `api/language/edit?LanguageId=${languageId}`;
  putResource(path, data, onSuccess, onFail);
}
export function deleteLanguageFromSystem(id, onSuccess, onFail) {
  const path = `api/language/delete/${id}`;
  deleteResource(path, onSuccess, onFail);
}

export function addNewQualityToSystem(data, onSuccess, onFail) {
  const path = `api/videoQualities`;
  postResource(path, data, onSuccess, onFail);
}

export function editQualityOnSystem(
  { qualityId, videoQuality },
  onSuccess,
  onFail
) {
  const data = { videoQuality };
  const path = `api/videoQualities?id=${qualityId}`;
  putResource(path, data, onSuccess, onFail);
}

export function deleteQualityFromSystem(id, onSuccess, onFail) {
  const path = `api/videoQualities?id=${id}`;
  deleteResource(path, onSuccess, onFail);
}
//search
export function getSearchData({ search }, onSuccess, onFail) {
  const data = { search };
  const path = `api/search`;
  postResource(path, data, onSuccess, onFail);
}
//Questions
export function getAllQuestions(onSuccess, onFail) {
  const path = `api/question/all`;
  getResource(path, onSuccess, onFail);
}
export function deleteQuestions({ questionId }, onSuccess, onFail) {
  const path = `api/question/${questionId}`;
  deleteResource(path, onSuccess, onFail);
}

export function addQuestion(data, onSuccess, onFail) {
  const path = `api/question`;
  postResource(path, data, onSuccess, onFail);
}
export function editQuestion(data, onSuccess, onFail) {
  const path = `api/question/${data?.questionId}`;
  patchResource(path, data, onSuccess, onFail);
}
export function getQuestionDataById(id, onSuccess, onFail) {
  const path = `api/question/getQuestionById?questionId=${id}`;
  getResource(path, onSuccess, onFail);
}

export function removeUnUsedFiles(data, onSuccess, onFail) {
  const path = "api/removeUnusedFiles";
  deleteResourceWithData(path, data, onSuccess, onFail);
}
export function getAllAdminsData(onSuccess, onFail) {
  const path = "api/admins?lang=en";
  getResource(path, onSuccess, onFail);
}
export function addAdmin(data, onSuccess, onFail) {
  const path = "api/admins";
  postResource(path, data, onSuccess, onFail);
}
export function editAdmin(data, onSuccess, onFail) {
  const path = `api/admins/${data?.adminId}`;
  patchResource(path, data, onSuccess, onFail);
}
export function getAllCodes(onSuccess, onFail) {
  const path = `api/codes`;
  getResource(path, onSuccess, onFail);
}
export function addCode(data, onSuccess, onFail) {
  const path = `api/codes`;
  postResource(path, data, onSuccess, onFail);
}
export function getAllAnswers(data, onSuccess, onFail) {
  const path = `api/question/getAllUsersAnswered`;
  postResource(path, data, onSuccess, onFail);
}
export function declareWinner(data, onSuccess, onFail) {
  const path = `api/question/setIsWinner`;
  postResource(path, data, onSuccess, onFail);
}
