import React, { useState } from "react";
import { Button, Col, Form, Input, Row, Space } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "modules/SubComponents/Header";
import SubHeader from "modules/SubComponents/SubHeader";
import SecondaryButton from "modules/SubComponents/Buttons/SecondaryButton";
import PrimaryButton from "modules/SubComponents/Buttons/PrimaryButton";
import editImage from "assets/icons/pen.svg";
import { baseUrl } from "services";
import { useEffect } from "react";
import ROUTES from "constants/NewRoutes";
import { toastError, toastSuccess } from "helpers/toasters";
import { editActor, uploadImage } from "Network";

function EditActor() {
  const [mode, setMode] = useState(null);
  const [loading, setLoading] = useState(false);
  const [actorImagePath, setActorImagePath] = useState("");
  const [englishActorName, setEnglishActorName] = useState(""); //
  const [englishActorNameError, setEnglishActorNameError] = useState(false); //
  const [arabicActorName, setArabicActorName] = useState(""); //
  const [arabicActorNameError, setArabicActorNameError] = useState(false); //
  const [actorImageError, setActorImageError] = useState(false); //
  const location = useLocation();
  const { searchVal, selectedPage } = location?.state;
  const navigate = useNavigate();

  useEffect(() => {
    if (location?.state) {
      setEnglishActorName(location.state?.nameEn);
      setArabicActorName(location.state?.nameAr);
      setActorImagePath(location.state?.imgPath);
      setMode(location.state?.mode);
    } else {
      navigate(ROUTES.CAST);
    }
  }, [location, navigate]);

  function handleBackButton() {
    navigate(ROUTES.CAST, {
      state: {
        currentPage: selectedPage,
        searchVal,
      },
    });
  }

  const handleEnglishActorNameOnChange = (e) => {
    if (mode === "edit") {
      setEnglishActorName(e.target.value);
      setEnglishActorNameError(false);
    }
  };

  const handleArabicActorNameOnChange = (e) => {
    if (mode === "edit") {
      setArabicActorName(e.target.value);
      setArabicActorNameError(false);
    }
  };

  function handleEditImage(e) {
    const actorFile = e.target.files[0];
    if (actorFile.type.includes("image")) {
      const actorData = new FormData();
      actorData.append("img", actorFile);
      uploadImage(
        actorData,
        (success) => {
          if (success?.isSuccess) {
            setActorImagePath(success.data?.filePath);
            setActorImageError(false);
          }
        },
        (fail) => {
          toastError();
        }
      );
    } else {
      toastError("Please, choose an image file ! ");
    }
  }

  const data = {
    actorId: location?.state?.actorId,
    imgPath: actorImagePath,
    nameEn: englishActorName,
    nameAr: arabicActorName,
  };

  function onSubmit() {
    if (mode === "edit") {
      if (englishActorName?.length === 0) {
        setEnglishActorNameError(true);
      }
      if (arabicActorName?.length === 0) {
        setArabicActorNameError(true);
      }
      if (actorImagePath?.length === 0) {
        setActorImageError(true);
      }
      if (
        englishActorName?.length > 0 &&
        arabicActorName?.length > 0 &&
        actorImagePath?.length > 0
      ) {
        setLoading(true);
        editActor(
          data,
          (success) => {
            if (success?.isSuccess) {
              toastSuccess(success.message);
              setLoading(false);
              navigate(ROUTES.CAST, {
                state: {
                  currentPage: selectedPage,
                  searchVal,
                },
              });
            } else {
              toastError(success.err_msg);
              setLoading(false);
            }
          },
          (fail) => {
            if (fail?.data) {
              toastError(fail.data?.err_msg);
              setLoading(false);
            } else {
              toastError();
              setLoading(false);
            }
          }
        );
      }
    }
  }

  return (
    <section className="users ">
      <div className="users__all-contents ">
        <div className="users__content">
          <div className="users__usersData">
            <Header title="Cast" />
            <SubHeader
              bgColor="#F58234"
              title={mode === "edit" ? "Edit Actor" : "View Actor"}
            />
            <div className="edit-actor  ">
              <Form className="edit-actor__content " onFinish={onSubmit}>
                <div className="edit-actor__image-name ">
                  <div
                    className={`edit-actor__image ${
                      actorImageError && "bord-red radius-12"
                    }`}
                  >
                    {location?.state?.imgPath && mode === "edit" && (
                      <div className="new-actor__close-image ">
                        <label htmlFor="edit-image">
                          <img src={editImage} alt="" role="button" />
                        </label>
                      </div>
                    )}
                    {actorImageError && (
                      <div className="episode-errors">
                        {"Please enter the actor image "}
                      </div>
                    )}
                    <input
                      type={"file"}
                      onChange={handleEditImage}
                      id="edit-image"
                      className="d-none"
                    />
                    <figure className="edit-actor__figure ">
                      <img
                        src={
                          mode === "edit"
                            ? actorImagePath
                              ? baseUrl + actorImagePath
                              : baseUrl + location?.state?.imgPath
                            : baseUrl + location?.state?.imgPath
                        }
                        alt=""
                        className="edit-actor__img "
                      />
                    </figure>
                  </div>

                  <div className="edit-actor__names ">
                    <div className="edit-actor__name ">
                      <label className="users__parent-info-label m-0 fs-16 fw-400">
                        English Actor Name
                      </label>
                      <Input
                        readOnly={mode === "edit" ? false : true}
                        value={englishActorName}
                        type="text"
                        className={`search__searchField py-2 ${
                          englishActorNameError && "bord-red"
                        }`}
                        onChange={handleEnglishActorNameOnChange}
                      />
                      {englishActorNameError && (
                        <div className="episode-errors">
                          {"Please enter the actor name "}
                        </div>
                      )}
                    </div>
                    <div className="edit-actor__name mrg-ys-10">
                      <label className="users__parent-info-label m-0 fs-16 fw-400 flex justify-end">
                        اسم الممثل بالعربية
                      </label>
                      <Input
                        readOnly={mode === "edit" ? false : true}
                        dir="rtl"
                        value={arabicActorName}
                        type="text"
                        className={`search__searchField py-2 ${
                          arabicActorNameError && "bord-red"
                        }`}
                        onChange={handleArabicActorNameOnChange}
                      />
                      {arabicActorNameError && (
                        <div className="episode-errors-ltr">
                          {"من فضلك ادخل اسم الممثل  "}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <Row justify="end" className="mrg-ys-20">
                  <Col>
                    {mode === "edit" ? (
                      <Space size={30}>
                        <SecondaryButton
                          buttonName="Back"
                          onClick={handleBackButton}
                        />
                        <PrimaryButton
                          loading={loading}
                          buttonName="Save"
                          type="submit"
                        />
                      </Space>
                    ) : (
                      <Button
                        onClick={handleBackButton}
                        className="primary-popup-button "
                      >
                        Back
                      </Button>
                    )}
                  </Col>
                </Row>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default EditActor;
