import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Form, Row, Col, Button, Input } from "antd";
import { Container } from "react-bootstrap";
import WebsiteTitle from "helpers/WebsiteTitle";
import { useTranslation } from "react-i18next";
import { toastError, toastSuccess } from "helpers/toasters";
import { login } from "store/authorization";
import {
  getAllEpisodesOnSystem,
  getAllGenresOnSystem,
  getAllLanguageOnSystem,
  getAllPartsOnSystem,
  getAllSeasonsOnSystem,
  getAllVideoQualities,
  signinAdmin,
} from "Network";
import {
  genresOnSystem,
  seasonsOnSystem,
  episodesOnSystem,
  languagesOnSystem,
  videoQualitiesOnSystem,
  partsOnSystem,
} from "store/staticData";
import { updateCurrentPath } from "store/dynamicData";

function SignIn() {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const getGenres = () => {
    getAllGenresOnSystem(
      (success) => {
        if (success?.isSuccess) {
          dispatch(genresOnSystem(success.data));
        }
      },
      (fail) => {
        toastError(fail.err_msg);
      }
    );
  };
  const getSeasons = () => {
    getAllSeasonsOnSystem(
      (success) => {
        if (success?.isSuccess) {
          dispatch(seasonsOnSystem(success.data));
        }
      },
      (fail) => {
        if (fail.data) {
          toastError(fail.data.err_msg);
        }
        toastError();
      }
    );
  };
  const getEpisodes = () => {
    getAllEpisodesOnSystem(
      (success) => {
        if (success?.isSuccess) {
          dispatch(episodesOnSystem(success.data));
        }
      },
      (fail) => {
        if (fail?.data) {
          toastError(fail.data.err_msg);
        } else {
          toastError();
        }
      }
    );
  };
  const getParts = () => {
    getAllPartsOnSystem(
      (success) => {
        if (success?.isSuccess) {
          dispatch(partsOnSystem(success.data));
        }
      },
      (fail) => {
        if (fail?.data) {
          toastError(fail.data.err_msg);
        } else {
          toastError();
        }
      }
    );
  };
  const getLanguages = () => {
    getAllLanguageOnSystem(
      (success) => {
        if (success?.isSuccess) {
          dispatch(languagesOnSystem(success.data));
        }
      },
      (fail) => {
        if (fail.data) {
          toastError(fail.data.err_msg);
        }
        toastError();
      }
    );
  };
  const getVideoQualities = () => {
    getAllVideoQualities(
      (success) => {
        if (success?.isSuccess) {
          dispatch(videoQualitiesOnSystem(success.data));
        }
      },
      (fail) => {
        if (fail.data) {
          toastError(fail.data.err_msg);
        }
      }
    );
  };

  function onFinish(values) {
    if (values?.email && values?.password) {
      setLoading(true);
      signinAdmin(
        values,
        (success) => {
          setLoading(false);
          if (success?.isSuccess) {
            toastSuccess(t("loginSuccessfully"));
            dispatch(login(success?.data));
            dispatch(updateCurrentPath("dashboard"));
            getGenres();
            getSeasons();
            getEpisodes();
            getParts();
            getLanguages();
            getVideoQualities();
          }
        },
        (fail) => {
          setLoading(false);
          toastError(fail?.data?.err_msg);
        }
      );
    }
  }

  return (
    <section className="sign-in main-section ">
      <WebsiteTitle title={"Signin"} />
      <Container>
        <div className="title  ">{t("signin")}</div>
        <Row className="mrg-ys-100">
          <Col
            xs={{ offset: 3, span: 18 }}
            sm={{ offset: 3, span: 18 }}
            md={{ offset: 6, span: 12 }}
          >
            <Form onFinish={onFinish}>
              <Row gutter={[0, 8]}>
                <Col span={24}>
                  <Form.Item
                    name="email"
                    placeholder={t("email")}
                    rules={[
                      { required: true, message: t("provideYourEmail") },
                      {
                        type: "email",
                        message: t("pleaseProvideValidEmail"),
                      },
                    ]}
                  >
                    <Input className={"form-item"} placeholder={t("email")} />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="password"
                    rules={[
                      { required: true, message: t("providePassword") },
                    ]}
                  >
                    <Input.Password
                      className={"form-item"}
                      placeholder={t("password")}
                    />
                  </Form.Item>
                </Col>
                <Col
                  xs={{ offset: 3, span: 18 }}
                  sm={{ offset: 3, span: 18 }}
                  md={{ offset: 5, span: 14 }}
                >
                  <Button
                    loading={loading}
                    className="btn-login button-primary "
                    htmlType="submit"
                  >
                    {t("signin")}
                  </Button>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default SignIn;
