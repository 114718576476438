import React from "react";
import { Form, Input } from "antd";
import magnifyingGlass from "assets/icons/search.svg";
import { useDispatch, useSelector } from "react-redux";
import { searchInputValue } from "store/dynamicData";

function Search({ placeholder, getSearchedValue }) {
  const dispatch = useDispatch();
  const { dynamicDataOnSystem } = useSelector((state) => state?.dynamicData);

  const handleSearchOnChange = (e) => {
    dispatch(searchInputValue(e.target.value));
  };

  const submitSearch = (e) => {
    e.preventDefault();
  };

  return (
    <Form className="search" onFinish={submitSearch}>
      <div className="search__form-input ">
        <Input
          type="text"
          className="search__searchField py-2"
          placeholder={placeholder}
          value={dynamicDataOnSystem?.searchInputValue}
          // value={searchValue}
          onChange={handleSearchOnChange}
          prefix={
            <button className="search__button" type="submit">
              <img src={magnifyingGlass} alt="search" />
            </button>
          }
        />
      </div>
    </Form>
  );
}

export default Search;
