import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import SubHeader from "modules/SubComponents/SubHeader";
import ROUTES from "constants/NewRoutes";
import { Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import CategoriesByGenreId from "modules/TVShows/CategoriesDependsOnGenre";
import SeriesOrMovieModal from "./SeriesOrMovieModal";
import { useDispatch, useSelector } from "react-redux";
import { emptySearchInputValue } from "store/dynamicData";

function TVShows() {
  const [isSeriesMovieVisible, setIsSeriesMovieVisible] = useState(false);
  const [selectedGenreId, setSelectedGenreId] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const { genresOnSystem } = useSelector(
    (state) => state?.staticData?.staticDataOnSystem,
  );

  const [genreId, setGenreId] = useState(null);
  const [genreName, setGenreName] = useState(null);

  useEffect(() => {
    if (location.pathname === "/anime") {
      setGenreId(genresOnSystem?.length && genresOnSystem[2]?.id);
      setGenreName(genresOnSystem?.length && genresOnSystem[2]?.name);
    } else if (location.pathname === "/tv-shows") {
      setGenreName(genresOnSystem?.length && genresOnSystem[0]?.name);
      setGenreId(genresOnSystem?.length && genresOnSystem[0]?.id);
    } else if (location.pathname === "/tv-programs") {
      setGenreName(genresOnSystem?.length && genresOnSystem[3]?.name);
      setGenreId(genresOnSystem?.length && genresOnSystem[3]?.id);
    } else if (location.pathname === "/fun-store") {
      setGenreName(genresOnSystem?.length && genresOnSystem[4]?.name);
      setGenreId(genresOnSystem?.length && genresOnSystem[4]?.id);
    } else if (location.pathname === "/movies") {
      setGenreName(genresOnSystem?.length && genresOnSystem[1]?.name);
      setGenreId(genresOnSystem?.length && genresOnSystem[1]?.id);
    }
  }, [genreId, location.pathname]);

  useEffect(() => {
    if (genreId) {
      setSelectedGenreId(genreId);
      dispatch(emptySearchInputValue());
    }
  }, [genreId, dispatch]);

  const handleAddNewTVShows = () => {
    if (genreName === "Series" || genreName === "Know it") {
      navigate(ROUTES.ADDNEWHEADER, {
        state: { genreId, genreName },
      });
    } else if (genreName === "Movies") {
      navigate(ROUTES.ADDNEWMOVIE, {
        state: { genreId, genreName },
      });
    } else {
      setIsSeriesMovieVisible(true);
    }
  };

  const handleSelectSeriesOrMovieFromModal = (isSeries) => {
    if (isSeries) {
      navigate(ROUTES.ADDNEWHEADER, {
        state: { genreId, genreName },
      });
    } else {
      navigate(ROUTES.ADDNEWMOVIE, {
        state: { genreId, genreName },
      });
    }
  };

  const onCancel = () => {
    setIsSeriesMovieVisible(false);
  };
  return (
    <section className='users'>
      <div className='users__all-contents '>
        <div className='users__content'>
          <div className='users__usersData'>
            <div className='tv-header wd-100 flex centering  mb-5'>
              <div className='tv-header-title '>{genreName}</div>
              <div
                className='tv-header-plus-button  '
                style={{ width: "170px" }}
              >
                <Button
                  className='new-plus-button-tv '
                  onClick={() => handleAddNewTVShows()}
                >
                  <PlusOutlined className='new-plus-button-icon-tv' />
                  {`Add New ${
                    genreName === "Movies"
                      ? "Movie"
                      : genreName === "Series"
                      ? "series"
                      : ""
                  }`}
                </Button>
              </div>
            </div>
            <div className='tv-shows   '>
              <CategoriesByGenreId
                genreName={genreName}
                selectedGenre={selectedGenreId?.length && selectedGenreId}
              />
              <SeriesOrMovieModal
                seriesMovieModalVisible={isSeriesMovieVisible}
                subHeader={
                  <SubHeader
                    bgColor='#F58234'
                    title='Add New'
                    closeButton
                    onCancel={onCancel}
                  />
                }
                primaryBtnName={"Add"}
                secondaryBtnName={"Cancel"}
                onCancel={onCancel}
                action={handleSelectSeriesOrMovieFromModal}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default TVShows;
