import React, { useState, useEffect } from "react";
import { Table } from "antd";
import Navbar from "modules/Navbar/Navbar";
import SideBar from "modules/SideBar/SideBar";
import Header from "modules/SubComponents/Header";
import { Container } from "react-bootstrap";
import AddPackageModal from "./AddPackageModal";
import { editPackage, getAllPackages } from "Network";
import { toastError, toastSuccess } from "helpers/toasters";
import SubHeader from "modules/SubComponents/SubHeader";
import DeleteModal from "modules/Modals/DeleteModal";
import warrningIcon from "assets/icons/warning.svg";

const Subscriptions = () => {
  const [loadingTable, setLoadingTable] = useState(true);
  const [loadingEditModal, setLoadingEditModal] = useState(false);
  const [addModalVisible, setAddModalVisible] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [renderMe, setRenderMe] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);

  useEffect(() => {
    getAllPackages(
      (success) => {
        if (success?.isSuccess) {
          setDataSource(success.data);
          setLoadingTable(false);
        }
      },
      (fail) => {
        toastError(fail?.data?.err_msg);
      }
    );
  }, [renderMe]);

  const showDeleteModal = (record) => {
    setSelectedRowData(record);
    setDeleteModalVisible(true);
  };

  const onCancel = () => {
    setAddModalVisible(false);
    setDeleteModalVisible(false);
    setRenderMe((prev) => !prev);
  };

  const handleAddPackage = () => {
    setAddModalVisible(true);
  };

  const columns = [
    { title: "PACKAGE NAME", key: "packageName", dataIndex: "packageName" },
    {
      title: "MONTHLY PRICE",
      key: "amountPerMonth",
      dataIndex: "amountPerMonth",
    },
    {
      title: "Number Of Months",
      key: "noOfMonths",
      dataIndex: "noOfMonths",
    },
    {
      title: "Discount percentage",
      key: "discount",
      dataIndex: "discount",
    },
    {
      title: "STATUS",
      key: "status",
      dataIndex: "status",
      render: (_, record) => {
        return (
          <span role={"button"} onClick={() => showDeleteModal(record)}>
            {record?.isActive ? "Active" : "Inactive"}
          </span>
        );
      },
    },
  ];

  const handleEditPackageStatus = () => {
    setLoadingEditModal(true);
    if (selectedRowData) {
      editPackage(
        { id: selectedRowData?.id, isActive: !selectedRowData?.isActive },
        (success) => {
          if (success?.isSuccess) {
            toastSuccess(success.message);
            setDeleteModalVisible(false);
            setLoadingEditModal(false);
            setRenderMe((prev) => !prev);
          } else {
            toastError(success.err_msg);
            setLoadingEditModal(false);
          }
        },
        (fail) => {
          if (fail?.data) {
            toastError(fail.data?.err_msg);
            setLoadingEditModal(false);
          } else {
            toastError();
            setLoadingEditModal(false);
          }
        }
      );
    }
  };

  return (
    <section className="users ">
      <div className="users__all-contents ">
        {/* <SideBar /> */}
        <div className="users__content">
          {/* <Navbar /> */}
          {/* <Container className=""> */}
          <div className="users__usersData">
            <Header
              title="Subscriptions"
              plusButtonName="Create"
              plusButtonAction={handleAddPackage}
            />
            <div className="cast-data__table  grid-table ">
              <Table
                rowKey={(row) => row?.id}
                className=""
                columns={columns}
                dataSource={dataSource}
                rowClassName={(record, index) =>
                  index % 2 === 0 && "background-even-records "
                }
                scroll={{ x: 600 }}
                loadingTable={loadingTable}
              />
            </div>
          </div>
          {/* </Container> */}
        </div>
      </div>
      <AddPackageModal
        addModalVisible={addModalVisible}
        SubHeader={
          <SubHeader
            bgColor="#F58234"
            title="Create Package"
            closeButton
            onCancel={onCancel}
          />
        }
        onCancel={onCancel}
        primaryBtnName="Add"
        secondaryBtnName="Cancel"
      />
      <DeleteModal
        deleteModalVisible={deleteModalVisible}
        loading={loadingEditModal}
        SubHeader={
          <SubHeader
            bgColor="#F58234"
            title="Edit Package Status"
            closeButton
            onCancel={onCancel}
          />
        }
        icon={warrningIcon}
        msg={`Are you sure you want to ${
          selectedRowData?.isActive ? "deactivate" : "activate"
        } this package? `}
        onCancel={onCancel}
        action={handleEditPackageStatus}
        secondaryBtnName="Cancel"
        primaryBtnName={`${
          selectedRowData?.isActive ? "Deactivate" : "Activate"
        }`}
      />
    </section>
  );
};

export default Subscriptions;
