// import React, { useState } from "react";
// import { Col, Form, Input, Row, Space } from "antd";
// import Header from "modules/SubComponents/Header";
// import SubHeader from "modules/SubComponents/SubHeader";
// import PrimaryButton from "modules/SubComponents/Buttons/PrimaryButton";
// import SecondaryButton from "modules/SubComponents/Buttons/SecondaryButton";
// import { toastError, toastSuccess } from "helpers/toasters";
// import { UploadOutlined } from "@ant-design/icons";
// import { addAnActor, uploadImage } from "Network";
// import { baseUrl } from "services";
// import ROUTES from "constants/NewRoutes";
// import { useLocation, useNavigate } from "react-router-dom";
// import deleteIcon from "assets/icons/deleteAvatar.svg";
// import uploadBtnIcon from "assets/icons/uploadImageIcon.svg";

// function NewActor() {
//   const [loading, setLoading] = useState(false);
//   const [actorImagePath, setActorImagePath] = useState("");
//   const [showUploadButton, setShowUploadButton] = useState(true);
//   const [showCloseIcon, setShowCloseIcon] = useState(false);
//   const [actorEnglishName, setActorEnglishName] = useState("");
//   const [actorEnglishNameError, setActorEnglishNameError] = useState(false); //
//   const [actorArabicName, setActorArabicName] = useState("");
//   const [actorArabicNameError, setActorArabicNameError] = useState(false); //
//   const [actorImageError, setActorImageError] = useState(false);
//   const navigate = useNavigate();
//   const location = useLocation();
//   const { selectedPage, searchVal } = location?.state;
//   const handleActorEnglishName = (e) => {
//     setActorEnglishName(e.target.value);
//     setActorEnglishNameError(false);
//   };
//   const handleActorArabicName = (e) => {
//     setActorArabicName(e.target.value);
//     setActorArabicNameError(false);
//   };

//   const handleUploadActor = (e) => {
//     const actorFile = e.target.files[0];
//     if (actorFile.type.includes("image")) {
//       const actorData = new FormData();
//       actorData.append("img", actorFile);
//       uploadImage(
//         actorData,
//         (success) => {
//           if (success?.isSuccess) {
//             setActorImagePath(success.data?.filePath);
//             setActorImageError(false);
//             setShowUploadButton(false);
//             setShowCloseIcon(true);
//           }
//         },
//         (fail) => {}
//       );
//     } else {
//       toastError("Please upload an image file");
//     }
//   };

//   const data = {
//     imgPath: actorImagePath,
//     nameEn: actorEnglishName,
//     nameAr: actorArabicName,
//   };

//   function handleOnSubmit() {
//     if (actorImagePath?.length === 0) {
//       setActorImageError(true);
//     }
//     if (actorEnglishName?.length === 0) {
//       setActorEnglishNameError(true);
//     }
//     if (actorArabicName?.length === 0) {
//       setActorArabicNameError(true);
//     }

//     if (
//       actorEnglishName?.length > 0 &&
//       actorArabicName?.length > 0 &&
//       actorImagePath?.length > 0
//     ) {
//       setLoading(true);
//       addAnActor(
//         data,
//         (success) => {
//           if (success?.isSuccess) {
//             toastSuccess(success.message);
//             setLoading(false);
//             navigate(ROUTES.CAST);
//           } else {
//             toastError(success.err_msg);
//             setLoading(false);
//           }
//         },
//         (fail) => {
//           if (fail?.data) {
//             toastError(fail.data?.err_msg);
//             setLoading(false);
//           } else {
//             toastError();
//             setLoading(false);
//           }
//         }
//       );
//     }
//   }

//   const handleBackButton = () => {
//     navigate(ROUTES.CAST, {
//       state: { currentPage: selectedPage, searchVal },
//     });
//   };

//   const closeActorImage = () => {
//     setActorImagePath("");
//     setShowUploadButton(true);
//     setShowCloseIcon(false);
//   };
//   return (
//     <section className="users ">
//       <div className="users__all-contents ">
//         <div className="users__content">
//           <div className="users__usersData">
//             <Header title="Cast" />
//             <SubHeader bgColor="#F58234" title="Add New Actor" />
//             <div className="new-actor ">
//               <Form action="" onFinish={handleOnSubmit}>
//                 <div
//                   className={`new-actor__upload-container mb-2 ${
//                     actorImageError && "bord-red"
//                   } `}
//                 >
//                   {showCloseIcon && (
//                     <div className="new-actor__close-image ">
//                       <img
//                         src={deleteIcon}
//                         alt=""
//                         onClick={closeActorImage}
//                         role="button"
//                       />
//                     </div>
//                   )}
//                   {actorImagePath && (
//                     <figure>
//                       <img
//                         className="new-actor-image"
//                         src={baseUrl + actorImagePath}
//                         alt="actorImage"
//                       />
//                     </figure>
//                   )}
//                   {showUploadButton && (
//                     <div className="new-actor__upload-content  ">
//                       <div className="new-actor__upload-image ">
//                         <img
//                           className="new-actor__upload-img"
//                           src={uploadBtnIcon}
//                           alt="upload"
//                         />
//                       </div>
//                       <input
//                         type={"file"}
//                         onChange={handleUploadActor}
//                         id="upload-actor"
//                         className="d-none"
//                       />
//                       <label htmlFor="upload-actor">
//                         <div
//                           role="button"
//                           className="new-actor__upload-button "
//                         >
//                           <div className="new-actor__upload-icon ">
//                             <UploadOutlined style={{ fontSize: "17px" }} />
//                           </div>
//                           <div className="new-actor__upload-title">
//                             Upload Actor's Image
//                           </div>
//                         </div>
//                       </label>
//                     </div>
//                   )}
//                 </div>
//                 {actorImageError && (
//                   <div className="episode-errors">
//                     {"Please enter the actor image "}
//                   </div>
//                 )}
//                 <div className="new-actor__name mb-5">
//                   <label className="users__parent-info-label mb-2" htmlFor="">
//                     Actor Name
//                   </label>
//                   <Input
//                     type="text"
//                     className={`search__searchField py-2 ${
//                       actorEnglishNameError && "bord-red"
//                     }`}
//                     placeholder="Actor Name"
//                     onChange={handleActorEnglishName}
//                   />
//                   {actorEnglishNameError && (
//                     <div className="episode-errors">
//                       {"Please enter the actor name "}
//                     </div>
//                   )}
//                 </div>
//                 <div className="new-actor__name mb-5">
//                   <label
//                     className="users__parent-info-label mb-2 d-flex justify-content-end"
//                     htmlFor=""
//                   >
//                     اسم الممثل
//                   </label>
//                   <Input
//                     dir="rtl"
//                     type="text"
//                     className={`search__searchField py-2 ${
//                       actorArabicNameError && "bord-red"
//                     }`}
//                     placeholder="اسم الممثل"
//                     onChange={handleActorArabicName}
//                     value={actorArabicName}
//                   />
//                   {actorArabicNameError && (
//                     <div className="episode-errors-ltr">
//                       {"من فضلك ادخل اسم الممثل  "}
//                     </div>
//                   )}
//                 </div>
//                 <Row justify="end">
//                   <Col>
//                     <Space size={30}>
//                       <SecondaryButton
//                         buttonName="Back"
//                         onClick={handleBackButton}
//                       />
//                       <PrimaryButton
//                         loading={loading}
//                         buttonName="Add"
//                         type="submit"
//                       />
//                     </Space>
//                   </Col>
//                 </Row>
//               </Form>
//             </div>
//           </div>
//         </div>
//       </div>
//     </section>
//   );
// }
// export default NewActor;

import React, { useState } from "react";
import { Col, Form, Input, Progress, Row, Space } from "antd";
import Header from "modules/SubComponents/Header";
import SubHeader from "modules/SubComponents/SubHeader";
import PrimaryButton from "modules/SubComponents/Buttons/PrimaryButton";
import SecondaryButton from "modules/SubComponents/Buttons/SecondaryButton";
import { toastError, toastSuccess } from "helpers/toasters";
import { UploadOutlined } from "@ant-design/icons";
import { addAnActor, uploadImage } from "Network";
import { baseUrl } from "services";
import ROUTES from "constants/NewRoutes";
import { useLocation, useNavigate } from "react-router-dom";
import deleteIcon from "assets/icons/deleteAvatar.svg";
import uploadBtnIcon from "assets/icons/uploadImageIcon.svg";
import uploadImageIcon from "assets/icons/upImageIcon.svg";

function NewActor() {
  const [loading, setLoading] = useState(false);
  const [actorImagePath, setActorImagePath] = useState("");
  const [showUploadButton, setShowUploadButton] = useState(true);
  const [showCloseIcon, setShowCloseIcon] = useState(false);
  const [actorEnglishName, setActorEnglishName] = useState("");
  const [actorEnglishNameError, setActorEnglishNameError] = useState(false);
  const [actorArabicName, setActorArabicName] = useState("");
  const [actorArabicNameError, setActorArabicNameError] = useState(false);
  const [actorImageError, setActorImageError] = useState(false);
  const [dragActive, setDragActive] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);

  const navigate = useNavigate();
  const location = useLocation();
  const { selectedPage, searchVal } = location?.state;

  const handleActorEnglishName = (e) => {
    setActorEnglishName(e.target.value);
    setActorEnglishNameError(false);
  };

  const handleActorArabicName = (e) => {
    setActorArabicName(e.target.value);
    setActorArabicNameError(false);
  };

  const handleUploadActor = (actorFile) => {
    if (actorFile.type.includes("image")) {
      const actorData = new FormData();
      actorData.append("img", actorFile);
      uploadImage(
        actorData,
        (success) => {
          if (success?.isSuccess) {
            setActorImagePath(success.data?.filePath);
            setActorImageError(false);
            setShowUploadButton(false);
            setShowCloseIcon(true);
            // setUploadProgress(0);
            // console.log(success);
          }
        },
        (fail) => {}
      );
    } else {
      toastError("Please upload an image file");
    }
  };

  const handleFileChange = (e) => {
    handleUploadActor(e.target.files[0]);
  };

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragIn = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(true);
  };

  const handleDragOut = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleUploadActor(e.dataTransfer.files[0]);
    }
  };

  const data = {
    imgPath: actorImagePath,
    nameEn: actorEnglishName,
    nameAr: actorArabicName,
  };

  function handleOnSubmit() {
    if (actorImagePath?.length === 0) {
      setActorImageError(true);
    }
    if (actorEnglishName?.length === 0) {
      setActorEnglishNameError(true);
    }
    if (actorArabicName?.length === 0) {
      setActorArabicNameError(true);
    }

    if (
      actorEnglishName?.length > 0 &&
      actorArabicName?.length > 0 &&
      actorImagePath?.length > 0
    ) {
      setLoading(true);
      addAnActor(
        data,
        (success) => {
          if (success?.isSuccess) {
            toastSuccess(success.message);
            setLoading(false);
            navigate(ROUTES.CAST);
          } else {
            toastError(success.err_msg);
            setLoading(false);
          }
        },
        (fail) => {
          if (fail?.data) {
            toastError(fail.data?.err_msg);
            setLoading(false);
          } else {
            toastError();
            setLoading(false);
          }
        }
      );
    }
  }

  const handleBackButton = () => {
    navigate(ROUTES.CAST, {
      state: { currentPage: selectedPage, searchVal },
    });
  };

  const closeActorImage = () => {
    setActorImagePath("");
    setShowUploadButton(true);
    setShowCloseIcon(false);
  };

  return (
    <section className="users ">
      <div className="users__all-contents ">
        <div className="users__content">
          <div className="users__usersData">
            <Header title="Cast" />
            <SubHeader bgColor="#F58234" title="Add New Actor" />
            <div className="new-actor ">
              <Form action="" onFinish={handleOnSubmit}>
                <div
                  className={`new-actor__upload-container mb-2 ${
                    actorImageError && "bord-red"
                  }`}
                  onDragEnter={handleDragIn}
                  onMouseEnter={handleDragIn}
                  onDragLeave={handleDragOut}
                  onMouseLeave={handleDragOut}
                  onDragOver={handleDrag}
                  onDrop={handleDrop}
                >
                  {showCloseIcon && (
                    <div className="new-actor__close-image ">
                      <img
                        src={deleteIcon}
                        alt=""
                        onClick={closeActorImage}
                        role="button"
                      />
                    </div>
                  )}
                  {actorImagePath && (
                    <figure>
                      <img
                        className="new-actor-image"
                        src={baseUrl + actorImagePath}
                        alt="actorImage"
                      />
                    </figure>
                  )}
                  {showUploadButton && (
                    <div
                      className="new-actor__upload-content  "
                      style={{
                        width: "100%",
                        height: "100%",
                        border:
                          dragActive && !actorImagePath
                            ? "1px dashed #425dc9"
                            : "",
                        borderRadius: "12px",
                      }}
                    >
                      {/*  */}
                      {/* <div className="new-actor__upload-image ">
                        <img
                          className="new-actor__upload-img"
                          src={uploadBtnIcon}
                          alt="upload"
                        />
                      </div> */}
                      <input
                        type={"file"}
                        onChange={handleFileChange}
                        id="upload-actor"
                        className="d-none"
                      />
                      <label htmlFor="upload-actor">
                        <div
                          role="button"
                          className="new-actor__upload-button "
                          style={{
                            width: "100%",
                            boxShadow: "none",
                            backgroundColor: "transparent",
                            justifyContent: "center",
                            gap: "30px",
                            margin: "auto",
                            alignItems: "center",
                          }}
                        >
                          <div className="new-actor__upload-icon ">
                            <UploadOutlined style={{ fontSize: "17px" }} />
                          </div>
                          <div className="new-actor__upload-title">
                            Upload Actor's Image
                          </div>
                          <img src={uploadImageIcon} alt="" />
                        </div>
                      </label>
                    </div>
                  )}
                </div>
                {uploadProgress > 0 && (
                  <Progress
                    percent={uploadProgress}
                    status="active"
                    showInfo={false}
                    className="upload-progress"
                  />
                )}
                {actorImageError && (
                  <div className="episode-errors">
                    {"Please enter the actor image "}
                  </div>
                )}
                <div className="new-actor__name mb-5">
                  <label className="users__parent-info-label mb-2" htmlFor="">
                    Actor Name
                  </label>
                  <Input
                    type="text"
                    className={`search__searchField py-2 ${
                      actorEnglishNameError && "bord-red"
                    }`}
                    placeholder="Actor Name"
                    onChange={handleActorEnglishName}
                  />
                  {actorEnglishNameError && (
                    <div className="episode-errors">
                      {"Please enter the actor name "}
                    </div>
                  )}
                </div>
                <div className="new-actor__name mb-5">
                  <label
                    className="users__parent-info-label mb-2 d-flex justify-content-end"
                    htmlFor=""
                  >
                    اسم الممثل
                  </label>
                  <Input
                    dir="rtl"
                    type="text"
                    className={`search__searchField py-2 ${
                      actorArabicNameError && "bord-red"
                    }`}
                    placeholder="اسم الممثل"
                    onChange={handleActorArabicName}
                    value={actorArabicName}
                  />
                  {actorArabicNameError && (
                    <div className="episode-errors-ltr">
                      {"من فضلك ادخل اسم الممثل  "}
                    </div>
                  )}
                </div>
                <Row justify="end">
                  <Col>
                    <Space size={30}>
                      <SecondaryButton
                        buttonName="Back"
                        onClick={handleBackButton}
                      />
                      <PrimaryButton
                        loading={loading}
                        buttonName="Add"
                        type="submit"
                      />
                    </Space>
                  </Col>
                </Row>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default NewActor;
