import React, { useEffect, useState } from "react";
import { Col, Input, Row, Select, Tabs } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { baseUrl } from "services";
import { toastError } from "helpers/toasters";
import Header from "modules/SubComponents/Header";
import SubHeader from "modules/SubComponents/SubHeader";
import ROUTES from "constants/NewRoutes";
import ViewPartM3u8 from "./ViewPartM3u8";
import {
  getAllCategoryDataByGenre,
  getAllVideosDetailsForMovie,
} from "Network";
import { dropdownStyle } from "helpers/helpfulFunctions";

function ViewMovieM3u8() {
  const location = useLocation();
  const { selectedHeaderId, genreId, genreName } = location?.state;
  const [allMovieDataWithVideos, setAllMovieDataWithVideos] = useState([]);
  const [allPartsVideos, setAllPartsVideos] = useState([]);
  const [categoryName, setCategoryName] = useState(null);
  const [currentPartsCast, setCurrentPartsCast] = useState([]);
  const [selectedPartId, setSelectedPartId] = useState(null);
  const [castsByPartId, setCastsByPartId] = useState([]);

  const { languagesOnSystem, partsOnSystem } = useSelector(
    (state) => state?.staticData?.staticDataOnSystem
  );
  const navigate = useNavigate();
  // get all movie data
  useEffect(() => {
    if (selectedHeaderId?.length > 0) {
      getAllVideosDetailsForMovie(
        { videoHeaderId: selectedHeaderId },
        (success) => {
          if (success?.isSuccess) {
            setAllMovieDataWithVideos(success.data[0]);
            let allParts = [];
            let videos = success.data[0].videos;
            if (videos) {
              videos.map((item, idx) =>
                allParts.push({
                  label: item?.episodePart,
                  key: idx,
                  children: (
                    <ViewPartM3u8
                      headerId={success.data[0]?.videoHeaderId}
                      partId={item?.id}
                    />
                  ),
                })
              );
              setAllPartsVideos(allParts);
            }
          }
        },
        (fail) => {
          toastError(fail.data.err_msg);
        }
      );
    } else {
      navigate(ROUTES.TVSHOWS, {
        state: { genreId },
      });
    }
  }, [selectedHeaderId, navigate, genreId]);

  useEffect(() => {
    if (allMovieDataWithVideos?.genreId?.length > 0) {
      getAllCategoryDataByGenre(
        { genreId: allMovieDataWithVideos.genreId },
        (success) => {
          if (success?.isSuccess) {
            const categoriesByGenre = success.data.filter(
              (category) =>
                category?.categoryId === allMovieDataWithVideos.categoryId
            );
            setCategoryName(categoriesByGenre[0]?.category);
          }
        },
        (fail) => {
          if (fail?.data?.error) {
            toastError(fail.data.err_msg);
          }
        }
      );
    }
  }, [allMovieDataWithVideos]);
  //get current casts for every part
  useEffect(() => {
    if (allMovieDataWithVideos?.castIds?.length > 0) {
      //these changes after editing the api esponse
      setCurrentPartsCast(allMovieDataWithVideos.castIds);
      setSelectedPartId(allMovieDataWithVideos.castIds[0]?.partId);
      setCastsByPartId(allMovieDataWithVideos.castIds[0]?.casts);
    }
  }, [allMovieDataWithVideos, partsOnSystem]);

  const getCastsByPartId = (partId) => {
    //get all casts for this part
    if (partId) {
      const castsByPart = currentPartsCast?.filter(
        (partCasts) => partCasts?.partId === partId
      )[0]?.casts;
      setCastsByPartId(castsByPart);
    }
  };

  const handleSelectPart = (partId) => {
    if (partId?.length > 0) {
      setSelectedPartId(partId);
      getCastsByPartId(partId);
    }
  };

  return (
    <section className="users">
      <div className="users__all-contents ">
        <div className="users__content">
          <div className="tv-shows wd-100 ">
            <Header
              title={
                genreName === "Movies" ? genreName : genreName + " | Movie"
              }
            />
            <div className="tvshows-form wd-100 pad-x-20 ">
              <Row gutter={[24, 24]} className="mrg-ye-20">
                <Col span={24}>
                  <div className="new-actor__name new-category ">
                    <label className="users__parent-info-label" htmlFor="">
                      Title
                    </label>
                    <Input
                      type="text"
                      className="search__searchField py-2"
                      value={allMovieDataWithVideos?.title}
                    />
                  </div>
                </Col>
                <Col className="gutter-row" span={24}>
                  <div className="new-actor__name new-category">
                    <label className="users__parent-info-label" htmlFor="">
                      Category
                    </label>
                    <Input
                      type="text"
                      className="search__searchField py-2"
                      value={categoryName}
                    />
                  </div>
                </Col>
                <Col xs={24} md={12}>
                  <div className="new-actor__name new-category select-genre">
                    <label className="users__parent-info-label " htmlFor="">
                      Choose Part
                    </label>
                    <Select
                      className="search__searchField py-1 wd-100"
                      value={selectedPartId}
                      onChange={handleSelectPart}
                      dropdownStyle={dropdownStyle}
                    >
                      {currentPartsCast?.map(({ partId, part }, index) => (
                        <Select.Option key={index} value={partId}>
                          {part}
                        </Select.Option>
                      ))}
                    </Select>
                  </div>
                </Col>
                <Col xs={24} md={12}>
                  <div className="new-actor__name new-category  select-genre">
                    <label className="users__parent-info-label " htmlFor="">
                      Cast of this part
                    </label>
                    <Select
                      maxTagCount="responsive"
                      maxTagTextLength={5}
                      mode="multiple"
                      className="search__searchField py-1 wd-100"
                      value={castsByPartId.map((c) => c._id)}
                      dropdownStyle={dropdownStyle}
                    >
                      {castsByPartId.map((actor, actorIndex) => (
                        <Select.Option value={actor?._id} key={actorIndex}>
                          {actor?.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <SubHeader bgColor="#27A2D9" title="Image" noPadding />
                </Col>
                <Col span={24}>
                  <div className="upload-files mrg-ye-30">
                    <div className="upload-files__container  relative">
                      <figure>
                        <img
                          className="upload-files__upload-file"
                          src={baseUrl + allMovieDataWithVideos?.imgPath}
                          alt="actorImage"
                        />
                      </figure>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <div className="mrg-ye-30">
                    <Tabs
                      tabPosition="top"
                      size={"large"}
                      tabBarGutter={80}
                      type="line"
                      items={allPartsVideos}
                      destroyInactiveTabPane={true}
                    />
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ViewMovieM3u8;
