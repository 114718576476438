import { Upload } from "antd";
import { customUploadFile } from "Network";
import { toastError } from "helpers/toasters";
import ImgCrop from "antd-img-crop";
import axios from "axios";
import { useState } from "react";
import { baseUrl } from "services";
import Resumable from "resumablejs";
import { useSelector } from "react-redux";

function CustomFileUploader({
  setProgressPercent,
  setShowProgressBar,
  setFilePath,
  children,
  acceptedTypes,
  formDataType,
  withCrop,
  aspect,
  disableMe,
  selectedEpisodeIndex,
  episodeUploadingOptions,
  // setVideoOptions,
}) {
  const [cancelToken, setCancelToken] = useState(null);
  const { authorization } = useSelector((state) => state.auth);
  const token = authorization?.token;

  // const customUploadVideo = (formDataType, file) => {
  //   const resumable = new Resumable({
  //     target: `${baseUrl}api/${formDataType}/upload/chunk`, // The URL to upload the file to
  //     chunkSize: 1024 * 1024, // The size of each chunk in bytes (default is 1MB)
  //     simultaneousUploads: 4, // The number of chunks to upload simultaneously (default is 3)
  //     testChunks: true, // Whether to test each chunk before uploading (default is true)
  //     throttleProgressCallbacks: 1, // How often to update the progress bar (default is 0.5 seconds)
  //     xhr: { headers: { Authorization: `Bearer ${token}` } },
  //     headers: { Authorization: `Bearer ${token}` },
  //     method: "POST",
  //   });
  //   resumable.addFile(file);
  //   resumable.on("fileAdded", (file) => {
  //
  //     resumable.upload();
  //   });

  //   resumable.on("fileProgress", (file) => {
  //     const progress = file.progress() * 100;
  //     if (Math.round(progress)) {
  //       setProgressPercent(Math.round(progress));
  //     }
  //   });

  //   resumable.on("fileSuccess", (file, message) => {
  //
  //     const { size, uniqueIdentifier, fileName } = file;
  //     axios
  //       .post(
  //         `${baseUrl}api/video/upload/complete`,
  //         { uniqueIdentifier, size, fileName },
  //         {
  //           headers: {
  //             Authorization: `Bearer ${token}`, // Add your authorization token here
  //           },
  //         },
  //       )
  //       .then(() => {
  //
  //       });
  //   });

  //   resumable.on("fileError", (file, message) => {
  //     console.error("File error:", file, message);
  //   });
  //   resumable.on("chunkingComplete", (file) => {
  //     const { size, uniqueIdentifier } = file;

  //     // Send a POST request to the server to start the upload
  //     axios
  //       .post(
  //         `${baseUrl}api/video/upload/start`,
  //         { size, uniqueIdentifier },
  //         {
  //           headers: {
  //             Authorization: `Bearer ${token}`, // Add your authorization token here
  //           },
  //         },
  //       )
  //       .then(() => {
  //         // Iterate over each chunk and upload it using Axios
  //         const chunks =
  //           resumable.getFromUniqueIdentifier(
  //             uniqueIdentifier,
  //           ).chunks;

  //         for (const chunk of chunks) {
  //           const chunkData = new FormData();
  //           chunkData.append("video", chunk.file, chunk.filename);

  //           axios({
  //             url: `${baseUrl}api/video/upload/chunk`,
  //             data: chunkData,
  //             headers: {
  //               "Content-Type": "multipart/form-data",
  //               Authorization: `Bearer ${token}`, // Add your authorization token here
  //             },
  //           }).then((res) => {
  //
  //           });
  //         }

  //         // Send a POST request to the server to complete the upload
  //         axios
  //           .post(
  //             `${baseUrl}api/video/upload/complete`,
  //             { uniqueIdentifier },
  //             {
  //               headers: {
  //                 Authorization: `Bearer ${token}`, // Add your authorization token here
  //               },
  //             },
  //           )
  //           .then(() => {
  //             setProgressPercent(100);
  //
  //           });
  //       });
  //   });
  // };
  const uploadMe = (file) => {
    const chunkSize = 1024 * 1024;
    const data = new FormData();
    data.append(formDataType, file);
    setShowProgressBar(true);
    const source = axios.CancelToken.source();
    setCancelToken(source);

    // if (formDataType === "video") {
    // 	customUploadVideo(formDataType, file);
    // } else {
    customUploadFile(
      { formDataType },
      data,
      (progress) => {
        const { loaded, total } = progress;
        let progressData = (loaded / total) * 100;
        if (progress) {
          if (formDataType === "video") {
            setProgressPercent(progressData);
          } else {
            setProgressPercent(Math.round(progressData));
          }
        }
      },
      (success) => {
        if (success?.isSuccess) {
          if (formDataType === "img") {
            setFilePath(success.data?.filePath);
          } else if (formDataType === "video") {
            setFilePath(
              success.data?.filePath,
              success.data?.lowQualityfilePath,
              success.data?.fileName,
              success.data?.duration
            );
          } else {
            setFilePath(success.data?.filePath, success.data?.fileName);
          }
        }
      },
      (fail) => {
        if (fail) {
          toastError(fail?.data?.err_msg);
        } else {
          // toastError("An error occured during upload");
        }
      }
      // source.token
    );
    // }
  };
  function customUploader(options) {
    const { file } = options;
    if (formDataType === "video") {
      if (file?.type === "video/mp4") {
        uploadMe(file);
      } else {
        toastError(
          "You can not upload a video in a format other than the .MP4"
        );
      }
    } else if (formDataType === "audio") {
      if (file?.type === "audio/mpeg") {
        uploadMe(file);
      } else {
        toastError(
          "You can not upload an audio in a format other than the .MP3"
        );
      }
    } else {
      uploadMe(file);
    }
  }

  const handleBeforeUpload = () => {
    if (
      episodeUploadingOptions?.isUploading &&
      episodeUploadingOptions?.episodeIndex === selectedEpisodeIndex
    ) {
      if (cancelToken) {
        cancelToken.cancel();
        setProgressPercent(0);
        setFilePath("", "", "");
      }
      //Here we must abort previous uploading
      // if (episodeUploadingOptions?.videoOPtions) {
      // episodeUploadingOptions.videoOPtions?.cancel();
      // setProgressPercent(0);
      // setFilePath("", "", "");
      // }
    }
  };

  return (
    <>
      {withCrop ? (
        <ImgCrop zoomSlider={true} rotateSlider={false} aspect={aspect}>
          <Upload
            {...(acceptedTypes && { accept: acceptedTypes })}
            customRequest={customUploader}
            showUploadList={false}
            disabled={disableMe}
          >
            {children}
          </Upload>
        </ImgCrop>
      ) : (
        <>
          <Upload
            {...(acceptedTypes && { accept: acceptedTypes })}
            customRequest={customUploader}
            showUploadList={false}
            disabled={disableMe}
            beforeUpload={handleBeforeUpload}
          >
            {children}
          </Upload>
        </>
      )}
    </>
  );
}

export default CustomFileUploader;
