import React from "react";
import { Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";

function PlusButton({
  buttonName,
  action,
  styling,
  type,
  disableMe,
  breakpointData,
}) {
  return (
    <Button
      onClick={action}
      htmlType={type ? type : "button"}
      className={styling ? "secondary-popup-button" : "plus-button"}
      icon={<PlusOutlined />}
      disabled={disableMe}
      // style={{
      //   width: breakpointData?.winWidth > 768 ? "170px" : "fit-content",
      // }}
    >
      {buttonName}
    </Button>
  );
}

export default PlusButton;
