import React, { Fragment, useEffect, useRef, useState } from "react";
import { Button, Col, Divider, Form, Input, Progress, Row, Select } from "antd";
import SecondaryPlusButton from "modules/SubComponents/Buttons/SecondaryPlusButton";
import Header from "modules/SubComponents/Header";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { baseUrl } from "services";
import uploadButton from "assets/icons/uploadButton.svg";
import uploadImageIcon from "assets/icons/upImageIcon.svg";
import PrimaryButton from "modules/SubComponents/Buttons/PrimaryButton";
import { toastError, toastSuccess } from "helpers/toasters";
import AddEpisodes from "./AddEpisodes";
import editIcon from "assets/icons/pen.svg";
import {
  addHeaderToTVShows,
  editTvHeader,
  getAllActors,
  getAllCategoriesByGenreId,
} from "Network";
import { dropdownStyle } from "helpers/helpfulFunctions";
import DraggerUploader from "modules/SubComponents/Upload/DraggerUploader";
import AddEpisodesM3u8 from "./AddEpisodesM3u8";

function AddNewHeaderM3u8() {
  const { languagesOnSystem, episodesOnSystem, seasonsOnSystem } = useSelector(
    (state) => state?.staticData?.staticDataOnSystem
  );
  const [mode, setMode] = useState("add");
  const [addSeries, setAddSeries] = useState([
    {
      videoHeaderId: "",
      title: "",
      genreId: "",
      categoryId: "",
      seasonsCast: [{ seasonId: seasonsOnSystem[0].indexId, castIds: [] }],
      imgPath: "",
      isSeries: true,
    },
  ]);

  const [addSeriesErrors, setAddSeriesErrors] = useState([
    {
      hasError: false,
      error: "Please provide title",
    },
    {
      hasError: false,
      error: "Please select genre",
    },
    {
      hasError: false,
      error: "Please select category",
    },
    {
      seasons: [{ hasError: false, error: "Please select season" }],
    },
    {
      casts: [{ hasError: false, error: "Please select casts" }],
    },
    {
      hasError: false,
      error: "Please upload an  image",
    },
  ]);
  const [loading, setLoading] = useState(false);
  const [isSeriesAdded, setIsSeriesAdded] = useState(false);
  const [seriesAddedId, setSeriesAddedId] = useState(null);
  const [numOfEpisodes, setNumOfEpisodes] = useState(1);
  const [episodesCount, setEpisodesCount] = useState(null);
  const [categoriesByGenreId, setCategoriesByGenreId] = useState([]);
  const [allActorsOnSystem, setAllActorsOnSystem] = useState([]);
  const [allLanguagesOnSystem, setAllLanguagesOnSystem] = useState([]);
  const [progressPercent, setProgressPercent] = useState(0);
  const [showProgressBar, setShowProgressBar] = useState(false);

  const location = useLocation();
  const { genreId, genreName } = location?.state;

  useEffect(() => {
    let allSeries = [...addSeries];
    allSeries[0].genreId = genreId;
    setAddSeries(allSeries);
    //eslint-disable-next-line
  }, [genreId]);

  useEffect(() => {
    let languages = [];
    getAllActors(
      (success) => {
        if (success?.isSuccess) {
          let casts = [];
          success.data.forEach((cast, castIndex) => {
            casts.push({ label: cast?.name, value: cast?.id });
          });
          setAllActorsOnSystem(casts);
        }
      },
      (fail) => {
        if (fail.data) {
          toastError(fail.data.err_msg);
        }
        toastError();
      }
    );
    getAllCategoriesByGenreId(
      { genreId },
      (success) => {
        if (success?.isSuccess) {
          setCategoriesByGenreId(success?.data);
        }
      },
      (fail) => {
        if (fail?.data?.error) {
          toastError(fail.data.err_msg);
        }
      }
    );

    languagesOnSystem.forEach((lang) =>
      languages.push({ label: lang?.name, value: lang?.id })
    );
    setAllLanguagesOnSystem(languages);
    //eslint-disable-next-line
  }, []);

  const handleChangeTitle = (e) => {
    let allSeries = [...addSeries];
    if (typeof e.target.value === "string") {
      allSeries[0].title = e.target.value;
      setAddSeries(allSeries);
      let allSeriesErrors = [...addSeriesErrors];
      allSeriesErrors[0].hasError = false;
      setAddSeriesErrors(allSeriesErrors);
    } else {
      toastError("numbers not allowed");
    }
  };

  const handleSelectCategory = (categoryId) => {
    if (categoryId?.length > 0) {
      let allSeries = [...addSeries];
      allSeries[0].categoryId = categoryId;
      setAddSeries(allSeries);
      let allSeriesErrors = [...addSeriesErrors];
      allSeriesErrors[2].hasError = false;
      setAddSeriesErrors(allSeriesErrors);
    }
  };

  const handleSelectSeason = (seasonId, seasonCastIndex) => {
    if (seasonId?.length > 0) {
      let allSeriess = [...addSeries];
      const seasonIsFound = allSeriess[0].seasonsCast.some(
        (part) => part.seasonId === seasonId
      );
      if (seasonIsFound) {
        toastError("This season was selected before!");
        allSeriess[0].seasonsCast[seasonCastIndex].seasonId = "";
        setAddSeries(allSeriess);
      } else {
        allSeriess[0].seasonsCast[seasonCastIndex].seasonId = seasonId;
        setAddSeries(allSeriess);
        let allSeriesErrors = [...addSeriesErrors];
        allSeriesErrors[3].seasons[seasonCastIndex].hasError = false;
        setAddSeriesErrors(allSeriesErrors);
      }
    }
  };

  const handleChangeCasts = (castsId, seasonCastIndex) => {
    // if (castsId?.length > 0) {
    let allSeries = [...addSeries];
    allSeries[0].seasonsCast[seasonCastIndex].castIds = castsId;
    setAddSeries(allSeries);
    let allSeriesErrors = [...addSeriesErrors];
    allSeriesErrors[4].casts[seasonCastIndex].hasError = false;
    setAddSeriesErrors(allSeriesErrors);
    // }
  };

  const handleAddAnotherSeason = () => {
    let allSeries = [...addSeries];
    allSeries[0].seasonsCast.push({
      seasonId: seasonsOnSystem[allSeries[0].seasonsCast.length].indexId,
      castIds: [],
    });
    setAddSeries(allSeries);
    let allSeriesErrors = [...addSeriesErrors];

    allSeriesErrors[3].seasons.push({
      hasError: false,
      error: "Please select season",
    });
    allSeriesErrors[4].casts.push({
      hasError: false,
      error: "Please select casts",
    });
    setAddSeriesErrors(allSeriesErrors);
  };

  const handleDeleteSeasonCasts = (seasonCast, seasonCastIndex) => {
    let allSeries = [...addSeries];
    let allSeriesErrors = [...addSeriesErrors];
    if (allSeries[0].seasonsCast?.length > 1) {
      allSeries[0].seasonsCast.splice(seasonCastIndex, 1);
      allSeries[0].seasonsCast.forEach((season, seasonIndex) => {
        if (seasonIndex >= seasonCastIndex) {
          season.seasonId = seasonsOnSystem[seasonIndex].indexId;
        }
      });
      setAddSeries(allSeries);
      allSeriesErrors[3].seasons.splice(seasonCastIndex, 1);
      allSeriesErrors[4].casts.splice(seasonCastIndex, 1);
      setAddSeriesErrors(allSeriesErrors);
    }
  };

  const handleSetFilePath = (filePath) => {
    if (filePath?.length > 0) {
      let allSeries = [...addSeries];
      allSeries[0].imgPath = filePath;
      setAddSeries(allSeries);
      let allSeriesErrors = [...addSeriesErrors];
      allSeriesErrors[5].hasError = false;
      setAddSeriesErrors(allSeriesErrors);
    }
  };

  const handleUpdateNumOfEpisodes = (num) => {
    setNumOfEpisodes(num);
  };

  const handleChangeNumOfEpisodes = (e) => {
    if (e?.target?.value >= 0 && e?.target?.value <= episodesOnSystem?.length) {
      setNumOfEpisodes(e.target.value);
    }
  };

  const validateAddSeries = () => {
    let allSeriesErrors = [...addSeriesErrors];
    if (addSeries[0]?.title === "") {
      allSeriesErrors[0].hasError = true;
      setAddSeriesErrors(allSeriesErrors);
    }

    if (addSeries[0]?.categoryId === "") {
      allSeriesErrors[2].hasError = true;
      setAddSeriesErrors(allSeriesErrors);
    }

    if (addSeries[0]?.imgPath === "") {
      allSeriesErrors[5].hasError = true;
      setAddSeriesErrors(allSeriesErrors);
    }

    addSeries[0]?.seasonsCast.map((partCast, seasonCastIndex) => {
      if (partCast?.seasonId?.length === 0) {
        allSeriesErrors[3].seasons[seasonCastIndex].hasError = true;
        setAddSeriesErrors(allSeriesErrors);
      }
    });
  };

  const handleOnFinish = () => {
    let allSeries = [...addSeries];

    validateAddSeries();
    const checkAddSeriesError = [];
    let seriesHasError = addSeriesErrors.some(
      (movieError) => movieError?.hasError
    );
    if (seriesHasError) {
      checkAddSeriesError.push(seriesHasError);
    }
    let seasonsHasError = addSeriesErrors[3]?.seasons.some(
      (seasonError) => seasonError?.hasError
    );
    if (seasonsHasError) {
      checkAddSeriesError.push(seasonsHasError);
    }
    let castsHasError = addSeriesErrors[4]?.casts.some(
      (castError) => castError?.hasError
    );
    if (castsHasError) {
      checkAddSeriesError.push(castsHasError);
    }

    if (checkAddSeriesError?.length > 0) {
      toastError("Please, fill all required fields");
    } else {
      setLoading(true);
      const data = addSeries[0];
      if (mode === "add") {
        delete data.videoHeaderId;
        addHeaderToTVShows(
          data,
          (success) => {
            if (success?.isSuccess) {
              setSeriesAddedId(success?.data?.video?._id);
              allSeries[0].videoHeaderId = success?.data?.video?._id;
              setAddSeries(allSeries);
              setIsSeriesAdded(true);
              toastSuccess(success.message);
              setLoading(false);
              setMode("edit");
              setEpisodesCount(numOfEpisodes);
            } else {
              toastError(success.err_msg);
              setLoading(false);
            }
          },
          (fail) => {
            if (fail?.data) {
              toastError(fail?.data?.err_msg);
              setLoading(false);
            } else {
              toastError();
              setLoading(false);
            }
          }
        );
      } else {
        editTvHeader(
          data,
          (success) => {
            if (success?.isSuccess) {
              toastSuccess(success.message);
              setLoading(false);
              setEpisodesCount(numOfEpisodes);
            } else {
              toastError(success.err_msg);
              setLoading(false);
            }
          },
          (fail) => {
            if (fail?.data) {
              toastError(fail.data?.err_msg);
              setLoading(false);
            } else {
              toastError();
              setLoading(false);
            }
          }
        );
      }
    }
  };

  return (
    <section className="users">
      <div className="users__all-contents ">
        <div className="users__content">
          <div className="tv-shows wd-100 ">
            <Header
              title={
                genreName === "Series" ? genreName : genreName + " | Series"
              }
            />
            <Form
              onFinish={handleOnFinish}
              className="tvshows-form wd-100 pad-x-20 "
            >
              <Row gutter={[24, 24]} className="mrg-ye-20">
                <Col span={24}>
                  <div className="new-actor__name new-category ">
                    <label className="view-tvshow-lbl" htmlFor="">
                      Title
                    </label>
                    <Input
                      placeholder="Enter the title"
                      type="text"
                      className="search__searchField py-2 "
                      onChange={handleChangeTitle}
                    />
                  </div>
                  {addSeriesErrors[0]?.hasError && (
                    <div className="episode-errors">
                      {addSeriesErrors[0]?.error}
                    </div>
                  )}
                </Col>
                <Col className="gutter-row" span={24}>
                  <div className="new-actor__name new-category">
                    <label className="view-tvshow-lbl" htmlFor="">
                      Choose Category
                    </label>
                    <Select
                      placeholder="Choose Category"
                      className="search__searchField py-1 wd-100"
                      onChange={handleSelectCategory}
                      dropdownStyle={dropdownStyle}
                    >
                      {categoriesByGenreId?.map(({ id, name }, index) => {
                        return (
                          <Select.Option key={index} value={id}>
                            {name}
                          </Select.Option>
                        );
                      })}
                    </Select>
                    {addSeriesErrors[2]?.hasError && (
                      <div className="episode-errors">
                        {addSeriesErrors[2]?.error}
                      </div>
                    )}
                  </div>
                </Col>
                {addSeries[0]?.seasonsCast.map(
                  (seasonCast, seasonCastIndex) => (
                    <Fragment key={seasonCastIndex}>
                      <Col span={10}>
                        <div className="new-actor__name new-category select-genre">
                          <label className="view-tvshow-lbl" htmlFor="">
                            Choose Season
                          </label>
                          <Select
                            placeholder="Choose Season"
                            className="search__searchField py-1 wd-100"
                            onChange={(seasonId) =>
                              handleSelectSeason(seasonId, seasonCastIndex)
                            }
                            dropdownStyle={dropdownStyle}
                            value={seasonCast?.seasonId || undefined}
                          >
                            {seasonsOnSystem?.map(
                              ({ indexId, season }, index) => {
                                if (index === seasonCastIndex) {
                                  return (
                                    <Select.Option key={index} value={indexId}>
                                      {season}
                                    </Select.Option>
                                  );
                                }
                              }
                            )}
                          </Select>
                        </div>
                        {addSeriesErrors[3]?.seasons[seasonCastIndex]
                          .hasError && (
                          <div className="episode-errors">
                            {addSeriesErrors[3]?.seasons[seasonCastIndex].error}
                          </div>
                        )}
                      </Col>
                      <Col span={10}>
                        <div className="new-actor__name new-category  select-genre">
                          <label className="view-tvshow-lbl" htmlFor="">
                            Cast of this season
                          </label>
                          <Select
                            placeholder="Choose Casts of this season"
                            mode="multiple"
                            maxTagCount={2}
                            maxTagTextLength={8}
                            className="search__searchField py-1 wd-100"
                            onChange={(castsIds) =>
                              handleChangeCasts(castsIds, seasonCastIndex)
                            }
                            value={seasonCast?.castIds || undefined}
                            dropdownStyle={dropdownStyle}
                            options={allActorsOnSystem}
                            filterOption={(input, option) =>
                              (option?.label ?? "")
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                            filterSort={(optionA, optionB) =>
                              (optionA?.label ?? "")
                                .toLowerCase()
                                .localeCompare(
                                  (optionB?.label ?? "").toLowerCase()
                                )
                            }
                          />
                        </div>
                        {addSeriesErrors[4]?.casts[seasonCastIndex]
                          .hasError && (
                          <div className="episode-errors">
                            {addSeriesErrors[4]?.casts[seasonCastIndex].error}
                          </div>
                        )}
                      </Col>
                      <Col span={4}>
                        <div className="mrg-xe-20">
                          <label htmlFor=""></label>
                          <Button
                            className="delete-video-button"
                            onClick={() =>
                              handleDeleteSeasonCasts(
                                seasonCast,
                                seasonCastIndex
                              )
                            }
                            disabled={
                              seasonCastIndex === 0 || mode === "edit"
                                ? true
                                : false
                            }
                            // disabled={
                            //   addSeries[0]?.seasonsCast?.length === 1
                            //     ? true
                            //     : false
                            // }
                          >
                            Delete
                          </Button>
                        </div>
                      </Col>
                    </Fragment>
                  )
                )}
              </Row>
              <Row justify="end">
                <Col>
                  <SecondaryPlusButton
                    buttonName={"Add Another Season"}
                    action={() => handleAddAnotherSeason()}
                  />
                </Col>
              </Row>
              <Row gutter={[24, 24]} className="mrg-ye-20">
                <Col span={24}>
                  <div className="upload-files ">
                    <div className="upload-files__container ">
                      {addSeries[0]?.imgPath && (
                        <figure>
                          <img
                            className="upload-files__upload-file"
                            src={baseUrl + addSeries[0]?.imgPath}
                            alt="actorImage"
                            style={{
                              position: "absolute",
                              zIndex: "3",
                              width: "100%",
                            }}
                          />
                        </figure>
                      )}
                      <DraggerUploader
                        aspect={2 / 1}
                        withCrop
                        formDataType={"img"}
                        acceptedTypes={"image/*"}
                        setShowProgressBar={setShowProgressBar}
                        setProgressPercent={setProgressPercent}
                        setFilePath={handleSetFilePath}
                      >
                        {!addSeries[0]?.imgPath ? (
                          <div
                            role="button"
                            className="upload-files__button  "
                            style={{
                              boxShadow: "none",
                              backgroundColor: "transparent",
                              justifyContent: "center",
                              gap: "30px",
                              margin: "auto",
                            }}
                          >
                            <div className="upload-files__icon">
                              <img src={uploadButton} alt="" />
                            </div>
                            <div className="upload-files__title">
                              {"Upload Image"}
                            </div>
                            <div className="upload-files__icon">
                              <img src={uploadImageIcon} alt="" />
                            </div>
                          </div>
                        ) : (
                          <div className="upload-edit-icon mrg-x-20">
                            <img role="button" src={editIcon} alt="edit" />
                          </div>
                        )}
                      </DraggerUploader>
                    </div>
                    {addSeriesErrors[5]?.hasError && (
                      <div className="episode-errors">
                        {addSeriesErrors[5]?.error}
                      </div>
                    )}
                  </div>
                  {showProgressBar && <Progress percent={progressPercent} />}
                </Col>
              </Row>
              <Row className="mrg-ye-20">
                <Col span={24} md={12}>
                  <div className="new-actor__name new-category ">
                    <label className="view-tvshow-lbl" htmlFor="">
                      Number of Episodes that you want to upload
                    </label>
                    <Input
                      placeholder="Enter number of episodes"
                      type="number"
                      className="search__searchField py-2 "
                      onChange={handleChangeNumOfEpisodes}
                      value={numOfEpisodes}
                    />
                  </div>
                </Col>{" "}
              </Row>
              <Row justify="end">
                <Col>
                  <PrimaryButton
                    type={"submit"}
                    buttonName={mode === "edit" ? "Save" : "Add"}
                    loading={loading}
                  />
                </Col>
              </Row>
              <Divider
                style={{
                  backgroundColor: "#CEE2F6",
                  height: "2px",
                  opacity: 0.5,
                }}
              />
            </Form>
            {isSeriesAdded && (
              <AddEpisodesM3u8
                headerAddedId={seriesAddedId}
                genreId={genreId}
                genreName={genreName}
                numOfEpisodes={episodesCount}
                updateNumOfEpisodes={handleUpdateNumOfEpisodes}
              />
            )}
          </div>
          {/* </Container> */}
        </div>
      </div>
    </section>
  );
}

export default AddNewHeaderM3u8;
