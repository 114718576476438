import { Col, Form, Input, Modal, Row, Space } from "antd";
import PrimaryButton from "../SubComponents/Buttons/PrimaryButton";
import SecondaryButton from "../SubComponents/Buttons/SecondaryButton";
import Select from "antd/es/select";
import { useEffect, useState } from "react";
import { dropdownStyle } from "helpers/helpfulFunctions";
import { getAllPackages } from "Network";
import { toastError } from "helpers/toasters";

function AddCodeModal({
  isModalVisible,
  SubHeader,
  primaryBtnName,
  secondaryBtnName,
  onCancel,
  action,
  isModalLoading,
}) {
  const [prefex, setPrefex] = useState(null);
  const [allPackages, setAllPackages] = useState([]);
  const [isCodeError, setIsCodeError] = useState(false);
  const [isQuantityError, setIsQuantityError] = useState(false);
  const [packageId, setPackageId] = useState(null);
  const [quantity, setQuantity] = useState("");
  const [isPackageError, setIsPackageError] = useState(false);
  const [price, setPrice] = useState(null);
  const [isPriceError, setIsPriceError] = useState(false);
  const activePackages = allPackages.filter(
    (packagee) => packagee.isActive === true,
  );

  useEffect(() => {
    setIsCodeError(false);
    setIsPackageError(false);
    setIsQuantityError(false);
    setPrefex(null);
    setPackageId(null);
    setPrice(null);
    setQuantity(null);
  }, [isModalVisible]);

  useEffect(() => {
    const getPackagesOnSystem = async () => {
      getAllPackages(
        (success) => {
          if (success?.isSuccess) {
            setAllPackages(success.data);
          } else {
            toastError(success.err_msg);
          }
        },
        (fail) => {
          if (fail?.data) {
            toastError(fail.data?.err_msg);
          } else {
            toastError();
          }
        },
      );
    };
    getPackagesOnSystem();
  }, []);
  const handleChangeQuantity = (e) => {
    const quantityValue = e.target.value.trim(); // Remove leading/trailing whitespace

    if (quantityValue === "") {
      setQuantity("");
      setIsQuantityError("Please enter the quantity");
    } else if (!/^\d+(\.\d+)?$/.test(quantityValue)) {
      setQuantity(quantityValue);
      setIsQuantityError("Please enter a valid number for quantity");
    } else {
      const floatValue = parseFloat(quantityValue);
      if (Number.isInteger(floatValue) && floatValue > 0) {
        setQuantity(floatValue);
        setIsQuantityError(false);
      } else {
        setQuantity(quantityValue);
        setIsQuantityError("Please enter a valid number for quantity");
      }
    }
  };
  const handleChangeCode = (e) => {
    const inputValue = e.target.value.slice(0, 3); // Limit input to first 3 characters
    const filteredValue = inputValue.replace(/[^a-zA-Z0-9]/g, ""); // Filter out special characters

    setPrefex(filteredValue); // Set the filtered uppercase value to state
    setIsCodeError(filteredValue.length !== 3); // Set error if the length is not 3 characters
  };

  const handleChangePackage = (packageId) => {
    setPackageId(packageId);
    if (packageId) {
      setIsPackageError(false);
    } else {
      setIsPackageError(true);
    }
  };
  const handleChangePrice = (e) => {
    const priceValue = +e.target.value;
    if (!isNaN(priceValue) && priceValue > 0) {
      setPrice(priceValue);
      setIsPriceError(false);
    } else {
      setPrice(priceValue);
      setIsPriceError(true);
    }
  };

  const validateForm = async () => {
    let arrHasError = [];
    if (!prefex) {
      setIsCodeError(true);
      arrHasError.push("codeError");
    }
    if (!Number.isInteger(quantity) || quantity <= 0) {
      // Check if quantity is not an integer or is less than or equal to 0
      setIsQuantityError(true);
      arrHasError.push("َQuantityError");
    }
    if (price !== null && price <= 0) {
      setIsPriceError(true);
      arrHasError.push("priceError");
    }
    if (!packageId) {
      setIsPackageError(true);
      arrHasError.push("packageError");
    }
    if (arrHasError?.length === 0) {
      let payload = {
        packageId,
        prefex,
        price,
        quantity,
      };
      action(payload);
    }
  };

  const handleOnFinish = async (values) => {
    await validateForm();
  };

  return (
    isModalVisible && (
      <Modal
        title={SubHeader}
        open={isModalVisible}
        className='msg-modal text-center'
        footer={false}
        closable={false}
        onCancel={onCancel}
      >
        <Form className='edit-actor__content  ' onFinish={handleOnFinish}>
          <Row gutter={[24, 30]}>
            <Col span={24}>
              <div className='new-actor__name new-category mb-5'>
                <label className='view-tvshow-lbl mb-2' htmlFor=''>
                  Prefix Code
                </label>
                <Input
                  type='text'
                  className={`search__searchField py-2 ${
                    isCodeError ? "bord-red" : ""
                  }`}
                  placeholder='Code'
                  value={prefex}
                  onChange={handleChangeCode}
                />
                {isCodeError && (
                  <div className='episode-errors'>
                    {/* {"The Code should be 3 character"} */}
                    {"Please enter the code"}
                  </div>
                )}
              </div>
            </Col>
            <Col span={24}>
              <div className='new-actor__name new-category mb-5'>
                <label className='view-tvshow-lbl mb-2' htmlFor=''>
                  Quantity
                </label>
                <Input
                  type='number'
                  className={`search__searchField py-2 ${
                    isQuantityError ? "bord-red" : ""
                  }`}
                  placeholder='Enter the quantity' // Ensure this placeholder is updated
                  value={quantity !== null ? quantity : ""}
                  onChange={handleChangeQuantity}
                />

                {isQuantityError && (
                  <div className='episode-errors'>
                    {"you should enter valid quantity"}
                  </div>
                )}
              </div>
            </Col>

            <Col span={24}>
              <div className='new-actor__name new-category mb-5'>
                <label className='view-tvshow-lbl mb-2' htmlFor=''>
                  Price
                </label>
                <Input
                  type='number'
                  className={`search__searchField py-2 ${
                    isPriceError ? "bord-red" : ""
                  }`}
                  placeholder='Enter the price'
                  min='0'
                  value={price !== null ? price : ""}
                  onChange={handleChangePrice}
                  suffix='LE'
                />

                {isPriceError && (
                  <div className='episode-errors'>
                    {"The price should be more than 0 "}
                  </div>
                )}
              </div>
            </Col>
            <Col span={24}>
              {" "}
              <div className='new-actor__name new-category  select-genre'>
                <label className='view-tvshow-lbl mb-2' htmlFor=''>
                  Choose Package
                </label>
                <Select
                  className={`search__searchField py-1 wd-100 ${
                    isPackageError ? "bord-red" : ""
                  }`}
                  placeholder='choose package'
                  dropdownStyle={dropdownStyle}
                  allowClear
                  value={packageId}
                  onChange={handleChangePackage}
                >
                  {activePackages?.map((pkg, index) => {
                    return (
                      <Select.Option value={pkg?.id} key={index}>
                        {pkg?.packageName}
                      </Select.Option>
                    );
                  })}
                </Select>
                {isPackageError && (
                  <div className='episode-errors'>
                    {"Please Choose Package"}
                  </div>
                )}
              </div>
            </Col>
          </Row>
          <Row className='mrg-ys-30 mrg-x-auto'>
            <Space size={20}>
              <SecondaryButton
                buttonName={secondaryBtnName}
                onClick={onCancel}
              />
              <PrimaryButton
                buttonName={primaryBtnName}
                loading={isModalLoading}
                type='submit'
              />
            </Space>
          </Row>
        </Form>
      </Modal>
    )
  );
}

export default AddCodeModal;
