import React, { useState, useEffect } from "react";
import { Row, Col, Input } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { getUserAccountsDataWithId } from "Network";
import Header from "../SubComponents/Header";
import SubHeader from "../SubComponents/SubHeader";
import { toastError } from "helpers/toasters";
import { baseUrl } from "services";
import ROUTES from "constants/NewRoutes";
import SecondaryButton from "modules/SubComponents/Buttons/SecondaryButton";
import { addTokenToMedia } from "helpers/addTokenToMedia";

function UsersInformation() {
  const [userAccountData, setUserAccountData] = useState([]);
  const [kidId, setKidId] = useState("");
  const [kidName, setKidName] = useState("");
  const [kidAge, setKidAge] = useState("");
  const location = useLocation();
  const { selectedPage, searchValue } = location?.state;
  const navigate = useNavigate();
  const userId = location.state?.userId;

  useEffect(() => {
    if (location?.state?.userId) {
      getUserAccountsDataWithId(
        { userId },
        (success) => {
          if (success?.isSuccess) {
            setUserAccountData(success?.data);
          }
        },
        (fail) => {
          toastError(fail.data.err_msg);
        },
      );
    } else {
      navigate(ROUTES.USERS, {
        state: { currentPage: selectedPage },
      });
    }
    //eslint-disable-next-line
  }, [location?.state?.userId, userId, navigate]);

  // const parent = userAccountData?.filter((user) => user?.isParent === true)[0];
  const parent = userAccountData[0];
  const handleClickBackButton = () => {
    navigate(ROUTES.USERS, {
      state: { currentPage: selectedPage, searchVal: searchValue },
    });
  };
  const handleKidOnClick = (kidId, kidName, kidAge) => {
    setKidId(kidId);
    setKidName(kidName);
    setKidAge(kidAge);
  };

  return (
    <section className='users '>
      <div className='users__all-contents '>
        <div className='users__content'>
          <div className='users__usersData'>
            <Header title='Users' />
            <SubHeader bgColor='#4D5157' title='Parent information' />
            <div className='users__parent-data '>
              <figure className='users__parent-figure '>
                <img
                  className='users__parent-image'
                  src={addTokenToMedia(baseUrl + parent?.imgPath)}
                  alt=''
                />
              </figure>
              <div className='users__parent-info '>
                <Row gutter={[16, 16]}>
                  <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                    <label className='users__parent-info-label' htmlFor=''>
                      Parent name
                    </label>
                    <Input
                      type='text'
                      className='search__searchField userData-input py-2'
                      readOnly
                      value={parent?.name}
                    />
                  </Col>
                  <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                    <label className='users__parent-info-label' htmlFor=''>
                      Email
                    </label>
                    <Input
                      type='text'
                      className='search__searchField userData-input py-2'
                      readOnly
                      value={parent?.email}
                    />
                  </Col>
                  <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                    <label className='users__parent-info-label' htmlFor=''>
                      Mobile number
                    </label>
                    <Input
                      type='text'
                      className='search__searchField userData-input py-2'
                      readOnly
                      value={location?.state?.record?.phone}
                    />
                  </Col>

                  <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                    <label className='users__parent-info-label' htmlFor=''>
                      Date created
                    </label>
                    <Input
                      type='text'
                      className='search__searchField userData-input py-2'
                      readOnly
                      value={parent?.creationDate}
                    />
                  </Col>
                  <Col span={24}>
                    <label className='users__parent-info-label' htmlFor=''>
                      Pricing plan
                    </label>
                    <Input
                      type='text'
                      className='search__searchField userData-input py-2'
                      readOnly
                      value={`${
                        parent?.pricingPlan?.packages?.length > 0
                          ? parent.pricingPlan.packages.join(",") +
                            ", free days : " +
                            parent.pricingPlan.freeDays
                          : "No Packages"
                      }`}
                    />
                  </Col>
                </Row>
              </div>
            </div>
            <SubHeader bgColor='#4d5157' title='Kids information' />
            <div className='users__kids-data mb-5 '>
              <div className='users__all-kids  '>
                {userAccountData
                  ?.filter((kids) => kids?.isParent === false)
                  .map((kid, index) => (
                    <div className='users__kids-fig-container  ' key={index}>
                      <figure className='users__kids-figure  '>
                        <img
                          id={index}
                          className='users__kids-image'
                          src={addTokenToMedia(baseUrl + kid?.imgPath)}
                          alt={kid?.name}
                          onClick={() =>
                            handleKidOnClick(kid?._id, kid?.name, kid?.age)
                          }
                        />
                      </figure>
                      <label
                        onClick={() =>
                          handleKidOnClick(kid?._id, kid?.name, kid?.age)
                        }
                        className={`users__kids-info-label kid-name mb-3 ${
                          kidId === kid._id ? "is-active" : ""
                        }`}
                      >
                        {kid?.name}
                      </label>
                    </div>
                  ))}
              </div>
              <div className='users__kids-info '>
                <Row className='users__kids-info-row'>
                  <Col span={11}>
                    <label className='users__kids-info-label' htmlFor=''>
                      Kid name
                    </label>
                    <Input
                      type='text'
                      className='search__searchField py-2'
                      value={kidName}
                      readOnly
                    />
                  </Col>
                  <Col span={11}>
                    <label className='users__kids-info-label' htmlFor=''>
                      Age
                    </label>
                    <Input
                      type='text'
                      className='search__searchField py-2'
                      value={kidAge}
                      readOnly
                    />
                  </Col>
                </Row>{" "}
                <Row justify='end' className=' wd-100 pad-x-20 mrg-y-30'>
                  <Col>
                    <SecondaryButton
                      buttonName='Back'
                      onClick={handleClickBackButton}
                    />
                  </Col>
                </Row>
              </div>
            </div>
          </div>
          {/* </Container> */}
        </div>
      </div>
    </section>
  );
}

export default UsersInformation;
