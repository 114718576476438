import React, { Fragment } from "react";
import { Button } from "antd";

function SecondaryButton({
  buttonName,
  onClick,
  loading,
  btnWidth,
  id,
  disableMe,
}) {
  return (
    <Fragment>
      <Button
        loading={loading}
        onClick={onClick}
        className="secondary-popup-button"
        style={{ width: btnWidth }}
        id={id}
        disabled={disableMe ? disableMe : false}
      >
        {buttonName}
      </Button>
    </Fragment>
  );
}

export default SecondaryButton;
